/* eslint-disable sort-keys,react/destructuring-assignment */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Container, Form, Grid, Header, Segment } from 'semantic-ui-react';

import '../css/LTIPlayerLauncher.less';

import { SatCoreComponent, SatCoreRegister, getRegisteredClass } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import { ASSIGNMENT_STATUS, ASSIGNMENT_TYPE } from '../managers/AssignmentManager';
import { CONTENT_MODE, PLAYER_TYPES } from '../managers/ContentManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import AssignmentService from '../services/AssignmentService';
import DynamicSatelliteService from '../services/DynamicSatelliteService';
import ImageService from '../services/ImageService';
import InitService from '../services/InitService';

export default
@inject(
  'assignmentManager', 'classroomManager', 'contentManager', 'courseManager',
  'dynamicSatelliteManager', 'navigationManager', 'productManager', 'userManager')
@observer
class LTIPlayerLauncher extends Component {
  constructor(props) {
    super(props);
    this.state = { message: false, errorMessage: null };

    this.DocReaderModal = SatCoreComponent('DocReaderModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
    this.LearnosityPlayerModal = SatCoreComponent('LearnosityPlayerModal');
    this.LearnosityScoringModal = SatCoreComponent('LearnosityScoringModal');
    this.Logo = SatCoreComponent('Logo');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');

    this.state = {
      learnosityPlayerShowing: false,
      learnosityScoringPlayerShowing: false,
      lessonPlayerShowing: false,
      fileViewerShowing: false,
      docreaderViewerShowing: false,
      activityInstanceId: null,
      activityId: null,
      studentName: null,
      contentName: null,
      contentSubtitle: null,
      contentItemType: null,
      contentItemId: null,
      contentImageUrl: null,
      resourceName: null,
      disablePresent: false,
      errorMessage: null,
      loaded: false
    };
  }

  async componentDidMount() {
    const { assignmentManager, navigationManager, productManager } = this.props;
    navigationManager.setView(VIEW_SELECTION.LOGIN);
    productManager.setIsFromProduct(true);
    assignmentManager.isLtiLaunched = true;

    const { dynamicSatelliteManager } = this.props;

    if (dynamicSatelliteManager.isDynamicSatellite && !Auth.publisherSatelliteCode) {
      await DynamicSatelliteService.fetchDynamicSatelliteByDomain();
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 500);
    } else {
      this.setState({ loaded: true });
    }

    // look for verification ID in URL params
    const urlParams = new URLSearchParams(this.props.location.search);
    let paramData = null;
    let activityId = null;
    let activityInstanceId = null;
    let authKey = null;
    let contentItemId = null;
    let contentItemEntityTypeId = null;
    let contentMode = null;
    let courseResourceElementId = null;
    let error = '';
    let isTeacher = null;
    let onClose = null;
    let status = null;

    // get params
    if (urlParams.has('error') && urlParams.get('error') !== 'null') {
      error = urlParams.get('error');
    }

    if (urlParams.has('authKey') && urlParams.get('authKey') !== 'null') {
      authKey = urlParams.get('authKey');
    }

    if (urlParams.has('ciid') && urlParams.get('ciid') !== 'null') {
      contentItemId = urlParams.get('ciid');
    }

    if (urlParams.has('creid') && urlParams.get('creid') !== 'null') {
      courseResourceElementId = urlParams.get('creid');
    }

    if (urlParams.has('status') && urlParams.get('status') !== 'null') {
      status = urlParams.get('status');
    }

    if (urlParams.has('aiid') && urlParams.get('aiid') !== 'null') {
      activityInstanceId = urlParams.get('aiid');
    }

    if (urlParams.has('aid') && urlParams.get('aid') !== 'null') {
      activityId = urlParams.get('aid');
    }

    if (urlParams.has('otype') && urlParams.get('otype') !== 'null') {
      contentItemEntityTypeId = urlParams.get('otype');
    }

    if (urlParams.has('mode') && urlParams.get('mode') !== 'null') {
      contentMode = urlParams.get('mode');
    }

    if (urlParams.has('isStudent') && urlParams.get('isStudent') !== 'null') {
      isTeacher = urlParams.get('isStudent') === 'true';
    } else {
      isTeacher = true;
    }

    // TODO - not sure what to do with this for now.
    // will this be anything but "exit"?
    if (urlParams.has('onClose')) {
      onClose = urlParams.get('onClose');
    }

    paramData = {
      contentItemId,
      courseResourceElementId,
      status,
      activityInstanceId,
      activityId,
      contentItemEntityTypeId,
      contentMode,
      isTeacher
    };

    if (error) {
      this.verificationError('Notice', error);
    } else if (authKey) {
      this.playerLogIn(authKey, paramData);
    } else {
      this.verificationError('Error', 'Not Authorized');
    }
  }

  // satellite Branded Error panel
  failPanel = () => {
    const { errorTitle, errorMessage } = this.state;
    const { Logo } = this;
    return (
      <Grid className='fullHeight' textAlign='center' verticalAlign='middle'>
        <Grid.Column className='max-width-558'>
          <Form>
            <Header as='h2' attached='top' block>
              <div className='message-header'>
                <Logo />
                <div>{errorTitle}</div>
              </div>
            </Header>
            <Segment attached>
              <Form.Field>
                <Container className='login-error-message' text>
                  {errorMessage}
                </Container>
                {/* <div className='panel-body'>
                  <Button id='home-btn' basic primary  onClick={this.navToLogin}>Retry Login</Button>
                </div> */}
              </Form.Field>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }

  verificationError = (errorTitle, errorMessage) => {
    this.setState({ errorTitle, errorMessage });
  }

  // This has some non-learnosity stuff in it for now.  That could be stripped out but leaving in case we expand
  // this to work for Lessons and PDF/Flowpaper.
  handlePresent = async (params) => {
    const { contentManager, userManager, assignmentManager, classroomManager, courseManager, tagContentManager } = this.props;
    const { assignment } = params;
    let option = null;
    if (assignment.contentMode === CONTENT_MODE.PREVIEW) {
      const alternateModeId = assignment.alternateModeIdOverride;
      if (userManager.isStudent) {
        option = await contentManager.getOptionsForStudentPreview(
          assignment.contentItemId, assignment.contentItemEntityTypeId, assignment.pdfDeliveryFormat,
          window.location.origin, userManager.isTeacher, userManager.userId, assignment.courseResourceElementId = null,
          assignment.deliveryMode = '', classroomManager.currentClassroomId, alternateModeId);
      } else {
        option = await contentManager.getOptionsForTeacherPreviewAssigned(assignment, window.location.origin,
          userManager.isTeacher, userManager.userId, alternateModeId);
      }
    } else if (userManager.isTeacher && assignment.contentMode === CONTENT_MODE.PRESENT) {
      const today = new Date();
      const todayWithBuffer = new Date();
      todayWithBuffer.setHours(today.getHours() + 1);
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      await assignmentManager.createAssignment(assignment.contentItemId, assignment.courseResourceElementId,
        assignment.classroomId, courseManager.currentCourseId, todayWithBuffer, tomorrow, '',
        ASSIGNMENT_TYPE.TEACHER, userManager.userId, false, false, false, ASSIGNMENT_STATUS.PRESENTATION, 1);
      const currentAssignment = await assignmentManager.getLastAddedAssignment();

      option = await contentManager.getOptionsForTeacherPresent(currentAssignment.contentItemId, currentAssignment, currentAssignment.entityTypeId, window.location.origin, userManager.isTeacher);
    } else if (userManager.isStudent && assignment.contentMode === CONTENT_MODE.ASSESS) {
      if (this.state.disablePresent) {
        return false;
      }
      this.setState({ disablePresent: true });
      if (assignment.contentMode === CONTENT_MODE.ASSESS) {
        const preStartContentItemId = assignment.contentItemId;
        if (ASSIGNMENT_STATUS.getCheckableStatuses().includes(assignment.status)) {
          const isReady = await assignmentManager.checkActivity(assignment.activityId);
          if (isReady) {
            const isOk = await assignmentManager.startActivity(assignment.activityInstanceId, assignment.activityId);
            if (isOk === false) {
              console.warn('LTIPlayerLauncher: unable to view, start activity failed');
              return false;
            }
          } else {
            this.setState({ disablePresent: false });
            console.warn('LTIPlayerLauncher: unable to view, check activity failed');
            return false;
          }
        }

        const postStartContentItemId = assignment.contentItemId;
        if (preStartContentItemId !== postStartContentItemId && tagContentManager.useContentTags) {
          // this has been versioned. Make sure the image is added.
          // console.log('*** A new version: ' + postStartContentItemId +' has been created from: '+ preStartContentItemId);
          tagContentManager.createNewCardContentTagFromOld(preStartContentItemId, postStartContentItemId);
        }
      }

      option = await contentManager.getOptionsForStudentAssessment(assignment, window.location.origin, userManager.isTeacher, userManager.userId);
    } else if (assignment.contentMode === CONTENT_MODE.REVIEW) {
      if (userManager.isStudent) {
        option = await contentManager.getOptionsForStudentReview(assignment, window.location.origin, userManager.isTeacher, userManager.userId, null);
      } else {
        option = await contentManager.getOptionsForTeacherReviewAndScoring(
          assignment, assignment, window.location.origin, userManager.isTeacher,
          assignment.courseResourceElementId, assignment.activityId
        );
      }
    } else {
      // TODO: put in default and be mindful that this is a shared view (teacher/student).
      console.warn('LTIPlayerLauncher: unable to view, unknown content mode');
      return false;
    }

    const { playerType, viewUrl } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      this.setState({ disablePresent: false });
      return true;
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);
    const contentImageUrl = ImageService.getImageUrl(assignment);

    const {
      resourceWebTitle, resourceWebSubtitle
    } = AssignmentService.getAssignmentTitleAndDescription(assignment);

    this.setState({
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      learnosityScoringPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_SCORING_PLAYER),
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      contentUrl: viewUrl,
      contentName: resourceWebTitle,
      contentSubtitle: resourceWebSubtitle,
      activityId: assignment.activityId,
      activityInstanceId: assignment.activityInstanceId,
      contentItemId: assignment.contentItemId,
      previewContentType: assignment.contentItemEntityTypeId,
      previewContentItemId: assignment.activityInstanceId,
      resourceName: assignment.name,
      instruction: assignment.instruction ? assignment.instruction : assignment.activityInstruction ? assignment.activityInstruction : null,
      contentImageUrl,
      contentMode: assignment.contentMode,
      studentName: (assignment.studentName) ? assignment.studentName : '',
      grade: (assignment.grade) ? assignment.grade : 0,
      maxScore: (assignment.maxScore) ? assignment.maxScore : 100,
      totalScore: (assignment.totalScore) ? assignment.totalScore : 0,
      isFlowpaper: assignment.isFlowpaper,
    });
    return true;
  }

  hideIframeFromOuterClick = (event) => {
    if (event.data === 'hideIframe' || event.data === 'closePressed') {
      this.hideModal();
    }
  }

  playerLogIn = async (authKey, paramData) => {
    const { courseManager, assignmentManager, navigationManager, userManager } = this.props;
    let loginResult = null;
    if (authKey) {
      InitService.doLogoutCleanUp('', { authKey });
      loginResult = await userManager.authKeyLogIn(authKey);
    } else {
      this.verificationError('Error', 'No Authorization Provided');
    }
    if (loginResult) {
      // If user has multiple roles, the active permission id doesn't get set.
      const { activePermissionId } = userManager;
      if (!activePermissionId) {
        const UserService = getRegisteredClass('UserService');
        const roles = UserService.getAvailableRoles();
        if (roles.includes('teacher_user')) {
          userManager.setActivePermissionId('teacher_user');
        } else if (roles.length > 0) {
          // TODO: Should possibly set a specific role depending on the operation being performed.
          userManager.setActivePermissionId(roles[0]);
        }
      }

      navigationManager.setView('assignments');

      // Get activity/instance data if applicable or content data otherwise
      let resource = null;
      let assignment = null;
      let courseResourceElement = null;
      if (paramData.activityInstanceId) {
        assignment = await assignmentManager.fetchActivityInstance(paramData.activityInstanceId);
        if (assignment) {
          const user = await userManager.fetchUser(assignment.userId);
          if (user) {
            assignment.studentName = `${user.firstName} ${user.lastName}`;
          }
        }
      } else if (paramData.activityId) {
        assignment = await assignmentManager.fetchActivity(paramData.activityId);
      }

      if (paramData.courseResourceElementId) {
        courseResourceElement = await courseManager.fetchCourseResourceElement(paramData.courseResourceElementId);
      }

      // if we have an assignment or activity instance, then use that, otherwise use the CRE
      // merge param data to ensure we have everything we need.
      if (assignment) {
        // do this so null values in paramData won't overwrite valid values in assignment
        const pData = Object.fromEntries(Object.entries(paramData).filter(([_, v]) => v != null));

        resource = { ...assignment, ...pData };
      } else if ( courseResourceElement && paramData) {
        resource = { ...courseResourceElement, ...paramData };
        resource.contentItemId = courseResourceElement.entityTypeId === 'content_item' ? courseResourceElement.entityId : '';
        if(assignment) {
          resource.courseContentItemId = assignment.courseContentItemId;
        } else {
          resource.courseContentItemId = null;
        }
      }
      let result = null;
      if (resource) {
        result = this.handlePresent({ assignment: resource });
      }

      if (!result) {
        this.verificationError('Error', 'There was a problem providing the content.');
      }
    } else {
      this.verificationError('Error', 'Not Authorized');
    }
  };

  hideModal = () => {
    this.setState({
      lessonPlayerShowing: false,
      learnosityPlayerShowing: false,
      learnosityScoringPlayerShowing: false,
      fileViewerShowing: false, docreaderViewerShowing: false,
      errorTitle: 'Assignment Exited',
      errorMessage: 'You have closed this assignment!',
    });

    // determine if in iframe or new tab window
    if (window.self !== window.top) {
      // post message to parent to close iframe
      window.parent.postMessage({
        type: 'lti',
        custom: {}
      }, '*');
    } else {
      // close tab window
      window.parent.close();
    }
  }

  renderLessonPlayer = () => {
    const { userManager } = this.props;
    const FullscreenModal = SatCoreComponent('FullscreenModal');
    const hideIframe = (this.hideIframe) ? this.hideIframe : () => {};
    return (
      <div className='course-content'>
        <FullscreenModal
          className={'lesson-player'}
          closeIframeCallback={hideIframe}
          contentImageUrl={this.state.contentImageUrl}
          contentName={this.state.contentName}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderFileViewer = () => {
    const { userManager } = this.props;
    const { FileViewerModal } = this;
    return (
      <div className='course-content'>
        <FileViewerModal
          assignmentId={this.state.activityInstanceId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          grade={this.state.grade}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isFromLTI={true}
          isTeacher={userManager.isTeacher}
          maxScore={this.state.maxScore}
          page='file-viewer'
          resourceName={this.state.resourceName}
          studentName={this.state.studentName}
          totalScore={this.state.totalScore}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { userManager, contentManager } = this.props;
    const { DocReaderModal } = this;
    if (this.props.renderDocreaderViewer !== undefined) {
      return this.props.renderDocreaderViewer();
    }

    return (
      <div className='course-content'>
        <DocReaderModal
          assignmentId={this.state.activityInstanceId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          grade={this.state.grade}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isFromLTI={true}
          isTeacher={userManager.isTeacher}
          maxScore={this.state.maxScore}
          page='docreader-viewer'
          resourceName={this.state.resourceName}
          sessionId={contentManager.currentflowPaperSessionId}
          studentName={this.state.studentName}
          totalScore={this.state.totalScore}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderLearnosityPlayer = () => {
    const { userManager, history } = this.props;
    const { LearnosityPlayerModal } = this;
    return (
      <div className='course-content'>
        <LearnosityPlayerModal
          assignmentId={this.state.activityInstanceId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentItemId={this.state.contentItemId}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentSubtitle={this.state.contentSubtitle}
          history={history}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-player'
          resourceName={this.state.resourceName} />
      </div>
    );
  }

  renderLearnosityScoringModal = () => {
    const { userManager } = this.props;
    const { LearnosityScoringModal } = this;
    return (
      <div className='course-content'>
        <LearnosityScoringModal
          activityInstanceId={this.state.activityInstanceId}
          assignmentId={this.state.activityId}
          canManageScores={userManager.canManageLearnosityScores}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentItemId={this.state.contentItemId}
          contentSubTitle={this.state.contentItemDescription}
          contentTitle={this.state.contentName}
          grade={this.state.grade}
          history={this.props.history}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-scoring'
          resourceName={this.state.resourceName}
          studentName={this.state.studentName} />
      </div>
    );
  }

  render() {
    const { SatCoreLoader } = this;
    if (!this.state.loaded) {
      return <SatCoreLoader />;
    } else if (this.state.learnosityPlayerShowing) {
      return this.renderLearnosityPlayer();
    } else if (this.state.learnosityScoringPlayerShowing) {
      return this.renderLearnosityScoringModal();
    } else if (this.state.lessonPlayerShowing) {
      return (this.renderLessonPlayer());
    } else if (this.state.fileViewerShowing) {
      return (this.renderFileViewer());
    } else if (this.state.docreaderViewerShowing) {
      return (this.renderDocreaderViewer());
    } else if (this.state.errorMessage) {
      return (
        <div className='lrn-player-launcher-container'>
          {this.failPanel()}
        </div>
      );
    }
    return null;
  }
}

SatCoreRegister('LTIPlayerLauncher', LTIPlayerLauncher);
