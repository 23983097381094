/* eslint-disable sort-keys */
export const GRADEBOOK_ENDPOINTS = {
  FETCH_AGGREGATE_GRADEBOOK_DATA: '/api/viewAggregateGradebook',
  FETCH_AGGREGATE_GRADEBOOK_DETAILS_LIKERT: '/api/viewAggregateGradebookLikertDetail',
  FETCH_AGGREGATE_GRADEBOOK_DETAILS_NORMAL: '/api/viewAggregateGradebookDetail',
  FETCH_AGGREGATE_GRADEBOOK_DETAILS_TYPING: '/api/viewAggregateGradebookTypingDetail',
  FETCH_AGGREGATE_GRADEBOOK_STANDARDS: '/api/viewAggregateGradebookStandards',
  FETCH_ASSIGNMENTS_PROCESSING_COUNT: '/api/getAssignmentsProcessingCount',
  FETCH_GRADEBOOK_ACTIVITY: '/api/viewActivityGradebook',
  FETCH_GRADEBOOK_DETAILS: '/api/viewActivityGradebookDetail',
  SUBMIT_REPORT_REQUEST: '/api/submitReportRequest',
  UPDATE_ACTIVITY_ELEMENT_AGGREGATE_EXCLUSION: '/api/setAggregateElementExclusion',
  UPDATE_ACTIVITY_ELEMENT_EXCLUSION: '/api/setActivityElementExclusion',
  UPDATE_GRADE: '/api/updateActivityInstanceGrade'
};

export const GRADEBOOK_CONTEXT = {
  SUMMARY: 'summary',
  DETAILS: 'details',
  LIKERT: 'likert',
  TYPING: 'typing',
  ENGAGEMENT: 'engagement',
  STANDARDS: 'standards'
};

export const GRADEBOOK_TABLE_SORT_DEFAULT_ENTRIES = [
  [GRADEBOOK_CONTEXT.SUMMARY, {
    sortColumn: null,
    sortDirection: 'ascending',
  }],
  [GRADEBOOK_CONTEXT.DETAILS, {
    sortColumn: null,
    sortDirection: 'ascending',
    sortScoreIndex: -1
  }],
  [GRADEBOOK_CONTEXT.LIKERT, {
    sortColumn: null,
    sortDirection: 'ascending',
    sortScoreIndex: -1
  }],
  [GRADEBOOK_CONTEXT.TYPING, {
    sortColumn: null,
    sortDirection: 'ascending',
    sortScoreIndex: -1
  }]
];

export const GRADEBOOK_TYPE = {
  AGGREGATE: 'aggregate',
  // NON_AGGREGATE: 'nonAggregate' // defunct
};

export const GRADEBOOK_RESPONSE_CONTENT_STATE = {
  HAS_RESPONSE_CONTENT: 'HasResponseContent',
  NO_RESPONSE_CONTENT: 'NoResponseContent',
};

export const GRADEBOOK_RESPONSE_SUBMITTED_STATE = {
  RESPONSE_NOT_SUBMITTED: 'ResponseNotSubmitted',
  RESPONSE_SUBMITTED: 'ResponseSubmitted',
};

export const GRADEBOOK_SCORE_STATE = {
  AUTO_SCORED: 'AutoScored',
  MANUALLY_OVERRIDEN: 'ManuallyOverridden',
  MANUALLY_SCORED: 'ManuallyScored',
  NOT_SCORED: 'NotScored'
};

export const GRADEBOOK_TABLE_ROWS_MAX_PAGE_SIZE = 9999;
