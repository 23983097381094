import React, { useEffect, useRef } from 'react';
import { Modal, Ref } from 'semantic-ui-react';
import { focusTrap } from '../utils';

const focusModal = () => {
  const modals = document.getElementsByClassName('ui modal');
  modals[modals.length - 1]?.focus();
};

const ModalWrapped = (props) => {
  const { open } = props;
  const modalRef = useRef();
  useEffect(() => {
    if (open) {
      // Timeout to give time for the modal to open completely
      const timer = setTimeout(() => {
        modalRef.current?.querySelector('.ui.modal')?.focus();
      }, 50);
      return () => clearTimeout(timer);
    } else {
      focusModal();
    }
  }, [open, modalRef]);
  useEffect(() => focusModal, []);

  return (
    <Ref innerRef={modalRef}>
      <Modal
        aria-modal='true'
        onKeyDown={(event) => focusTrap(modalRef.current, event)}
        role='dialog'
        tabIndex='0'
        {...props} />
    </Ref>
  );
};

ModalWrapped.defaultProps = Modal.defaultProps;
ModalWrapped.autoControlledProps = Modal.autoControlledProps;
ModalWrapped.Actions = Modal.Actions;
ModalWrapped.Content = Modal.Content;
ModalWrapped.Description = Modal.Description;
ModalWrapped.Dimmer = Modal.Dimmer;
ModalWrapped.Header = Modal.Header;

export default ModalWrapped;
