import React, { Component, Fragment } from 'react';

import { inject, observer } from 'mobx-react';

import { Card, Image, Loader, Popup } from 'semantic-ui-react';

import '../css/CourseListing.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { ELEMENT_TYPE } from '../managers/CourseManager';
import { DIALOG_NAMES } from '../managers/DialogManager';

import GroupService from '../services/GroupService';
import ImageService from '../services/ImageService';
import NavigationService from '../services/NavigationService';
import ResourcePacingService from '../services/ResourcePacingService';

import iconMoveItemDown from '../img/caret-move-down.svg';
import iconMoveItemUp from '../img/caret-move-up.svg';

@inject(
  'assessmentManager',
  'assignmentManager',
  'classroomManager',
  'contentManager',
  'courseDeliveryManager',
  'courseManager',
  'dialogManager',
  'productManager',
  'reportIdentityManager',
  'studentContentCardManager',
  'tagContentManager',
  'userManager')
@observer
class CourseElementList extends Component {
  constructor(props) {
    super(props);
    this.CourseTreeBranch = SatCoreComponent('CourseTreeBranch');
    this.CourseTreeLeaf = SatCoreComponent('CourseTreeLeaf');
    this.PopupButton = SatCoreComponent('PopupButton');
    this.ResourcePacingModal = SatCoreComponent('ResourcePacingModal');
    this.SatCoreLoader = SatCoreComponent('SatCoreLoader');

    this.state = {
      isResourcePacingModalOpen: false,
      publisherModeId: null, // eslint-disable-line react/no-unused-state
      toolbarItem: null
    };
  }

  componentDidMount() {
    const { studentContentCardManager } = this.props;
    if (studentContentCardManager.isLoading) {
      studentContentCardManager.setIsLoading(false);
    }
  }

  componentWillUnmount() {
    const { tagContentManager } = this.props;
    tagContentManager.clearTagContentItemIds();
  }

  handleView = async (courseElement, contentMode, pdfFormatSelected, publisherModeId = null) => {
    const { assignmentManager, isTeacher, dialogManager, handleView } = this.props;
    // TODO remove; causes DEMO-1773
    // if (reportIdentityManager.isReport) {
    //   setSessionStorageItem('shouldCloseCourseElementModalOnUpdate', 'true');
    // }
    const alternateModeId = publisherModeId || courseElement.alternateModeIdOverride;
    if (isTeacher && publisherModeId === null && courseElement.modeOverrideAllowed) {
      // see if we have multiple modes to choose from.
      const modes = await assignmentManager.getAssignmentModes(courseElement.elementId || courseElement.courseResourceElementId);
      // If mode override is allowed and we have multiple, open a modal for the teacher to select a mode for preview
      if (modes && modes.length > 1) {
        dialogManager.setOpenDialog(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL, {
          close: this.closeSelectPreviewThemeModal,
          contentMode,
          courseElement,
          modes,
          open: true,
          pdfFormatSelected,
          setPreviewModeId: this.setPreviewModeId
        });
      }
    } else if (alternateModeId !== null) {
      return handleView(courseElement, contentMode, pdfFormatSelected, alternateModeId);
    } else {
      // eslint-disable-next-line react/destructuring-assignment
      return handleView(courseElement, contentMode, pdfFormatSelected);
    }
  }

  closeSelectPreviewThemeModal = () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL);
  }

  setPreviewModeId = async (courseElement, contentMode, pdfFormatSelected, publisherModeId) => {
    const { dialogManager } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    this.handleView(courseElement, contentMode, pdfFormatSelected, publisherModeId);
    dialogManager.closeDialog(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL);
  };

  handleClickMoveItem = async (courseElement, endIndex, listSize) => {
    const { courseManager } = this.props;
    if (endIndex < 0) {
      endIndex = 0;
    } else if (endIndex > listSize - 1) {
      endIndex = listSize - 1;
    }
    const courseResourceElementId = courseElement.elementId;
    const courseContentItemId = courseManager.currentCourseId;
    const parentElementId = courseManager.currentElementId || courseElement.parentElementId;
    const moveResponse = await courseManager.moveCourseResourceElement(courseContentItemId, parentElementId, courseResourceElementId, endIndex);
    console.log(`Move result: ${moveResponse} In reorder endIndex: ${endIndex} courseResourceElementId: ${courseResourceElementId} courseContentItemId: ${courseContentItemId} parentElementId: ${parentElementId} `);
    this.fetchCourseData();
  }

  renderButton = ({
    basic = false,
    buttonLabel = '',
    disabled = false,
    onClick = () => {},
    popupMsg = undefined,
    primary = true
  } = {}) => {
    const { PopupButton } = this;
    const { t } = this.props;
    return (
      <PopupButton
        basic={basic}
        buttonLabel={t(buttonLabel)}
        disabled={disabled}
        onClick={onClick}
        popupMsg={popupMsg && t(popupMsg)}
        primary={primary} />
    );
  }

  renderTree = () => {
    const {
      history,
      assessmentManager,
      closeContentAssignments,
      currentElement,
      courseElementList,
      courseDeliveryManager,
      courseManager,
      handleAddAssignment,
      handleAdminDetails,
      handleAdminLibraryAssessmentConfirmEdit,
      handleAdminResourceConfirmEdit,
      handleAdminLibraryEdit,
      handleAdminTagsAndStandards,
      handleAdminShowItemUsageExport,
      handleAnswerKey,
      handleOpenInfo,
      handlePresent,
      handleSelfPacedOpen,
      isLoading,
      isTeacher,
      productManager,
      showAdminControls,
      showAdminIcons,
      showBanners,
      showContentAssignments,
      showContentStudentAssignments,
      showTags,
      tagContentManager,
      userManager
    } = this.props;

    const { CourseTreeBranch, CourseTreeLeaf } = this;
    let { currentCourseElementList } = courseManager;
    let unitOrderCount = -1;

    if (courseManager.isCustomCourse()) {
      currentCourseElementList = assessmentManager.cachedAssessmentList;
    } else if (courseElementList.length > 0) {
      currentCourseElementList = courseElementList;
    }

    const showHidden = !userManager.isStudent && courseDeliveryManager.shouldShowHiddenResourcesObservable;

    const shouldUpdateTagContentManagerFromCourses = typeof ImageService.updateTagContentManagerFromCourses !== 'undefined';

    return (
      <>
        {courseManager.allowRibbonTOC && currentElement && GroupService.isGroupResource(currentElement.groupingMode) && !userManager.isStudent && ResourcePacingService.visibilityMode(currentElement) === 'visible_to_students' && !productManager.isFromProduct && (
          <div className='assign-all-ribbon-container'>
            {this.renderButton({
              buttonClassNames: 'btn-assign-all',
              buttonLabel: 'assignAll',
              onClick: () => {
                handleAddAssignment(currentElement, currentElement.imageUrl, {
                  allowMultipleResources: true
                });
              }
            })}
          </div>
        )}
        {currentCourseElementList.map((courseElement, index) => {
          if (shouldUpdateTagContentManagerFromCourses) {
            const contentItemId = courseElement?.contentItemId;
            if (contentItemId && !tagContentManager.tagContentItemIdSet.has(contentItemId)) {
              tagContentManager.addTagId(contentItemId);
            }
          }

          const visibilityMode = ResourcePacingService.visibilityMode(courseElement);

          // Make sure that BreadCrumbsCourseDropdown component have same logic
          const orderNum = (!courseElement.unitHidden) ? ++unitOrderCount : -1;

          if (!(!userManager.isStudent && courseElement.deliveryMode === 'teacher_only')
          && !showHidden && visibilityMode === 'hidden_from_students'
          ) {
            return null;
          }
          const imageUrl = ImageService.getImageUrl(courseElement);
          const elementListLength = currentCourseElementList.length - 1;
          return (
            <Fragment key={`${courseElement.id}-B`}>
              {(ELEMENT_TYPE.isBranchType(courseElement)) ? (
                <CourseTreeBranch
                  callback={this.handleResourcePacingToolbarCallback}
                  courseElement={courseElement}
                  handleAddAssignment={handleAddAssignment}
                  handleClick={this.handleClick}
                  refreshCourseData={this.fetchCourseData}
                  showAdminControls={showAdminControls}
                  showAdminIcons={showAdminIcons}
                  unitOrderCount={orderNum} />
              ) : (
                <CourseTreeLeaf
                  callback={this.handleResourcePacingToolbarCallback}
                  closeContentAssignments={closeContentAssignments}
                  courseElement={courseElement}
                  handleAddAssignment={handleAddAssignment}
                  handleAdminDetails={handleAdminDetails}
                  handleAdminLibraryAssessmentConfirmEdit={handleAdminLibraryAssessmentConfirmEdit}
                  handleAdminLibraryEdit={handleAdminLibraryEdit}
                  handleAdminResourceConfirmEdit={handleAdminResourceConfirmEdit}
                  handleAdminShowItemUsageExport={handleAdminShowItemUsageExport}
                  handleAdminTagsAndStandards={handleAdminTagsAndStandards}
                  handleAnswerKey={handleAnswerKey}
                  handleOpenInfo={handleOpenInfo}
                  handlePresent={handlePresent}
                  handleSelfPacedOpen={handleSelfPacedOpen}
                  handleView={this.handleView}
                  history={history}
                  imageUrl={imageUrl}
                  isLoading={isLoading}
                  isTeacher={isTeacher}
                  refreshCourseData={this.fetchCourseData}
                  showAdminControls={showAdminControls}
                  showAdminIcons={showAdminIcons}
                  showBanner={showBanners}
                  showContentAssignments={showContentAssignments}
                  showContentStudentAssignments={showContentStudentAssignments}
                  showTags={showTags} />
              )}
              {showAdminControls && (
                <div className='move-tree-item-container'>
                  {(index > 0 || (elementListLength > 0 && index === elementListLength)) && (
                    <Popup
                      className='move-icon-popup'
                      content={<div>Move item up.</div>}
                      on='hover'
                      trigger={<Image alt='Move item up' className='move-item-up' onClick={() => this.handleClickMoveItem(courseElement, index - 1, currentCourseElementList.length)} src={iconMoveItemUp} />} />
                  )}
                  {(index < elementListLength) && (
                    <Popup
                      className='move-icon-popup'
                      content={<div>Move item down.</div>}
                      on='hover'
                      trigger={<Image alt='Move item down' className='move-item-down' onClick={() => this.handleClickMoveItem(courseElement, index + 1, currentCourseElementList.length)} src={iconMoveItemDown} />} />
                  )}
                </div>
              )}
            </Fragment>
          );
        })}
      </>
    );
  }

  renderPlaceHolder = () => {
    const { SatCoreLoader } = this;
    const { courseManager } = this.props;
    const { currentCourseElementList } = courseManager;
    if (currentCourseElementList && currentCourseElementList.length) {
      return (
        <>
          <SatCoreLoader height='70vh' />
        </>
      );
    }
  }

  handleClick = async (courseElement) => {
    const { contentManager, courseManager, history } = this.props;
    courseManager.setTreeLoading(true);
    courseManager.courseTreeMap.clear();
    contentManager.contentItems.clear();
    await NavigationService.handleCourseElementClick(courseElement, history);
    courseManager.setTreeLoading(false);
  }

  /** Called when an item from `ResourcePacingToolbar` is clicked */
  handleResourcePacingToolbarCallback = (TOOLBAR_ITEM, currentCourseElement) => {
    this.setState({
      currentCourseElement,
      isResourcePacingModalOpen: true,
      toolbarItem: TOOLBAR_ITEM
    });
  }

  handleCloseResourcePacingModal = async (shouldRefreshCourseData) => {
    if (shouldRefreshCourseData) {
      await this.fetchCourseData();
    }
    this.setState({
      isResourcePacingModalOpen: false,
      toolbarItem: null
    });
  }

  fetchCourseData = async () => {
    const { classroomManager, courseManager, userManager } = this.props;
    const courseId = courseManager.currentCourseId;
    const classroomId = classroomManager.currentClassroomId;
    const elementId = courseManager.currentElementId;
    const force = true;
    const { isStudent } = userManager;
    const skipSetElement = false;
    await courseManager.fetchCourseData(
      courseId, classroomId, elementId, force, isStudent, skipSetElement
    );
  }

  render() {
    const { courseElementList, courseManager, showAdminControls, studentContentCardManager } = this.props;

    const { treeLoading } = courseManager;
    if (treeLoading) {
      return <Loader active />;
    }
    let { currentCourseElementList } = courseManager;

    if (courseElementList.length > 0) {
      currentCourseElementList = courseElementList;
    }

    const { ResourcePacingModal } = this;
    const { currentCourseElement, isResourcePacingModalOpen, toolbarItem } = this.state;
    return (
      <>
        {studentContentCardManager.isLoading &&
          <Loader active />}
        <Card.Group className={showAdminControls ? 'admin' : ''}>
          {
            (courseManager.courseElementListLoading || !currentCourseElementList || !currentCourseElementList.length)
              ? this.renderPlaceHolder()
              : this.renderTree()
          }
        </Card.Group>
        {isResourcePacingModalOpen && (
          <ResourcePacingModal
            isOpen={isResourcePacingModalOpen}
            onClose={this.handleCloseResourcePacingModal}
            resource={currentCourseElement}
            toolbarItem={toolbarItem}
            {...this.props} />
        )}
      </>
    );
  }
}
export default CourseElementList;

SatCoreRegister('CourseElementList', CourseElementList);
