import { attempt, isError } from 'lodash';

/**
* Safely parses a JSON string into an object, returning null if parsing fails
* @param {string} jsonStr - The JSON string to parse
* @returns {object|null} The parsed object if successful, null otherwise
*/
export const safeJsonParse = (jsonStr) => {
  if (!jsonStr) {
    return null;
  }
  const result = attempt(JSON.parse, jsonStr);
  return isError(result) ? null : result;
};

/**
* Safely stringifies a value to JSON, returning null if stringification fails
* @param {*} value - The value to stringify
* @returns {string|null} The JSON string if successful, null otherwise
*/
export const safeJsonStringify = (value) => {
  const result = attempt(JSON.stringify, value);
  return isError(result) ? null : result;
};
