import { registerClass } from '../../SatCoreRegistry';

import { GRADEBOOK_CONTEXT, GRADEBOOK_TYPE } from '../../constants';

import assignmentManager from '../../managers/AssignmentManager';
import classroomManager from '../../managers/ClassroomManager';
import courseManager from '../../managers/CourseManager';
import gradebookManager from '../../managers/GradebookManager';
import navigationManager, { VIEW_SELECTION } from '../../managers/NavigationManager';

export default class GradebookNavigationService {
  static navToAggregateGradebookSummaryFromExternalModal = async (props, activeGradebookTable = 'summary') => {
    let { assignment = null } = props;
    const {
      allAssignmentsLabel,
      assignCardData = null,
      currentClassroomId = null,
      fromAssignments = false,
      history,
      urlParamStr = ''
    } = props;

    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = urlParams.get('classroomId') || currentClassroomId ||
      classroomManager.currentClassroomId;
    if (!assignCardData && !assignment) {
      // eslint-disable-next-line prefer-destructuring
      assignment = assignmentManager.assignmentArray[0];
    }
    let assignmentId;
    if (assignment && !history.location.pathname.includes('class-detail-students-standards')) {
      assignmentId = assignment.id;
    } else {
      const { currentCourseElementList } = courseManager;
      let courseElement = null;
      if (assignCardData) {
        courseElement = {
          classroomId,
          contentItemId: assignCardData.contentItemId || assignCardData.resourceContentItemId,
          courseContentItemId: assignCardData.courseContentItemId ||
            (assignCardData.currentCourseId !== 'ALL_COURSES' ? assignCardData.currentCourseId : null),
          elementId: assignCardData.elementId || assignCardData.courseResourceElementId
        };
      } else if (currentCourseElementList && currentCourseElementList[0]) {
        // eslint-disable-next-line prefer-destructuring
        courseElement = currentCourseElementList[0];
      }
      if (courseElement) {
        const { courseContentItemId, elementId, contentItemId } = courseElement;
        const page = 0, dialogOpen = false, returnImmediatelyAfterResponse = true;
        const assignments = await assignmentManager.fetchContentAssignments(
          courseContentItemId, elementId, classroomId,
          contentItemId, page, dialogOpen, returnImmediatelyAfterResponse
        );
        if (assignments && assignments[0]) {
          assignmentId = assignments[0].id;
        }
      }
    }
    gradebookManager.setActiveGradebookTypeOnGradebookComponentMount(GRADEBOOK_TYPE.AGGREGATE);
    // Set the default Active Table unless one was passed in; right now only looking for engagement
    if (activeGradebookTable && activeGradebookTable === GRADEBOOK_CONTEXT.ENGAGEMENT) {
      gradebookManager.setActiveGradebookTableOnGradebookComponentMount(activeGradebookTable);
    } else {
      gradebookManager.setActiveGradebookTableOnGradebookComponentMount(GRADEBOOK_CONTEXT.SUMMARY);
    }

    let routerUrl = `/gradebook?assignmentId=${assignmentId}&view=${VIEW_SELECTION.GRADEBOOK}`;
    routerUrl += !urlParamStr?.includes('classroomId') ? `&classroomId=${classroomId}` : '';

    routerUrl += urlParamStr ? urlParamStr?.replace('?', '&') : '';

    routerUrl += fromAssignments ? `&fromAssignments=${fromAssignments}` : '';

    if (allAssignmentsLabel) {
      await navigationManager.setBreadcrumb({
        fromView: VIEW_SELECTION.GRADEBOOK,
        path: {
          name: allAssignmentsLabel,
          routerUrl: `${routerUrl}${urlParamStr}`
        }
      });
    }
    history.push(routerUrl);
  }

  static getAssignmentLaunchUrl = (linkId) => {
    return `${window.location.protocol}//${window.location.hostname}:${window.location.port}/launch?linkId=${linkId}`;
  }
}

registerClass('GradebookNavigationService', GradebookNavigationService);
