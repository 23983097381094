import art1_avatar from '../../img/avatars/teacher/85px-art-01.png';
import art2_avatar from '../../img/avatars/teacher/85px-art-02.png';
import art3_avatar from '../../img/avatars/teacher/85px-art-03.png';
import art4_avatar from '../../img/avatars/teacher/85px-art-04.png';
import art5_avatar from '../../img/avatars/teacher/85px-art-05.png';
import art6_avatar from '../../img/avatars/teacher/85px-art-06.png';

import eng1_avatar from '../../img/avatars/teacher/85px-eng-01.png';
import eng2_avatar from '../../img/avatars/teacher/85px-eng-02.png';
import eng3_avatar from '../../img/avatars/teacher/85px-eng-03.png';
import eng4_avatar from '../../img/avatars/teacher/85px-eng-04.png';
import eng5_avatar from '../../img/avatars/teacher/85px-eng-05.png';
import eng6_avatar from '../../img/avatars/teacher/85px-eng-06.png';

import his1_avatar from '../../img/avatars/teacher/85px-his-01.png';
import his2_avatar from '../../img/avatars/teacher/85px-his-02.png';
import his3_avatar from '../../img/avatars/teacher/85px-his-03.png';
import his4_avatar from '../../img/avatars/teacher/85px-his-04.png';
import his5_avatar from '../../img/avatars/teacher/85px-his-05.png';
import his6_avatar from '../../img/avatars/teacher/85px-his-06.png';

import math1_avatar from '../../img/avatars/teacher/85px-math-01.png';
import math2_avatar from '../../img/avatars/teacher/85px-math-02.png';
import math3_avatar from '../../img/avatars/teacher/85px-math-03.png';
import math4_avatar from '../../img/avatars/teacher/85px-math-04.png';
import math5_avatar from '../../img/avatars/teacher/85px-math-05.png';
import math6_avatar from '../../img/avatars/teacher/85px-math-06.png';

import sci1_avatar from '../../img/avatars/teacher/85px-sci-01.png';
import sci2_avatar from '../../img/avatars/teacher/85px-sci-02.png';
import sci3_avatar from '../../img/avatars/teacher/85px-sci-03.png';
import sci4_avatar from '../../img/avatars/teacher/85px-sci-04.png';
import sci5_avatar from '../../img/avatars/teacher/85px-sci-05.png';
import sci6_avatar from '../../img/avatars/teacher/85px-sci-06.png';

export const AVATAR_PICKER_DEFAULT_LIST = [
  // art
  {
    name: 'art-01',
    src: art1_avatar
  },
  {
    name: 'art-02',
    src: art2_avatar
  },
  {
    name: 'art-03',
    src: art3_avatar
  },
  {
    name: 'art-04',
    src: art4_avatar
  },
  {
    name: 'art-05',
    src: art5_avatar
  },
  {
    name: 'art-06',
    src: art6_avatar
  },
  // eng
  {
    name: 'eng-01',
    src: eng1_avatar
  },
  {
    name: 'eng-02',
    src: eng2_avatar
  },
  {
    name: 'eng-03',
    src: eng3_avatar
  },
  {
    name: 'eng-04',
    src: eng4_avatar
  },
  {
    name: 'eng-05',
    src: eng5_avatar
  },
  {
    name: 'eng-06',
    src: eng6_avatar
  },
  // his
  {
    name: 'his-01',
    src: his1_avatar
  },
  {
    name: 'his-02',
    src: his2_avatar
  },
  {
    name: 'his-03',
    src: his3_avatar
  },
  {
    name: 'his-04',
    src: his4_avatar
  },
  {
    name: 'his-05',
    src: his5_avatar
  },
  {
    name: 'his-06',
    src: his6_avatar
  },
  // math
  {
    name: 'math-01',
    src: math1_avatar
  },
  {
    name: 'math-02',
    src: math2_avatar
  },
  {
    name: 'math-03',
    src: math3_avatar
  },
  {
    name: 'math-04',
    src: math4_avatar
  },
  {
    name: 'math-05',
    src: math5_avatar
  },
  {
    name: 'math-06',
    src: math6_avatar
  },
  // sci
  {
    name: 'sci-01',
    src: sci1_avatar
  },
  {
    name: 'sci-02',
    src: sci2_avatar
  },
  {
    name: 'sci-03',
    src: sci3_avatar
  },
  {
    name: 'sci-04',
    src: sci4_avatar
  },
  {
    name: 'sci-05',
    src: sci5_avatar
  },
  {
    name: 'sci-06',
    src: sci6_avatar
  }
];
