import React from 'react';
import { Login, SatCoreRegister } from 'sat-core';
// import iconHelp from '../img/icon-help.svg';

const LoginOverride = (props) => {
  return (
    <Login {...props}
      // iconHelp={iconHelp}
      shouldUseDefaultRegistrationMessage />
  );
};

SatCoreRegister('Login', LoginOverride);
