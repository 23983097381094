let ecmsBaseUrl;
let publisherBaseUrl;
// const publisherSatelliteCode = 'EVOTEXT_DEMO';
const publisherSatelliteCode = ''; // this will be defined dynamically

let lessonBaseUrl;

const satelliteBaseUrl = window?.location?.origin || '';

let baseUrlPrefix = '';

if (satelliteBaseUrl.includes('localhost')) {
  baseUrlPrefix = process.env.REACT_APP_URI_SCHEME === 'https' ? 'https://localhost' : 'http://localhost';
  ecmsBaseUrl = `${baseUrlPrefix}:8080`;
  publisherBaseUrl = `${baseUrlPrefix}:7777`;
  lessonBaseUrl = `${baseUrlPrefix}:3001`;
} else {
  if (satelliteBaseUrl.includes('qaprod-')) {
    baseUrlPrefix = 'https://qaprod-';
  } else if (satelliteBaseUrl.includes('qa-') || satelliteBaseUrl.includes('dev.')) {
    baseUrlPrefix = 'https://qa-';
  } else if (satelliteBaseUrl.includes('ast-')) {
    baseUrlPrefix = 'https://ast-';
  } else if (satelliteBaseUrl.includes('staging-')) {
    baseUrlPrefix = 'https://staging-';
  } else {
    baseUrlPrefix = 'https://';
  }

  ecmsBaseUrl = satelliteBaseUrl;
  publisherBaseUrl = `${baseUrlPrefix}publisher.content2classroom.com`;
  lessonBaseUrl = `${baseUrlPrefix}lesson.content2classroom.com`;
}

// TODO remove
// console.info('satelliteBaseUrl', satelliteBaseUrl);
// console.info('ecmsBaseUrl', ecmsBaseUrl);
// console.info('publisherBaseUrl', publisherBaseUrl);
// console.info('lessonBaseUrl', lessonBaseUrl);

export const SATELLITE_ROOT = `${satelliteBaseUrl}`;
export const API_ROOT = `${ecmsBaseUrl}`;
export const PUBLISHER_ROOT = `${publisherBaseUrl}`;
export const PUBLISHER_SATELLITE_CODE = `${publisherSatelliteCode}`;
export const LESSON_ROOT = `${lessonBaseUrl}`;
