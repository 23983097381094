import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { isBoolean, isNumber } from 'lodash';

import classNames from 'classnames';

import '../css/GradebookDetailsTable.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import UsageService from '../services/UsageService';

import { safeJsonParse, stripImgHtmlTags } from '../utils';
import UtilityService from '../services/UtilityService';

const GradebookTypingDetailsTable = observer(({ GradebookDetailsTable, ...props } = {}) => {
  const { t } = props;

  const { gradebookManager } = useContext(MobXProviderContext);

  const {
    activeGradebookTable,
    activeGradebookType,
  } = gradebookManager;

  const renderDetailsBodyCellScoreLabelContent = (scoreCellDetails = {}) => {
    return (
      <div className={classNames('cell-score-popup-content-wrapper', activeGradebookType, activeGradebookTable)}>
        {renderScoreLabelContent_questionStem(scoreCellDetails)}
        {renderScoreLabelContent_typingMetrics(scoreCellDetails)}
        {renderScoreLabelContent_typingAdvancedMetricsMetadata(scoreCellDetails)}
      </div>
    );
  };

  const renderScoreLabelContent_questionStem = (scoreCellDetails) => {
    let { questionStem } = scoreCellDetails;
    questionStem = stripImgHtmlTags(questionStem);
    return questionStem && (
      <>
        <div className='cell-question-stem'>
          {UtilityService.reactHtmlParserWrapper(questionStem).parsed}
        </div>
        <div className='cell-question-stem-bottom-spacer'>
          {/* placeholder */}
        </div>
      </>
    );
  };

  const renderScoreLabelContent_typingMetrics = (scoreCellDetails = {}) => {
    const { isKeyboardingType, student, scoreIndex } = scoreCellDetails;
    if (!student || typeof scoreIndex !== 'number') {
      return null;
    }
    const timeOnSlideInSeconds = student.viewedTimes?.[scoreIndex] || 0;
    const timeOnSlideInMinutesStr = UsageService.getUnroundedUsageLabel(timeOnSlideInSeconds);

    const playedTimeInSeconds = student.playedTimes?.[scoreIndex] || 0;

    // currently we will show N/A if playedTimes[scoreIndex] is 0 or null/undefined,
    // since TypingMaster appears to not be passing `playedTime` to us in many cases
    // (`playedTime` is beyond our control - it is intended to be passed to us via TypingMaster)
    const playedTimeInMinutesStr = isKeyboardingType && !!playedTimeInSeconds ? (
      UsageService.getUnroundedUsageLabel(playedTimeInSeconds)
    ) : t('notApplicable');

    const speed = student.speeds?.[scoreIndex] || 0;
    const speedStr = isKeyboardingType ? (
      t('valueSpeed', { speed })
    ) : t('notApplicable');

    // const stars = student.stars?.[scoreIndex] || 0;

    const accuracy = student.accuracies?.[scoreIndex] || 0;
    const accuracyStr = isKeyboardingType ? (
      t('valueAccuracy', { accuracy })
    ) : t('notApplicable');

    const attempts = student.attempts?.[scoreIndex] || 0;
    const attemptsStr = isKeyboardingType ? attempts : t('notApplicable');

    return (
      <div className='cell-typing-metrics'>
        {renderScoreLabelContent_typingMetric('labelTimeOnSlide', timeOnSlideInMinutesStr)}
        {renderScoreLabelContent_typingMetric('labelPlayedTime', playedTimeInMinutesStr)}
        {renderScoreLabelContent_typingMetric('labelSpeed', speedStr)}
        {renderScoreLabelContent_typingMetric('labelAccuracy', accuracyStr)}
        {renderScoreLabelContent_typingMetric('labelAttempts', attemptsStr)}
      </div>
    );
  };

  const renderScoreLabelContent_typingAdvancedMetricsMetadata = (scoreCellDetails = {}) => {
    const { isKeyboardingType, student } = scoreCellDetails;

    const parsedAssignmentMetadata = safeJsonParse(student.assignmentMetadata || '{}');

    const typingAllowBackspace = parsedAssignmentMetadata?.typingAllowBackspace;
    const typingAllowBackspaceStr = isKeyboardingType && isBoolean(typingAllowBackspace) ? t('valueMetadataAllowBackspace', {
      typingAllowBackspace: typingAllowBackspace ? t('yes') : t('no')
    }) : t('notApplicable');

    const typingMaxDurationInSeconds = +parsedAssignmentMetadata?.typingDurationInSeconds >= 0 ? (
      +parsedAssignmentMetadata?.typingDurationInSeconds
    ) : undefined;
    // eslint-disable-next-line no-nested-ternary
    const typingMaxDurationInMinutesStr = isKeyboardingType && isNumber(typingMaxDurationInSeconds) ? (
      typingMaxDurationInSeconds ? UsageService.getUnroundedUsageLabel(typingMaxDurationInSeconds) : t('notApplicable')
    ) : t('notApplicable');

    const typingMinSpeed = +parsedAssignmentMetadata?.typingMinSpeed >= 0 ? +parsedAssignmentMetadata?.typingMinSpeed : undefined;
    const typingMinSpeedStr = isKeyboardingType && isNumber(typingMinSpeed) ? (
      t('valueSpeed', { speed: typingMinSpeed })
    ) : t('notApplicable');

    const typingMinAccuracy = +parsedAssignmentMetadata?.typingMinAccuracy >= 0 ? +parsedAssignmentMetadata?.typingMinAccuracy : undefined;
    const typingMinAccuracyStr = isKeyboardingType && isNumber(typingMinAccuracy) ? (
      t('valueAccuracy', { accuracy: typingMinAccuracy })
    ) : t('notApplicable');

    return (
      <div className='cell-typing-advanced-metrics'>
        <div className='cell-typing-metrics-header-label'>
          {t('typingAdvancedMetricsHeaderLabel')}
        </div>
        {/* TODO */}
        {renderScoreLabelContent_typingMetric('labelMetadataAllowBackspace', typingAllowBackspaceStr)}
        {renderScoreLabelContent_typingMetric('labelMetadataMaxDuration', typingMaxDurationInMinutesStr)}
        {renderScoreLabelContent_typingMetric('labelMetadataMinSpeed', typingMinSpeedStr)}
        {renderScoreLabelContent_typingMetric('labelMetadataMinAccuracy', typingMinAccuracyStr)}
      </div>
    );
  };

  const renderScoreLabelContent_typingMetric = (typingMetricLabelKey, typingMetricValue) => {
    return (
      <div className='cell-typing-metric'>
        <div className='cell-typing-metric-label'>
          <strong>{t(typingMetricLabelKey)}</strong>
        </div>
        <div className='cell-typing-metric-value'>
          {typingMetricValue}
        </div>
      </div>
    );
  };

  return (
    <GradebookDetailsTable {...props}
      renderDetailsBodyCellScoreLabelContent={renderDetailsBodyCellScoreLabelContent} />
  );
});
export default GradebookTypingDetailsTable;

SatCoreRegister('GradebookTypingDetailsTable', GradebookTypingDetailsTable);
