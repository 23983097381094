import React from 'react';
import { SatCoreRegister } from '../SatCoreRegistry';

import '../css/AriaSkipLink.less';

/**
 * **AriaSkipLink** is an accessibility component that provides keyboard and/or screenreader users
 * the ability to bypass navigation and jump directly to the main content of the page.
 *
 * The link is visually hidden by default but becomes visible when focused via keyboard
 * navigation (Tab key). This follows WCAG 2.1 Success Criterion 2.4.1 (Bypass Blocks).
 *
 * @see https://webaim.org/techniques/skipnav/
 * @see https://www.w3.org/WAI/WCAG21/Understanding/bypass-blocks.html
 */
const AriaSkipLink = (props = {}) => {
  const { mainContentId = 'main-content', t } = props;

  const label = props.label || t('skipToMainContent');

  const handleClick = (e) => {
    e.preventDefault();
    const mainContent = document.getElementById(mainContentId);
    if (mainContent) {
      mainContent.focus();
      mainContent.scrollIntoView();
    }
  };

  return (
    <a
      className='aria-skip-link'
      href={`#${mainContentId}`}
      onClick={handleClick}>
      {label}
    </a>
  );
};
export default AriaSkipLink;

SatCoreRegister('AriaSkipLink', AriaSkipLink);
