import { registerClass } from '../../SatCoreRegistry';

import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import reportTypeManager from '../../managers/reports/ReportTypeManager';

import { flattenChildren } from '../../utils';

import { REPORT_LESSON_FILTER_TYPE } from './ReportConstants';

import ReportCourseService from './ReportCourseService';
import ReportIdentityService from './ReportIdentityService';
import ReportStandardsService from './ReportStandardsService';

export default class ReportTypeService {
  /**
   * Initialize `ReportTable` data using `reportIdentityManager.reportResponseJson`
   */
  static initReportTableData = () => {
    // TODO remove // this.resetCertainSessionStorageItemsToDefaultIfApplicable();
    const { isCourseReport, isStandardsReport } = reportIdentityManager;
    if (isCourseReport) {
      return ReportCourseService._initReportTableData();
    } else if (isStandardsReport) {
      return ReportStandardsService._initReportTableData();
    }
  }

  // TODO remove; this is now handled via ReportContextSwitcher
  // static resetCertainSessionStorageItemsToDefaultIfApplicable = () => {
  //   if (reportIdentityManager.isTableSummaryReport) {
  //     removeSessionStorageItem('reportContextType');
  //     reportContextManager.setReportContextType(reportOverrideManager.reportContextTypeDefaultOrderArray[0]);
  //   }
  //   // TODO remove
  //   // const isReportStandardsClassroomDetailStudents = ReportIdentityService.isReportStandardsClassroomDetailStudents();
  //   // if (isReportStandardsClassroomDetailStudents) {
  //   //   removeSessionStorageItem('reportStandardsClassroomDetailStudentsContextType');
  //   //   reportContextManager.setReportStandardsClassroomDetailStudentsContextType(
  //   //     REPORT_CONTEXT_TYPE.RESOURCE_LEVEL
  //   //   );
  //   // }
  // }

  /**
   * Initialize and return **score summary bar table cell config** for 'summary' reports.
   *
   * Note: If the user is not on a summary report, initialization will be set to an empty array,
   * and an empty array will be returned.
   */
  static initReportTableScoreSummaryBarCellConfig = (props) => {
    const { isCourseReport, isStandardsReport, isTableSummaryReport } = reportIdentityManager;
    if (isTableSummaryReport) {
      if (isCourseReport) {
        return ReportCourseService._initReportTableScoreSummaryBarCellConfig(props);
      } else if (isStandardsReport) {
        return ReportStandardsService._initReportTableScoreSummaryBarCellConfig(props);
      }
    } else {
      return [];
    }
  }

  /**
   * Initialize and return **faculty score table cell config** for 'detail' reports.
   *
   * Note: If the user is not on a detail report, initialization will be set to an empty array,
   * and an empty array will be returned.
   */
  static initReportTableFacultyCellConfigArray = (props) => {
    const {
      isCourseReport,
      isStandardsReport,
      isTableDetailReport,
      isTableIndividualReport
    } = reportIdentityManager;
    if (isTableDetailReport || isTableIndividualReport) {
      if (isCourseReport) {
        return ReportCourseService._initReportTableFacultyCellConfigArray(props);
      } else if (isStandardsReport) {
        return ReportStandardsService._initReportTableFacultyCellConfigArray(props);
      }
    } else {
      return [];
    }
  };

  static getAvailableReportCoursesOrStandards = () => {
    const { isCourseReport, isStandardsReport } = reportIdentityManager;
    if (isCourseReport) {
      return ReportCourseService.getAvailableReportCourses();
    } else if (isStandardsReport) {
      return ReportStandardsService.getAvailableReportStandards();
    }
  }

  static getLessonFilterReportIdIfApplicable = (lessonFilterType) => {
    lessonFilterType = lessonFilterType || reportTypeManager.selectedReportLessonFilterType;
    const { activeReportFacultyType, isStandardsReport } = reportIdentityManager;
    if (isStandardsReport && (!lessonFilterType || lessonFilterType !== REPORT_LESSON_FILTER_TYPE.ALL)) {
      const lessonFilterReportId = ReportIdentityService.getReportId(lessonFilterType, activeReportFacultyType, {
        isStandardsFilterReportId: true
      });
      return lessonFilterReportId;
    } else {
      return undefined;
    }
  }

  static applyGenericFilterToReportTableData = ({
    propName,
    propNameCondition = (prop) => !!prop,
    sortPropName = 'order',
    tableData
  } = {}) => {
    if (!tableData) {
      throw new TypeError('ReportTypeService.applyGenericFilterToReportTableData: tableData is required');
    }
    if (!propName) {
      throw new TypeError('ReportTypeService.applyGenericFilterToReportTableData: propName is required');
    }
    const customFilterTableData = tableData.map((tableRowParent) => {
      const filterInfoMap = new Map();
      const tableRowByFilterMap = new Map();

      const flatTableRows = flattenChildren(tableRowParent.subRows, 'subRows');
      flatTableRows.forEach((tableRow) => {
        if (propNameCondition(tableRow[propName])) {
          const filterInfo = tableRow[propName];
          filterInfoMap.set(filterInfo.id, filterInfo);
          tableRowByFilterMap.set(filterInfo.id, [
            ...(tableRowByFilterMap.get(filterInfo.id) || []),
            tableRow
          ]);
        }
      });
      return {
        ...tableRowParent,
        subRows: Array.from(filterInfoMap.values()).map((filterInfo) => {
          return {
            ...filterInfo,
            hasRollup: true,
            subRows: tableRowByFilterMap.get(filterInfo.id) || []
          };
        }).map((subRow) => {
          return {
            ...subRow,
            overallRollup: ReportStandardsService.getRollupAverageScoreInfoObjs(subRow?.subRows)?.[0],
          };
        }).sort((a, b) => a[sortPropName] - b[sortPropName])
      };
    }).filter((tableRowParent) => tableRowParent?.subRows?.length);
    return customFilterTableData;
  }
}

registerClass('ReportTypeService', ReportTypeService);
