/* eslint-disable max-len, sort-keys */
import { isBoolean } from 'lodash';

import { AccommodationsService, dynamicSatelliteManager } from 'sat-core';

AccommodationsService.ACCOMMODATIONS_CONFIG = () => {
  const accommodationsConfigOverride = (
    dynamicSatelliteManager?.dynamicSatelliteLayoutConfig?.satelliteIndexFlagOverrides?.accommodationsConfig
  );

  const config = accommodationsConfigOverride;

  const assessmentToggles = config?.assessmentSection?.toggles || {};

  const lessonToggles = config?.lessonSection?.toggles || {};

  return {
    assessmentSection: {
      // `show` overrides all other assessment options if false
      show: isBoolean(config?.assessmentSection?.show) ? config.assessmentSection.show : true,
      toggles: {
        // toggles marked as true will appear in the order shown here
        basicCalculatorToggle: isBoolean(assessmentToggles.basicCalculatorToggle) ? assessmentToggles.basicCalculatorToggle : true,
        scientificCalculatorToggle: isBoolean(assessmentToggles.scientificCalculatorToggle) ? assessmentToggles.scientificCalculatorToggle : false,
        graphingCalculatorToggle: isBoolean(assessmentToggles.graphingCalculatorToggle) ? assessmentToggles.graphingCalculatorToggle : false,
        textToSpeechToggle: isBoolean(assessmentToggles.textToSpeechToggle) ? assessmentToggles.textToSpeechToggle : true,
        translationToggle: isBoolean(assessmentToggles.translationToggle) ? assessmentToggles.translationToggle : true,
        dictionaryToggle: isBoolean(assessmentToggles.dictionaryToggle) ? assessmentToggles.dictionaryToggle : false,
        contentLanguageToggle: isBoolean(assessmentToggles.contentLanguageToggle) ? assessmentToggles.contentLanguageToggle : true,
        spellcheckerToggle: isBoolean(assessmentToggles.spellcheckerToggle) ? assessmentToggles.spellcheckerToggle : false
      }
    },
    lessonSection: {
      // `show` overrides all other lesson options if false
      show: isBoolean(config?.lessonSection?.show) ? config.lessonSection.show : true,
      toggles: {
        // toggles marked as true will appear in the order shown here
        basicCalculatorToggle: isBoolean(lessonToggles.basicCalculatorToggle) ? assessmentToggles.basicCalculatorToggle : true,
        scientificCalculatorToggle: isBoolean(lessonToggles.scientificCalculatorToggle) ? assessmentToggles.scientificCalculatorToggle : false,
        graphingCalculatorToggle: isBoolean(lessonToggles.graphingCalculatorToggle) ? assessmentToggles.graphingCalculatorToggle : false,
        textToSpeechToggle: isBoolean(lessonToggles.textToSpeechToggle) ? assessmentToggles.textToSpeechToggle : true,
        translationToggle: isBoolean(lessonToggles.translationToggle) ? assessmentToggles.translationToggle : true,
        dictionaryToggle: isBoolean(lessonToggles.dictionaryToggle) ? assessmentToggles.dictionaryToggle : true,
        // contentLanguageToggle: isBoolean(lessonToggles.contentLanguageToggle) ? assessmentToggles.contentLanguageToggle : true,
        spellcheckerToggle: isBoolean(lessonToggles.spellcheckerToggle) ? assessmentToggles.spellcheckerToggle : false
      }
    },
    cellDividerClassName: typeof config?.cellDividerClassName === 'string' ? config.cellDividerClassName : 'section-divider',
    showTopHeader: isBoolean(config?.showTopHeader) ? config.showTopHeader : true,
    tableHeaderFontSize: typeof config?.tableHeaderFontSize === 'string' ? config.tableHeaderFontSize : 'inherit',
    treatAllSectionsAsOne: isBoolean(config?.treatAllSectionsAsOne) ? config.treatAllSectionsAsOne : false
  };
};
