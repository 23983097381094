import React, { Component } from 'react';
import { inject } from 'mobx-react';

import {
  Button, Confirm, Dropdown, Icon, Image, Menu, Popup, Segment
} from 'semantic-ui-react';

import { dateFormat, stripHtmlTags } from '../utils';

import '../css/CourseTreeCard.less';

import iconKebab from '../img/icon-kebab-menu.svg';
import iconInfo from '../img/icon-info.svg';
import iconNotSubmitted from '../img/icon-not-submitted.svg';
import iconSubmitted from '../img/icon-submitted.svg';
import iconTag from '../img/icon-tag.svg';
import iconTrash from '../img/icon-trash-can.svg';
import adminAssessmentDefaultImage from '../img/assessment-card-image.svg';
// resourceBankResources
import articleIcon from '../img/icon-article-resource.svg';
import audioIcon from '../img/icon-audio-resource.svg';
import bookmarkIcon from '../img/icon-bookmark-resource.svg';
import fileIcon from '../img/icon-file-resource.svg';
import imageIcon from '../img/icon-image-resource.svg';
import micrositeIcon from '../img/icon-microsite-resource.svg';
import pdfIcon from '../img/icon-pdf-resource.svg';
import resourceBankIcon from '../img/icon-resource-bank.svg';
import videoIcon from '../img/icon-resource-video.svg';
// resourceBankResources

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { DELIVERY_MODES, PDF_DELIVERY_FORMATS } from '../managers/AssignmentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';
import { CONTENT_ITEM_TYPES, CONTENT_MODE, RESOURCE_BANK_CONTENT_ITEM_TYPES } from '../managers/ContentManager';

import ImageService from '../services/ImageService';
import ResourcePacingService from '../services/ResourcePacingService';
import UtilityService from '../services/UtilityService';
import CourseService from '../services/CourseService';

import PopupService from '../services/PopupService';

export default
@inject(
  'appManager',
  'assignmentManager',
  'classroomManager',
  'contentManager',
  'courseDeliveryManager',
  'courseManager',
  'dialogManager',
  'libraryManager',
  'productManager',
  'reportIdentityManager',
  'studentContentCardManager',
  'tagContentManager',
  'userManager')
class CourseTreeLeaf extends Component {
  constructor(props) {
    super(props);
    this.AdditionalResourcePills = SatCoreComponent('AdditionalResourcePills');
    this.CautionIcon = SatCoreComponent('CautionIcon');
    this.PopupButton = SatCoreComponent('PopupButton');
    this.ResourcePacingToolbar = SatCoreComponent('ResourcePacingToolbar');
    this.StandardsList = SatCoreComponent('StandardsList');

    this.state = {
      confirmMakeAssignableOpen: false,
      confirmRemoveAssessmentId: null,
      confirmRemoveAssessmentOpen: false,
      contentItem: null,
      popupOpen: false,
      downloadUrl: ''
    };
  }

  async componentDidMount() {
    const { contentManager, courseElement, courseManager } = this.props;

    const { currentCourseId } = courseManager;

    const isFileResource = courseElement.type === CONTENT_ITEM_TYPES.FILE_RESOURCE;
    if (isFileResource) {
      this.getResource(courseElement.contentItemId);
    }
    let contentItem = contentManager.getContentItem(courseElement.contentItemId);

    if (!contentItem) {
      await contentManager.fetchContentItem(courseElement.contentItemId);
      contentItem = contentManager.getContentItem(courseElement.contentItemId);
    }
    let currentCourseContentItem = contentManager.getContentItem(currentCourseId);
    if (!currentCourseContentItem) {
      await contentManager.fetchContentItem(currentCourseId);
      currentCourseContentItem = contentManager.getContentItem(currentCourseId);
    }
    this.setState({ contentItem, currentCourseContentItem });
  }

  // eslint-disable-next-line react/sort-comp
  handleConfirmAssignable = async (_event, { value }) => {
    if (value === null || value === 'default') {
      return false;
    }
    const { contentManager } = this.props;
    const item = await contentManager.fetchResourceItem(value, { forceFetchResourceItem: true });
    if (item.populated) {
      this.setState({ confirmMakeAssignableOpen: true });
    } else {
      this.handleOpenErrorDialog('This assessment has no associated questions. Please add questions before you make assignable.');
    }
  }

  submitMakeAssignable = async (entityId, mode) => {
    const { courseManager } = this.props;
    courseManager.makeAssignable(entityId, mode);
  }

  getCardNavButtonsForRemovedCourses = () => (
    <div className='card-nav-buttons course-tree-leaf-card-nav-buttons'>
      <Button
        basic
        className='card-nav-button-export'
        onClick={() => this.handleExportAssignmentsFromRemovedCourses(
          'Assignments from Removed Courses'
        )}
        primary
        type='button'>
        <Icon name='download' />
        Export
      </Button>
    </div>
  )

  handleExportAssignmentsFromRemovedCourses = (className) => {
    const {
      courseElement, courseManager
    } = this.props;
    // note: courseManager.extraActivities is an array of ASSIGNMENTS FROM REMOVED COURSES, if any
    if (courseManager.extraActivities || !courseManager.extraActivities.length) {
      const assignment = courseManager.extraActivities.filter((activity) => activity.contentItemId === courseElement.contentItemId)[0];
      assignment ? this.openExportAssignmentDialog(className, assignment, {
        isAssignmentsFromRemovedCourses: true
      }) :
        this.handleNoDataFound();
    } else {
      this.handleNoDataFound();
    }
  }

  handleNoDataFound = () => {
    // eslint-disable-next-line no-alert
    alert('Sorry, an error occurred. Please try again.');
    window.location.href = '/';
  }

  handleAssignmentViewPresent = (params) => {
    if (params.assignment) {
      const { handlePresent } = this.props;
      handlePresent(params);
    }
  }

  submitAndClose = async () => {
    const { assignmentManager, userManager, assignmentId, studentContentCardManager } = this.props;
    if (!userManager.canViewAsTeacher) {
      await assignmentManager.submitActivityInstance(assignmentId);
    }
    studentContentCardManager.setIsLoading(false);
    this.closeModal();
  }

  handleAssignmentsOpen = () => {
    const { showContentStudentAssignments, courseElement } = this.props;
    if (courseElement) {
      showContentStudentAssignments(courseElement);
    }
  }

  openExportAssignmentDialog = (className, assignment, { isAssignmentsFromRemovedCourses = false } = {}) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.EXPORT_ASSIGNMENT, {
      assignment,
      assignmentId: assignment.id,
      className,
      isAssignmentsFromRemovedCourses
    }, this.closeExportAssignmentDialog);
  }

  closeExportAssignmentDialog = () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.EXPORT_ASSIGNMENT);
  }

  openTagsDialog = async () => {
    const { dialogManager, tagContentManager, courseElement } = this.props;
    const tags = await tagContentManager.fetchContentItemTags(courseElement.contentItemId);
    dialogManager.setOpenDialog(DIALOG_NAMES.TAGS, {
      lessonName: courseElement.name,
      tags,
    }, this.closeTagsDialog);
  }

  closeTagsDialog = () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.TAGS);
  }

  handleOpenErrorDialog = (message) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.ERROR, {
      errorLabel: 'Warning:',
      errorMessage: message
    }, () => dialogManager.closeDialog(DIALOG_NAMES.ERROR));
  }

  getResource = async (contentItemId) => {
    const fileDownloadUrl = CONTENT_ITEM_TYPES.getFileDownloadUrl(contentItemId);
    this.setState({ downloadUrl: fileDownloadUrl });
  }

  handleRemoveResource = async (elementId, currentCourseId) => {
    const { dialogManager, libraryManager, refreshCourseData } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.CONFIRM);
    await libraryManager.removeAssessmentFromBank(elementId, currentCourseId);
    refreshCourseData();
  }

  confirmRemoveFromBank = (elementId, currentCourseId, elementName) => {
    const { dialogManager, t } = this.props;
    const title = t('removeFromBankDialogLabel');
    const message = `${t('removeFromBankMessagePrefix')} "${elementName}" ${t('removeFromBankMessageSuffix')}`;
    dialogManager.setOpenDialog(DIALOG_NAMES.CONFIRM, {
      title,
      message,
      cancelButtonClass: 'cancelButton',
      cancelButtonName: t('removeFromBankCancelButton'),
      confirmButtonClass: 'saveButton',
      confirmButtonName: t('removeFromBankConfirmButton'),
      confirmHandler: () => this.handleRemoveResource(elementId, currentCourseId)
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.CONFIRM));
  }

  handleOpenPopup = () => {
    this.setState({ popupOpen: true });
  }

  handleClosePopup = () => {
    this.setState({ popupOpen: false });
  }

  // This renders custom admin only controls that are viewed from the admin library Assessment Bank Course builder.
  getAdminControls = (courseElement, contentItem) => {
    const {
      courseManager, handleAdminDetails, handleAdminLibraryAssessmentConfirmEdit, handleAdminResourceConfirmEdit,
      handleAdminLibraryEdit, handleAdminTagsAndStandards, handleView, refreshCourseData, showAdminControls, handleAdminShowItemUsageExport,
      showAdminIcons, userManager, t
    } = this.props;
    const { currentCourseId } = courseManager;
    const { currentCourseContentItem, popupOpen } = this.state;
    const { canViewLibraryItemBank, isDistrictOrSchoolAdmin } = userManager;
    const isAllowedEdit = !(currentCourseContentItem?.published || currentCourseContentItem?.contextType === 'published') && (courseElement?.contentItemEntityTypeId === 'assessment' || courseElement?.contentItemEntityTypeId === 'lesson');

    if (isDistrictOrSchoolAdmin && showAdminControls) {
      return (
        PopupService.renderPopup({
          className: 'resource-option-popup',
          content: (
            <Menu vertical>
              {(isAllowedEdit && canViewLibraryItemBank) && (
                <Menu.Item key='edit-resource'
                  name={t('Edit', 'Edit')}
                  onClick={
                    () => {
                      // if the options tooltip is open, close it
                      if (popupOpen) {
                        this.handleClosePopup();
                      }
                      if (contentItem?.contextType === 'published') {
                        // this is a resource bank resource
                        handleAdminResourceConfirmEdit(courseElement, contentItem);
                      } else {
                        // this is a library assessment resource
                        if (contentItem?.published) {
                          handleAdminLibraryAssessmentConfirmEdit(courseElement);
                        } else {
                          handleAdminLibraryEdit(courseElement);
                        }
                      }
                    }
                  }>
                  {t('Edit', 'Edit')}
                </Menu.Item>
              )}
              <Menu.Item key='preview-resource'
                name='Preview'
                onClick={
                  () => {
                    // if the options tooltip is open, close it
                    if (popupOpen) {
                      this.handleClosePopup();
                    }
                    handleView(courseElement, null, courseElement.pdfDeliveryFormat);
                  }
                }>
                Preview
              </Menu.Item>
              <Menu.Item key='view-details'
                name={t('details', 'Details')}
                onClick={
                  () => {
                    // if the options tooltip is open, close it
                    if (popupOpen) {
                      this.handleClosePopup();
                    }
                    const cardData = {
                      id: courseElement.entityId,
                      courseElement,
                      libraryResource: {
                        entityTypeId: 'assessment',
                        published: contentItem.published
                      }
                    };
                    handleAdminDetails(cardData, refreshCourseData);
                  }
                }>
                {t('details', 'Details')}
              </Menu.Item>
              {contentItem?.published && (
                <Menu.Item key='view-tags-and-standards'
                  name={t('tagsAndStandards', 'Tags and Standards')}
                  onClick={
                    () => {
                      // if the options tooltip is open, close it
                      if (popupOpen) {
                        this.handleClosePopup();
                      }
                      const cardData = {
                        id: courseElement.entityId,
                        libraryResource: {
                          entityTypeId: 'assessment'
                        },
                        typeDisplayName: 'Assessment'
                      };
                      handleAdminTagsAndStandards(cardData);
                    }
                  }>
                  {t('tagsAndStandards', 'Tags and Standards')}
                </Menu.Item>
              )}
              <Menu.Item key='view-usage'
                name={t('itemUsage', 'Item Usage Report')}
                onClick={
                  () => {
                    // if the options tooltip is open, close it
                    if (popupOpen) {
                      this.handleClosePopup();
                    }
                    const cardData = {
                      id: courseElement.entityId,
                      title: courseElement.contentItemName,
                      courseElement,
                      libraryResource: {
                        entityTypeId: 'assessment',
                        published: contentItem.published
                      }
                    };
                    handleAdminShowItemUsageExport(cardData);
                  }
                }>
                {t('itemUsage', 'Item Usage Report')}
              </Menu.Item>
              {isAllowedEdit && (
                <Menu.Item key='remove-from-bank'
                  name={t('removeFromBank', 'Remove from Bank')}
                  onClick={
                    () => {
                      // if the options tooltip is open, close it
                      if (popupOpen) {
                        this.handleClosePopup();
                      }
                      this.confirmRemoveFromBank(courseElement.elementId, currentCourseId, courseElement.name);
                    }
                  }>
                  {t('removeFromBank', 'Remove from Bank')}
                </Menu.Item>
              )}
            </Menu>
          ),
          on: 'hover',
          onClose: this.handleClosePopup,
          onOpen: this.handleOpenPopup,
          open: popupOpen,
          position: 'bottom center',
          trigger: <Image alt='options' src={iconKebab} />
        })
      );
    } else {
      return <></>;
    }
  }

  getCustomCardRemoveButton = (courseElement, isCustomCourse) => {
    const { handleCustomAssessmentRemove, hideTypicalCardNavButtons, showTrashIcon } = this.props;
    if (isCustomCourse && hideTypicalCardNavButtons && showTrashIcon) {
      return (
        <div className='card-nav-buttons course-tree-leaf-card-nav-buttons'>
          <Button
            basic
            className='custom-card-remove-btn'
            onClick={(event) => { handleCustomAssessmentRemove(event, courseElement); }}
            primary
            type='button'>
            <Image src={iconTrash} />
          </Button>
        </div>
      );
    } else {
      return <></>;
    }
  }

  getArchivedViewButton = (courseElement, archivedStatus) => {
    const { handleView, t } = this.props;
    if (archivedStatus) {
      <div className='card-nav-buttons course-tree-leaf-card-nav-buttons'>
        <Button basic onClick={() => handleView(courseElement)} primary>
          {t('ViewPreview', 'View')}
        </Button>
      </div>;
    } else {
      return <></>;
    }
  }

  getCardNav = (
    courseElement, assignment, hasRemovedCourses, archivedStatus, allowStudentPacedAttempt,
    numberOfActivities, allowTimeframeViewing, timeframeData
  ) => {
    const { hideTypicalCardNavButtons, productManager } = this.props;
    if (!hideTypicalCardNavButtons && !archivedStatus) {
      if (productManager.isFromProduct) {
        return this.getProductAccessNavButtons(allowTimeframeViewing);
      } else {
        if (hasRemovedCourses) {
          return this.getCardNavButtonsForRemovedCourses();
        } else {
          return this.getCardNavButtons(courseElement.hasActivities, numberOfActivities, assignment, courseElement.hasActivitiesAnywhere,
            allowStudentPacedAttempt, allowTimeframeViewing, timeframeData);
        }
      }
    } else {
      return <></>;
    }
  }

  satellitePreview = () => {
    const { courseElement, handleView } = this.props;
    handleView(courseElement);
  }

  getCardNavButtons = (
    hasActivities, numberOfActivities, assignment, hasActivitiesAnywhere, allowStudentPacedAttempt, allowTimeframeViewing, timeframeData
  ) => {
    const { PopupButton } = this;

    const {
      courseElement, courseManager, handleAddAssignment, handleView,
      imageUrl, productManager, studentContentCardManager, t, userManager
    } = this.props;

    const { downloadUrl } = this.state;

    // TODO remove
    // const { currentReportStandardsFullCourseElement } = reportStandardsClassroomDetailStudentsManager;

    const licenseExpired = !!(courseElement.licenseExpired/* || (
      reportIdentityManager.isReport && currentReportStandardsFullCourseElement?.licenseExpired
    ) */);

    const { isDistrictOrSchoolAdmin, isTeacher, isStudent } = userManager;

    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    if (this.props.getCardNavButtons !== undefined) {
      return this.props.getCardNavButtons(this.props, this);
    }
    const { deliveryMode } = courseElement;
    const studentAlwaysMode = ([DELIVERY_MODES.STUDENT_ALWAYS, DELIVERY_MODES.STUDENT_ALWAYS_ASSIGNABLE].indexOf(deliveryMode) >= 0);
    const studentAlwaysAssignableMode = ([DELIVERY_MODES.STUDENT_ALWAYS_ASSIGNABLE, DELIVERY_MODES.STUDENT_ALWAYS_READ_ONLY_UNTIL_ASSIGNED].indexOf(deliveryMode) >= 0);
    const assignable = deliveryMode && (deliveryMode.indexOf('assign') > -1);

    const isPdfResource = courseElement.type === CONTENT_ITEM_TYPES.PDF_RESOURCE;
    const isPdfAndFlowpaper = courseElement.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_FLOWPAPER;
    const isLessonPlan = courseElement.type === CONTENT_ITEM_TYPES.LESSON_PLAN;
    const isDocReaderAndFlowpaper = courseElement.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_DOCREADER;
    const isFileResource = courseElement.type === CONTENT_ITEM_TYPES.FILE_RESOURCE;

    let isVisible = ResourcePacingService.visibilityMode(courseElement) === 'visible_to_students';
    isVisible = (isTeacher && deliveryMode === 'teacher_only') || isVisible;
    let teacherButtonsPdfAndFlowpaper;

    const { formattedTimeframeStartDate, formattedTimeframeEndDate, formattedTimeframeStartTime, formattedTimeframeEndTime, timeframeStartPending, timeframeEndPassed } = timeframeData;
    const timeFrameMessage = timeframeStartPending ? `Assessment is not available until ${formattedTimeframeStartDate} at ${formattedTimeframeStartTime}`
      :
      timeframeEndPassed ? `Assessment closed on ${formattedTimeframeEndDate} at ${formattedTimeframeEndTime}` : '';

    if ((isDistrictOrSchoolAdmin || isTeacher) && isPdfResource && (isPdfAndFlowpaper || isDocReaderAndFlowpaper)) {
      let popupViewMsg;
      if (licenseExpired) {
        popupViewMsg = (!isStudent || productManager.allowShowExpiredLicenseWarningForStudent) ?
          t('expiredLicenseButtonViewMsg') : '';
      }

      teacherButtonsPdfAndFlowpaper = (
        <>
          <PopupButton
            basic={!isDistrictOrSchoolAdmin}
            buttonLabel={(
              <>
                {isDistrictOrSchoolAdmin && <Icon name='file text' />}
                {t('ViewPreview', 'View')}
              </>
            )}
            disabled={licenseExpired}
            onClick={() => handleView(courseElement, null, courseElement.pdfDeliveryFormat)}
            popupMsg={popupViewMsg}
            popupWide={false}
            primary />
          <PopupButton
            basic={!isDistrictOrSchoolAdmin}
            buttonLabel={t('teacherButtonPdf')}
            disabled={licenseExpired}
            onClick={() => handleView(courseElement, null, PDF_DELIVERY_FORMATS.PDF)}
            popupMsg={popupViewMsg}
            popupWide={false}
            primary />
        </>
      );
    }

    let popupViewMsg = timeFrameMessage;
    if (licenseExpired) {
      popupViewMsg = (!isStudent || productManager.allowShowExpiredLicenseWarningForStudent) ?
        t('expiredLicenseButtonViewMsg') : '';
    }

    let teacherButtonView;
    if ((isDistrictOrSchoolAdmin || isTeacher)
      && ((isPdfResource && (!isPdfAndFlowpaper && !isDocReaderAndFlowpaper)) || !isPdfResource) && !isFileResource
    ) {
      teacherButtonView = (
        <>
          {isDistrictOrSchoolAdmin || (!licenseExpired && allowTimeframeViewing) ? (
            <Button
              basic={!isDistrictOrSchoolAdmin}
              disabled={!allowTimeframeViewing}
              onClick={() => handleView(courseElement)}
              primary>
              {isDistrictOrSchoolAdmin && <Icon name='file text' />}
              {t('ViewPreview', 'View')}
            </Button>
          ) : (
            <Popup
              disabled={!popupViewMsg}
              hideOnScroll={true}
              hoverable={true}
              on='hover'
              position='bottom center'
              trigger={(
                <Button
                  basic={!isDistrictOrSchoolAdmin}
                  className='pseudo-disabled'
                  primary>
                  {isDistrictOrSchoolAdmin && <Icon name='file text' />}
                  {t('ViewPreview', 'View')}
                </Button>
              )}
              wide={!licenseExpired ? 'very' : undefined}>
              {popupViewMsg}
            </Popup>
          )}
        </>
      );
    }

    let downloadButton;
    if (isFileResource && !isStudent) {
      downloadButton = (
        <a download href={downloadUrl}>
          <Button basic primary>{t('DownloadCourse', 'Download')}</Button>
        </a>
      );
    }

    let teacherButtonAssign = <></>;
    if (isTeacher && !isLessonPlan && assignable) {
      let popupMsg;
      if (licenseExpired) {
        popupMsg = t('expiredLicenseButtonAssignMsg');
      } else if (!isVisible) {
        popupMsg = t('hiddenMsg');
      }

      teacherButtonAssign = (
        <PopupButton
          basic
          buttonLabel={t('teacherButtonAssign')}
          disabled={licenseExpired || !isVisible}
          onClick={() => handleAddAssignment(courseElement, imageUrl)}
          popupMsg={popupMsg}
          popupWide={!licenseExpired ? 'very' : false}
          primary />
      );
    }

    let teacherButtonRemove = <></>;
    if (isTeacher && isCustomCourse && !hasActivitiesAnywhere) {
      teacherButtonRemove = (
        <>
          <PopupButton
            basic
            buttonLabel={t('teacherButtonRemove')}
            disabled={!isVisible}
            onClick={() => this.handleClickRemoveAssessment(courseElement.elementId)}
            popupMsg={!isVisible && t('hiddenMsg')}
            primary />
        </>
      );
    }

    let teacherButtonLinkToCourse;
    // TODO: Link to course button may not be needed so commented out for now.
    // if (assignmentManager.isLtiLaunched) {
    //   teacherButtonLinkToCourse = <Button basic onClick={this.handleLinkToCourse} primary>Link to Course</Button>;
    // }
    return (
      <div className='card-nav-buttons course-tree-leaf-card-nav-buttons'>
        {/* TODO: DEMO-680 Change this out with new control (if custom assessment) */
          (isCustomCourse && courseElement.deliveryMode === DELIVERY_MODES.TEACHER_ONLY) ? (
            <>
              <div className='combo-button'>
                <Button.Group>
                  <Button basic className='combo' onClick={() => this.handleAssessmentEditor(courseElement)} primary>Draft</Button>
                  <Dropdown
                    className='button icon'
                    floating
                    onChange={this.handleConfirmAssignable}
                    options={[{ key: 'make-assignable-none', text: '', value: 'default' },
                      { key: 'make-assignable', text: 'Make Assignable', value: courseElement.contentItemId }]}
                    trigger={<></>}
                    value='default' />
                </Button.Group>
              </div>
              <Confirm
                cancelButton='No, continue editing'
                confirmButton='Yes, Make Assignable'
                content='By making this assessment assignable, you will no longer be able to make edits. You will be able to add it to other courses, and it will be assignable in other classes using this course.'
                header='Make Assessment Assignable'
                onCancel={() => this.setState({ confirmMakeAssignableOpen: false })}
                onConfirm={() => {
                  this.submitMakeAssignable(courseElement.entityId, DELIVERY_MODES.ASSIGNABLE);
                  this.setState({ confirmMakeAssignableOpen: false });
                }}
                open={this.state.confirmMakeAssignableOpen} />
            </>
          ) : null
        }
        {isCustomCourse && (
          <Confirm
            cancelButton='No, Cancel'
            confirmButton='Yes, Continue'
            content={t('askRemoveAssessment')}
            header='Warning!'
            onCancel={() => this.setState({
              confirmRemoveAssessmentId: null,
              confirmRemoveAssessmentOpen: false
            })}
            onConfirm={() => {
              this.handleConfirmRemoveAssessment(this.state.confirmRemoveAssessmentId);
              this.setState({
                confirmRemoveAssessmentId: null,
                confirmRemoveAssessmentOpen: false
              });
            }}
            open={this.state.confirmRemoveAssessmentOpen} />
        )}

        {teacherButtonsPdfAndFlowpaper}
        {teacherButtonView}
        {downloadButton}
        {teacherButtonLinkToCourse}
        {teacherButtonAssign}
        {teacherButtonRemove}
        {(!isDistrictOrSchoolAdmin && !isTeacher && !!studentAlwaysMode && !isFileResource) && (
          (!licenseExpired && (!timeframeStartPending && !timeframeEndPassed)) ? (
            <Button
              className={`leaf-button student timeframeStartPending: ${timeframeStartPending} timeframeEndPassed: ${timeframeEndPassed}`}
              onClick={() => handleView(courseElement)}
              primary>
              <Icon name='file text' />
              {t('studentPreview', 'View')}
            </Button>
          ) : (
            <Popup
              disabled={!popupViewMsg}
              hideOnScroll={true}
              hoverable={true}
              on='hover'
              position='bottom center'
              trigger={(
                <Button
                  className='pseudo-disabled '
                  primary>
                  <Icon name='file text' />
                  {t('studentPreview', 'View')}
                </Button>
              )}
              wide>
              {popupViewMsg}
            </Popup>
          )
        )}
        {(!isTeacher && (studentAlwaysAssignableMode || isFileResource)) &&
          studentContentCardManager.getStudentCourseActionButtons(
            assignment, courseElement, hasActivities, numberOfActivities, deliveryMode,
            allowStudentPacedAttempt, this.handleAssignmentViewPresent, this.handleAssignmentsOpen, this.handleSelfPacedOpen)}
      </div>
    );
  }

  closeLibraryAssessmentBuilderModal = async () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW);
  }

  handleAssessmentEditor = async (courseElement) => {
    const { dialogManager, history } = this.props;

    dialogManager.setOpenDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW, {
      closeLibraryAssessmentBuilderModal: this.closeLibraryAssessmentBuilderModal,
      contentItemId: courseElement.contentItemId,
      satellitePreview:this.satellitePreview,
      history
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.LIBRARY_ASSESSMENT_BUILDER_VIEW));
  }

  handleClickRemoveAssessment = (elementId) => {
    this.setState({
      confirmRemoveAssessmentOpen: true,
      confirmRemoveAssessmentId: elementId
    });
  }

  handleLinkToCourse = () => {
    const { assignmentManager, courseElement } = this.props;
    assignmentManager.createLtiLinkToCourse(courseElement.id);
  }

  handleConfirmRemoveAssessment = async (elementId) => {
    const { courseManager } = this.props;
    courseManager.setCourseElementListLoading(true);
    await courseManager.deleteCourseResourceElement(elementId);
    courseManager.setCourseElementListLoading(false);
  }

  handleSelfPacedOpen = (contentMode, courseElement) => {
    const { handleSelfPacedOpen, studentContentCardManager } = this.props;
    studentContentCardManager.setIsLoading(true);
    handleSelfPacedOpen(contentMode, courseElement);
  }

  getProductAccessNavButtons = (allowTimeframeViewing) => {
    const { PopupButton } = this;

    const {
      courseElement, handleView, productManager, t, userManager
    } = this.props;

    const { isStudent } = userManager;

    const { downloadUrl } = this.state;

    // TODO remove
    // const { currentReportStandardsFullCourseElement } = reportStandardsClassroomDetailStudentsManager;

    const licenseExpired = !!(courseElement.licenseExpired/* || (
      reportIdentityManager.isReport && currentReportStandardsFullCourseElement?.licenseExpired
    ) */);

    const isPdfResource = courseElement.type === CONTENT_ITEM_TYPES.PDF_RESOURCE;
    const isPdfAndFlowpaper = courseElement.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_FLOWPAPER;
    const isDocReaderAndFlowpaper = courseElement.pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF_AND_DOCREADER;
    const isFileResource = courseElement.type === CONTENT_ITEM_TYPES.FILE_RESOURCE;

    let popupViewMsg;
    if (licenseExpired) {
      popupViewMsg = (!isStudent || productManager.allowShowExpiredLicenseWarningForStudent) ?
        t('expiredLicenseButtonViewMsg') : '';
    }

    return (
      <div className='card-nav-buttons course-tree-leaf-card-nav-buttons'>
        {((courseElement.type === CONTENT_ITEM_TYPES.LESSON || courseElement.type === CONTENT_ITEM_TYPES.ASSESSMENT)) ? <Button className='teacher-present-button' onClick={() => handleView(courseElement, CONTENT_MODE.PRESENT)} primary>Present </Button> : null}
        {isPdfResource && (isPdfAndFlowpaper || isDocReaderAndFlowpaper) && (
          <>
            <PopupButton basic
              buttonLabel={t('ViewPreview', 'View')}
              disabled={licenseExpired}
              onClick={() => handleView(courseElement, null, courseElement.pdfDeliveryFormat)}
              popupMsg={popupViewMsg}
              primary />
            <PopupButton basic
              buttonLabel={t('pdf')}
              disabled={licenseExpired}
              onClick={() => handleView(courseElement, null, PDF_DELIVERY_FORMATS.PDF)}
              popupMsg={popupViewMsg}
              primary />
          </>
        )}
        {isPdfResource && (!isPdfAndFlowpaper && !isDocReaderAndFlowpaper) && (
          <PopupButton basic
            buttonLabel={t('ViewPreview', 'View')}
            disabled={licenseExpired}
            onClick={() => handleView(courseElement)}
            popupMsg={popupViewMsg}
            primary />
        )}

        {isFileResource && !isStudent && (
          <a download href={downloadUrl}>
            <Button basic primary>{t('DownloadCourse', 'Download')}</Button>
          </a>
        )}

        {(courseElement.type !== CONTENT_ITEM_TYPES.PDF_RESOURCE && !isFileResource) && (
          <PopupButton basic
            buttonLabel={t('ViewPreview', 'View')}
            disabled={licenseExpired || !allowTimeframeViewing}
            onClick={() => handleView(courseElement)}
            popupMsg={popupViewMsg}
            primary />
        )}
      </div>
    );
  }

  getDefaultImage() {
    const { showAdminControls, showAdminIcons, courseElement } = this.props;
    const courseElementType = courseElement.type;
    let imageJsx = {};
    let wrapperClassName = '';
    if (showAdminIcons || showAdminControls) {
      if (courseElementType === CONTENT_ITEM_TYPES.ASSESSMENT) {
        const className = 'custom-default-special';
        imageJsx = <Image alt={`${courseElementType} default image`} className={className} src={adminAssessmentDefaultImage} />;
      } else if (courseElementType === CONTENT_ITEM_TYPES.LESSON) {
        const { defaultImageSource, className } = ImageService.getDefaultImageSourceAndClassName();
        imageJsx = <Image alt={` ${courseElementType} default image`} className={className} src={defaultImageSource} />;
      } else { // for all other resources
        const contentImgUrl = CONTENT_ITEM_TYPES.getContentImageUrl(courseElement.imageUrl, 'userFiles');
        let defaultIcon = resourceBankIcon;
        if (courseElementType === RESOURCE_BANK_CONTENT_ITEM_TYPES.ARTICLE_RESOURCE) {
          defaultIcon = articleIcon;
        }
        if (courseElementType === RESOURCE_BANK_CONTENT_ITEM_TYPES.AUDIO_RESOURCE) {
          defaultIcon = audioIcon;
        }
        if (courseElementType === RESOURCE_BANK_CONTENT_ITEM_TYPES.FILE_RESOURCE) {
          defaultIcon = fileIcon;
        }
        if (courseElementType === RESOURCE_BANK_CONTENT_ITEM_TYPES.IMAGE_RESOURCE) {
          defaultIcon = imageIcon;
        }
        if (courseElementType === RESOURCE_BANK_CONTENT_ITEM_TYPES.BOOKMARK_RESOURCE) {
          defaultIcon = bookmarkIcon;
        }
        if (courseElementType === RESOURCE_BANK_CONTENT_ITEM_TYPES.MICROSITE_RESOURCE) {
          defaultIcon = micrositeIcon;
        }
        if (courseElementType === RESOURCE_BANK_CONTENT_ITEM_TYPES.PDF_RESOURCE) {
          defaultIcon = pdfIcon;
        }
        if (courseElementType === RESOURCE_BANK_CONTENT_ITEM_TYPES.VIDEO_RESOURCE) {
          defaultIcon = videoIcon;
        }
        const backgroundImg = contentImgUrl || defaultIcon;
        if (contentImgUrl) {
          wrapperClassName = 'resourceBankResource customImage';
        } else {
          wrapperClassName = 'resourceBankResource';
        }
        imageJsx = <Image alt={` ${courseElementType} image`} src={backgroundImg} />;
      }
    } else {
      const { defaultImageSource, className } = ImageService.getDefaultImageSourceAndClassName();
      imageJsx = <Image alt='Lesson default image' className={className} src={defaultImageSource} />;
    }
    return {
      defaultImage: imageJsx,
      wrapperClassName
    };
  }

  getBanner = () => {
    const { courseElement } = this.props;
    const { contextPath } = courseElement;
    const path = contextPath.slice(1).reverse();
    const pathNames = path.map((x) => stripHtmlTags(x.name)).filter((x) => x);
    const banner = pathNames.join(', ');
    return banner;
  }

  render() {
    const { AdditionalResourcePills, ResourcePacingToolbar, StandardsList } = this;
    const { contentItem } = this.state;

    const {
      appManager, classroomManager, courseDeliveryManager, courseElement, courseManager,
      dialogManager, disabled, handleAnswerKey, handleClick, handleOpenInfo, imageUrl,
      productManager, showAdminControls, showBanner, showTags, studentContentCardManager,
      t, userManager
    } = this.props;

    const { studentContentCardDateFormatStrategy } = studentContentCardManager;

    // const { currentReportStandardsFullCourseElement } = reportStandardsClassroomDetailStudentsManager;

    const licenseExpired = !!(courseElement.licenseExpired/* || (
      reportIdentityManager.isReport && currentReportStandardsFullCourseElement?.licenseExpired
    ) */);

    const { CautionIcon } = this;

    const { isDistrictAdmin, hasPublisherPermission, isSchoolAdmin, isProductAdmin, isTeacher, isStudent } = userManager;
    const isAdmin = hasPublisherPermission || isDistrictAdmin || isSchoolAdmin || isProductAdmin;

    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);
    const { deliveryMode } = courseElement;
    const teacherMode = (deliveryMode === DELIVERY_MODES.TEACHER_ONLY);

    const urlParams = new URLSearchParams(window.location.search);
    const courseId = urlParams.get('courseId');
    const hasRemovedCourses = courseId === 'EXTRA_ASSIGNMENTS';

    const { resourceInfo, contentItemDescription } = courseElement;

    const hideInformationIcon = hasRemovedCourses || (!resourceInfo && !contentItemDescription);
    const archivedStatus = classroomManager.currentClassArchiveStatus(classroomManager.currentClassroomId);

    let showKey = false;
    let assignmentKeyTitle = '';
    if (!hasRemovedCourses && courseElement.attachmentContentItemId) {
      showKey = true;
      assignmentKeyTitle = courseElement.attachmentContentItemTitle ? courseElement.attachmentContentItemTitle : 'Answer Key';
    }

    const courseElementNameObj = UtilityService.reactHtmlParserWrapper(
      courseElement.name
    );

    // only look at the courseElement description for course resource cards.
    let description = '';
    let hasDescription = false;
    if (courseElement && courseElement.description) {
      description = courseElement.description;
      hasDescription = true;
    }

    const courseElementDescriptionObj = UtilityService.reactHtmlParserWrapper(
      description
    );

    // const transformFn = UtilityService.reactHtmlParserTransform;
    let additionalCourseElementDetails = <></>;

    let assignment = null;

    let statusImage = iconNotSubmitted;
    let assignmentGrade = null;
    let assignmentGradeClass = '';
    let numberOfActivities = 0;
    let allowStudentPacedAttempt = false;

    // timeframe stuff
    const { reviewOutsideTimeframe, timeframeStartDate, timeframeEndDate, timeframeStartDateStr, timeframeStartTimeStr, timeframeEndDateStr, timeframeEndTimeStr  } = courseElement;
    const timeframeData = CourseService.formatTimeframeCardData(reviewOutsideTimeframe, timeframeStartDate, timeframeEndDate, timeframeStartDateStr, timeframeStartTimeStr, timeframeEndDateStr, timeframeEndTimeStr);
    const { allowTimeframeViewing, showTimeframe, timeframeText } = timeframeData;
    // end timeframe stuff

    if (!hasRemovedCourses) {
      let courseTreeLeafDescriptionJsx = (
        <div
          className={hasDescription ? 'leaf-description-text fullHeight' : 'leaf-description-text'}>
          {hasDescription ? courseElementDescriptionObj.parsed : null}
        </div>
      );
      const trigger = courseTreeLeafDescriptionJsx;
      const popup = (
        <Popup
          content={courseElementDescriptionObj.parsed}
          hoverable
          on='hover'
          position='bottom left'
          positionFixed
          trigger={trigger}
          wide='very' />
      );
      courseTreeLeafDescriptionJsx = popup;

      // check for assignment details, and student pacing/attempts
      const pacingMode = ResourcePacingService.pacingMode(courseElement);
      const isStudentPaced = pacingMode === 'student_paced';
      const attempts = ResourcePacingService.attempts(courseElement);

      if (!isAdmin && !isTeacher) {
        if (courseElement.hasActivities) {
          const { studentActivities } = courseElement;
          numberOfActivities = studentActivities.length;
          if (studentActivities) {
            if (studentActivities.length === 1) {
              // eslint-disable-next-line prefer-destructuring
              assignment = studentActivities[0];
            }
          }
        }
        // get whether student paced is allowed
        if (studentContentCardManager.getAllowStudentPacedAttempt) {
          allowStudentPacedAttempt = studentContentCardManager.getAllowStudentPacedAttempt(
            assignment, pacingMode, attempts, numberOfActivities, false);
        }
      }

      let submittedLabel = 'Not Submitted';
      if (assignment && assignment.submitted) {
        submittedLabel = 'Submitted';
        statusImage = iconSubmitted;
      }

      if (assignment) {
        const assignmentGradeValues = studentContentCardManager.getAssignmentGradeValues(assignment);
        assignmentGrade = assignmentGradeValues.assignmentGrade;
        assignmentGradeClass = assignmentGradeValues.assignmentGradeClass;
      }

      const { showResourcePacingToolbar } = courseDeliveryManager;

      const isCustomAssessmentDialogOpen = dialogManager.openedDialogs.has(DIALOG_NAMES.ADD_CUSTOM_ASSESSMENT);

      additionalCourseElementDetails = (
        <>
          <div className='subtitle'>
            {courseTreeLeafDescriptionJsx}
            {(showKey && (isAdmin || isTeacher)) ? (
              <>
                {(description) &&
                  <span className='spacer'>|</span>}
                <div className='answer-key card-list-text blue' onClick={() => handleAnswerKey(courseElement)}>
                  {assignmentKeyTitle}
                </div>
              </>
            )
              : ''}
          </div>
          <StandardsList
            assignmentId={courseElement.id}
            contentImageUrl={imageUrl}
            standards={courseElement.standardsInfo}
            title={courseElement.name} />
          {!courseManager.allowCourseTreeLeafFooter ? (
            <div className='footer'>
              {(isTeacher) ?
                (teacherMode)
                && <div className='leaf-tag tag1 leaf-tag-teacher'>Teacher</div>
                : null}

              {isTeacher && !teacherMode && !isCustomAssessmentDialogOpen && (
                <>
                  <ResourcePacingToolbar
                    callback={this.props.handleResourcePacingToolbarCallback}
                    {...this.props}
                    resource={courseElement} />
                  {!showResourcePacingToolbar && isTeacher && !teacherMode &&
                  <div className='leaf-tag tag2 leaf-tag-student'>Student</div>}
                </>
              )}
              {isTeacher && !teacherMode && courseDeliveryManager.allowAdditionalResourcePills && (
                <AdditionalResourcePills {...this.props} />
              )}
              {isTeacher && !teacherMode && showTimeframe && (
                <div className='fg-column timeframe'>
                  {timeframeText}
                </div>
              )}
              {(assignment && userManager.isStudent) && (
                <div className='flex-grid-spaced dash-bar sub-meta'>
                  {(assignment && assignment.submitted) ? (
                    <div className='fg-column submitted-date'>
                      <div className='status-image'><Image alt='' className={studentContentCardManager.getStatusClass(assignment)} src={statusImage} /></div>
                      <div className='card-label'>Submitted:</div>
                      <div className='card-value'>
                        {/* {studentContentCardManager.convertToLongDate(assignment.submittedDate)} */}
                        {dateFormat(assignment.submittedDate, studentContentCardDateFormatStrategy, appManager.locale)}
                      </div>
                    </div>
                  )
                    : (
                      <div className='fg-column'>
                        <div className='status-image'><Image alt='' className={studentContentCardManager.getStatusClass(assignment)} src={statusImage} /></div>
                        <div className='card-value'>{submittedLabel}</div>
                      </div>
                    )}
                  <div className='fg-column due-date'>
                    <div className='card-label'>{t('dueDate')}</div>
                    <div className='card-value'>
                      {/* {studentContentCardManager.convertToLongDate(assignment.timezoneEndDate)} */}
                      {dateFormat(assignment.timezoneEndDate, studentContentCardDateFormatStrategy, appManager.locale)}
                    </div>
                  </div>
                  {!(assignment.status === 'closed' || assignment.status === 'completed') && (
                    <div className='fg-column due-time'>
                      <div className='card-label'>{t('dueTime')}</div>
                      <div className='card-value'>{studentContentCardManager.formatTime(assignment.timezoneEndTime)}</div>
                    </div>
                  )}
                  {(assignmentGrade && assignment.scoresReleased) && (
                    (!assignment.hasLikertElements || assignment.hasConventionalElements)
                  ) && (
                    <div className={`fg-column grade${assignmentGrade === t('waitingOnGrade') || t('notSubmitted') ? ' waiting-on-grade' : ''}`}>
                      <div className='card-label grade'>{t('grade')}</div>
                      <div className={`card-grade-value ${assignmentGradeClass}`}>{assignmentGrade}</div>
                    </div>
                  )}
                </div>
              )}
              {(!isStudentPaced && (numberOfActivities < 1 && userManager.isStudent && deliveryMode === DELIVERY_MODES.STUDENT_ALWAYS_READ_ONLY_UNTIL_ASSIGNED)) && (
                <div className='flex-grid-spaced dash-bar sub-meta'>
                  <div className='fg-column'>
                    <div className='card-value'>Not Yet Assigned</div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            isTeacher && !teacherMode && showTimeframe && (
              <div className='fg-column timeframe'>
                {timeframeText}
              </div>
            )
          )}
        </>
      );
    }

    let cardStyle = {};
    const useSpecialBackgroundColor = disabled && userManager.clientPrefix === 'SIRIUS';
    if (useSpecialBackgroundColor) {
      cardStyle = { opacity: '0.5' };
    } else if (disabled) {
      cardStyle = { opacity: '0.5' };
    }

    const { defaultImage, wrapperClassName } = this.getDefaultImage();

    return (
      <>
        {showBanner && <div className='course-tree-card-top'>{this.getBanner()}</div>}
        <Segment
          key={`leaf_card_${courseElement.id}`}
          className='leaf-card-satcore list-card'
          onClick={handleClick}
          style={cardStyle}>
          <div className={`leaf-card-image-wrapper${isCustomCourse ? ' custom' : ''} ${wrapperClassName}`}>
            <div className={(imageUrl === null || imageUrl === '')
              ? `leaf-card-image default${isCustomCourse ? ' custom' : ''}`
              : 'leaf-card-image'}>
              {isCustomCourse || !imageUrl
                ? defaultImage
                : <Image alt={contentItem?.altText ? contentItem.altText : null} src={imageUrl} wrapped />}
            </div>
          </div>
          <div className='card-body'>
            <div className='header'>
              {PopupService.renderPopup({
                content: courseElementNameObj.parsed,
                position: 'top left',
                trigger: (
                  <div className='leaf-name'>
                    {courseElementNameObj.parsed}
                  </div>
                )
              })}

              {!hideInformationIcon && !isStudent && (
                <Image
                  alt=''
                  className='leaf-info-icon'
                  onClick={() => handleOpenInfo(
                    courseElement.name,
                    contentItemDescription,
                    resourceInfo, imageUrl
                  )}
                  spaced
                  src={iconInfo} />
              )}
              {showTags && (
                <Image
                  alt=''
                  className='leaf-info-icon'
                  onClick={() => this.openTagsDialog()}
                  spaced
                  src={iconTag} />
              )}
              {licenseExpired && (
                !userManager.isStudent || productManager.allowShowExpiredLicenseWarningForStudent
              ) && (<CautionIcon expiredLicenseCautionIcon />)}
            </div>
            {/* {(showAdminControls && contentItem?.published) && (
            <div className='admin-live-message'>
              ({t('isLiveLabelAssessment')})
            </div>
            )} */}
            {additionalCourseElementDetails}
          </div>
          {(showAdminControls && contentItem) ? (
            <div className='admin-options-container'>
              {this.getAdminControls(courseElement, contentItem)}
            </div>
          ) : (
            <>
              {this.getCustomCardRemoveButton(courseElement, isCustomCourse)}
              {!licenseExpired && this.getArchivedViewButton(courseElement, archivedStatus)}
              {this.getCardNav(courseElement,
                assignment,
                hasRemovedCourses,
                archivedStatus,
                allowStudentPacedAttempt,
                numberOfActivities,
                allowTimeframeViewing,
                timeframeData)}
            </>
          )}
        </Segment>
      </>
    );
  }
}

SatCoreRegister('CourseTreeLeaf', CourseTreeLeaf);
