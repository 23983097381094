import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { SatCoreRegister } from '../SatCoreRegistry';

const GradebookLikertDetailsTable = observer(({ GradebookDetailsTable, ...props } = {}) => {
  const { /* managers */ } = useContext(MobXProviderContext);

  // we can add method overrides here and pass them to GradebookDetailsTable
  // see GradebookTypingDetailsTable as an example

  return (
    <GradebookDetailsTable {...props} />
  );
});
export default GradebookLikertDetailsTable;

SatCoreRegister('GradebookLikertDetailsTable', GradebookLikertDetailsTable);
