import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button, Container, Header, Image } from 'semantic-ui-react';
import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import Modal from '../Modal';

import '../../css/InfoDialog.less';

import UtilityService from '../../services/UtilityService';

export default class InfoModal extends Component {
  render() {
    const {
      addInfoOpen,
      infoName,
      resourceInfo,
      contentItemDescription,
      studentInstruction,
      closeInfoModal,
      imageUrl,
      t
    } = this.props;

    const ModalBanner = SatCoreComponent('ModalBanner');

    const transformFn = UtilityService.reactHtmlParserTransform;

    return (
      <Modal className='info-dialog' open={addInfoOpen}>
        <ModalBanner
          label='Info: '
          onClose={() => { closeInfoModal(); }}
          title={infoName} />
        <Modal.Content image>
          <Image src={imageUrl} style={{ display: imageUrl ? 'block' : 'none' }} wrapped />
          <Modal.Description>
            {(contentItemDescription) ? (
              <>
                <Header className='info-subtitle'>{t('ContentHeader', 'Content Header')}</Header>
                <Container>
                  {ReactHtmlParser(contentItemDescription, {
                    transform: transformFn
                  })}
                </Container>
              </>
            ) : null}
            {(resourceInfo) ? (
              <>
                <Header className='info-subtitle'>{t('WhenToUse', 'When to Use')}</Header>
                <Container>
                  {ReactHtmlParser(resourceInfo, {
                    transform: transformFn
                  })}
                </Container>
              </>
            ) : null}
            {(studentInstruction) ? (
              <>
                <Header>Student Instructions</Header>
                <Container>
                  {ReactHtmlParser(studentInstruction, {
                    transform: transformFn
                  })}
                </Container>
              </>
            ) : null}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => { closeInfoModal(); }} primary>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('InfoModal', InfoModal);
