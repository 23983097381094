import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Button, Form, Loader, Message } from 'semantic-ui-react';

import Modal from '../Modal';

import '../../css/CopyLibraryResourceModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';
import UserManager from '../../managers/UserManager';

import { DIALOG_NAMES } from '../../managers/DialogManager';

export default
@inject('assessmentManager', 'contentItemManager', 'dialogManager', 'libraryManager', 'userManager')
class CopyLibraryResourceModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.ModalBanner = SatCoreComponent('ModalBanner');
  }

  getInitialState = () => {
    return {
      error: null,
      newContentItemName: ''
    };
  }

  componentDidMount = async () => {
    this.configureNewContentItemName();
  }

  clearError = () => {
    this.setState({ error: null });
  }

  handleChangeContentItemName = async (event) => {
    const newContentItemName = event.target.value;
    if (newContentItemName.length <= 200) {
      await this.setState({ error: null, newContentItemName });
      this.validate();
    }
  }

  handlePreloadContentItemName = async () => {
    const { placeholderText } = this.state;
    this.setState({ error: null, newContentItemName: placeholderText });
  }

  addResourceToUnit = async (contentItemId) => {
    const { currentCourseId, currentElementId, libraryManager } = this.props;
    const result = await libraryManager.addAssessmentsToBankUnit(
      currentCourseId,
      currentElementId,
      [contentItemId]
    );
    if (!result) {
      alert('An error occurred when trying to add resource to collection. Please try again later.');
      this.setState({ error: errorMessage, isSaving: false });
    }
  }

  removeAssessmentFromBank = async (elementId, currentCourseId) => {
    const { libraryManager } = this.props;
    await libraryManager.removeAssessmentFromBank(elementId, currentCourseId);
  }

  copyLibraryResource = async () => {
    const { contentItemId, contentItemManager, currentCourseId, currentElementId,libraryManager, onPostCopyCallback = null, resourceElementId, resourceName, selectedResourceType, targetLocation } = this.props;
    let { newContentItemName } = this.state;
    const isAssessmentOrLesson = selectedResourceType === 'assessment' || selectedResourceType === 'lesson';
    if (!newContentItemName || (newContentItemName && newContentItemName.length < 1)) {
      if (isAssessmentOrLesson) {
        newContentItemName = `Copy of_${resourceName}`;
      } else {
        newContentItemName = 'Copy of';
      }
    }
    const isValid = await this.validate();
    if (isValid) {
      let success = false;
      if (isAssessmentOrLesson) {
        // check if this is an itembank resource which goes to a resource bank only or a regular my draft copy.
        const response = await libraryManager.copyLibraryAssessment(contentItemId, newContentItemName, currentCourseId, currentElementId);
        if (response.status === 'SUCCESS') {
          success = true;
          if (targetLocation === 'resourceBank') {
            // Code to add resource to collection/Unit has been moved to the BE copy call, 
            // so now that the copied resource is added we need to remove the original.
            await this.removeAssessmentFromBank(resourceElementId, currentCourseId);
          }
        }
      } else if (selectedResourceType === 'course_resource') {
        success = await contentItemManager.deepCloneContentItem(contentItemId, newContentItemName);
      }
      if (success) {
        if (onPostCopyCallback) {
          onPostCopyCallback();
        }
        this.closeCopyLibraryResourceModal(true);
        this.showCopyInfoModal();
      } else {
        const errorMessage = `There was an error when copying the assessment: ${newContentItemName}.`;
        this.setState({ error: errorMessage, isSaving: false });
      }
    }
  }

  validate = async () => {
    const { assessmentManager, contentItemId, resourceName, selectedResourceType } = this.props;
    let { error, newContentItemName } = this.state;
    let isValid = true;
    if (!contentItemId) {
      this.setState({ error: 'Resource id was not found.' });
      return false;
    }
    // remove whitespace around values
    newContentItemName = newContentItemName.trim();
    // check for existing name
    const resourceNameExists = await assessmentManager.checkContentItemName(newContentItemName, selectedResourceType);
    if (resourceNameExists) {
      this.setState({ error: 'Resource name is already in use.', isSaving: false });
      return false;
    }
    if (newContentItemName && newContentItemName.length > 0) {
      isValid = newContentItemName !== resourceName;
      if (!isValid) {
        if (error) {
          this.setState({ error: `${error} ' The resource name cannot be the same.'`, isSaving: false });
        } else {
          this.setState({ error: 'The resource name cannot be the same.', isSaving: false });
        }
        return false;
      }
    }
    return isValid;
  }

  closeCopyLibraryResourceModal = (refresh) => {
    const { close, onCloseCopyLibraryResourceModal } = this.props;
    onCloseCopyLibraryResourceModal(refresh);
    close();
  }

  showCopyInfoModal = () => {
    const { dialogManager } = this.props;
    const { t } = this.props;
    const title = t('postCopyTitle');
    let message = t('postCopyMessage');
    dialogManager.setOpenDialog(DIALOG_NAMES.TEXT, {
      title,
      message,
      closeButtonClass: 'deleteButton',
      closeButtonName: 'Ok'
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.TEXT));
  }

  clearError = () => {
    this.setState({ error: null });
  }

  configureNewContentItemName = async () => {
    const { assessmentManager, resourceName, selectedResourceType } = this.props;
    const isAssessmentOrLesson = selectedResourceType === 'assessment' || selectedResourceType === 'lesson';
    let placeholderText = isAssessmentOrLesson ? `Copy of_${resourceName}` : 'Copy of';
    const resourceNameExists = await assessmentManager.checkContentItemName(placeholderText, selectedResourceType);
    if (resourceNameExists) {
      placeholderText = isAssessmentOrLesson ? `Copy of_${placeholderText}` : 'Copy of';
    }
    this.setState({ error: null, placeholderText });
  }

  render() {
    const { copyLibraryResourceOpen, onCloseCopyLibraryResourceModal, headerLabel, resourceName, selectedResourceType, targetLocation, t, userManager} = this.props;
    const { newContentItemName, placeholderText, isSaving, error } = this.state;
    const { ModalBanner } = this;
    const isAssessmentOrLesson = selectedResourceType === 'assessment' || selectedResourceType === 'lesson';
    const inputLabelText = isAssessmentOrLesson ? t('nameLabelText') : t('nameLabelTextBank');
    const inputLabelSubText = isAssessmentOrLesson ? t('nameLabelSubText') : t('nameLabelSubTextBank');
    const descriptionSuffix = targetLocation === 'resourceBank' ? t('resourceDescriptionTextSuffix') : t('descriptionTextSuffix');
    const descriptionPrefix = `${t('descriptionTextPrefix')} "${resourceName}"`;
    const fullDescription = userManager.isTeacher ? `${descriptionPrefix}` : `${descriptionPrefix} ${descriptionSuffix}`
    return (
      <Modal className='copy-library-resource-modal' open={copyLibraryResourceOpen} size='tiny'>
        <ModalBanner
          label={headerLabel || t('headerLabel')}
          onClose={onCloseCopyLibraryResourceModal} />
        <Modal.Content>
          <div className='description-text'>{fullDescription}</div>
          <div className='inputLabelText'>{inputLabelText}</div>
          <div className='inputLabelSubText'>{inputLabelSubText}</div>
          <Form.Field className='create-new-resource-field'>
            <Form.Input
              className='inputText'
              name='resourceName'
              onChange={this.handleChangeContentItemName}
              onFocus={this.handlePreloadContentItemName}
              placeholder={placeholderText}
              type='text'
              value={newContentItemName} />
          </Form.Field>
          {error ? (
            <Message
              className='copy-library-resource-modal-error-msg'
              content={error}
              error
              visible={error !== null} />
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <Button basic onClick={() => { this.closeCopyLibraryResourceModal(); }} primary>{t('cancelButtonLabel', 'Cancel')}</Button>
          <Button
            disabled={(error !== null) || (!newContentItemName || newContentItemName.length < 1)}
            onClick={() => {
              this.setState({ isSaving: true });
              this.copyLibraryResource();
            }}
            primary>
            {isSaving ? <Loader active inline size='tiny' /> : t('copyButtonLabel', 'Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('CopyLibraryResourceModal', CopyLibraryResourceModal);
