import articleQuestionIcon from '../img/resources/ArticleQuestion.png';
import audioIcon from '../img/resources/Audio.png';
import imageIcon from '../img/resources/Image.png';
import imageQuestionIcon from '../img/resources/ImageQuestion.png';
import keyboardingIcon from '../img/resources/Keyboarding.png';
import pdfIcon from '../img/resources/Pdf.png';
import userFileIcon from '../img/resources/UserFile.png';
import videoIcon from '../img/resources/Video.png';
import videoQuestionIcon from '../img/resources/VideoQuestion.png';
import webPageIcon from '../img/resources/WebPage.png';

export const getContentTypeIcon = (contentType) => {
  switch (contentType) {
    case 'Audio': {
      return audioIcon;
    }
    case 'Video': {
      return videoIcon;
    }
    case 'UserFile': {
      return userFileIcon;
    }
    case 'Image': {
      return imageIcon;
    }
    case 'ImageQuestion': {
      return imageQuestionIcon;
    }
    case 'VideoQuestion': {
      return videoQuestionIcon;
    }
    case 'WebPage': {
      return webPageIcon;
    }
    case 'Pdf': {
      return pdfIcon;
    }
    case 'Keyboarding': {
      return keyboardingIcon;
    }
    default: {
      return articleQuestionIcon;
    }
  }
};
