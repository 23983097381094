import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import {
  Button, Icon, Image, Popup
} from 'semantic-ui-react';

import iconHourglass from '../img/icon-hourglass.svg';

import '../css/GradebookTable.less';

import { SatCoreRegister } from '../SatCoreRegistry';

import { ASSIGNMENT_STATUS, PDF_DELIVERY_FORMATS } from '../managers/AssignmentManager';
import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';

import GradebookLikertService from '../services/gradebook/GradebookLikertService';
import GradebookMainService from '../services/gradebook/GradebookMainService';

const GradeSummaryCell = observer((props = {}) => {
  const { instanceInfo, isPercent, t } = props;

  const { assignmentManager, gradebookManager } = useContext(MobXProviderContext);

  const { isGradebookLikert } = gradebookManager;

  const { ASSESSMENT, LEARNOSITY_ACTIVITY_RESOURCE, LESSON, PDF_RESOURCE } = CONTENT_ITEM_TYPES;

  const isLoading = props.loading || gradebookManager.gradebookManagerLoadingFlagActive;

  const isReadOnly = GradebookMainService.isGradebookReadOnly();

  const renderGradeSummaryCell = () => {
    if (!instanceInfo) {
      return;
    }

    const assignment = assignmentManager.getAssignment(instanceInfo.activityId) || gradebookManager.currentAssignment || {};

    const entityTypeId = assignment.contentItemEntityTypeId || assignment.entityTypeId || instanceInfo.assignEntityTypeId;

    const pdfDeliveryFormat = assignment.pdfDeliveryFormat || gradebookManager.currentAssignment?.pdfDeliveryFormat;

    // Safely handle the assessment and lesson unsubmitted scenario without affecting other types.
    if ((entityTypeId === LESSON || entityTypeId === ASSESSMENT ||
      (entityTypeId === LEARNOSITY_ACTIVITY_RESOURCE && instanceInfo.status !== ASSIGNMENT_STATUS.COMPLETED))
      && (instanceInfo && !instanceInfo.submitted)
    ) {
      return (<div className='empty'>—</div>);
    }

    const isGradeableContentItemType = CONTENT_ITEM_TYPES.isGradeableType(entityTypeId);

    if (instanceInfo.submitted
      || instanceInfo.status === ASSIGNMENT_STATUS.CLOSED
      || instanceInfo.status === ASSIGNMENT_STATUS.COMPLETED
      || instanceInfo.status === 'pending-rescore'
      || isGradeableContentItemType
    ) {
      if (!isGradebookLikert && props.checkIsPendingScore(instanceInfo)) {
        return renderPendingScoreHourglass();
      } else if (instanceInfo.manualScoring) {
        let grade;
        if (isGradebookLikert) {
          grade = instanceInfo.likertGrade;
        } else {
          grade = instanceInfo.grade;
        }

        const isGradeableWithScoreAssignmentModal = (
          (entityTypeId === PDF_RESOURCE && pdfDeliveryFormat === PDF_DELIVERY_FORMATS.PDF) || (
            isGradeableContentItemType
          )
        );

        const hasGrade = grade !== null && grade !== '' && grade !== undefined;

        if (gradebookManager.useStudentWorkScoreButton) {
          // satellite has opted to use 'Score' button instead of pencil icon for grade
          if (!hasGrade) {
            if (isGradeableWithScoreAssignmentModal) {
              return renderModalGraderLauncherButton();
            } else {
              return renderLessonGraderLauncherButton();
            }
          } else {
            // `grade` has a value, so display it here
            if (isGradeableWithScoreAssignmentModal) {
              return renderModalGraderLauncherClickableScore();
            } else {
              return renderLessonGraderLauncherClickableScore();
            }
          }
        } else {
          // satellite has opted to use default (pencil) icon instead of 'Score' button for grade
          if (!hasGrade) {
            if (isGradeableWithScoreAssignmentModal) {
              return renderModalGraderLauncherPencilIcon();
            } else {
              return renderLessonGraderLauncherPencilIcon();
            }
          } else {
            // `grade` has a value, so display it here
            if (isGradeableWithScoreAssignmentModal) {
              return renderModalGraderLauncherClickableScore();
            } else {
              return renderLessonGraderLauncherClickableScore();
            }
          }
        }
      } else {
        return renderLessonGraderLauncherClickableScore();
      }
    }
    return (<div className='empty'>—</div>);
  };

  const renderPendingScoreHourglass = () => {
    const toolTipStyle = props.getTooltipStyle();
    return (
      <Popup
        className='gradebook-summary-table-popup gradebook-hourglass-popup'
        hideOnScroll
        hoverable
        on='hover'
        position='top center'
        style={toolTipStyle}
        trigger={<Image alt='' className='icon-hourglass' src={iconHourglass} />}>
        <Popup.Header><div className='popup-message header'>{t('waitingScoreSyncHeader')}</div></Popup.Header>
        <Popup.Content>
          <div className='popup-message'>{t('waitingScoreSyncDescription')}</div>
        </Popup.Content>
      </Popup>
    );
  };

  const renderModalGraderLauncherButton = () => {
    return (
      <Button
        className='primary submit-button'
        disabled={isLoading || isReadOnly}
        onClick={() => {
          props.handleGradeAssignment(instanceInfo);
        }}>
        {t('scoreButtonLabel', 'Score')}
      </Button>
    );
  };

  const renderModalGraderLauncherPencilIcon = () => {
    return (
      <Icon
        className={classNames(!isReadOnly ? 'clickable link-color' : '')}
        name='pencil'
        onClick={() => {
          if (!isLoading && !isReadOnly) {
            props.handleGradeAssignment(instanceInfo);
          }
        }} />
    );
  };

  const renderLessonGraderLauncherButton = () => {
    return (
      <Button
        className='primary submit-button'
        disabled={isLoading || isReadOnly}
        onClick={() => props.handlePresent({ instance: instanceInfo })}>
        {t('scoreButtonLabel', 'Score')}
      </Button>
    );
  };

  const renderLessonGraderLauncherPencilIcon = () => {
    return (
      <Icon
        className={classNames(!isReadOnly ? 'clickable link-color' : '')}
        name='pencil'
        onClick={() => {
          if (!isLoading && !isReadOnly) {
            props.handlePresent({ instance: instanceInfo });
          }
        }} />
    );
  };

  const renderModalGraderLauncherClickableScore = () => {
    let gradeStr;
    if (isGradebookLikert) {
      gradeStr = GradebookLikertService.getLikertGradeStr({ instanceInfo });
    } else {
      gradeStr = `${instanceInfo.grade}%`;
    }
    return (
      <div
        className={classNames(!isReadOnly ? 'view-link clickable blue' : '')}
        onClick={() => {
          if (!isLoading && !isReadOnly) {
            props.handleGradeAssignment(instanceInfo);
          }
        }}>
        {gradeStr}
      </div>
    );
  };

  const renderLessonGraderLauncherClickableScore = () => {
    const gradePopup = props.getGradePopup(instanceInfo, isPercent);
    return gradePopup;
  };

  return renderGradeSummaryCell();
});
export default GradeSummaryCell;

SatCoreRegister('GradeSummaryCell', GradeSummaryCell);
