import React from 'react';
import { SatCoreRegister, StudentAssignmentCard } from 'sat-core';

const StudentAssignmentCardOverride = (props) => (
  <StudentAssignmentCard
    {...props}
    dashboard={false}
    hideCourseTitle={false} />
);

SatCoreRegister('StudentAssignmentCard', StudentAssignmentCardOverride);
