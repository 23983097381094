import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import InfiniteScroll from 'react-infinite-scroller';

import {
  Container, Grid, Input, Loader
} from 'semantic-ui-react';

import '../css/AdminDashboard.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { VIEW_SELECTION } from '../managers/NavigationManager';

import { dateFormat } from '../utils/dateFormat';

export default
@inject(
  'appManager', 'dynamicSatelliteManager', 'navigationManager', 'schoolManager', 'userManager'
)
@observer
class AdminDashboard extends Component {
  constructor() {
    super();
    this.AdminSchoolCard = SatCoreComponent('AdminSchoolCard');

    this.state = {
      permissionId: '',
      scrollKey: 1
    };
  }

  async componentDidMount() {
    const { dynamicSatelliteManager, navigationManager, schoolManager } = this.props;
    const { isDynamicSatellite } = dynamicSatelliteManager;

    // ensure we are still on the AdminDashboard before loading its data
    if (!isDynamicSatellite || window.location.pathname === '/') {
      navigationManager.setView(VIEW_SELECTION.DASHBOARD);

      const urlParams = new URLSearchParams(window.location.search);
      const institutionName = urlParams.get('institutionName');
      if (institutionName) {
        schoolManager.setSearchText(institutionName);
      }
      this.setState({ permissionId: this.getPermissionId() });
      const { searchText } = schoolManager;
      this.fetchInstitutions(searchText);
    }
  }

  getPermissionId() {
    const {
      userManager: {
        isDistrictAdmin, isSchoolAdmin, isDistrictProductAdmin, isSchoolProductAdmin,
        districtAdminId, schoolAdminId, districtProductAdminId, schoolProductAdminId
      }
    } = this.props;
    if (isDistrictAdmin) {
      return districtAdminId;
    } else if (isSchoolAdmin) {
      return schoolAdminId;
    } else if (isDistrictProductAdmin) {
      return districtProductAdminId;
    } else if (isSchoolProductAdmin) {
      return schoolProductAdminId;
    }
    return '';
  }

  /**
   * On input, wait for user to stop typing,
   * then call api for filtered schools when text length >= 3.
   */
  handleChangeSearch = (_event, { value }) => {
    const { schoolManager } = this.props;
    schoolManager.setSearchText(value);
    schoolManager.setSearchTextTimeout(clearTimeout(schoolManager.searchTextTimeout));
    schoolManager.setSearchTextTimeout(setTimeout(() => {
      if (!value || value.length >= 3) {
        this.fetchInstitutions(value);
        this.setState({ scrollKey: this.state.scrollKey + 1 });
      }
    }, 1000));
  }

  handleLoadMore = async (page) => {
    const { schoolManager } = this.props;
    const { searchText } = schoolManager;

    this.fetchInstitutions(searchText, page);
  }

  fetchInstitutions = async (searchText, page = 0) => {
    const { schoolManager, userManager } = this.props;
    const { authKey } = userManager;
    const pageSize = schoolManager.SCHOOL_FETCH_PAGE_SIZE;
    await schoolManager.fetchSatelliteInstitutions(searchText, page, pageSize, authKey);
  }

  render() {
    return (
      <>
        <Container className='admin-dashboard-container'>
          {this.renderAdminTop()}
          {this.renderSchoolCards()}
        </Container>
      </>
    );
  }

  renderAdminTop() {
    return (
      <div className='admin-dashboard-container-top'>
        <div className='admin-dashboard-container-topleft'>
          {this.renderTopWelcome()}
        </div>
        <div className='admin-dashboard-container-topmid'>
          {/* placeholder */}
        </div>
        <div className='admin-dashboard-container-topright'>
          {this.renderTopSearch()}
        </div>
      </div>
    );
  }

  renderTopWelcome() {
    const { appManager, t, userManager } = this.props;
    return (
      <div className='admin-dashboard-welcome-wrapper'>
        <div className='admin-dashboard-welcome theme-header-title'>
          {t('welcomeLabel')}&nbsp;
          <br className='mobile-only' />
          {userManager.firstName}
          &nbsp;
          {userManager.lastName}
          !
        </div>
        <div className='admin-dashboard-welcome-details-wrapper'>
          <div className='admin-dashboard-welcome-details-text'>
            {t('mySchools')}
          </div>
          <div className='admin-dashboard-welcome-details-spacer'>
            |
          </div>
          <div className='admin-dashboard-welcome-details-date'>
            {dateFormat(Date.now(), 'fullDate', appManager.locale)}
          </div>
        </div>
      </div>
    );
  }

  renderTopSearch() {
    const { schoolManager } = this.props;
    return (
      <div className='admin-dashboard-search-wrapper'>
        <Grid>
          <Grid.Column>
            <Input
              icon='search'
              // name='search' // TODO this seems to cause unwanted chrome autofill behavior for some users
              onChange={this.handleChangeSearch}
              placeholder='Search'
              type='text'
              value={schoolManager.searchText} />
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  renderSchoolCards() {
    const { schoolManager } = this.props;
    const { AdminSchoolCard } = this;
    const { scrollKey } = this.state;
    const schoolCards = [];

    schoolManager.schoolsArray.map((school) => {
      schoolCards.push(
        <AdminSchoolCard
          key={`school_${school.id}`}
          {...this.props}
          school={school} />
      );
    });
    const noSchoolsMsg = schoolManager.loaded && !schoolCards.length ?
      <>No schools found.</> : <></>;
    return (
      <div className='admin-dashboard-schoolcards-container'>
        <InfiniteScroll
          key={scrollKey}
          hasMore={schoolManager.loaded && schoolManager.hasMore}
          initialLoad={true}
          loadMore={this.handleLoadMore}
          loader={<Loader key={0} active inline />}
          pageStart={0}
          useWindow={true}>
          {schoolCards}
        </InfiniteScroll>
        {noSchoolsMsg}
      </div>
    );
  }
}

SatCoreRegister('AdminDashboard', AdminDashboard);
