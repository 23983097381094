import React from 'react';

import classNames from 'classnames';

import { registerClass } from '../../SatCoreRegistry';

import { register } from '../../i18n';

const t = register('GradebookTypingDetailsTable');

export default class GradebookTypingService {
  static getGradebookTypingDetailsScorableCellLabel = (_score, {
    headerInfo,
    // shouldRound,
    student,
    trueScoreIndex
  } = {}) => {
    const isKeyboardingType = headerInfo[trueScoreIndex].type === 'Keyboarding';

    if (!isKeyboardingType) {
      return <span>—</span>;
    }

    const speed = student.speeds?.[trueScoreIndex] || 0;
    const speedStr = t('valueSpeed', { speed });

    const accuracy = student.accuracies?.[trueScoreIndex] || 0;
    const accuracyStr = t('valueAccuracy', { accuracy });

    return (
      <div className='cell-typing-score-inner'>
        <div className='cell-typing-score-speed'>
          {speedStr}
        </div>
        <div className='cell-typing-score-accuracy'>
          {accuracyStr}
        </div>
      </div>
    );
  }

  static getGradebookTypingDetailsTableScoreCellCreditClassName = (
    _score, _maxScore, isScorable = true, {
      headerInfo, /* student, */trueScoreIndex
    } = {}) => {
    // TODO for now, we are not color coding the GradebookTypingDetailsTable score cells based on credit
    // const speed = student.speeds?.[trueScoreIndex] || 0;
    // const accuracy = student.accuracies?.[trueScoreIndex] || 0;

    // const parsedAssignmentMetadata = safeJsonParse(student.assignmentMetadata || '{}');
    // const typingMinSpeed = parsedAssignmentMetadata?.typingMinSpeed || 0;
    // const typingMinAccuracy = parsedAssignmentMetadata?.typingMinAccuracy || 0;

    // if (isKeyboardingType) {
    //   if (!speed || !accuracy) {
    //     return classNames('no-credit');
    //   } else if (
    //     (typingMinSpeed && typingMinSpeed < speed) ||
    //     (typingMinAccuracy && typingMinAccuracy < accuracy)
    //   ) {
    //     return classNames('partial-credit');
    //   }
    //   return classNames('full-credit');
    // }
    const isKeyboardingType = headerInfo[trueScoreIndex].type === 'Keyboarding';
    return isScorable ? (
      classNames('scorable-credit', {
        keyboarding: isKeyboardingType
      })
    ) : '';
  }
}

registerClass('GradebookTypingService', GradebookTypingService);
