import { registerClass } from '../../SatCoreRegistry';

import { ASSIGNMENT_STATUS } from '../../managers/AssignmentManager';

import { toFixed } from '../../utils';

// const t = register('GradebookLikertService');

export default class GradebookLikertService {
  static getLikertGradeStr = ({
    instanceInfo,
  } = {}) => {
    const allowLikertGrade = typeof instanceInfo?.likertGrade === 'number' && (
      instanceInfo.submitted ||
      instanceInfo.status !== ASSIGNMENT_STATUS.CLOSED ||
      instanceInfo.status !== ASSIGNMENT_STATUS.COMPLETED
    );

    const likertGrade = allowLikertGrade ? instanceInfo?.likertGrade : undefined;

    const shouldRound = false;
    const roundedLikertGrade = typeof likertGrade === 'number' ? (
      toFixed(likertGrade, shouldRound) || '—'
    ) : '—';

    return roundedLikertGrade;
  }
}

registerClass('GradebookLikertService', GradebookLikertService);
