import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ReactHtmlParser from 'react-html-parser';

import {
  Button,
  Image,
  Item,
  Popup,
  Segment
} from 'semantic-ui-react';

import info from '../img/group-1583.svg';

import assessmentDefaultCardImage from '../img/default-assessment.svg';
import { customAssessmentDefaultCardImageUri } from '../services/UriService';

import '../css/AssignmentCard.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';

import AssignmentService from '../services/AssignmentService';
import CourseService from '../services/CourseService';
import UtilityService from '../services/UtilityService';

export default
@inject('courseManager', 'productManager', 'userManager')
@observer
class AggregateAssignmentCard extends Component {
  constructor(props) {
    super(props);
    this.CautionIcon = SatCoreComponent('CautionIcon');
  }

  getDefaultImage(useExpanded = '') {
    const { courseManager } = this.props;
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    let className = 'default';
    let cardImg = assessmentDefaultCardImage;

    if (courseManager.useSpecialCustomCourseCardImages && isCustomCourse) {
      className = 'custom-default-special';
      cardImg = customAssessmentDefaultCardImageUri;
    }
    return <Image alt='Lesson default image' className={`${className} ${useExpanded}`} src={cardImg} />;
  }

  formatTime = (str) => {
    str = str.replace(/\s/g, '').replace(/^0+/, '');
    return str.toLowerCase();
  }

  handlePresent = async (params) => {
    const { handlePresent } = this.props;
    handlePresent(params);
  }

  render() {
    const { CautionIcon } = this;

    const {
      cardData,
      contentImageUrl,
      handleOpenInfo,
      handleGradebook,
      hideCourseTitle,
      productManager,
      t,
      userManager
    } = this.props;

    // if there is any data to show in info panel
    const showInfoIconButton = AssignmentService.showAggregateAssignmentInfoIconButton(cardData);

    const {
      courseResourceElementTitle, courseResourceElementSubtitle
    } = AssignmentService.getAggregateAssignmentTitleAndDescription(cardData, false);

    let showSubTitle = false;
    if (courseResourceElementSubtitle !== '' && courseResourceElementSubtitle) {
      showSubTitle = true;
    }

    let showCourseTitle = false;
    if (!hideCourseTitle && cardData.courseContentItemName !== null && cardData.courseContentItemName !== '') {
      showCourseTitle = true;
    }

    // Not sure if we will be using expanded image frame in the future so leaving it for now.
    const useExpanded = ''; // useExpandedAssignmentCard ? 'useExpanded' : '';

    const assignmentNameObj = UtilityService.reactHtmlParserWrapper(
      courseResourceElementTitle
    );
    const transformFn = UtilityService.reactHtmlParserTransform;

    const cardEntityTitle = cardData.classroomNickname ? cardData.classroomNickname : (cardData.classroomName || '');

    const strippedAssignmentName = assignmentNameObj.stripped || '';

    // timeframe stuff
    const { courseResourceElementReviewOutsideTimeframe, courseResourceElementTimeframeStartDate, courseResourceElementTimeframeEndDate,
      timeframeStartDateStr, timeframeStartTimeStr, timeframeEndDateStr, timeframeEndTimeStr
     } = cardData;
    const timeframeStartDate = courseResourceElementTimeframeStartDate;
    const timeframeEndDate = courseResourceElementTimeframeEndDate;
    const reviewOutsideTimeframe = courseResourceElementReviewOutsideTimeframe;

    const timeframeData = CourseService.formatTimeframeCardData(reviewOutsideTimeframe, timeframeStartDate, timeframeEndDate, timeframeStartDateStr, timeframeStartTimeStr, timeframeEndDateStr, timeframeEndTimeStr);
    const {
      allowTimeframeViewing,
      formattedTimeframeStartDate,
      formattedTimeframeEndDate,
      formattedTimeframeStartTime,
      formattedTimeframeEndTime,
      showTimeframe,
      timeframeStartPending,
      timeframeEndPassed,
      timeframeText
    } = timeframeData;

    const timeFrameMessage =  timeframeStartPending ? `Assessment is not available until ${formattedTimeframeStartDate} at ${formattedTimeframeStartTime}`
                              :
                              timeframeEndPassed ? `Assessment closed on ${formattedTimeframeEndDate} at ${formattedTimeframeEndTime}` : '';
    // end timeframe stuff

    let popupViewMsg = timeFrameMessage;
    if (cardData.licenseExpired) {
      popupViewMsg = (!userManager.isStudent || productManager.allowShowExpiredLicenseWarningForStudent) ?
        t('expiredLicenseButtonViewMsg') : '';
    }

    const isFileResource = cardData.resourceContentItemEntityTypeId === CONTENT_ITEM_TYPES.FILE_RESOURCE;

    let viewPreviewTranslationKey;
    if (isFileResource) {
      viewPreviewTranslationKey = 'download';
    } else {
      viewPreviewTranslationKey = 'ViewPreview';
    }

    let buttons = (
      <div className='card-button-container card-nav-buttons'>
        {!cardData.licenseExpired && allowTimeframeViewing ? (
          <Button basic onClick={() => {
            if (isFileResource) {
              const fileDownloadUrl = CONTENT_ITEM_TYPES.getFileDownloadUrl(cardData.resourceContentItemId);
              window.open(fileDownloadUrl);
            } else {
              this.handlePresent({ cardData });
            }
          }} primary>
            {t(viewPreviewTranslationKey)}
          </Button>
        ) : (
          <Popup
            hideOnScroll={true}
            hoverable={true}
            on='hover'
            position='bottom center'
            trigger={(
              <Button
                basic
                className='pseudo-disabled'
                primary>
                {t(viewPreviewTranslationKey)}
              </Button>
            )}
            // wide
            wide={undefined}>
            {popupViewMsg}
          </Popup>
        )}

        <Button basic onClick={() => handleGradebook(cardData)} primary>{t('gradebookLabel', 'Gradebook')}</Button>
      </div>
    );

    if (cardData.processing) {
      buttons = (
        <div className='processing-container'>
          <p>processing
            <span className='ellipsis-anim'><span>.</span><span>.</span><span>.</span></span>
          </p>
        </div>
      );
    }

    return (
      <>
        <Item key={cardData.courseResourceElementId} as={Segment}
          className='assignment-card aggregate' data-assignment-name={strippedAssignmentName} data-classroom-name={cardEntityTitle}>
          {contentImageUrl ? <Image className={`content-image ${useExpanded}`} src={contentImageUrl} /> : this.getDefaultImage(useExpanded)}
          <Item.Content>
            <div className='flex-grid main'>
              <div className='card-grid-container'>
                <div className='flex-grid header'>
                  <div className='flex-grid row header'>
                    <div
                      className='flex-header'
                      title={strippedAssignmentName}>
                      {assignmentNameObj.parsed}
                    </div>
                    {showInfoIconButton && (
                      <div className='fg-column img'>
                        {/* {showInfoIconButton && ( */}
                        <Image
                          alt=''
                          className='img-info'
                          onClick={() => handleOpenInfo(
                            cardData, contentImageUrl
                          )}
                          src={info} />
                        {/* )} */}
                      </div>
                    )}
                    {cardData.licenseExpired && (
                      !userManager.isStudent || productManager.allowShowExpiredLicenseWarningForStudent
                    ) && (<CautionIcon expiredLicenseCautionIcon />)}
                  </div>
                </div>
                <div className='flex-grid'>
                  <div className='flex-grid row'>
                    <div className='subtitle'>
                      <span className='expander'>
                        {(showSubTitle) &&
                          ReactHtmlParser(courseResourceElementSubtitle,
                            { transform: transformFn }
                          )}
                      </span>
                      {(showSubTitle && showCourseTitle) &&
                        <span className='spacer'>|</span>}
                      {showCourseTitle && (
                        <span className='expander'>
                          <b>{t('course')}</b>
                          {ReactHtmlParser(
                            cardData.courseContentItemName,
                            { transform: transformFn }
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className='flex-grid'>
                  <div className='flex-grid row'>
                    <div className='classroomName'>
                      {cardEntityTitle}
                    </div>
                  </div>
                </div>
                <div className='flex-grid'>
                  <div className='flex-grid row'>
                    <div className='classroomName'>
                      {showTimeframe && (
                        <div className='fg-column timeframe'>
                          {timeframeText}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {buttons}
            </div>
          </Item.Content>
        </Item>
      </>
    );
  }
}

SatCoreRegister('AggregateAssignmentCard', AggregateAssignmentCard);
