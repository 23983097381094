import { action, computed, makeObservable, observable } from 'mobx';

// import { getRegisteredClass } from '../SatCoreRegistry';

import Auth from './AuthManager';

import { generateUrl } from '../utils/url';

const ENGAGEMENT_ENDPOINTS = {
  // FETCH_AGGREGATE_GRADEBOOK_TYPING_ENGAGEMENT: '/api/viewAggregateGradebookTyping' // TODO unused/defunct
  FETCH_AGGREGATE_GRADEBOOK_ENGAGEMENT: '/api/viewAggregateGradebookEngagement',
};

export class EngagementManager {
  // 'summary' | 'timeOnTaskRatio' | 'feedbackUsage' |
  // 'resourcesUsage' | 'annotationsUsage' | 'languageToolUsage'
  @observable activeEngagementTable = 'summary';

  @observable engagementData = [];
  @observable engagementDataTotal = 0;

  @observable loadingEngagementData = false;

  constructor() {
    makeObservable(this);
  }

  @action clearAll = () => {
    this.clearEngagementTable();
  }

  @action clearEngagementTable = () => {
    this.setEngagementData([]);
    this.setEngagementDataTotal(0);
  }

  @action fetchAggregateGradebookEngagement = async (assignment) => {
    try {
      const pageSize = this.ENGAGEMENT_TABLE_ROWS_PAGE_SIZE;
      this.setLoadingEngagementData(true);
      const skip = this.engagementData.length;

      const apiUrlPrefix = `${Auth.ecms}${ENGAGEMENT_ENDPOINTS.FETCH_AGGREGATE_GRADEBOOK_ENGAGEMENT}`;
      const { contentItemId, courseContentItemId } = assignment;
      let classroomId;
      if (assignment.classroomId) {
        classroomId = assignment.classroomId;
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        classroomId = urlParams.get('classroomId');
      }
      let apiUrl = generateUrl(apiUrlPrefix, {
        classroomId,
        contentItemId,
        courseContentItemId
      });

      apiUrl += `&skip=${skip}`;
      apiUrl += `&pageSize=${pageSize}`;

      const response = await Auth.fetch(apiUrl, {
        method: 'GET'
      });
      if (!response.data) {
        throw new TypeError('fetchAggregateGradebookEngagement: response.data not found');
      }

      this.setEngagementDataTotal(response.pageTotal);
      this.setEngagementData(response.data);

      this.setLoadingEngagementData(false);
    } catch (error) {
      console.error(error);
      this.setLoadingEngagementData(false);
    }
  }

  // TODO unused/defunct - implementation for TY-35 has been replaced with TY-66
  // @action fetchAggregateGradebookTypingEngagement = async (assignment) => {
  //   try {
  //     const pageSize = this.ENGAGEMENT_TABLE_ROWS_PAGE_SIZE;
  //     this.setLoadingEngagementData(true);
  //     const skip = this.engagementData.length;

  //     const apiUrlPrefix = `${Auth.ecms}${ENGAGEMENT_ENDPOINTS.FETCH_AGGREGATE_GRADEBOOK_TYPING_ENGAGEMENT}`;
  //     const { contentItemId, courseContentItemId } = assignment;
  //     let classroomId;
  //     if (assignment.classroomId) {
  //       classroomId = assignment.classroomId;
  //     } else {
  //       const urlParams = new URLSearchParams(window.location.search);
  //       classroomId = urlParams.get('classroomId');
  //     }
  //     let apiUrl = generateUrl(apiUrlPrefix, {
  //       classroomId,
  //       contentItemId,
  //       courseContentItemId
  //     });

  //     apiUrl += `&skip=${skip}`;
  //     apiUrl += `&pageSize=${pageSize}`;

  //     const response = await Auth.fetch(apiUrl, {
  //       method: 'GET'
  //     });

  //     const modifiedResponseData = response.data?.map((obj) => {
  //       const { speed, accuracy, viewedTime, playedTime, stars, totalScore, challengeNumber, ...coreData } = obj;
  //       const engagementData = {
  //         accuracy,
  //         challengeNumber,
  //         playedTime,
  //         speed,
  //         stars,
  //         totalScore,
  //         viewedTime
  //       };
  //       return {
  //         ...coreData,
  //         engagementData
  //       };
  //     });

  //     if (!response.data) {
  //       throw new TypeError('fetchAggregateGradebookTypingEngagement: response.data not found');
  //     }

  //     this.setEngagementDataTotal(response.pageTotal);
  //     this.setEngagementData(modifiedResponseData);

  //     this.setLoadingEngagementData(false);
  //   } catch (error) {
  //     console.error(error);
  //     this.setLoadingEngagementData(false);
  //   }
  // }

  @action setLoadingEngagementData = (loading) => {
    this.loadingEngagementData = loading;
  }

  @action setActiveEngagementTable = (activeEngagementTable) => {
    this.activeEngagementTable = activeEngagementTable;
  }

  @action setEngagementData = (data) => {
    if (!data || !data.length) {
      this.engagementData = [];
      return;
    }
    this.engagementData = [...this.engagementData, ...data];
  }

  @action setEngagementDataTotal = (pageTotal) => {
    this.engagementDataTotal = pageTotal;
  }

  @computed get ENGAGEMENT_TABLE_ROWS_PAGE_SIZE() {
    return 25;
  }
}

export default new EngagementManager();
