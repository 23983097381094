import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container, Dropdown, Loader
} from 'semantic-ui-react';

import '../../css/ContentElementModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import GradebookNavigationService from '../../services/gradebook/GradebookNavigationService';

import Modal from '../Modal';

export default @inject(
  'assignmentManager', 'classroomManager', 'courseManager')
@observer
class ContentElementModal extends Component {
  state = {
    courseElementList: null,
    currentCourseElement: null,
    loading: true
  }

  constructor(props) {
    super(props);
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.AssignmentCard = SatCoreComponent('AssignmentCard');
    this.CourseList = SatCoreComponent('CourseElementList');
  }

  async componentDidMount() {
    await this.fetchData(0);
  }

  closeContentElementModal = async ({ navToAggregateGradebook = false } = {}) => {
    const { courseManager, t } = this.props;
    const allAssignmentsLabel = t('gradebookLabel', 'Gradebook');

    this.props.closeContentAssignmentModal({
      allAssignmentsLabel, navToAggregateGradebook
    });
    courseManager.clearCourseElementCourseOptions();

    if (navToAggregateGradebook) {
      await GradebookNavigationService.navToAggregateGradebookSummaryFromExternalModal({
        ...this.props, allAssignmentsLabel
      });
    }
  }

  fetchData = async (page) => {
    this.setState({ loading: true });

    const {
      assignmentManager, cmapElementId, courseManager,
      currentClassroomId, showFirstCourseElementOnly, standardId, studentId
    } = this.props;

    if (standardId) {
      await courseManager.fetchCourseElementsByStandardData(currentClassroomId, standardId, studentId, null, page, 0);
      const courseElementList = courseManager.getCourseElementListById(currentClassroomId + standardId) || [];
      this.setState({
        courseElementList: showFirstCourseElementOnly ? [courseElementList[0]] : courseElementList,
        loading: false
      });
    } else {
      await courseManager.fetchCourseElementsByCmapElementData(
        currentClassroomId, cmapElementId, studentId, null, page, 0
      );
      let courseElementList = courseManager.getCourseElementListById(currentClassroomId + cmapElementId);
      if (showFirstCourseElementOnly) {
        if (courseElementList && courseElementList.length) {
          courseElementList = [courseElementList[0]];
        }
      }
      const currentCourseElement = courseElementList ? courseElementList[0] : null;

      if (currentCourseElement) {
        const { courseContentItemId } = currentCourseElement;
        const courseResourceElementId = null;
        const { currentClassroomId } = this.props;
        const resourceContentItemId = currentCourseElement.entityId;
        const page = 0;
        const dialogOpen = false;

        await assignmentManager.fetchContentAssignments(
          courseContentItemId, courseResourceElementId,
          currentClassroomId, resourceContentItemId,
          page, dialogOpen
        );
      }

      this.setState({
        courseElementList,
        currentCourseElement,
        loading: false
      });
    }
  }

  filterCourseContent = async (_event, { value }) => {
    const { courseManager, currentClassroomId, standardId, cmapElementId, studentId } = this.props;
    this.setState({ courseElementList: null });
    if (standardId) {
      courseManager.fetchCourseElementsByStandardData(
        currentClassroomId, standardId, studentId, value, 0, 0
      ).then(() => {
        this.setState({
          courseElementList: courseManager.getCourseElementListById(currentClassroomId + standardId)
        });
      });
    } else {
      courseManager.fetchCourseElementsByCmapElementData(
        currentClassroomId, cmapElementId, studentId, value, 0, 0
      ).then(() => {
        this.setState({
          courseElementList: courseManager.getCourseElementListById(currentClassroomId + cmapElementId)
        });
      });
    }
  };

  render() {
    const { CourseList } = this;
    const {
      classroomManager, contentAssignmentsOpen, contentName,
      courseManager, infoPopupText, labelInfo, modalBannerTitle,
      showCourseDropdown, t
    } = this.props;

    const { courseElementList, loading } = this.state;

    const { courseElementCourseOptions } = courseManager;
    const { ModalBanner } = this;

    let modalInnerContentJsx = <></>;

    if (!loading) {
      modalInnerContentJsx = (
        <div className='ui items parent'>
          {(!courseElementList || !courseElementList.length) ?
            <>{t('noContentFoundToAssign')}</> : (
              <CourseList
                closeContentAssignments={this.closeContentAssignments}
                courseElementList={courseElementList}
                currentClassroomId={classroomManager.currentClassroomId}
                currentCourseId={courseManager.currentCourseId}
                currentElement={null}
                handleAddAssignment={this.props.handleAddAssignment}
                handleAnswerKey={this.props.handleAnswerKey}
                handleOpenInfo={this.props.handleOpenInfo}
                handlePresent={this.props.handlePresent}
                handleView={this.props.handleView}
                history={this.props.history}
                isTeacher={true}
                showContentAssignments={this.props.showContentAssignments} />
            )}
        </div>
      );
    } else if (loading) {
      return <Loader active />;
    }

    return (
      <>
        <Modal
          className='ContentElementModal'
          closeOnDimmerClick={false}
          closeOnEscape={true}
          onClose={this.closeContentElementModal}
          open={contentAssignmentsOpen}
          size='large'>

          <ModalBanner
            infoPopupText={infoPopupText}
            label={labelInfo}
            onClose={this.closeContentElementModal}
            title={modalBannerTitle || contentName} />

          <Modal.Content>
            {showCourseDropdown && courseElementCourseOptions && courseElementCourseOptions.length > 1 && (
              <Container className='filter-wrapper'>
                <Dropdown
                  defaultValue={0}
                  fluid
                  onChange={this.filterCourseContent}
                  options={courseElementCourseOptions}
                  placeholder='All Courses' />
              </Container>
            )}
            <Container className='scroller-wrapper' fluid>
              {modalInnerContentJsx}
            </Container>
          </Modal.Content>
          <Modal.Actions>
            <Button basic className='cancelButton' onClick={this.closeContentElementModal} primary>Close</Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

SatCoreRegister('ContentElementModal', ContentElementModal);
