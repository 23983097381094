import React, { useContext, useEffect, useState } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import { Container } from 'semantic-ui-react';

import '../../css/reports/ReportView.less';

import { SatCoreComponent, SatCoreRegister, getRegisteredClass } from '../../SatCoreRegistry';

import { VIEW_SELECTION } from '../../managers/NavigationManager';

import reportContextManager from '../../managers/reports/ReportContextManager';

import {
  REPORT_FAILURE_BEHAVIOR_TYPE, REPORT_LESSON_FILTER_TYPE, REPORT_TABLE_TYPE, REPORT_TYPE
} from '../../services/reports/ReportConstants';

import GradebookNavigationService from '../../services/gradebook/GradebookNavigationService';
import ReportBreadcrumbService from '../../services/reports/ReportBreadcrumbService';
import ReportIdentityService from '../../services/reports/ReportIdentityService';
import ReportJsonHelperService from '../../services/reports/ReportJsonHelperService';
import ReportMoreFiltersService from '../../services/reports/ReportMoreFiltersService';
import ReportOverrideService from '../../services/reports/ReportOverrideService';
import ReportTypeService from '../../services/reports/ReportTypeService';

const ReportView = observer((props) => {
  const { history } = props;

  const {
    navigationManager, reportCourseManager, reportIdentityManager,
    reportOverrideManager, reportStandardsManager, reportTableManager,
    reportTypeManager
  } = useContext(MobXProviderContext);

  const [loadingReportView, setLoadingReportView] = useState(true);

  const BreadCrumbs = SatCoreComponent('BreadCrumbs');
  const Footnotes = SatCoreComponent('Footnotes');
  const LTIReportBreadCrumbs = SatCoreComponent('LTIReportBreadCrumbs');
  const ReportStandardsClassroomDetailStudentsTable = SatCoreComponent('ReportStandardsClassroomDetailStudentsTable');
  const ReportTable = SatCoreComponent('ReportTable');
  const ReportHeader = SatCoreComponent('ReportHeader');
  const SatCoreLoader = SatCoreComponent('SatCoreLoader');

  const { pathname } = window.location;
  const isLti = pathname.includes('/lti-courses');

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    (async () => {
      setLoadingReportView(true);
      navigationManager.setView(VIEW_SELECTION.REPORTS);

      reportTableManager.clearHiddenReportTableFacultyCellsMap();

      const { activeReportId } = reportIdentityManager;
      const { allowLessonFilterDropdown } = reportOverrideManager;
      const { selectedReportLessonFilterType } = reportTypeManager;

      const isMoreFiltersTypeFilterSelected = ReportMoreFiltersService.isTypeFilterSelected();

      const { reportType, reportFacultyType } = ReportIdentityService.parseReportTypesFromBaseUrl();

      const isStandardsReport = reportType === REPORT_TYPE.STANDARDS;
      const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();

      const {
        RETRY_WITH_PARSED_REPORT_ID_AND_FORCE_NO_DATA_STATE,
        NORMAL
        // TODO remove // RETRY_WITH_DIFFERENT_COURSE_OR_CMAP
      } = REPORT_FAILURE_BEHAVIOR_TYPE;
      let failureBehaviorType = NORMAL, reportId;
      // check for reportId special cases, else set the normal reportId for the current report
      const hasSpecialLessonFilterTypeSelected = selectedReportLessonFilterType
        && selectedReportLessonFilterType !== REPORT_LESSON_FILTER_TYPE.ALL;

      if (isStandardsReport && allowLessonFilterDropdown && hasSpecialLessonFilterTypeSelected) {
        // STANDARDS SPECIAL CASE - `hasSpecialLessonFilterTypeSelected`
        reportId = ReportTypeService.getLessonFilterReportIdIfApplicable(selectedReportLessonFilterType);
        if (reportId) {
          failureBehaviorType = RETRY_WITH_PARSED_REPORT_ID_AND_FORCE_NO_DATA_STATE;
        }
        reportId = reportId || ReportIdentityService.getReportId(reportType, reportFacultyType);
      } else if (shouldUseCmapId && isMoreFiltersTypeFilterSelected) {
        // STANDARDS SPECIAL CASE - `moreFiltersSelectedCount > 0`
        const { selectedReportMoreFiltersContentType, selectedReportMoreFiltersAssignmentType } = reportStandardsManager;
        reportId = ReportIdentityService.getReportId(
          selectedReportMoreFiltersContentType, selectedReportMoreFiltersAssignmentType, {
            isStandardsFilterReportId: true
          });
      } else {
        reportId = ReportIdentityService.getReportId(reportType, reportFacultyType);
      }

      if (!activeReportId || (reportId !== activeReportId)) {
        ReportIdentityService.clearAllReportManagers();

        const courseId = await initReportCourseDropdownDataIfApplicable(reportId);
        const curriculumMapId = await initReportCmapDataIfApplicable(reportId);

        await reportIdentityManager.fetchReportResponseJson({
          // TODO remove // failureBehaviorType: curriculumMapId ? RETRY_WITH_DIFFERENT_COURSE_OR_CMAP : failureBehaviorType,
          courseId,
          curriculumMapId,
          failureBehaviorType,
          reportId
        });

        const cmaps = ReportJsonHelperService.REPORT_CMAPS();
        const { isFacultyClassroomReport } = reportIdentityManager;

        !!isFacultyClassroomReport && await reportStandardsManager.setReportCmapsByInstitutionMap(cmaps);

        await ReportBreadcrumbService.initBreadcrumbs();
        setLoadingReportView(false);
      } else {
        await ReportBreadcrumbService.initBreadcrumbs();
        setLoadingReportView(false);
      }
    })();
  }, []);

  // TODO unused
  /* equivalent to componentDidUpdate(), i.e. called whenever component is updated */
  // useEffect(() => {
  //   /* placeholder */
  // });

  // TODO unused
  /** equivalent to componentWillUnmount(), i.e. called whenever component is unmounting */
  // useEffect(() => () => {
  //   /* placeholder */
  // });

  const initReportCourseDropdownDataIfApplicable = async (reportId) => {
    const ReportOverrideService = getRegisteredClass('ReportOverrideService');
    const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();

    const isFacultyAdminReport = reportId.includes('DIST') || reportId.includes('SCH');
    if (isFacultyAdminReport && shouldUseCmapId) {
      return;
    }
    const isStandardsReport = reportId.includes('STANDARDS');
    const isStandardsAdminReport = isStandardsReport && isFacultyAdminReport;
    await reportCourseManager.fetchReportCourseNamesByInstitutionMap({
      shouldFetch: isFacultyAdminReport
    });

    const { selectedReportCourseContentItemId } = reportCourseManager;
    let courseId; // used by standards admin reports only (as part of entityId for `api/viewReportOutput`)
    if (isStandardsReport) {
      if (isStandardsAdminReport) {
        // fetch report of first resource in the `reportCourseNamesByInstitutionMap` as a temporary reportResponseJson placeholder
        const courseNamesMap = reportCourseManager.reportCourseNamesByInstitutionMap;
        // eslint-disable-next-line prefer-destructuring
        courseId = selectedReportCourseContentItemId || Array.from(courseNamesMap.keys())[0];
        return courseId;
      } else {
        courseId = selectedReportCourseContentItemId;
        return courseId;
      }
    }
  };

  const initReportCmapDataIfApplicable = async (reportId) => {
    const ReportOverrideService = getRegisteredClass('ReportOverrideService');
    const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();

    const isFacultyAdminReport = reportId.includes('DIST') || reportId.includes('SCH');

    if (!shouldUseCmapId || !isFacultyAdminReport) {
      return;
    }
    const isStandardsReport = reportId.includes('STANDARDS');
    const isStandardsAdminReport = isStandardsReport && isFacultyAdminReport;

    isFacultyAdminReport && await reportStandardsManager.fetchReportCmapObjsByInstitutionMap();

    const { selectedReportCmapContentItemId } = reportStandardsManager;

    let cmapId;
    if (isStandardsReport) {
      if (isStandardsAdminReport) {
        // fetch report of first resource in the `reportCmapObjsByInstitutionMap` as a temporary reportResponseJson placeholder
        const cmapObjsByInstitutionMap = reportStandardsManager.reportCmapObjsByInstitutionMap;
        // eslint-disable-next-line prefer-destructuring
        cmapId = selectedReportCmapContentItemId || Array.from(cmapObjsByInstitutionMap.keys())[0];
        return cmapId;
      } else {
        cmapId = selectedReportCmapContentItemId;
        return cmapId;
      }
    }
  };

  const handleViewNextReport = (options = {}) => {
    const {
      classroomId = null,
      classroomStatus = null,
      courseId = null,
      institutionId = null,
      institutionName = null,
      specificReportUrl = null,
      studentFullName = null,
      studentId = null,
      ...rest
    } = options;

    const fromAdminClassroomsView = ReportIdentityService.userCameFromAdminClassroomsView() || null;

    // NOTE: keep these declarations here.
    // declaring them at the top of the component was causing issues when attempting to get actual current values of some props
    const {
      activeReportFacultyType, activeReportTableType, activeReportType,
      isFacultyClassroomReport, isTableIndividualReport
    } = reportIdentityManager;

    reportTableManager.setShouldUpdateReportTableExpandedRows(false);

    if (specificReportUrl) {
      history.push(specificReportUrl);
      return;
    }

    let nextReportFacultyType, nextReportTableType;

    // for debugging purposes; i.e. this should never happen in a real case scenario
    const isLastReport = isFacultyClassroomReport && isTableIndividualReport;
    if (isLastReport) {
      return; // no need to run through the remaining logic since there is no 'next' report
    }

    const reportTableTypesForActiveFacultyType = ReportIdentityService.getOrderedReportTableTypes(activeReportFacultyType);
    const lastIndex = reportTableTypesForActiveFacultyType.length - 1;
    const lastTableTypeForActiveFacultyType = reportTableTypesForActiveFacultyType[lastIndex];

    if (activeReportTableType === lastTableTypeForActiveFacultyType) {
      nextReportFacultyType = ReportIdentityService.getNextReportFacultyType();
      const reportTableTypesForNextFacultyType = ReportIdentityService.getOrderedReportTableTypes(nextReportFacultyType);
      const firstTableTypeForNextFacultyType = reportTableTypesForNextFacultyType[0];
      nextReportTableType = firstTableTypeForNextFacultyType;
    } else {
      nextReportFacultyType = activeReportFacultyType;
      nextReportTableType = ReportIdentityService.getNextReportTableType();
    }

    const nextReportId = ReportIdentityService.getReportId(activeReportType, nextReportFacultyType);

    const nextReportUrl = ReportIdentityService.getReportUrl(
      activeReportType,
      nextReportFacultyType,
      nextReportTableType, {
        classroomId,
        classroomStatus,
        courseId,
        fromAdminClassroomsView,
        institutionId,
        institutionName,
        reportId: nextReportId,
        studentFullName,
        studentId,
        ...rest
      }
    );

    if (history) {
      history.push(nextReportUrl);
    } else if (isLti) {
      if (nextReportUrl.includes('standards-classroom-detail')) {
        reportIdentityManager.setLtiReportTable(REPORT_TABLE_TYPE.DETAIL);
      } else if (nextReportUrl.includes('standards-classroom-individual')) {
        reportIdentityManager.setLtiReportTable(REPORT_TABLE_TYPE.INDIVIDUAL);
        reportIdentityManager.setStudentId(studentId);
        reportIdentityManager.setStudentFullName(studentFullName);
      }
    }
  };

  const navToAggregateGradebook = async (cardData) => {
    await GradebookNavigationService.navToAggregateGradebookSummaryFromExternalModal({
      allAssignmentsLabel: undefined,
      assignCardData: cardData,
      currentClassroomId: cardData.classroomId,
      history,
      urlParamStr: window.location.search
    }, reportContextManager.reportContextType);
  };

  const renderReportBreadcrumbsComponent = () => {
    return (
      <Container className='report-breadcrumbs bread-crumb-wrapper' fluid>
        {history && (
          <BreadCrumbs {...props} />
        )}
        {isLti && (
          <LTIReportBreadCrumbs />
        )}
      </Container>
    );
  };

  const renderReportHeaderComponent = () => {
    return (
      <ReportHeader {...props} />
    );
  };

  const renderReportTableComponent = () => {
    const isReportStandardsClassroomDetailStudents = ReportIdentityService.isReportStandardsClassroomDetailStudents();
    if (isReportStandardsClassroomDetailStudents) {
      return (
        <ReportStandardsClassroomDetailStudentsTable {...props}
          handleViewNextReport={handleViewNextReport} />
      );
    } else {
      return (
        <ReportTable {...props}
          handleViewNextReport={handleViewNextReport}
          navToAggregateGradebook={navToAggregateGradebook} />
      );
    }
  };

  const renderFootnotesIfApplicable = () => {
    const { isFacultyClassroomReport } = reportIdentityManager;
    return isFacultyClassroomReport && (
      <Footnotes additionalClassNames='print-only' showModifiedContentFootnote />
    );
  };

  const { hasReportResponseJsonError, reportResponseJson } = reportIdentityManager;
  if (hasReportResponseJsonError) {
    // eslint-disable-next-line no-alert
    alert('Sorry, this report was not found. No data to display.');
    ReportIdentityService.clearAllReportManagers();
    history.goBack();
    return <SatCoreLoader />;
  } else if (!loadingReportView && reportResponseJson) {
    return (
      <div className={`report-view ${reportIdentityManager.reportInfoClassNames}`}>
        {renderReportBreadcrumbsComponent()}
        {renderReportHeaderComponent()}
        {renderReportTableComponent()}
        {renderFootnotesIfApplicable()}
      </div>
    );
  } else {
    return <SatCoreLoader />;
  }
});
export default ReportView;

SatCoreRegister('ReportView', ReportView);
