import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Container, Header, Input, Item, Loader, Menu, Pagination, Popup } from 'semantic-ui-react';

import classNames from 'classnames';

import '../css/AssignmentView.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';
import tagContentManager from '../managers/TagContentManager';

import { CONTENT_MODE, PLAYER_TYPES } from '../managers/ContentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import { ASSIGNMENT_TYPE, TEACHER_ASSIGNMENT_MENU_STATUS } from '../managers/AssignmentManager';

import AssignmentService from '../services/AssignmentService';
import CourseService from '../services/CourseService';
import ContentService from '../services/ContentService';
import GradebookNavigationService from '../services/gradebook/GradebookNavigationService';
import ImageService from '../services/ImageService';

import { dateFormat, setDocumentTitleFromSelectionView, stripHtmlTags, unfocus } from '../utils';

import InfiniteScrollResetable from '../components/InfiniteScrollResetable';

export default @inject(
  'assignmentManager',
  'classroomManager',
  'contentManager',
  'courseManager',
  'dialogManager',
  'filteredHeaderTableManager',
  'navigationManager',
  'userManager'
)
@observer
class AssignmentView extends Component {
  filteredHeaderTableKey='teacher-assignment-list-table-key';

  constructor(props) {
    super(props);
    this.AssignmentFilter = SatCoreComponent('AssignmentFilter');
    this.FilteredHeaderTable = SatCoreComponent('FilteredHeaderTable');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.LearnosityPlayerModal = SatCoreComponent('LearnosityPlayerModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
    this.DocReaderModal = SatCoreComponent('DocReaderModal');
    this.AggregateAssignmentCard = SatCoreComponent('AggregateAssignmentCard');
    this.InfiniteScrollResetable = SatCoreComponent('InfiniteScrollResetable'); // resetable wrapper component for IS

    this.state = {
      addInfoOpen: false,
      assignmentId: null,
      assignmentInstruction: null,
      contentImageUrl: null,
      contentItemDescription: null,
      contentItemId: '',
      contentItemImageUrl: null,
      contentName: '',
      contentSubtitle: '',
      editAssignmentId: null,
      filePlayerShowing: false,
      infoName: null,
      instruction: null,
      isUpdating: false,
      learnosityPlayerShowing: false,
      loadingAssignmentView: false,
      pageStart: 0,
      resourceInfo: null,
      search: ''
    };
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const { assignmentManager, classroomManager, filteredHeaderTableManager, navigationManager, t } = this.props;

    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.ASSIGNMENTS);
    }

    const settings = assignmentManager.filteredAssignmentSettings || {};
    const menuStatus = settings.menuStatus || TEACHER_ASSIGNMENT_MENU_STATUS.ASSIGNED;

    setDocumentTitleFromSelectionView(urlParams.get('view') || VIEW_SELECTION.ASSIGNMENTS, {
      VIEW_SELECTION,
      suffix: menuStatus === 'list' ? t('assignmentListLabel') : t(TEACHER_ASSIGNMENT_MENU_STATUS.getTitle(menuStatus))
    });

    if (urlParams.has('classroomId') && classroomManager.currentClassroomId === null) {
      classroomManager.setCurrentClassroomId(urlParams.get('classroomId'));
    }

    filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  // re-fetch activities if any previously returned activities are still in the process of being created
  poll = async () => {
    const { assignmentManager } = this.props;

    // if there are activities being created, re-fetch until they're done processing
    const { hasProcessingActivities } = assignmentManager;

    // stop polling when there are no activities processing
    if (!hasProcessingActivities) {
      clearInterval(this.timer);
      this.timer = null;
    }

    const settings = assignmentManager.filteredAssignmentSettings || {};
    const { menuStatus } = settings;
    const assignedOrAll = menuStatus === 'assigned' || menuStatus === 'all';

    if (hasProcessingActivities && assignedOrAll) {
      // re-fetch activities
      const assignerEntityIds = settings.assignerEntityIds || null;
      const assignerType = settings.assignerType || 'all';
      const assignmentType = settings.assignmentType || '';
      const assignToIds = settings.assignToIds || null;
      const classroomId = settings.classroomId || '';
      const contentTypes = settings.contentTypes || '';
      const dateSearchType = settings.dateSearchType || 'forward';
      const selectedGroupIds = settings.selectedGroupIds || '';
      const startDateStr = settings.filterDateStr || null;
      const endDateStr = settings.filterEndDateStr || null;
      const search = settings.search || null;
      const status = settings.status || null;

      this.resetSearch(classroomId, startDateStr, status, assignmentType, search, contentTypes,
        assignerType, dateSearchType, selectedGroupIds, endDateStr, assignerEntityIds, assignToIds);
    }
  }

  handleOpenInfo = (cardData, contentItemImageUrl) => {
    const { dialogManager } = this.props;

    dialogManager.setOpenDialog(DIALOG_NAMES.INFO, {
      contentItemDescription: cardData.resourceContentItemDescription,
      contentItemImageUrl,
      infoName: cardData.courseResourceElementTitle || cardData.resourceContentItemName,
      resourceInfo: cardData.courseResourceElementResourceInformation,
      studentInstruction: cardData.courseResourceElementResourceInstruction,
    }, () => dialogManager.closeDialog(DIALOG_NAMES.INFO));
  }

  handleGradebook = async (cardData) => {
    const { history, navigationManager, t } = this.props;
    navigationManager.clearAllPaths(true);
    await GradebookNavigationService.navToAggregateGradebookSummaryFromExternalModal(
      {
        allAssignmentsLabel: t('assignmentsLabel', 'Assignments'),
        assignCardData: cardData,
        currentClassroomId: cardData.classroomId,
        fromAssignments: window.location.pathname?.includes('/assignments'),
        history
      }
    );
    // history.push(`/gradebook?contentData=${assignment.id}&view=${VIEW_SELECTION.GRADEBOOK}&fromAssignments=true&classroomId=${assignment.classroomId}`);
  }

  renderAssignmentCards = () => this.renderAggregateAssignmentCardsTeacher()

  renderAggregateAssignmentCardsTeacher = () => {
    const { assignmentManager } = this.props;
    const { AggregateAssignmentCard } = this;
    const items = [];
    assignmentManager.aggregateAssignmentArray.map((cardData) => {
      const imageData = {
        contentItemId: cardData.resourceContentItemId,
        elementContentItemImageUrl: cardData.courseContentItemImageUrl,
        imageUrl: cardData.resourceContentItemImageUrl
      };
      const contentImageUrl = ImageService.getImageUrl(imageData);
      const entityId = cardData.classroomId + cardData.courseResourceElementId + cardData.processing;
      items.push(<AggregateAssignmentCard
        key={`AAC_${entityId}`}
        cardData={cardData}
        contentImageUrl={contentImageUrl}
        dialogIncludesAssignmentInfo={true}
        handleGradebook={this.handleGradebook}
        // handleAnswerKey={this.handleAnswerKey}
        // handleEditAssignment={this.handleEditAssignment}
        handleOpenInfo={this.handleOpenInfo}
        handlePresent={this.handleView} /> // if the DIALOG_NAMES.ASSIGNMENT_INFO is used, it includes assignment info.
      );
      return true;
    });
    return items;
  };

  fetchAssignments = async (
    classroomId, startDate, endDate, status, assignmentType,
    search, contentTypes, assignerType, dateSearchType, currentPage = 0, clear = true, groupIds = null,
    assignerEntityIds = null, assignToIds = null
  ) => {
    const { assignmentManager, filteredHeaderTableManager } = this.props;
    const settings = assignmentManager.filteredAssignmentSettings || {};

    // For search only set times to the dates so the range works inclusively.
    const startRealDate = assignmentManager.convertJSStringToJSDate(startDate, '00:00:00');
    const endRealDate = assignmentManager.convertJSStringToJSDate(endDate, '23:59:59');

    // For assignment list use different api
    if (settings?.menuStatus && settings.menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.LIST) {
      const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
      await assignmentManager.fetchTeacherAggregateAssignmentSeries(classroomId, dateSearchType, startRealDate, endRealDate, status,
        assignmentType, search, contentTypes, assignerType, tableData.activePage, clear, 20, groupIds, assignerEntityIds, assignToIds);
    } else {
      await assignmentManager.fetchTeacherAggregateAssignments(classroomId, dateSearchType, startRealDate, endRealDate, status,
        assignmentType, search, contentTypes, assignerType, currentPage, clear, null, groupIds, assignerEntityIds, assignToIds);
    }
  }

  handleLoadMore = async (page) => {
    const { assignmentManager } = this.props;
    // console.log(`page: ${page}`);
    await assignmentManager.fetchMoreTeacherAggregateAssignments(page, false);
  }

  fetchTags = async () => {
    const result = await tagContentManager.fetchContentCategoryTagsByName();
    if (!result) {
      /* no tags used */
    }
  }

  getPublisherModeId = async (params) => {
    const { cardData, contentMode, pdfFormatSelected = null, publisherModeId = null } = params;
    const { dialogManager, userManager } = this.props;
    const { modeOverrideAllowed } = cardData;
    const alternateModeId = publisherModeId || cardData.alternateModeIdOverride;
    if (userManager.isTeacher && publisherModeId === null && modeOverrideAllowed) {
      // If mode override is allowed, open a modal for the teacher to select a mode for preview
      dialogManager.setOpenDialog(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL, {
        close: this.closeSelectPreviewModeModal,
        contentMode,
        courseElement: cardData,
        open: true,
        pdfFormatSelected,
        setPreviewModeId: this.setPreviewModeId
      });
    } else if (userManager.isTeacher && alternateModeId !== null) {
      return this.handleView({ cardData, alternateModeId });
    } else {
      // eslint-disable-next-line react/destructuring-assignment
      return this.handleView({ cardData });
    }
  }

  closeSelectPrevieModeModal = () => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL);
  }

  setPreviewModeId = async (courseElement, contentMode = null, pdfFormatSelected = null, publisherModeId) => {
    const { dialogManager } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    this.handleView({ cardData: courseElement, publisherModeId });
    dialogManager.closeDialog(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL);
  };

  handleView = async (params) => {
    const { cardData } = params;
    let { publisherModeId = null } = params;
    const {
      contentManager,
      courseManager,
      dialogManager,
      userManager,
      classroomManager
    } = this.props;

    const contentMode = CONTENT_MODE.PREVIEW;
    params.contentMode = contentMode;

    // TODO get the courseResourceElement using cardData.courseResourceElementId
    const courseElement = await courseManager.fetchCourseResourceElement(cardData.courseResourceElementId);

    if (!courseElement) {
      // eslint-disable-next-line no-console
      console.log(`courseResourceElement not found for elementId: ${cardData.courseResourceElementId}`);
      return;
    } else if (cardData.modeOverrideAllowed === undefined) {
      cardData.modeOverrideAllowed = courseElement.modeOverrideAllowed;
    }
    if (publisherModeId === null && cardData.modeOverrideAllowed) {
      this.getPublisherModeId(params);
      return;
    } else {
      publisherModeId = courseElement.alternateModeId;
    }

    let option = null;
    const { deliveryMode, entityTypeId, id, name, pdfDeliveryFormat, type } = courseElement;
    const contentItemId = cardData.resourceContentItemId;
    const courseResourceElementId = id;
    const classroomId = cardData.classroomId || classroomManager.currentClassroomId;

    option = await contentManager.getOptionsForTeacherPreview(
      contentItemId, type, pdfDeliveryFormat, origin,
      userManager.canViewAsTeacher, userManager.userId,
      courseResourceElementId, classroomId,
      publisherModeId, params.cardData.courseContentItemId
    );
    const { playerType, viewUrl, isFlowpaper } = option;

    if (!deliveryMode.includes('student_always') && !userManager.canViewAsTeacher) {
      // TODO: IS STUDENT PREVIEW MODE FOR LESSON GOING TO BE SOMETHING? API RETURNS ONLY TEACHER PREVIEW WITHOUT ACTIVITY.
      if (playerType === PLAYER_TYPES.LESSON_PLAYER) {
        this.handleGeneralMessage('This type is unsupported.');
        return false;
      }
    }
    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        contentItemId,
        contentItemType: entityTypeId,
        resourceName: name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
    }

    if ((playerType === PLAYER_TYPES.LESSON_PLAYER)) {
      contentManager.setLessonPlayerShowing((playerType === PLAYER_TYPES.LESSON_PLAYER));
      CourseService.setCourseResourceItemIdForRelatedItems(courseResourceElementId);
      CourseService.setCourseContentItemIdForRelatedItems(params.cardData.courseContentItemId);
    }

    this.setState({
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      contentUrl: viewUrl,
      contentMode,
      isFlowpaper,
      contentItemId,
      contentItemType: entityTypeId,
      contentName: name,
      assignmentId: '',
      previewContentType: entityTypeId,
      previewContentItemId: '',
      resourceName: '',
      instruction: '',
      contentImageUrl: ''
    });
  }

  // handleAnswerKey = async (assignment) => {
  //   const { contentManager, userManager } = this.props;
  //   const option = await contentManager.getOptionsForAnswerKey(
  //     assignment.attachmentContentItemId, CONTENT_ITEM_TYPES.PDF_RESOURCE,
  //     assignment.classroomId, origin, userManager.isTeacher, userManager.userId
  //   );
  //   const { playerType, viewUrl, isFlowpaper } = option;

  //   if (playerType === null && viewUrl !== null) {
  //     window.open(viewUrl, '_blank');
  //     return;
  //   }

  //   await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

  //   this.setState({
  //     contentName: assignment.attachmentContentItemTitle,
  //     contentUrl: viewUrl,
  //     docreaderViewerShowing: false,
  //     fileViewerShowing: true,
  //     instruction: null,
  //     isFlowpaper,
  //     learnosityPlayerShowing: false,
  //     lessonPlayerShowing: false
  //   });
  // }

  handleChangeMenuStatus = async (event, { menustatus }) => {
    const { assignmentManager, t } = this.props;

    setDocumentTitleFromSelectionView(VIEW_SELECTION.ASSIGNMENTS, {
      VIEW_SELECTION,
      suffix: menustatus === 'list' ? t('assignmentListLabel') : t(TEACHER_ASSIGNMENT_MENU_STATUS.getTitle(menustatus))
    });

    const settings = assignmentManager.filteredAssignmentSettings || {};
    const assignerEntityIds = settings.assignerEntityIds || null;
    const assignerType = settings.assignerType || 'all';
    const assignmentType = settings.assignmentType || '';
    const assignToIds = settings.assignToIds || null;
    const classroomId = settings.classroomId || '';
    const contentTypes = settings.contentTypes || '';
    const dateSearchType = settings.dateSearchType || 'forward';
    const selectedGroupIds = settings.selectedGroupIds || '';
    const startDateStr = settings.filterDateStr || null;
    const endDateStr = settings.filterEndDateStr || null;
    const search = settings.search || null;
    const status = assignmentManager.getAssignmentMenuStatus(menustatus);

    if (status && menustatus !== 'list') {
      this.resetSearch(classroomId, startDateStr, status, assignmentType, search, contentTypes,
        assignerType, dateSearchType, selectedGroupIds, endDateStr, assignerEntityIds, assignToIds, menustatus);
    } else if (menustatus === 'list') {
      const { rangeStartDateStr, rangeEndDateStr } = assignmentManager.getAssignmentListDates();
      this.resetSearch(classroomId, rangeStartDateStr, status, assignmentType, search, contentTypes,
        assignerType, 'range', selectedGroupIds, rangeEndDateStr, assignerEntityIds, assignToIds, menustatus);
    }
    unfocus();
  }

  handleChangeSearch = async (_event, { value }) => {
    const { assignmentManager } = this.props;
    const search = value;

    // Only the search text is changing so get all the other settings to pass on.
    const settings = assignmentManager.filteredAssignmentSettings || {};
    const assignerType = settings.assignerType || 'all';
    const assignerEntityIds = settings.assignerEntityIds || null;
    const assignmentType = settings.assignmentType || '';
    const assignToIds = settings.assignToIds || null;
    const classroomId = settings.classroomId || '';
    const contentTypes = settings.contentTypes || '';
    const dateSearchType = settings.dateSearchType || 'forward';
    const selectedGroupIds = settings.selectedGroupIds || '';
    const startDateStr = settings.filterDateStr || null;
    const endDateStr = settings.filterEndDateStr || null;
    const status = settings.status || null;

    assignmentManager.setSearchTextTimeout(clearTimeout(assignmentManager.searchTextTimeout));
    assignmentManager.setSearchTextTimeout(setTimeout(() => {
      if (!search || search.length >= 3) {
        this.resetSearch(classroomId, startDateStr, status, assignmentType, search, contentTypes,
          assignerType, dateSearchType, selectedGroupIds, endDateStr, assignerEntityIds, assignToIds);
      }
    }, 1000));
    this.setState({ search });
  }

  resetSearch = async (
    classroomId, startDateStr = null, status, assignmentType, search, contentTypes, assignerType,
    dateSearchType, selectedGroupIds, endDateStr = null, assignerEntityIds = null, assignToIds = null,
    menuStatus = null, clearPaginator = true
  ) => {
    const { assignmentManager, filteredHeaderTableManager } = this.props;
    const { pageStart } = this.state;
    assignmentManager.setLoaded(false);
    const clear = true;

    if (!menuStatus) {
      const settings = assignmentManager.filteredAssignmentSettings || {};
      menuStatus = settings.menuStatus || TEACHER_ASSIGNMENT_MENU_STATUS.ASSIGNED;
      status = assignmentManager.getAssignmentMenuStatus(menuStatus);
    }
    // console.log(`resetSearch startDate: ${startDateStr} endDate: ${endDateStr}`);// remove
    const filteredAssignmentSettings = {
      assignToIds,
      assignerEntityIds,
      assignerType,
      assignmentType,
      classroomId,
      contentTypes,
      dateSearchType,
      filterDateStr: startDateStr,
      filterEndDateStr: endDateStr,
      menuStatus,
      search,
      subdomainIds: selectedGroupIds,
      status
    };
    assignmentManager.setFilteredAssignmentSettings(filteredAssignmentSettings);

    if (clearPaginator) {
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, 1);
    }

    await this.fetchSearch(clear, classroomId, startDateStr, endDateStr, status, assignmentType, search,
      contentTypes, assignerType, dateSearchType, selectedGroupIds, assignerEntityIds, assignToIds);

    // reset page start by setting it to -1 to force the scroller to reset page
    this.setState({ pageStart: (pageStart === 0 ? -1 : 0), search });

    // if there are activities being created, start polling to re-fetch until they're done processing
    const { hasProcessingActivities } = assignmentManager;
    if (hasProcessingActivities && !this.timer) {
      this.timer = setInterval(() => this.poll(), 20000);
    }

    assignmentManager.setLoaded(true);
  }

  fetchSearch = async (clear = false, classroomId, startDateStr, endDateStr, status, assignmentType, search, contentTypes,
    assignerType, dateSearchType, selectedGroupIds = null, assignerEntityIds = null, assignToIds = null) => {
    this.setState({ isUpdating: true });
    await this.fetchAssignments(classroomId, startDateStr, endDateStr, status, assignmentType, search, contentTypes,
      assignerType, dateSearchType, 0, clear, selectedGroupIds, assignerEntityIds, assignToIds);
    this.setState({ isUpdating: false });
  }

  hideIframeFromOuterClick = (event) => {
    if ((event.origin === Auth.publisher || event.origin === Auth.lesson) && event.data === 'hideIframe') {
      this.setState({ lessonPlayerShowing: false });
      ContentService.setLessonPlayerShowing(false);
      CourseService.setCourseResourceItemIdForRelatedItems(null);
      CourseService.setCourseContentItemIdForRelatedItems(null);
      CourseService.setTagIds('', true);
      window.removeEventListener('message', this.hideIframeFromOuterClick);
    }
  }

  hideIframe = () => {
    this.setState({ lessonPlayerShowing: false });
  }

  hideModal = () => {
    this.setState({ learnosityPlayerShowing: false, fileViewerShowing: false, docreaderViewerShowing: false });
  }

  // --- assignment table config

  onPageChange = async (_event, pageInfo) => {
    const { assignmentManager, filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, pageInfo.activePage);

      const settings = assignmentManager.filteredAssignmentSettings || {};
      const assignerEntityIds = settings.assignerEntityIds || null;
      const assignerType = settings.assignerType || 'all';
      const assignmentType = settings.assignmentType || '';
      const assignToIds = settings.assignToIds || null;
      const classroomId = settings.classroomId || '';
      const contentTypes = settings.contentTypes || '';
      const dateSearchType = settings.dateSearchType || 'forward';
      const selectedGroupIds = settings.selectedGroupIds || '';
      const startDateStr = settings.filterDateStr || null;
      const endDateStr = settings.filterEndDateStr || null;
      const search = settings.search || null;
      const status = settings.status || null;
      const menuStatus = settings.menuStatus || TEACHER_ASSIGNMENT_MENU_STATUS.ASSIGNED;

      this.resetSearch(classroomId, startDateStr, status, assignmentType, search, contentTypes,
        assignerType, dateSearchType, selectedGroupIds, endDateStr, assignerEntityIds, assignToIds, menuStatus, false);
    }
  }

  createHeaderData = () => {
    const { t } = this.props;

    const headerData = [
      {
        label: t('classNameColumnLabel'),
        sortKey: '',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('activityNameColumnLabel'),
        sortKey: '',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      // {
      //   label: t('statusColumnLabel'),
      //   sortKey: '',
      //   filterKey: '',
      //   filterLabel: '',
      //   filterAutoCompleteCallback: null
      // },
      {
        label: t('assignedToColumnLabel'),
        sortKey: '',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('startDateColumnLabel'),
        sortKey: '',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('endDateColumnLabel'),
        sortKey: '',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
      {
        label: t('scorecardColumnLabel'),
        sortKey: '',
        filterKey: '',
        filterLabel: '',
        filterAutoCompleteCallback: null
      },
    ];
    return headerData;
  }

  createBodyData = () => {
    const { assignmentManager, t } = this.props;
    const assignmentRows = [];

    assignmentManager.aggregateAssignmentArray.map((cardData) => {
      const { courseResourceElementTitle } = AssignmentService.getAggregateAssignmentTitleAndDescription(cardData, false);

      const assignToContent = (cardData.activityType !== ASSIGNMENT_TYPE.CLASSROOM) ? this.renderAssignToContent(cardData) : null;

      const assignmentRow = [
        {
          /* Class Name */
          columnName: 'className',
          columnText: cardData.classroomName,
          columnClassName: classNames('truncate-medium', {
            warning: cardData.licenseExpired
          }),
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'hover'
        },
        {
          /* Activity Name */
          columnName: 'activityName',
          columnText: stripHtmlTags(courseResourceElementTitle),
          // columnClassName: 'truncate-extra-long', //'truncate-medium', - uncomment back to medium if we need to show status col.
          columnClassName: classNames('truncate-extra-long', {
            warning: cardData.licenseExpired
          }),
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: true,
          showTootipEvent: 'hover'
        },
        // {
        //   /* Status */
        //   columnName: 'status',
        //   columnText: 'status',
        //   columnClassName: 'truncate-tiny',
        //   columnOnClick: null,
        //   columnButtonOnClick: null,
        //   showTooltip: false,
        //   showTootipEvent: 'click'
        // },
        {
          /* Assigned To */
          columnName: 'assignedTo',
          columnText: t(`assignEntityTypeIdKey_${cardData.activityType}`),
          columnClassName: classNames('truncate-short', {
            warning: cardData.licenseExpired
          }),
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: (assignToContent !== null),
          showTootipEvent: 'hover',
          tooltipContent: assignToContent
        },
        {
          /* Start Date */
          columnName: 'startDate',
          columnText: dateFormat(cardData.timezoneStartDate, 'twoDigitDate'),
          columnClassName: classNames('truncate-short', {
            warning: cardData.licenseExpired
          }),
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: false,
          showTootipEvent: 'click'
        },
        {
          /* End Date */
          columnName: 'endDate',
          columnText: dateFormat(cardData.timezoneEndDate, 'twoDigitDate'),
          columnClassName: classNames('truncate-short', {
            warning: cardData.licenseExpired
          }),
          columnOnClick: null,
          columnButtonOnClick: null,
          showTooltip: false,
          showTootipEvent: 'click'
        },
        {
          /* 'Scorecard' button placeholder */
          columnName: 'scorecard',
          columnText: 'View',
          columnClassName: 'truncate-short',
          columnOnClick: () => this.handleGradebook(cardData),
          columnButtonOnClick: null
        }
      ];
      assignmentRows.push(assignmentRow);
    });
    return assignmentRows;
  }
  // --- END assignment table config

  renderFileViewer = () => {
    const { userManager } = this.props;
    const { FileViewerModal } = this;
    return (
      <div className='course-content'>
        <FileViewerModal
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='file-viewer'
          resourceName={this.state.resourceName}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { userManager, contentManager } = this.props;
    const { DocReaderModal } = this;
    if (this.props.renderDocreaderViewer !== undefined) {
      return this.props.renderDocreaderViewer();
    }

    return (
      <div className='course-content'>
        <DocReaderModal
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='docreader-viewer'
          resourceName={this.state.resourceName}
          sessionId={contentManager.currentflowPaperSessionId}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderLearnosityPlayer = () => {
    const { userManager, history } = this.props;
    const { LearnosityPlayerModal } = this;
    return (
      <div className='course-content'>
        <LearnosityPlayerModal
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentItemId={this.state.contentItemId}
          contentName={this.state.contentName}
          contentSubtitle={this.state.contentSubtitle}
          history={history}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-player'
          resourceName={this.state.resourceName} />
      </div>
    );
  }

  /** render content for assign to column popup */
  renderAssignToContent = (cardData) => {
    const { activityType = null, activityGroups = null, activityUsers = null } = cardData;
    const assignToList = [];
    if (cardData && activityType !== ASSIGNMENT_TYPE.CLASSROOM) {
      if (activityType === ASSIGNMENT_TYPE.USER) {
        if (activityUsers && activityUsers.length > 0) {
          const activityUsersArray = activityUsers.split(',');
          activityUsersArray.map((userName, index) => {
            assignToList.push(<li key={index}>{userName.trim()}</li>);
          });
        } else {
          assignToList.push(<li key={1}>No users assigned</li>);
        }
      } else if (activityType === ASSIGNMENT_TYPE.GROUP) {
        if (activityGroups && activityGroups.length > 0) {
          const activityGroupsArray = activityGroups.split(',');
          activityGroupsArray.map((groupName, index) => {
            assignToList.push(<li key={index}>{groupName.trim()}</li>);
          });
        } else {
          assignToList.push(<li key={1}>No groups assigned</li>);
        }
      }
    } else {
      return null;
    }
    return (
      <ul className='tooltip-list'>
        {assignToList}
      </ul>
    );
  }

  renderRest() {
    const { assignmentManager, filteredHeaderTableManager, t } = this.props;
    const { isUpdating, location, search, pageStart } = this.state;
    const { AssignmentFilter, FilteredHeaderTable } = this;

    const settings = assignmentManager.filteredAssignmentSettings || {};
    const menuStatus = settings.menuStatus || TEACHER_ASSIGNMENT_MENU_STATUS.ASSIGNED;

    const searchText = search && search !== 'undefined' ? search : '';

    // console.log(`renderRest pageStart ${pageStart} `);// remove

    const menuMap = [
      [t('AssignedAssignments'), TEACHER_ASSIGNMENT_MENU_STATUS.ASSIGNED],
      [t('InProgressAssignments'), TEACHER_ASSIGNMENT_MENU_STATUS.IN_PROGRESS],
      [t('ClosedAssignments'), TEACHER_ASSIGNMENT_MENU_STATUS.CLOSED],
      [t('GradedAssignments'), TEACHER_ASSIGNMENT_MENU_STATUS.GRADED],
      // [t('ShowAllAssignments'), TEACHER_ASSIGNMENT_MENU_STATUS.ALL]
      // [t('ShowAllAssignments'), TEACHER_ASSIGNMENT_MENU_STATUS.LIST]
    ];

    // console.log(`renderRest hasMore: ${assignmentManager.hasMoreAggregateAssignments}`);

    let tableBodyData = [];
    if (assignmentManager.loaded) {
      tableBodyData = this.createBodyData();
    }

    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    let activePage = 0;
    let column = '';
    let direction = '';
    let filters = [];
    if (tableData) {
      activePage = tableData.activePage;
      column = tableData.column;
      direction = tableData.direction;
      filters = tableData.filters;
    }

    return (
      <>
        <Container className='assignment-view-container' fluid>
          <div className='headerContainer'>
            <div className='headerWrapper'>
              <Header as='h1' className='theme-header-title'>{t('assignmentsLabel')}</Header>
            </div>
          </div>
          <div className='subHeaderWrapper'>
            <Button active={menuStatus === 'list'} menustatus='list' onClick={this.handleChangeMenuStatus}>
              {t('assignmentListLabel', 'Assignment List')}
            </Button>
            <div className='teacher-assignments-menu-control'>
              <Menu className='teacher-menu'>
                {assignmentManager.useAssignmentTabRollovers ?
                  menuMap.map(([children, status]) => (
                    <Popup
                      key={status}
                      content={t(`${status}HoverText`)}
                      hideOnScroll
                      mouseEnterDelay={500}
                      on='hover'
                      trigger={(
                        <Menu.Item key={status} active={menuStatus === status} as='button' menustatus={status}
                          onClick={this.handleChangeMenuStatus} tabIndex='0'>
                          <div>{children}</div>
                        </Menu.Item>
                      )} />
                  ))
                  :
                  menuMap.map(([children, status]) => (
                    <Menu.Item key={status} active={menuStatus === status} as='button' menustatus={status}
                      onClick={this.handleChangeMenuStatus} tabIndex='0'>
                      <div>{children}</div>
                    </Menu.Item>
                  ))}
              </Menu>
            </div>
            <Button active={menuStatus === 'all'} menustatus='all' onClick={this.handleChangeMenuStatus}>
              {t('showAllButtonLabel', 'Show All')}
            </Button>
            <Input
              className='content-name'
              icon='search'
              // name='search' // TODO remove
              onChange={this.handleChangeSearch}
              placeholder='Search'
              type='text'
              value={searchText} />
          </div>
          <div className='search-gui'>
            <AssignmentFilter
              {...this.props}
              fetchTags={this.fetchTags}
              isUpdating={isUpdating}
              location={location}
              menuStatus={menuStatus}
              resetSearch={this.resetSearch}
              search={search} />
            {menuStatus !== TEACHER_ASSIGNMENT_MENU_STATUS.LIST && (
              <Container className='cards'>
                {assignmentManager.loaded ? (
                  <Item.Group>
                    <InfiniteScrollResetable
                      className='ui items scroller'
                      hasMore={assignmentManager.hasMoreAggregateAssignments}
                      initialLoad={false}
                      loadMore={this.handleLoadMore}
                      loader={<Loader key={0} active inline />}
                      pageStart={pageStart}
                      useWindow={true}>
                      {this.renderAssignmentCards()}
                    </InfiniteScrollResetable>
                  </Item.Group>
                )
                  :
                  <Loader active inline />}
              </Container>
            )}
            {menuStatus === TEACHER_ASSIGNMENT_MENU_STATUS.LIST && (
              <Container className='table'>
                <div className='paginatorContainer'>
                  <div className='tablePagination'>
                    {tableBodyData.length > 0 &&
                    <Pagination activePage={activePage} onPageChange={this.onPageChange} totalPages={assignmentManager.totalPages} />}
                  </div>
                </div>
                <div className='tableContainer'>
                  <FilteredHeaderTable
                    allowClearAllFilters={false}
                    column={column}
                    direction={direction}
                    filtersData={filters}
                    handleFilter={this.handleFilter}
                    handleSort={this.handleSort}
                    tableBodyData={tableBodyData}
                    tableHeaderData={this.createHeaderData()}
                    tableId={this.filteredHeaderTableKey} />
                </div>
              </Container>
            )}
          </div>
        </Container>
      </>
    );
  }

  renderLessonPlayer = () => {
    const { FullscreenModal } = this;
    const { userManager } = this.props;
    const { contentImageUrl, contentName, contentUrl, instruction } = this.state;
    return (
      <div className='course-content'>
        <FullscreenModal
          className={'lesson-player'}
          closeIframeCallback={this.hideIframe}
          contentImageUrl={contentImageUrl}
          contentName={contentName}
          instruction={instruction}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={contentUrl} />
      </div>
    );
  }

  render() {
    const {
      learnosityPlayerShowing,
      lessonPlayerShowing,
      fileViewerShowing,
      docreaderViewerShowing
    } = this.state;
    if (learnosityPlayerShowing) {
      return this.renderLearnosityPlayer();
    } else if (lessonPlayerShowing) {
      return (this.renderLessonPlayer());
    } else if (fileViewerShowing) {
      return (this.renderFileViewer());
    } else if (docreaderViewerShowing) {
      return (this.renderDocreaderViewer());
    }
    return (this.renderRest());
  }
}

SatCoreRegister('AssignmentView', AssignmentView);
