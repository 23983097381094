import { getRegisteredClass, registerClass } from '../../SatCoreRegistry';

import { REPORT_CONTEXT_TYPE, REPORT_FACULTY_TYPE } from './ReportConstants';

import reportContextManager from '../../managers/reports/ReportContextManager';
import reportIdentityManager from '../../managers/reports/ReportIdentityManager';

/**
 * Contains various helper methods to return specific parts of the cached
 * `reportIdentityManager.reportResponseJson` mobx observable.
 *
 * NOTE: The typedefs and docs in this service are intended for quick reference only -
 * i.e. it is not guaranteed a certain report will (or will not) have all the
 * properties listed for a given section of the JSON.
 */
export default class ReportJsonHelperService {
  static ZERO_STATE_REPORT_RESPONSE_JSON = ({ entityId = '' } = {}) => {
    const ReportIdentityService = getRegisteredClass('ReportIdentityService');

    const { reportFacultyType } = ReportIdentityService.parseReportTypesFromBaseUrl();
    const reportId = ReportIdentityService.getReportIdFromParsedReportTypes();
    const reportFacultyTypeName = ReportIdentityService.getZeroStateReportFacultyName();

    return {
      data: [
        {
          contentJson: {
            data: {
              [`${reportFacultyType}Name`]: reportFacultyTypeName
            },
            reportId
          },
          entityId
        }
      ],
      entityId,
      reportId,
      timezoneReportDate: ''
    };
  }

  /**
   * @returns {{
   *   contentJson:      REPORT_CONTENT_JSON;
   *   creationDate:     string;
   *   entityId:         string;
   *   entityTypeId:     string;
   *   id:               string;
   *   modificationDate: string;
   *   reportId:         string;
   *   reportStartDate:  string;
   * }} `object`
   */
  static REPORT_RESPONSE_DATA = () => {
    const response = reportIdentityManager.reportResponseJson;
    return response?.data && response.data[0];
  }

  /**
   * @returns {{
   *   data:     REPORT_CONTENT_JSON_DATA
   *   entityId: string;
   *   reportId: string;
   * }} `object`
   */
  static REPORT_CONTENT_JSON = () => {
    const responseData = this.REPORT_RESPONSE_DATA();
    return responseData?.contentJson;
  }

  /**
   * @returns {{
   *   classroomName:                    string;                                            // classroom only
   *   classrooms:                       ReportClassroom[]                                  // school only
   *   courseElements:                   ReportCourseElement[];
   *   courses:                          ReportCourse[];                                    // course only
   *   curriculumMaps:                   ReportCmapObj[];                                   // standards classroom only
   *   districtId:                       string;                                            // district only
   *   districtName:                     string;                                            // district only
   *   grades:                           ReportCourseScoreInfo | ReportStandardsScoreInfo;
   *   leadTeacherName:                  string | null;                                     // classroom only
   *   readinessAndSupportingTags:       ReportReadinessAndSupportingTag[] | string;        // standards only
   *   reportDate:                       number;
   *   schoolId:                         string;                                            // school only
   *   schoolName:                       string;                                            // school only
   *   schools:                          ReportSchool[];                                    // district only
   *   standardDocuments:                any[];                                             // standards only
   *   standardToAlignmentsCollapsedMap: ReportStandardToAlignmentsMap;                     // standards only
   *   standardToAlignmentsMap:          ReportStandardToAlignmentsMap;                     // standards only
   *   standards:                        ReportStandard[];                                  // standards only
   *   students:                         ReportStudent[];                                   // classroom only
   * }} object
   */
  static REPORT_CONTENT_JSON_DATA = () => {
    const contentJson = this.REPORT_CONTENT_JSON();
    return contentJson?.data;
  }

  /** return applicable child faculty ID array based on the `activeReportFacultyType` */
  static REPORT_CHILD_FACULTY_IDS = () => {
    const { activeReportFacultyType } = reportIdentityManager;
    return this.REPORT_CHILD_FACULTIES().map((faculty) => {
      switch (activeReportFacultyType) {
        case REPORT_FACULTY_TYPE.DISTRICT:
          return faculty?.schoolId;
        case REPORT_FACULTY_TYPE.SCHOOL:
          return faculty?.classroomId;
        case REPORT_FACULTY_TYPE.CLASSROOM:
          return faculty?.studentId;
        default:
          return undefined;
      }
    }).filter((facultyId) => facultyId);
  }

  /** return applicable child faculty `object` array based on the `activeReportFacultyType` */
  static REPORT_CHILD_FACULTIES = () => {
    const { activeReportFacultyType } = reportIdentityManager;
    switch (activeReportFacultyType) {
      case REPORT_FACULTY_TYPE.DISTRICT:
        return this.REPORT_SCHOOLS();
      case REPORT_FACULTY_TYPE.SCHOOL:
        return this.REPORT_CLASSROOMS();
      case REPORT_FACULTY_TYPE.CLASSROOM:
        return this.REPORT_STUDENTS();
      default:
        return [];
    }
  }

  /**
   * For **district admin** reports only.
   *
   * @returns {ReportSchool[]} `ReportSchool[]`
   */
  static REPORT_SCHOOLS = () => {
    const contentJsonData = this.REPORT_CONTENT_JSON_DATA();
    const schools = contentJsonData?.schools;
    return schools?.length ? schools : [];
  }

  /**
   * For **school admin** reports only.
   *
   * @returns {ReportClassroom[]} `ReportClassroom[]`
   */
  static REPORT_CLASSROOMS = () => {
    const contentJsonData = this.REPORT_CONTENT_JSON_DATA();
    const classrooms = contentJsonData?.classrooms;
    return classrooms?.length ? classrooms : [];
  }

  /**
   * For **classroom** reports only.
   *
   * @returns {ReportStudent[]} `ReportStudent[]`
   */
  static REPORT_STUDENTS = () => {
    const contentJsonData = this.REPORT_CONTENT_JSON_DATA();
    const students = contentJsonData?.students;
    return students?.length ? students : [];
  }

  /**
   * For **course** reports only.
   *
   * @returns {ReportCourse[]} `ReportCourse[]`
   */
  static REPORT_COURSES = () => {
    const contentJsonData = this.REPORT_CONTENT_JSON_DATA();
    const courses = contentJsonData?.courses;
    return courses?.length ? courses : [];
  }

  /**
   * For **standards classroom** reports only.
   *
   * @returns {ReportCmapObj} `ReportCmapObj[]`
   */
  static REPORT_CMAPS = () => {
    const contentJsonData = this.REPORT_CONTENT_JSON_DATA();
    const cmaps = contentJsonData?.curriculumMaps;
    return cmaps?.length ? cmaps : [];
  }

  /**
   * For **standards** reports only.
   *
   * @returns {ReportStandard[]} `ReportStandard[]`
   */
  static REPORT_STANDARDS = (contentJsonData = undefined, {
    reportContextType = undefined
  } = {}) => {
    reportContextType = reportContextType || reportContextManager.reportContextType;

    const { LIKERT } = REPORT_CONTEXT_TYPE;

    contentJsonData = contentJsonData || this.REPORT_CONTENT_JSON_DATA();

    switch (reportContextType) {
      case LIKERT:
        const likertStandards = contentJsonData?.likertStandards;
        return likertStandards?.length ? likertStandards : [];
      default:
        const standards = contentJsonData?.standards;
        return standards?.length ? standards : [];
    }
  }

  /**
   * For **standards** reports only.
   *
   * @returns {ReportCourseElement[]} `ReportCourseElement[]`
   */
  static REPORT_STANDARDS_COURSE_ELEMENTS = () => {
    const contentJsonData = this.REPORT_CONTENT_JSON_DATA();
    const courseElements = contentJsonData?.courseElements;
    return courseElements?.length ? courseElements : [];
  }

  /**
   * For **course** reports only.
   *
   * @returns {ReportCourseElement[]} `ReportCourseElement[]`
   */
  static REPORT_COURSE_ELEMENTS_FROM_COURSE = (courseId) => {
    const courses = this.REPORT_COURSES();
    const course = courses.find((course) => course.courseId === courseId);
    return course?.courseElements ? course.courseElements : [];
  }

  /**
   * Intended for **course** reports only.
   *
   * @usage
   * ```
   * REPORT_SCORE_INFO_BY_ELEMENT_FOR_STUDENTS()[courseElementId][facultyId] // for all grade info
   * ```
   * @returns {ReportCourseScoreInfo} `ReportCourseScoreInfo`
   */
  static REPORT_SCORE_INFO_BY_ELEMENT_FOR_STUDENTS = () => {
    const contentJsonData = this.REPORT_CONTENT_JSON_DATA();
    const scoreInfo = contentJsonData?.grades;
    return scoreInfo || {};
  }

  /**
   * Intended for **standards** reports only.
   *
   * @usage
   * ```
   * REPORT_SCORE_INFO_BY_STANDARD_FOR_STUDENTS[standardId][studentId]
   * ```
   * @returns {ReportStandardsScoreInfo} `ReportStandardsScoreInfo`
   */
  static REPORT_SCORE_INFO_BY_STANDARD_FOR_STUDENTS = () => {
    const { reportContextType } = reportContextManager;
    const { LIKERT } = REPORT_CONTEXT_TYPE;

    const contentJsonData = this.REPORT_CONTENT_JSON_DATA();

    switch (reportContextType) {
      case LIKERT:
        const likertScoreInfo = contentJsonData?.likertGrades;
        return likertScoreInfo || {};
      default:
        const scoreInfo = contentJsonData?.grades;
        return scoreInfo || {};
    }
  }

   static REPORT_PROGRESS_INFO_BY_STANDARD_FOR_STUDENTS = () => {
     const contentJsonData = this.REPORT_CONTENT_JSON_DATA();
     const scoreInfo = contentJsonData?.progress;
     return scoreInfo || {};
   }

  /**
   * For **standards** 'itemLevel' context reports only.
   *
   * @returns {{
   *   [standardId]: ReportStandardAlignment[]
   * }} `REPORT_STANDARD_TO_ALIGNMENT_KEYS_WITH_DATA | REPORT_STANDARD_TO_COLLAPSED_ALIGNMENT_KEYS_WITH_DATA`
   */
  static REPORT_STANDARD_TO_ALIGNMENT_KEYS_WITH_DATA = (shouldReturnCollapsedIfContextResourceLevelReport = true) => {
    if (shouldReturnCollapsedIfContextResourceLevelReport && reportContextManager.isContextResourceLevelReport) {
      const contentJsonData = this.REPORT_CONTENT_JSON_DATA();
      return (contentJsonData?.standardToAlignmentsCollapsedMap) || {};
    } else {
      const contentJsonData = this.REPORT_CONTENT_JSON_DATA();
      return (contentJsonData?.standardToAlignmentsMap) || {};
    }
  }

  /**
   * For **standards** 'resourceLevel' context reports only.
   *
   * @returns {{
   *   [standardId]: ReportStandardAlignment[]
   * }} `REPORT_STANDARD_TO_COLLAPSED_ALIGNMENT_KEYS_WITH_DATA`
   */
  static REPORT_STANDARD_TO_COLLAPSED_ALIGNMENT_KEYS_WITH_DATA = () => {
    const contentJsonData = this.REPORT_CONTENT_JSON_DATA();
    return (contentJsonData?.standardToAlignmentsCollapsedMap) || {};
  }
}

/**
 * @typedef {{
 *   fullImageUrl: string | null;
 *   imageUrl:     string | null;
 *   schoolId:     string;
 *   schoolName:   string;
 * }} ReportSchool
 */

/**
 * @typedef {{
 *   classroomId:          string;
 *   classroomName:        string;
 *   classroomNickname:    string | null;
 *   courseIds:            string[];
 *   fullImageUrl:         string;
 *   imageUrl:             string | null;
 *   leadTeacherFirstName: string;
 *   leadTeacherLastName:  string;
 * }} ReportClassroom
 */

/**
 * @typedef {{
 *   firstName: string;
 *   lastName: string;
 *   middleName: string | null;
 *   profileImage: string;
 *   studentId: string;
 *   username: string;
 * }} ReportStudent
 */

/**
 * @typedef {{
 *   courseElements:     ReportCourseElement[];
 *   courseId:           string;
 *   courseName:         string;
 *   inProgressChapters: number;
 *   rootElement:        ReportCourseElement;
 *   totalChapters:      number;
 * }} ReportCourse
 */

/**
 * @typedef {{
 *   absoluteOrderNum:  number;
 *   averageScore:      number | null;  // admin only
 *   averageViewedTime: number | null;
 *   children:          Standard[];
 *   classAverage:      number | null;
 *   description:       string;
 *   hasChildren:       boolean;
 *   id:                string;
 *   items:             number;         // admin only
 *   name:              string;
 *   orderNum:          number;
 *   remaining:         number;         // classroom only
 *   taken:             number;         // classroom only
 *   takenActivityIds:  string[];       // classroom only
 * }} ReportStandard
 */

/**
 * @typedef {{
 *   absoluteOrderNum:           number;
 *   activityCount:              null;
 *   activityIds:                null;
 *   assignableTreeElement:      boolean;
 *   averageScore:               number | null;
 *   averageViewedTime:          number | null;
 *   children:                   ReportCourseElement[];
 *   classAverage:               null;
 *   contentType:                null;
 *   courseContentItemId:        string;
 *   courseId:                   string;
 *   courseImageUrl:             null;
 *   courseName:                 string;
 *   courseUnitName:             'Module' | 'Unit' | 'Week';
 *   elementContentItemImageUrl: string | null;
 *   elementContentItemName:     string | null;
 *   elementDeliveryMode:        'assignable' | 'student_always' | 'student_always_assignable'
 *     | 'student_always_rou_assigned' | 'teacher_only' | null;
 *   elementDescription:         string;
 *   elementId:                  string;
 *   elementName:                string | null;
 *   elementType:                'ROOT' | 'SECTION' | 'UNIT' | 'assessment' | 'lesson' | 'resource';
 *   gradbookView:               null;
 *   level:                      number;
 *   orderNum:                   number;
 *   parentElementId:            string | null;
 *   unitHidden:                 boolean;
 * }} ReportCourseElement
 */

/**
 * @typedef {ReportCourseScoreInfo | ReportStandardsScoreInfo} ReportScoreInfo
 */

/**
 * @typedef {{
 *   [courseElementId: string]: { [facultyId: string]: {
 *     activityId: string | null;                         // course
 *     activityInstanceId: string | null;                 // course classroom only
 *     averageScore: number | null;                       // standards only
 *     elementId: string;                                 // course
 *     excludeCount: number | null;                       // course
 *     grade: number | null;                              // course
 *     maxScore: number;
 *     remaining: number | null;                          // standards only
 *     reportingCategoryWeightedScore: number | null      // sirius standards only
 *     schoolId: string;                                  // admin only
 *     submittedScore: number | null                      // standards only
 *     status: string | null;                             // course
 *     submitted: number | null;                          // course
 *     submittedScore: number | null;                     // standards only
 *     submittedDate: number | null;                      // course
 *     userId: string                                     // course
 *     viewedTime: number | null;
 *   }
 *  }
 * }} ReportCourseScoreInfo
 */

/**
 * @typedef {{
 *   contentItemId: string;
 *   cutScore: string;
 *   id: string;
 *   name: string;
 * }} ReportCmapObj
 */

/**
 * @typedef {{
 *   [standardId: string]: { [facultyId: string]: {
 *     averageScore: number | null;
 *     averageViewedTime: number | null               // admin only
 *     items: number | null                           // admin only
 *     maxScore: number | null                        // classroom only
 *     remaining: number | null                       // classroom only
 *     reportingCategoryWeightedScore: number | null  // sirius classroom only (for now)
 *     submittedScore: number | null                  // classroom only
 *     taken: number | null                           // classroom only
 *     weightedScore: number | null                   // sirius classroom only (for now)
 *     viewedTime: number | null                      // classroom only
 *   }
 *  }
 * }} ReportStandardsScoreInfo
 */

/**
 * @typedef {{
 *   [standardId]: ReportStandardAlignment[]
 * }} ReportStandardToAlignmentsMap
 */

/**
 * @typedef {{
 *   alignmentEntityId: string;
 *   curriculumMapId: string;
 *   elementId: string;
 *   id: string;
 *   lessonElementId: string | null;
 *   questionNum: string | null;
 *   studentScores: { [studentId: string]: string }; // TODO legacy, studentScoreDetails is its successor
 *   studentScoreDetails: { [studentId: string]: ReportAlignmentStudentScoreDetails }
 * }} ReportStandardAlignment
 */

/**
 * @typedef {{
 *   grade: number;
 *   itemScore: number;
 *   maxScore: number;
 *   submittedDate: number;
 * }} ReportAlignmentStudentScoreDetails
 */

/**
 * @typedef {{
 *   name: 'Readiness' | 'Supporting';
 *   subTags: ReportReadinessAndSupportingTag[];
 *   id: string;
 *   order: number;
 * }} ReportReadinessAndSupportingTag
 */

registerClass('ReportJsonHelperService', ReportJsonHelperService);
