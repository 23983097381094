import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Container, Image, Loader, Popup, Table } from 'semantic-ui-react';

import { capitalize } from 'lodash';

import assessmentDefaultCardImage from '../img/default-assessment.svg';
import chapterImage from '../img/cardimage-chapter.png';
import { customAssessmentDefaultCardImageUri } from '../services/UriService';
import customCourseDefaultCardImage from '../img/image-custom-course-special.png';
import goldTrophy from '../img/icon-trophy-gold.png';
import silverTrophy from '../img/icon-trophy-silver.png';
import unitImage from '../img/cardimage-unit.png';

import chapterDefault from '../img/default-chapter.svg';
import courseDefault from '../img/default-course.svg';
import lessonDefault from '../img/default-lesson.svg';

import '../css/ProgressView.less';
import '../css/StudentSummaryWidget.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';

import { ONLINE_CONTENT_TYPES } from '../managers/TagContentManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';
import { useParams } from '../hoc/withParams';

import { getStyleVar } from '../utils';

import ReportIdentityService from '../services/reports/ReportIdentityService';

import D3PieChart from '../components/D3PieChart';
import FeedbackIcon from '../components/FeedbackIcon';
import NoAssignmentsMessage from '../components/NoAssignmentsMessage';
import ProgressBar from '../components/ProgressBar';
import StudentAssignmentsPopup from '../components/StudentAssignmentsPopup';
import Trophy from '../components/Trophy';
import WeeklyPerformanceChart from '../components/WeeklyPerformanceChart';

const ProgressView = observer((props) => {
  const {
    defaultImages = false,
    feedbackIconActiveImg = undefined,
    history,
    leftCol = true,
    pieChartFontColor = getStyleVar('--student-progress-summary-default-font-color'),
    progressWidget = true,
    summaryLegend,
    summaryWidget = false,
    t,
    trophiesWidget = true,
    weeklyPerformanceWidget = false
  } = props;

  const {
    appManager, classroomManager, courseManager, dialogManager,
    navigationManager, studentProgressManager, userManager
  } = useContext(MobXProviderContext);

  const {
    classroomProgressData, coursesVisibility,
    selectedContentType, trophyCounts
  } = studentProgressManager;

  const { params: { view = VIEW_SELECTION.PROGRESS } } = useParams(props);

  // eslint-disable-next-line no-unused-vars
  const [loadingProgressView, _setLoadingProgressView] = useState(false);

  const [renderAssignmentsPopup, setRenderAssignmentsPopup] = useState({
    activityInstanceId: '', courseElement: {}, key: '', render: false
  });

  studentProgressManager.setClassroom(classroomManager.currentClassroomId);

  const KeyboardActionListener = SatCoreComponent('KeyboardActionListener');

  useEffect(() => navigationManager.setView(view), []);

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  // useEffect(() => {
  //   (async () => {
  //     // TODO this appears to be unneeded
  //     // if (!appManager.initializingApp) {
  //     //   setLoadingProgressView(true);
  //     //   await StudentHelperService.fetchStudentProgressData();
  //     //   setLoadingProgressView(false);
  //     // }
  //   })();
  // }, []);

  const handleFeedBack = async (activityInstanceId) => {
    dialogManager.setOpenDialog(DIALOG_NAMES.STUDENT_FEEDBACK, {
      assignmentInstanceId: activityInstanceId
    }, () => dialogManager.closeDialog(DIALOG_NAMES.STUDENT_FEEDBACK));
  };

  const showAssignment = async (clickable, element, activityInstanceId) => {
    if (clickable) {
      setRenderAssignmentsPopup({
        activityInstanceId,
        courseElement: element,
        key: Math.random(),
        render: true
      });
    }
  };

  const hideAssignment = () => {
    setRenderAssignmentsPopup({
      activityInstanceId: '',
      courseElement: {},
      key: '',
      render: false
    });
  };

  const setDefaultImage = ({ course = null, elementType = null }) => {
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId, null, course);

    let customLeafCardImageClassNames = 'default';
    let defaultCustomLeafCardImg = assessmentDefaultCardImage;

    if (courseManager.useSpecialCustomCourseCardImages && isCustomCourse) {
      customLeafCardImageClassNames += 'custom-default-special';
      if (course) {
        defaultCustomLeafCardImg = customCourseDefaultCardImage;
      } else {
        defaultCustomLeafCardImg = customAssessmentDefaultCardImageUri;
      }
      return (
        <Image
          alt='Custom default lesson image'
          className={customLeafCardImageClassNames}
          src={defaultCustomLeafCardImg} />
      );
    }
    if (defaultImages) {
      switch (elementType) {
        case 'ROOT':
          return <div className='content-type-img default-image-root'><Image alt='' src={courseDefault} /></div>;
        case 'UNIT':
        case 'SECTION':
          return <div className='content-type-img default-image-chapter'><Image alt='' src={chapterDefault} /></div>;
        default:
          return <div className='content-type-img default-image-lesson'><Image alt='' src={lessonDefault} /></div>;
      }
    }
    return <div className='content-type-img' />;
  };

  const CourseElement = ({ element }) => {
    // const hasLikertOnly = element.hasLikert && !element.hasConventional;

    const allowDisplayGrade = true;
    // const allowDisplayGrade = !hasLikertOnly;

    // don't show the hierarchy if a filter is selected
    if ((selectedContentType === 'Clickbook' || selectedContentType === 'ExperTrack') &&
        (element.elementType === 'UNIT' || element.elementType === 'SECTION')) {
      return null;
    }

    if (selectedContentType === 'Clickbook' && !element.includeWithClickbook) {
      return null;
    } else if (selectedContentType === 'ExperTrack' && !element.includeWithExperTrack) {
      return null;
    }
    let contentString = ReportIdentityService.constructContentString('', element.elementName, element.elementDescription);
    contentString = contentString || (element.elementType ? capitalize(element.elementType) : t('untitledCourseElementRow'));

    const gradeData = classroomProgressData.grades[element.elementId];
    const feedbackData = classroomProgressData.feedback[element.elementId];
    let imageUrl;
    let elementClass;
    let clickable = false;
    let grade;
    const contentItemImage = element.attachmentContentItemId ?
      CONTENT_ITEM_TYPES.getContentIdImageUrl(element.attachmentContentItemId) : null;
    const elementContentItemImageUrl = element.elementContentItemImageUrl ?
      CONTENT_ITEM_TYPES.getContentImageUrl(element.elementContentItemImageUrl) : null;
    switch (element.elementType) {
      case 'UNIT':
        imageUrl = contentItemImage || unitImage;
        elementClass = 'no-score-element';
        grade = allowDisplayGrade ? element.averageScore : undefined;
        break;
      case 'SECTION':
        imageUrl = contentItemImage || chapterImage;
        elementClass = 'no-score-element';
        grade = allowDisplayGrade ? element.averageScore : undefined;
        break;
      default:
        imageUrl = elementContentItemImageUrl || contentItemImage || ONLINE_CONTENT_TYPES.getContentImageUrl(element.contentType);
        grade = allowDisplayGrade ? gradeData.grade : undefined;
        clickable = gradeData.studentReview;
        break;
    }

    let trophyImg;
    const gradeNum = (grade) || 0;
    if (allowDisplayGrade && gradeNum >= 80) {
      trophyImg = goldTrophy;
    } else if (allowDisplayGrade && gradeNum >= 60) {
      trophyImg = silverTrophy;
    }
    const gradeStr = (typeof grade === 'number') ? `${gradeNum}%` : 'n/a';

    let achievementLabel;
    if (allowDisplayGrade && grade) {
      if (gradeNum > 75) {
        achievementLabel = (
          <div className='high'>{t('achievementHigh', 'High')}</div>
        );
      } else if (gradeNum < 50) {
        achievementLabel = (
          <div className='low'>{t('achievementLow', 'Low')}</div>
        );
      } else {
        achievementLabel = (
          <div className='medium'>{t('achievementMedium', 'Medium')}</div>
        );
      }
    }

    return (
      <Table.Row
        className={elementClass} tabIndex={0}>
        <Table.Cell
          className='student-progress-content'
          onClick={() => showAssignment(clickable, element, gradeData?.activityInstanceId)}
          scope='col'>
          <div className='assignment-content'>
            <i aria-hidden='true' className='no-icon' />
            {imageUrl && !defaultImages ?
              <Image alt='' className='content-type-img' src={imageUrl} /> : setDefaultImage({ elementType: element.elementType })}
            <Popup
              content={<div className={`assignment-content-title ${clickable ? 'clickable' : ''}`}>{contentString}</div>}
              trigger={(
                <div
                  className={`assignment-content-title ${clickable ? 'clickable' : ''}`}
                  tabIndex={clickable ? 0 : undefined}>
                  {contentString}
                </div>
              )} />
          </div>
        </Table.Cell>
        <Table.Cell
          className='student-progress-score'
          scope='col'
          textAlign='center'>
          {gradeStr}
        </Table.Cell>
        <Table.Cell
          className='student-progress-trophy'
          scope='col'
          textAlign='center'>
          {!!trophyImg &&
            <Image alt='' className='assignment-trophy-img' src={trophyImg} />}
        </Table.Cell>
        <Table.Cell
          className='student-progress-achievement'
          scope='col'
          textAlign='center'>
          <div>{achievementLabel}</div>
        </Table.Cell>
        <Table.Cell
          className='student-progress-feedback'
          scope='col'
          textAlign='center'>
          {feedbackData && (
            <FeedbackIcon
              className='assignment-feedback'
              clickHandler={() => handleFeedBack(feedbackData.entityId)}
              hasFeedback={true}
              icon={feedbackIconActiveImg}
              tabIndex={0} />
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  const CourseElements = ({ elements }) => (
    elements.map((element) => (
      <CourseElement key={element.elementId} element={element} />
    ))
  );

  const NoAssignments = () => (
    <NoAssignmentsMessage message='No scores yet' />
  );

  const Assignments = () => {
    const loaded = !loadingProgressView && !appManager.initializingApp && userManager.loaded && classroomManager.loaded;
    return (
      <>
        <Table className='student-progress-table' unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                className='student-progress-content'
                scope='col'>
                {t('content')}
              </Table.HeaderCell>
              <Table.HeaderCell
                className='student-progress-score'
                scope='col'
                textAlign='center'>
                {t('score')}
              </Table.HeaderCell>
              <Table.HeaderCell
                className='student-progress-trophy'
                scope='col'
                textAlign='center'>
                {t('trophy')}
              </Table.HeaderCell>
              <Table.HeaderCell
                className='student-progress-achievement'
                scope='col'
                textAlign='center'>
                {t('achievement')}
              </Table.HeaderCell>
              <Table.HeaderCell
                className='student-progress-feedback'
                scope='col'
                textAlign='center'>
                {t('feedback')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loaded && classroomProgressData.courses.map((course, _index) => {
              // const hasLikertOnly = course.hasLikert && !course.hasConventional;

              const allowDisplayGrade = true;
              // const allowDisplayGrade = !hasLikertOnly;

              const isExpandedRow = coursesVisibility[course.courseId];
              const dropdownIconClassName = isExpandedRow ? 'dropdown icon' : 'dropdown icon counterclockwise rotated';
              const ariaLabelDropdownIcon = isExpandedRow ? t('ariaLabelDropdownIconExpanded') : t('ariaLabelDropdownIconCollapsed');

              let grade;
              if (allowDisplayGrade && classroomProgressData.courseGrades && classroomProgressData.courseGrades[course.courseId]) {
                grade = classroomProgressData.courseGrades[course.courseId].grade;
              }

              let trophyImg;
              let gradeStr = '';

              if (selectedContentType !== 'Clickbook' && selectedContentType !== 'ExperTrack') {
                const gradeNum = (grade) || 0;
                if (allowDisplayGrade && gradeNum >= 80) {
                  trophyImg = goldTrophy;
                } else if (allowDisplayGrade && gradeNum >= 60) {
                  trophyImg = silverTrophy;
                }
                gradeStr = (typeof grade === 'number') ? `${gradeNum}%` : 'n/a';
              }

              let achievementLabel;
              const gradeNum = (grade) || 0;
              if (allowDisplayGrade && grade) {
                if (gradeNum > 75) {
                  achievementLabel = <div className='high'>High</div>;
                } else if (gradeNum < 50) {
                  achievementLabel = <div className='low'>Low</div>;
                } else {
                  achievementLabel = <div className='medium'>Medium</div>;
                }
              }

              let imageUrl = null;
              if (course.courseImageUrl || course.rootElement?.courseImageUrl) {
                imageUrl = course.courseImageUrl || course.rootElement?.courseImageUrl;
              } else if (course.elementContentItemImageUrl) {
                imageUrl = course.elementContentItemImageUrl;
              }

              return (
                <React.Fragment key={course.courseId}>
                  <Table.Row
                    className='course-element no-score-element'
                    onClick={() => studentProgressManager.toggleCourseVisibility(course.courseId)}
                    tabIndex={0}>
                    <Table.Cell
                      className='student-progress-content'
                      scope='col'>
                      <div
                        className='assignment-content'>
                        <i className={dropdownIconClassName} />
                        {imageUrl ? (
                          <Image
                            alt=''
                            className='content-type-img'
                            src={CONTENT_ITEM_TYPES.getContentImageUrl(imageUrl)} />
                        ) : setDefaultImage({ course, elementType: course.rootElement?.elementType })}
                        <Popup
                          content={<div className='assignment-content-title'>{course.courseName}</div>}
                          trigger={(
                            <div
                              aria-label={ariaLabelDropdownIcon}
                              className='assignment-content-title'>
                              {course.courseName}
                            </div>
                          )} />
                      </div>
                    </Table.Cell>
                    <Table.Cell
                      className='student-progress-score'
                      scope='col'
                      textAlign='center'>
                      {gradeStr}
                    </Table.Cell>
                    <Table.Cell
                      className='student-progress-trophy'
                      scope='col'
                      textAlign='center'>
                      {!!trophyImg && <Image alt='' className='assignment-trophy-img' src={trophyImg} />}
                    </Table.Cell>
                    <Table.Cell
                      className='student-progress-achievement'
                      scope='col'
                      textAlign='center'>
                      <div>{achievementLabel}</div>
                    </Table.Cell>
                    <Table.Cell
                      className='student-progress-feedback'
                      scope='col'
                      textAlign='center' />
                  </Table.Row>
                  {studentProgressManager.coursesVisibility[course.courseId] &&
                  <CourseElements elements={course.courseElements} />}
                </React.Fragment>
              );
            })}
          </Table.Body>
        </Table>
        <Loader active={!loaded} />
        {loaded && !hasAssignments && <NoAssignments />}
      </>
    );
  };

  const ProgressWidget = () => {
    const loaded = !loadingProgressView && !appManager.initializingApp && userManager.loaded && classroomManager.loaded;
    return (
      <div className='widget progress-widget'>
        <div className='w-title'>{t('CourseProgressLabel', 'Missing course progress label translation')}</div>
        <div className='courses' tabIndex={-1}>
          {(classroomProgressData.courses.length > 0) ?
            classroomProgressData.courses.map((course) => {
              let inProgressChapters = course?.inProgressChapters || 0;
              let totalChapters = course?.totalChapters || 0;

              if (selectedContentType === 'ExperTrack') {
                inProgressChapters = course.experTrackInProgressChapters || 0;
                totalChapters = course.experTrackTotalChapters || 0;
              } else if (selectedContentType === 'Clickbook') {
                inProgressChapters = course.clickbookInProgressChapters || 0;
                totalChapters = course.clickbookTotalChapters || 0;
              }
              return (
                <div key={course.courseId} className='course-progress'>
                  <Popup
                    content={<div className='course-name'>{course.courseName}</div>}
                    trigger={<div className='course-name'>{course.courseName}</div>} />
                  <div className='progress'>
                    <span>
                      {inProgressChapters}
                      /
                      {totalChapters}
                    </span>
                    {' '}
                    {t('chaptersTitle', 'Chapters In Progress or Complete')}
                  </div>
                  <div className='progress-bar'>
                    <ProgressBar
                      progress={inProgressChapters}
                      total={totalChapters} />
                  </div>
                </div>
              );
            }) : (
              <div className='center'>
                {loaded && <p>{t('noScoresYet', 'No scores yet')}</p>}
              </div>
            )}
        </div>
      </div>
    );
  };

  const TrophiesWidget = () => {
    return (
      <div className='widget trophies-widget'>
        <div className='w-title'>{t('TrophiesLabel', 'Missing trophies label translation')}</div>
        <div className='trophy-content'>
          <Trophy count={trophyCounts.gold} type='gold' />
          <Trophy count={trophyCounts.silver} type='silver' />
        </div>
      </div>
    );
  };

  const SummaryWidget = ({ summary, hasAssignments, Legend }) => {
    const summaryPieData = [];
    if (summary) {
      if (summary.high) {
        summaryPieData.push({
          color: getStyleVar('--student-progress-summary-legend-high-color'),
          label: summary.high.toString(),
          text: t('highText'),
          value: summary.high
        });
      }

      if (summary.medium) {
        summaryPieData.push({
          color: getStyleVar('--student-progress-summary-legend-medium-color'),
          label: summary.medium.toString(),
          text: t('mediumText'),
          value: summary.medium
        });
      }

      if (summary.low) {
        summaryPieData.push({
          color: getStyleVar('--student-progress-summary-legend-low-color'),
          label: summary.low.toString(),
          text: t('lowText'),
          value: summary.low
        });
      }

      summaryPieData.forEach((x) => {
        x.tooltip = `
          <div>${x.text}</div>
          <div>${x.label}</div>
        `;
      });
    }

    return (
      <div className='widget summary-widget student-summary-widget'>
        <div className='w-title'>{t('SummaryLabel', 'Missing summary label translation')}</div>
        {hasAssignments && summary ? (
          <div className='summary'>
            <D3PieChart
              data={summaryPieData}
              fontColor={pieChartFontColor}
              fontSize={10}
              height={116}
              labelPlacement={0.3}
              width={116} />
            <Legend
              data={summary} t={t} />
          </div>
        ) : (
          <div className='no-scores'><p>No scores yet</p></div>
        )}
      </div>
    );
  };

  const WeeklyPerformanceWidget = () => (
    <div className='widget weekly-performance-widget'>
      <div className='w-title'>{t('WeeklyPerformanceLabel', 'Missing weekly performance label translation')}</div>
      {(hasAssignments && classroomProgressData.weekGrades) ? (
        <div className='weekly-performance-container'>
          <WeeklyPerformanceChart data={classroomProgressData.weekGrades} t={t} width={320} />
        </div>
      )
        :
        <div className='no-scores'><p>No scores yet</p></div>}
    </div>
  );

  const CourseListBanner = SatCoreComponent('CourseListBanner');
  const { currentClassroomId } = classroomManager;
  const course = courseManager.getCurrentCourse(currentClassroomId);
  const gradeCount = Object.keys(classroomProgressData.grades).length;
  const hasAssignments = gradeCount > 0;
  const { summary } = classroomProgressData;

  return (
    <Container className='progress-view' fluid>

      {/* the current value of this observable seems not to update in a timely manner unless we specifically reference it here */}
      {!!appManager.initializingApp}

      <CourseListBanner
        currentContentType={selectedContentType}
        hideAddStudentButton={true}
        history={history}
        isMyProgress={true}
        title={t('BannerTitle', 'No Progress View Banner Title')} />
      <div className='widget-wrapper'>
        {(userManager.loaded && classroomManager.loaded && course !== null) ? (
          <>
            {leftCol && (
              <div className='left-col'>
                {summaryWidget && <SummaryWidget Legend={summaryLegend} hasAssignments={hasAssignments} summary={summary} />}
                {progressWidget && <ProgressWidget />}
                {weeklyPerformanceWidget && <WeeklyPerformanceWidget />}
                {trophiesWidget && <TrophiesWidget />}
              </div>
            )}
            <div className='right-col'>
              <KeyboardActionListener>
                <div className='widget assignments-widget' tabIndex={0}>
                  <div className='assignments-top-padding' />
                  <div className='assignments-widget-inner'>
                    <Assignments />
                  </div>
                </div>
              </KeyboardActionListener>
            </div>
          </>
        ) : null}
      </div>
      {renderAssignmentsPopup.render && (
        <StudentAssignmentsPopup
          key={renderAssignmentsPopup.key}
          activityInstanceId={renderAssignmentsPopup.activityInstanceId}
          courseElement={renderAssignmentsPopup.courseElement}
          hideSelf={hideAssignment}
          history={history} />
      )}
    </Container>
  );
});

export default ProgressView;

SatCoreRegister('ProgressView', ProgressView);
