import React, { useContext } from 'react';

import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import { Image } from 'semantic-ui-react';

import iconCommentActive from '../img/icon-comment-active.svg';
import iconCommentNone from '../img/icon-comment-none.svg';

import { SatCoreRegister } from '../SatCoreRegistry';

import { register } from '../i18n';

const t = register('FeedbackIcon');

const FeedbackIcon = observer((props = {}) => {
  const { className, clickHandler, disabled, hasFeedback, icon, tabIndex } = props;

  const { contentManager } = useContext(MobXProviderContext);

  return (
    <Image
      alt=''
      aria-label={t('ariaFeedbackIcon')}
      className={classNames('feedback', 'clickable', className)}
      disabled={!!disabled}
      onClick={clickHandler}
      src={hasFeedback ? icon || contentManager.feedbackIconActiveImg || iconCommentActive : iconCommentNone}
      tabIndex={tabIndex} />
  );
});

export default FeedbackIcon;

SatCoreRegister('FeedbackIcon', FeedbackIcon);
