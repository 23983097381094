import { toJS } from 'mobx';

import classNames from 'classnames';

import { getRegisteredClass, registerClass } from '../../SatCoreRegistry';

import { GRADEBOOK_TABLE_ROWS_MAX_PAGE_SIZE } from '../../constants';

import assignmentManager from '../../managers/AssignmentManager';
import gradebookManager from '../../managers/GradebookManager';

import UtilityService from '../UtilityService';

import { toFixed } from '../../utils';

export default class GradebookDetailsService {
  static getGradebookDetailsTableScorableCellLabel = (score, {
    headerInfo, shouldRound, student, trueScoreIndex
  } = {}) => {
    const { isGradebookTyping } = gradebookManager;

    if (isGradebookTyping) {
      const GradebookTypingService = getRegisteredClass('GradebookTypingService');
      return GradebookTypingService.getGradebookTypingDetailsScorableCellLabel(score, {
        headerInfo, shouldRound, student, trueScoreIndex
      });
    }
    return toFixed(score, shouldRound);
  }

  static getGradebookDetailsTableScoreCellCreditClassName = (
    score, maxScore, isScorable = true, {
      headerInfo, student, trueScoreIndex
    } = {}) => {
    const { isGradebookTyping } = gradebookManager;

    if (isGradebookTyping) {
      const GradebookTypingService = getRegisteredClass('GradebookTypingService');
      return GradebookTypingService.getGradebookTypingDetailsTableScoreCellCreditClassName(
        score, maxScore, isScorable, { headerInfo, student, trueScoreIndex }
      );
    }
    // default behavior
    const allowAccessibilityTriangle = gradebookManager.allowFacultyGradebookAccessibility;
    if (typeof score === 'number' && isScorable) {
      if (!score) {
        return classNames('no-credit');
      } else if (maxScore && score < maxScore) {
        return classNames('partial-credit', {
          'accessibility-triangle': allowAccessibilityTriangle
        });
      }
      return classNames('full-credit', {
        'accessibility-triangle': allowAccessibilityTriangle
      });
    }
    return isScorable ? 'scorable-credit' : '';
  }

  static isSpecialAggregateGradebookDetailsSortCase = (clickedColumn, headerScoreIndex = -1) => (
    clickedColumn === 'status' ||
    clickedColumn === 'studentStatus' ||
      clickedColumn === 'grade' ||
      (clickedColumn && clickedColumn.includes('Slide')) ||
      headerScoreIndex >= 0
  )

  /**
   * Intended to be used as a special case to sort a given
   * `clickedColumn` in the aggregate gradebook **details** table.
   *
   * Uses backend to fetch the dataset, but the data is ultimately sorted on the frontend
   */
  static handleSpecialAggregateGradebookDetailsSortCase = async (
    clickedColumn, headerScoreIndex = -1
  ) => {
    const pageSize = GRADEBOOK_TABLE_ROWS_MAX_PAGE_SIZE;

    const rowsCurrentLength = gradebookManager.gradebookDetails?.students?.length || 0;
    const rowsTotalLength = gradebookManager.gradebookTableRowsTotalFromBackend || 0;

    if (!rowsTotalLength || rowsCurrentLength < rowsTotalLength) {
      // we do not have the entire dataset stored, so we need to fetch it
      await this.handleGradebookDetailsSortOnBackend(clickedColumn, pageSize);
    }
    await this.handleGradebookDetailsSortOnFrontend(clickedColumn, headerScoreIndex);
  }

  static handleGradebookDetailsSortOnBackend = async (
    clickedColumn, pageSize, { gradebookContext = '' } = {}
  ) => {
    pageSize = pageSize >= 0 ? pageSize : gradebookManager.GRADEBOOK_TABLE_ROWS_PAGE_SIZE;

    gradebookContext = gradebookContext || gradebookManager.activeGradebookTable;
    const sortObj = gradebookManager.gradebookTableSortMap.get(gradebookContext);

    const sortColumn = sortObj.sortColumn || null;
    const sortDirection = sortObj.sortDirection || 'ascending';

    let newDirection;
    if (!clickedColumn) {
      clickedColumn = sortColumn;
      newDirection = sortDirection;
    } else {
      if (sortColumn !== clickedColumn || sortDirection === 'descending') {
        newDirection = 'ascending';
      } else {
        newDirection = 'descending';
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const assignmentId = urlParams.get('assignmentId');
    const assignment = await assignmentManager.fetchActivity(assignmentId);

    const page = 0, clearStudentsFirst = true, clearAllFirst = true;
    const functions = {
      isSpecialAggregateGradebookDetailsSortCase: this.isSpecialAggregateGradebookDetailsSortCase
    };
    await gradebookManager.fetchAggregateGradebookDetails({
      assignment,
      clearAllFirst,
      clearStudentsFirst,
      functions,
      page,
      pageSize,
      sortDirection: newDirection,
      sortField: clickedColumn
    });
  }

  static handleGradebookDetailsSortOnFrontend = async (
    clickedColumn, headerScoreIndex = -1, {
      gradebookContext = ''
    } = {}
  ) => {
    gradebookManager.setGradebookTableSortTrueHeaderScoreIndex(headerScoreIndex);

    gradebookContext = gradebookContext || gradebookManager.activeGradebookTable;
    const sortObj = gradebookManager.gradebookTableSortMap.get(gradebookContext);

    const sortColumn = sortObj.sortColumn || null;
    const sortDirection = sortObj.sortDirection || 'ascending';

    let newDirection;
    if (!clickedColumn) {
      clickedColumn = sortColumn;
      newDirection = sortDirection;
    } else {
      if (sortColumn !== clickedColumn || sortDirection === 'descending') {
        newDirection = 'ascending';
      } else {
        newDirection = 'descending';
      }
    }

    const { activityId, classSummary, headerInfo, maxScores } = gradebookManager.gradebookDetails;
    const clearStudentsFirst = true, clearAllFirst = true;

    let students = toJS(gradebookManager.gradebookDetails.students);
    let sortingNumbers = false;

    if (headerScoreIndex >= 0) { // sort by CELL SCORE
      sortingNumbers = true;

      gradebookManager.setGradebookTableSortColumn(clickedColumn);
      gradebookManager.setGradebookTableSortDirection(newDirection);

      const path = `scores.${headerScoreIndex}`;
      students = UtilityService.sortColumn(students, newDirection, path, sortingNumbers);
      gradebookManager.setGradebookDetails({
        activityId, classSummary, headerInfo, maxScores, students
      }, clearStudentsFirst, clearAllFirst);
    } else if (clickedColumn === 'grade') { // sort by GRADE
      /* ensure all unsubmitted grades will be last in the list */
      sortingNumbers = true;
      let submittedStudents = students.filter((student) => {
        if (gradebookManager.activeGradebookType === 'aggregate' && typeof student.submitted === 'boolean') {
          return student.submitted;
        }
        const currentInstance = gradebookManager.assignmentInstances.find((instance) => instance.userId === student.studentId);
        return currentInstance ? currentInstance.submitted : false;
      });
      submittedStudents = UtilityService.sortColumn(submittedStudents, newDirection, clickedColumn, sortingNumbers);

      const unsubmittedStudents = students.filter((student) => {
        if (gradebookManager.activeGradebookType === 'aggregate' && typeof student.submitted === 'boolean') {
          return !student.submitted;
        }
        const currentInstance = gradebookManager.assignmentInstances.find((instance) => instance.userId === student.studentId);
        return currentInstance ? !currentInstance.submitted : true;
      });
      students = [...submittedStudents, ...unsubmittedStudents];

      gradebookManager.setGradebookTableSortColumn(clickedColumn);
      gradebookManager.setGradebookTableSortDirection(newDirection);

      gradebookManager.setGradebookDetails({
        activityId, classSummary, headerInfo, maxScores, students
      }, clearStudentsFirst, clearAllFirst);
    } else {
      students = UtilityService.sortColumn(students, newDirection, clickedColumn, sortingNumbers);

      gradebookManager.setGradebookTableSortColumn(clickedColumn);
      gradebookManager.setGradebookTableSortDirection(newDirection);

      gradebookManager.setGradebookDetails({
        activityId, classSummary, headerInfo, maxScores, students
      }, clearStudentsFirst, clearAllFirst);
    }
  }

  static getPaginatedGradebookDetailsHeaderInfo = () => {
    const PAGE_SIZE = gradebookManager.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE;
    const activePage = gradebookManager.activeGradebookDetailsHorizontalScoresPage;
    let paginatedHeaderInfo = gradebookManager.gradebookDetails.headerInfo;
    const start = (activePage - 1) * PAGE_SIZE;
    const end = activePage * PAGE_SIZE;
    paginatedHeaderInfo = paginatedHeaderInfo.slice(start, end);
    return paginatedHeaderInfo;
  }

  // TODO remove - unused
  // static getPaginatedGradebookDetailsMaxScores = () => {
  //   const PAGE_SIZE = gradebookManager.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE;
  //   const activePage = gradebookManager.activeGradebookDetailsHorizontalScoresPage;
  //   let paginatedMaxScores = gradebookManager.gradebookDetails.maxScores;
  //   const start = (activePage - 1) * PAGE_SIZE;
  //   const end = activePage * PAGE_SIZE;
  //   paginatedMaxScores = paginatedMaxScores.slice(start, end);
  //   return paginatedMaxScores;
  // }

  static getPaginatedGradebookDetailsStudentMaxScores = (student) => {
    const PAGE_SIZE = gradebookManager.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE;
    const activePage = gradebookManager.activeGradebookDetailsHorizontalScoresPage;
    let paginatedStudentMaxScores = [...student.maxScores];
    const start = (activePage - 1) * PAGE_SIZE;
    const end = activePage * PAGE_SIZE;
    paginatedStudentMaxScores = paginatedStudentMaxScores.slice(start, end);
    return paginatedStudentMaxScores;
  }

  static getPaginatedGradebookDetailsClassAverageScores = () => {
    const PAGE_SIZE = gradebookManager.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE;
    const activePage = gradebookManager.activeGradebookDetailsHorizontalScoresPage;
    let paginatedClassAverageScores = [...gradebookManager.gradebookDetails.classSummary.scores];
    const start = (activePage - 1) * PAGE_SIZE;
    const end = activePage * PAGE_SIZE;
    paginatedClassAverageScores = paginatedClassAverageScores.slice(start, end);
    return paginatedClassAverageScores;
  }

  static getPaginatedGradebookDetailsStudentScores = (student) => {
    const PAGE_SIZE = gradebookManager.GRADEBOOK_DETAILS_TABLE_HORIZONTAL_SCORES_PAGE_SIZE;
    const activePage = gradebookManager.activeGradebookDetailsHorizontalScoresPage;
    let paginatedStudentScores = [...student.scores];
    const start = (activePage - 1) * PAGE_SIZE;
    const end = activePage * PAGE_SIZE;
    paginatedStudentScores = paginatedStudentScores.slice(start, end);
    return paginatedStudentScores;
  }
}

registerClass('GradebookDetailsService', GradebookDetailsService);
