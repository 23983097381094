import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Container, Dropdown, Item, Loader
} from 'semantic-ui-react';

import '../css/StudentAssignmentView.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import { ASSIGNMENT_MENU_STATUS, ASSIGNMENT_STATUS } from '../managers/AssignmentManager';
import { CONTENT_MODE, PLAYER_TYPES } from '../managers/ContentManager';
import { DIALOG_NAMES } from '../managers/DialogManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import AssignmentService from '../services/AssignmentService';
import CourseService from '../services/CourseService';
import ContentService from '../services/ContentService';
import ImageService from '../services/ImageService';

import { dateFormat } from '../utils';

export default
@inject(
  'appManager',
  'assignmentManager',
  'classroomManager',
  'contentManager',
  'dialogManager',
  'navigationManager',
  'studentContentCardManager',
  'tagContentManager',
  'userManager')
@observer
class StudentAssignmentView extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.AssignmentMenu = SatCoreComponent('AssignmentMenu');
    this.DocReaderModal = SatCoreComponent('DocReaderModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.LearnosityPlayerModal = SatCoreComponent('LearnosityPlayerModal');
    this.LearnosityScoringModal = SatCoreComponent('LearnosityScoringModal');
    this.NoAssignmentsMessage = SatCoreComponent('NoAssignmentsMessage');
    this.StudentAssignmentCard = SatCoreComponent('StudentAssignmentCard');

    const today = new Date();

    this.state = {
      assignmentId: null,
      assignmentInstruction: null,
      assignmentResourceFilter: 'all-assignments',
      contentImageUrl: null,
      contentItemDescription: null,
      contentItemId: '',
      contentItemImageUrl: null,
      contentName: '',
      contentSubtitle: null,
      disablePresent: false,
      docreaderViewerShowing: false,
      editAssignmentId: null,
      fileViewerShowing: false,
      infoName: null,
      instruction: null,
      learnosityPlayerShowing: false,
      learnosityScoringPlayerShowing: false,
      loading: false,
      menuStatus: ASSIGNMENT_MENU_STATUS.CURRENT,
      resourceInfo: null,
      resourceName: null,
      search: '',
      sort: t('DefaultSortOrder', 'asc'),
      status: [ASSIGNMENT_STATUS.LOCKED, ASSIGNMENT_STATUS.READY, ASSIGNMENT_STATUS.STARTED],
      subheaderValue: ''
    };
  }

  componentDidMount() {
    const { navigationManager } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.ASSIGNMENTS);
    }
  }

  renderAssignmentCardsStudent = () => {
    const { assignmentManager } = this.props;
    const { menuStatus } = this.state;
    const { StudentAssignmentCard } = this;
    const items = [];

    assignmentManager.assignmentArray.map((assignment) => {
      const contentImageUrl = ImageService.getImageUrl(assignment);

      items.push(
        <StudentAssignmentCard
          key={`AAC_${assignment.id}`}
          assignment={assignment}
          contentImageUrl={contentImageUrl}
          disabled={assignment.licenseExpired}
          handlePresent={this.handlePresent}
          handleSelfPacedOpen={this.handleSelfPacedOpen}
          menuStatus={menuStatus} />
      );
      return true;
    });
    return items;
  };

  fetchAssignments = async (
    menuStatus, search, sort, contentTypes = null,
    currentPage = 0, _dueDate = false, clear = true
  ) => {
    const { appManager, assignmentManager, classroomManager } = this.props;
    if (appManager.initializingApp) {
      return;
    }

    let { assignmentResourceFilter } = this.state;
    if (this.state.menuStatus !== menuStatus) {
      assignmentResourceFilter = 'all-assignments';
    }

    let resourceTypes = null;
    if (assignmentResourceFilter === 'assessments-only') {
      resourceTypes = 'learnosity_activity_resource,assessment';
    }

    this.setState({
      assignmentResourceFilter,
      loading: true,
      menuStatus,
      search,
      sort,
    });

    let today = null;
    let status = [];

    // CURRENT: shows all current assignments by due date starting with the most recent (oldest) due date:
    // ONLY locked, started, ready statuses show on this filter.
    // Default SORT - Newest
    if (menuStatus === ASSIGNMENT_MENU_STATUS.CURRENT) {
      status = [ASSIGNMENT_STATUS.LOCKED, ASSIGNMENT_STATUS.READY, ASSIGNMENT_STATUS.STARTED];
    }
    // DUE TODAY: shows only assignments with due dates matching the current day.
    if (menuStatus === ASSIGNMENT_MENU_STATUS.TODAY) {
      today = Auth.getToday();
    }
    // CLOSED: Shows all closed status assignments from the most recent due date on.
    // Default SORT - Newest
    if (menuStatus === ASSIGNMENT_MENU_STATUS.CLOSED) {
      status = [ASSIGNMENT_STATUS.CLOSED];
    }
    // Graded: shows all graded assignments.
    // Default SORT - Newest
    if (menuStatus === ASSIGNMENT_MENU_STATUS.GRADED) {
      status = ['completed'];
    }

    assignmentManager.setAssignmentStatus(status);
    const classroomId = classroomManager.currentClassroomId;
    const filterDateStr = today;
    const assignmentType = null;
    const assignerType = null;
    const dueToday = today !== null;
    const pageSize = null;
    const searchAll = true;

    await assignmentManager.fetchStudentAssignments(
      classroomId, filterDateStr, status,
      assignmentType, search, contentTypes, assignerType,
      currentPage, dueToday, clear, pageSize, sort, searchAll,
      resourceTypes
    );
    this.setState({ loading: false });
  }

  handleLoadMore = async (page) => {
    this.setState({ loading: true });
    const { assignmentManager } = this.props;
    await assignmentManager.fetchMoreStudentAssignments(page);
    this.setState({ loading: false });
  }

  fetchTags = async () => {
    this.setState({ loading: true });
    const { tagContentManager } = this.props;
    const result = await tagContentManager.fetchContentCategoryTagsByName();
    if (!result) {
      /* no tags used */
    }
    this.setState({ loading: false });
  }

  handlePresent = async (params) => {
    const {
      assignmentManager, contentManager, studentContentCardManager, tagContentManager, userManager,
    } = this.props;

    const { assignment, contentMode, turnInLate } = params;
    const { disablePresent } = this.state;
    if (disablePresent) {
      return false;
    }
    this.setState({ disablePresent: true });

    if (contentMode === CONTENT_MODE.ASSESS) {
      const preStartContentItemId = assignment.contentItemId;
      if (ASSIGNMENT_STATUS.getCheckableStatuses().includes(assignment.status)) {
        const isReady = await assignmentManager.checkActivity(assignment.activityId);
        if (isReady) {
          const isOk = await assignmentManager.startActivity(assignment.id, assignment.activityId, turnInLate);
          if (isOk === false) {
            return false;
          }
        } else {
          this.setState({ disablePresent: false });
          return false;
        }
      }

      const postStartContentItemId = assignment.contentItemId;
      if (preStartContentItemId !== postStartContentItemId && tagContentManager.useContentTags) {
        // this has been versioned. Make sure the image is added.
        // console.log('*** A new version: ' + postStartContentItemId +' has been created from: '+ preStartContentItemId);
        tagContentManager.createNewCardContentTagFromOld(preStartContentItemId, postStartContentItemId);
      }
    }

    let option = null;
    if (contentMode === CONTENT_MODE.REVIEW) {
      option = await contentManager.getOptionsForStudentReview(
        assignment, window.location.origin, userManager.isTeacher, userManager.userId);
    } else {
      option = await contentManager.getOptionsForStudentAssessment(
        assignment, window.location.origin, userManager.isTeacher, userManager.userId);
    }

    const { playerType, viewUrl, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      this.setState({ disablePresent: false });

      if (studentContentCardManager.isLoading) {
        studentContentCardManager.setIsLoading(false);
        this.forceUpdate();
      }
      return;
    }

    contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    const contentImageUrl = ImageService.getImageUrl(assignment);

    const { dialogManager } = this.props;
    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        resourceName: (assignment.resourceWebTitle !== '' && assignment.resourceWebTitle != null) ?
          assignment.resourceWebTitle : assignment.name,
        assignmentId: assignment.id,
        contentItemId: assignment.contentItemId,
        contentItemType: assignment.contentItemEntityTypeId,
        contentMode,
        instruction: assignment.activityInstruction,
        closeModalCallback: this.hideModal
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
      this.setState({ disablePresent: false });
      return;
    }

    const {
      resourceWebTitle, resourceWebSubtitle
    } = AssignmentService.getAssignmentTitleAndDescription(assignment);

    this.setState({
      activityId: assignment.activityId,
      assignmentId: assignment.id,
      contentImageUrl,
      contentItemId: assignment.contentItemId,
      contentMode,
      contentName: resourceWebTitle,
      contentPreviewShowing: (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER),
      contentSubtitle: resourceWebSubtitle,
      contentUrl: viewUrl,
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      grade: assignment.grade,
      instruction: assignment.activityInstruction,
      isFlowpaper,
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      learnosityScoringPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_SCORING_PLAYER),
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      previewContentItemId: assignment.id,
      previewContentType: assignment.contentItemEntityTypeId,
      resourceName: assignment.contentItemName
    });

    this.setState({ disablePresent: false });
  }

  handleSelfPacedOpen = async (contentMode, assignment) => {
    const { assignmentManager, classroomManager } = this.props;
    // first, create the assignment
    await assignmentManager.studentCreateAssignment(
      assignment.contentItemId, assignment.courseResourceElementId, classroomManager.currentClassroomId, assignment.courseContentItemId);
    // then call to open it.
    if (assignmentManager.getLastAddedAssignment()) {
      this.handlePresent({ assignment: assignmentManager.getLastAddedAssignment(), contentMode });
    }
  }

  handleAssignmentResourceFilter = (event, data) => {
    this.setState({ assignmentResourceFilter: data.value }, () => {
      const { menuStatus, search, sort } = this.state;
      this.fetchAssignments(menuStatus, search, sort);
    });
  }

  hideModal = () => {
    const { menuStatus, search, sort } = this.state;
    this.setState({
      docreaderViewerShowing: false,
      fileViewerShowing: false,
      learnosityPlayerShowing: false,
      learnosityScoringPlayerShowing: false,
    });
    this.fetchAssignments(menuStatus, search, sort);
  }

  hideIframeFromOuterClick = (event) => {
    if ((event.origin === Auth.publisher || event.origin === Auth.lesson) && event.data === 'hideIframe') {
      this.setState({ lessonPlayerShowing: false });
      ContentService.setLessonPlayerShowing(false);
      CourseService.setCourseResourceItemIdForRelatedItems(null);
      CourseService.setCourseContentItemIdForRelatedItems(null);
      CourseService.setTagIds('',true);
      window.removeEventListener('message', this.hideIframeFromOuterClick);
    }
  }

  hideIframe = () => {
    this.setState({ lessonPlayerShowing: false });
  }

  renderFileViewer = () => {
    const { userManager } = this.props;
    const {
      activityId,
      assignmentId,
      contentImageUrl,
      contentUrl,
      contentItemId,
      contentMode,
      contentName,
      instruction,
      isFlowpaper,
      previewContentType,
      resourceName
    } = this.state;
    const { FileViewerModal } = this;
    return (
      <div className='course-content'>
        <FileViewerModal
          activityId={activityId}
          assignmentId={assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentItemId={contentItemId}
          contentMode={contentMode}
          contentName={contentName}
          contentType={previewContentType}
          instruction={instruction}
          isFlowpaper={isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='file-viewer'
          resourceName={resourceName}
          url={contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { contentManager, renderDocreaderViewer, userManager } = this.props;
    const {
      assignmentId,
      contentImageUrl,
      contentMode,
      contentName,
      previewContentType,
      instruction,
      isFlowpaper,
      resourceName,
      contentUrl
    } = this.state;
    const { DocReaderModal } = this;
    if (renderDocreaderViewer !== undefined) {
      return renderDocreaderViewer();
    }

    return (
      <div className='course-content'>
        <DocReaderModal
          assignmentId={assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentMode={contentMode}
          contentName={contentName}
          contentType={previewContentType}
          instruction={instruction}
          isFlowpaper={isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='docreader-viewer'
          resourceName={resourceName}
          sessionId={contentManager.currentflowPaperSessionId}
          url={contentUrl} />
      </div>
    );
  }

  renderLearnosityPlayer = () => {
    const { userManager, history } = this.props;
    const {
      assignmentId,
      contentImageUrl,
      contentItemId,
      contentName,
      contentSubtitle,
      instruction,
      resourceName
    } = this.state;
    const { LearnosityPlayerModal } = this;
    return (
      <div className='course-content'>
        <LearnosityPlayerModal
          assignmentId={assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentItemId={contentItemId}
          contentName={contentName}
          contentSubtitle={contentSubtitle}
          history={history}
          instruction={instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-player'
          resourceName={resourceName} />
      </div>
    );
  }

  renderLearnosityScoringModal = () => {
    const { history, userManager } = this.props;
    const {
      assignmentId,
      contentImageUrl,
      contentItemDescription,
      contentItemId,
      contentName,
      grade,
      instruction,
      resourceName
    } = this.state;
    const { LearnosityScoringModal } = this;
    return (
      <div className='course-content'>
        <LearnosityScoringModal
          activityInstanceId={assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={contentImageUrl}
          contentItemId={contentItemId}
          contentSubTitle={contentItemDescription}
          contentTitle={contentName}
          grade={grade}
          history={history}
          instruction={instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-scoring'
          resourceName={resourceName}
          studentName={`${userManager.firstName} ${userManager.lastName}`} />
      </div>
    );
  }

  renderRest() {
    const { appManager, assignmentManager, location, t } = this.props;
    const { loading, menuStatus, search, sort } = this.state;

    const { NoAssignmentsMessage } = this;
    const { AssignmentMenu } = this;

    // TODO remove // const todayStr = Moment(new Date()).format('dddd, MMMM Do');
    const todayStr = dateFormat(new Date(), 'fullDateNoYear', appManager.locale);

    return (
      <>
        <Container className='student-assignment-view-container' fluid>

          <AssignmentMenu
            fetchAssignments={this.fetchAssignments}
            fetchTags={this.fetchTags}
            location={location}
            menuStatus={menuStatus}
            search={search}
            sort={sort} />
          {/* eslint-disable-next-line no-nested-ternary */}
          {((!assignmentManager.loaded || loading) && !assignmentManager.assignmentArray.length) ? <Loader active /> :
            (!assignmentManager.assignmentArray.length) ? (
              <>
                {assignmentManager.studentAssignmentResourceTypeFilter && (
                  <div>
                    <div className='sa-assignment-filter-no-assignments'>
                      <div className='date-element sa-today-element-img'>
                        <div>
                          <div className='today-is'>{t('todayIs')}</div>
                          <div className='date-display'>{todayStr}</div>
                        </div>
                      </div>
                      <div className='sa-assignment-filter'>
                        <Dropdown
                          fluid
                          onChange={this.handleAssignmentResourceFilter}
                          options={[{ selected: true, text: 'All Assignments', value: 'all-assignments' },
                            { text: 'Assessments Only', value: 'assessments-only' }]}
                          selection
                          value={this.state.assignmentResourceFilter} />
                      </div>
                    </div>
                  </div>
                )}
                <NoAssignmentsMessage message={
                  t(ASSIGNMENT_MENU_STATUS.getNoAssignmentsMessage(menuStatus),
                    'Missing translation in StudentAssignmentView'
                  )
                } />
              </>
            ) : (
              <Container className='search-gui'>
                <Container className='cards'>
                  <Item.Group>
                    {this.renderAssignmentCardsStudent()}
                    {/* TODO InfiniteScroll appears broken for student assignments, so taking it out for now */}
                    {/* <InfiniteScroll
                      className='ui items scroller'
                      getScrollParent={() => this.scrollParentRef}
                      hasMore={assignmentManager.hasMore}
                      initialLoad={false}
                      loadMore={this.handleLoadMore}
                      pageStart={0}
                      useWindow={true}>
                      {this.renderAssignmentCardsStudent()}
                    </InfiniteScroll> */}
                  </Item.Group>
                </Container>
                <div>
                  <div className='date-element sa-today-element-img'>
                    <div>
                      <div className='today-is'>{t('todayIs')}</div>
                      <div className='date-display'>{todayStr}</div>
                    </div>
                  </div>
                  {assignmentManager.studentAssignmentResourceTypeFilter && (
                    <div className='sa-assignment-filter'>
                      <Dropdown
                        fluid
                        onChange={this.handleAssignmentResourceFilter}
                        options={[{ selected: true, text: 'All Assignments', value: 'all-assignments' },
                          { text: 'Assessments Only', value: 'assessments-only' }]}
                        selection
                        value={this.state.assignmentResourceFilter} />
                    </div>
                  )}
                </div>
              </Container>
            )}
        </Container>
      </>
    );
  }

  renderLessonPlayer = () => {
    const { userManager } = this.props;
    const { contentImageUrl, contentName, contentUrl, instruction } = this.state;
    const { FullscreenModal } = this;

    return (
      <div className='course-content'>
        <FullscreenModal
          className={'lesson-player'}
          closeIframeCallback={this.hideIframe}
          contentImageUrl={contentImageUrl}
          contentName={contentName}
          instruction={instruction}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={contentUrl} />
      </div>
    );
  }

  render() {
    const {
      docreaderViewerShowing,
      fileViewerShowing,
      learnosityPlayerShowing,
      learnosityScoringPlayerShowing,
      lessonPlayerShowing
    } = this.state;
    if (learnosityPlayerShowing) {
      return this.renderLearnosityPlayer();
    } else if (lessonPlayerShowing) {
      return (this.renderLessonPlayer());
    } else if (fileViewerShowing) {
      return (this.renderFileViewer());
    } else if (docreaderViewerShowing) {
      return (this.renderDocreaderViewer());
    } else if (learnosityScoringPlayerShowing) {
      return this.renderLearnosityScoringModal();
    }
    return (this.renderRest());
  }
}

SatCoreRegister('StudentAssignmentView', StudentAssignmentView);
