import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import '../css/SatelliteTab.less';
import Auth from '../managers/AuthManager';

import { SatCoreRegister } from '../SatCoreRegistry';
import CourseService from '../services/CourseService';
import ContentService from '../services/ContentService';

export default
@inject('contentManager', 'courseManager', 'userManager')
@observer
class SatelliteTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftSide: true,
      tabOpen: false
    };
  }

  componentDidMount() {
    window.removeEventListener('message', this.handleTagSatSearch);
    window.addEventListener('message', this.handleTagSatSearch);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleTagSatSearch);
  }

  handleTagSatSearch = async (event) => {
    const {courseManager} = this.props;
    if (event.origin === Auth.lesson && event.data.type && event.data.type === 'current_tags') {
      if (event.data.tagList && event.data.tagList.length > 0) {
        courseManager.setTagIds(event.data.tagList.split(','), true);
        await courseManager.searchCourseResourceElements(true, event.data.relatedContentSearchAndLogic);
      } else {
        courseManager.setTagIds('', true);
      }
    }
  }

  handleTabClick = (event) => {
    const { tabOpen } = this.state;
    this.setState({ tabOpen: !tabOpen });
  }

  render() {

    const { additionalClassName = '', ChildComponent, tabName, contentManager, courseManager, userManager } = this.props;
    const { lessonPlayerShowing } = contentManager;
    const { isStudent } = userManager;
    const { leftSide, tabOpen } = this.state;

    let satelliteTabClassName = 'satelliteTab';
    satelliteTabClassName += additionalClassName ? ` ${additionalClassName}` : '';

    let tabFinalName = 'Related Content';
    if (tabName !== null && tabName !== undefined) {
      tabFinalName = tabName;
    }

    if (!isStudent && ChildComponent !== null && ChildComponent !== undefined && lessonPlayerShowing && (courseManager.tagIds && courseManager.tagIds.length > 0)) {
      return (
        <div className={classNames({ left: leftSide, open: tabOpen }, satelliteTabClassName)}>
          <div className={classNames('contentPanel')}>
            <ChildComponent />
          </div>
          <div className={classNames({ left: leftSide }, 'satelliteTabButton')}
            onClick={(e) => this.handleTabClick(e)} role='tab'>
            <div className={classNames({ 'flyout-tab-label': true })} tabIndex='-1'>{tabFinalName}</div>
          </div>
        </div>
      );
    }

    return (
      <></>
    );
  }
}

SatCoreRegister('SatelliteTab', SatelliteTab);
