import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { Loader } from 'semantic-ui-react';

import classNames from 'classnames';

import { SatCoreRegister } from '../SatCoreRegistry';

import Auth from '../managers/AuthManager';

import DynamicSatelliteService from '../services/DynamicSatelliteService';

import '../css/DemolinkLauncherView.less';

import { renderButton } from '../utils';

import UtilityService from '../services/UtilityService';

const DemolinkLauncherView = observer((props) => {
  const { history, t } = props;

  const { dynamicSatelliteManager, userManager } = useContext(MobXProviderContext);

  const [loaded, setLoaded] = useState(false);

  const [demolinkId, setDemolinkId] = useState(undefined);

  const [demolinkResult, setDemolinkResult] = useState(undefined);

  useEffect(() => {
    (async () => {
      const _demolinkId = props?.match?.params?.demolinkId;
      setDemolinkId(_demolinkId);

      if (dynamicSatelliteManager.isDynamicSatellite && !Auth.publisherSatelliteCode) {
        await DynamicSatelliteService.fetchDynamicSatelliteByDomain();
        setTimeout(() => {
          setLoaded(true);
        }, 500);
      } else {
        setLoaded(true);
      }
    })();
  }, []);

  const handleLaunchDemolink = async () => {
    setLoaded(false);

    const firstName = t('demoUserFirstName', 'Juan');
    const lastName = t('demoUserLastName', 'Perez');

    const result = await userManager.registerDemolinkUser({ demolinkId, firstName, lastName });
    setDemolinkResult(result);

    if (result?.status === 'SUCCESS' && result?.authKey) {
      await Auth.logInWithAuthKey(result.authKey);
      setTimeout(() => {
        setLoaded(true);
        history.replace('/');
      }, 500);
    } else {
      setLoaded(true);
    }
  };

  const demolinkResultSuccess = demolinkResult?.status === 'SUCCESS';

  const demolinkBoxTitle = demolinkResult ? (demolinkResult?.statusMessage || t('demolinkBoxErrorTitle')) : t('demolinkBoxTitle');
  const demolinkBoxDescription = demolinkResult ? t('demolinkBoxErrorDescription') : t('demolinkBoxDescription');

  return loaded && !demolinkResultSuccess ? (
    <div className='DemolinkLauncherView'>
      <div className={classNames('demolink-launcher-box', {
        'has-result': !!demolinkResult
      })}>
        <div className='demolink-launcher-box-title'>
          {UtilityService.reactHtmlParserWrapper(demolinkBoxTitle)?.parsed}
        </div>
        <div className='demolink-launcher-box-description'>
          {UtilityService.reactHtmlParserWrapper(demolinkBoxDescription)?.parsed}
        </div>
        {!demolinkResult && (
          <div className='demolink-launcher-box-actions'>
            {renderButton({
              buttonKey: 'continue',
              onClick: handleLaunchDemolink,
              primary: true
            })}
          </div>
        )}
      </div>
    </div>
  ) : <Loader active />;
});
export default DemolinkLauncherView;

SatCoreRegister('DemolinkLauncherView', DemolinkLauncherView);
