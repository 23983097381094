import appManager from '../managers/AppManager';
import topNavManager from '../managers/TopNavManager';

import { register } from '../i18n';

import { ADMIN_TOP_NAV, STUDENT_TOP_NAV, TEACHER_TOP_NAV, TOP_NAV_NAME } from '../constants';
import { stripHtmlTags } from './stripHtml';

export const setDocumentTitle = (translationKey, {
  shouldPrefixWithSatelliteName = true,
  suffix = '',
  translationObjName
} = {}) => {
  translationObjName = translationObjName || topNavManager.activeTopNavName || TOP_NAV_NAME.TopNav;

  const t = register(translationObjName);

  const { satelliteName } = appManager;

  const documentTitle = shouldPrefixWithSatelliteName && satelliteName ? (
    `${satelliteName}: ${t(translationKey)}`
  ) : t(translationKey);

  if (suffix) {
    document.title = `${documentTitle} - ${suffix}`;
  } else {
    document.title = documentTitle;
  }
};

export const setDocumentTitleFromSelectionView = (viewSelectionValue, {
  shouldPrefixWithSatelliteName = true,
  suffix = '',
  translationObjName,
  VIEW_SELECTION
} = {}) => {
  translationObjName = translationObjName || topNavManager.activeTopNavName || TOP_NAV_NAME.TopNav;

  let USER_TOP_NAV;
  if (translationObjName === TOP_NAV_NAME.AdminTopNav) {
    USER_TOP_NAV = ADMIN_TOP_NAV;
  } else if (translationObjName === TOP_NAV_NAME.StudentTopNav) {
    USER_TOP_NAV = STUDENT_TOP_NAV;
  } else if (translationObjName === TOP_NAV_NAME.TopNav) {
    USER_TOP_NAV = TEACHER_TOP_NAV;
  }

  let activeTopNavObj, translationKey;
  if (USER_TOP_NAV) {
    activeTopNavObj = Object.values(USER_TOP_NAV).find((topNavObj) => {
      return VIEW_SELECTION[topNavObj.viewSelectionKey] === viewSelectionValue;
    });
    if (activeTopNavObj) {
      translationKey = activeTopNavObj.navTranslationKey;
    } else {
      translationKey = Object.keys(VIEW_SELECTION).find((viewSelectionKey) => {
        return VIEW_SELECTION[viewSelectionKey] === viewSelectionValue;
      });
      translationObjName = 'ViewSelection';
    }

    if (translationKey) {
      setDocumentTitle(translationKey, {
        shouldPrefixWithSatelliteName,
        suffix,
        translationObjName
      });
    } else {
      document.title = appManager.satelliteName;
    }
  }
};

export const setDocumentTitleFromBreadCrumbs = (pathName) => {
  const { satelliteName } = appManager;

  if (!pathName) {
    document.title = satelliteName;
    return;
  } else if (Array.isArray(pathName)) {
    [pathName] = pathName;
  }

  pathName = stripHtmlTags(pathName);

  const documentTitle = satelliteName ? (
    `${satelliteName}: ${pathName}`
  ) : pathName;

  document.title = documentTitle;
};
