import { toJS } from 'mobx';

import { registerClass } from '../../SatCoreRegistry';

import reportCourseManager from '../../managers/reports/ReportCourseManager';
import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import reportTableManager from '../../managers/reports/ReportTableManager';
import reportTypeManager from '../../managers/reports/ReportTypeManager';

import { flattenChildren, renameKeys } from '../../utils';

import { REPORT_LESSON_FILTER_TYPE } from './ReportConstants';

import ReportCourseClassroomService from './ReportCourseClassroomService';
import ReportCourseDistrictService from './ReportCourseDistrictService';
import ReportCourseSchoolService from './ReportCourseSchoolService';
import ReportJsonHelperService from './ReportJsonHelperService';
import ReportScoreService from './ReportScoreService';

export default class ReportCourseService {
  /** Intended to be called via `ReportTypeService.initReportTableData` */
  static _initReportTableData = async () => {
    const tableData = this.getAvailableReportCourses()?.availableReportCourses;
    reportTableManager.setReportTableData(tableData);
  }

  /** Intended to be called via `ReportTypeService.initReportTableScoreSummaryBarCellConfig` */
  static _initReportTableScoreSummaryBarCellConfig = (initProps) => {
    const { renderScoreSummaryBarCell, renderScoreSummaryBarHeaderCell } = initProps;
    const { reportInfoClassNames } = reportIdentityManager;
    return [
      {
        /** `cell-score-summary-bar` */
        Cell: (props) => renderScoreSummaryBarCell(props),
        Header: (props) => renderScoreSummaryBarHeaderCell(props),
        accessor: (props) => {
          const { elementId } = props;
          const allScoreInfo = ReportJsonHelperService.REPORT_SCORE_INFO_BY_ELEMENT_FOR_STUDENTS();
          const multiScoreInfoObj = allScoreInfo[elementId];
          const scoreSummaryBarData = ReportScoreService.getScoreSummaryData({ multiScoreInfoObj });
          return scoreSummaryBarData; // props.cell.value.scoreSummaryBarData
        },
        className: `cell-score-summary-bar ${reportInfoClassNames}`,
        id: 'score-summary-bar'
      }
    ];
  }

  /** Intended to be called via `ReportTypeService.initReportTableFacultyCellConfigArray` */
  static _initReportTableFacultyCellConfigArray = (props) => {
    const {
      isFacultyClassroomReport,
      isFacultyDistrictReport,
      isFacultySchoolReport
    } = reportIdentityManager;

    if (isFacultyDistrictReport) {
      return ReportCourseDistrictService._initReportTableFacultyCellConfigArray(props);
    } else if (isFacultySchoolReport) {
      return ReportCourseSchoolService._initReportTableFacultyCellConfigArray(props);
    } else if (isFacultyClassroomReport) {
      return ReportCourseClassroomService._initReportTableFacultyCellConfigArray(props);
    }
  }

  static getAvailableReportCourses = (reportResponseJson) => {
    const { selectedReportLessonFilterType } = reportTypeManager;

    reportResponseJson = reportResponseJson || reportIdentityManager.reportResponseJson;

    const { selectedReportCourseName } = reportCourseManager;
    const { isFacultyAdminReport } = reportIdentityManager;

    const REPORT_COURSES = (reportResponseJson?.data[0]?.contentJson?.data?.courses) || [];
    let availableReportCourses = [...REPORT_COURSES];

    // if admin report, filter by selectedReportCourseName
    availableReportCourses = availableReportCourses.filter((course) => {
      return !isFacultyAdminReport || !selectedReportCourseName || course.courseName === selectedReportCourseName;
    });

    // combine each `course` with `course.rootElement` so the contents are at the same level
    availableReportCourses = availableReportCourses.map((course) => {
      const { rootElement, ...obj } = course;
      const courseWithRootElementAtSameLevel = {
        ...obj,
        ...rootElement,
        hasConventional: course.hasConventional,
        hasLikert: course.hasLikert
      };
      return { ...courseWithRootElementAtSameLevel };
    });
    availableReportCourses = renameKeys({ children: 'subRows' }, availableReportCourses);

    availableReportCourses = selectedReportLessonFilterType && selectedReportLessonFilterType !== REPORT_LESSON_FILTER_TYPE.ALL ?
      this.applyLessonFilterToReportTableData(availableReportCourses) : availableReportCourses;

    return {
      availableReportCourses,
      originalReportCourses: toJS(REPORT_COURSES)
    };
  }

  static applyLessonFilterToReportTableData = (tableData) => {
    const { selectedReportLessonFilterType } = reportTypeManager;
    const modifiedData = tableData.map((tableRowParent) => {
      const flatTableRows = flattenChildren(tableRowParent.subRows, 'subRows');
      return {
        ...tableRowParent,
        subRows: [
          ...flatTableRows.filter((flatTableRow) => {
            const { contentType } = flatTableRow;
            return typeof contentType === 'string' &&
              contentType.toLowerCase().includes(selectedReportLessonFilterType);
          })
        ]
      };
    }).filter((tableRowParent) => !!tableRowParent?.subRows?.length);
    return modifiedData;
  }

  static getFirstMappedCourseId = () => {
    return reportCourseManager.reportCourseContentItemIds[0];
  }

  static removeCourseEntryFromMap = (courseContentItemId) => {
    reportCourseManager.removeCourseEntryFromMap(courseContentItemId);
  }

  static getSelectedReportCourseContentItemId = () => {
    return reportCourseManager.selectedReportCourseContentItemId;
  }

  static clearSelectedReportCourseInfo = () => {
    reportCourseManager.setSelectedReportCourseInfo(null, null);
  }
}

registerClass('ReportCourseService', ReportCourseService);
