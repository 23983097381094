/* eslint-disable sort-keys */
/* eslint-disable max-len */
import accommodationsManager from '../managers/AccommodationsManager';
import assignmentManager, { ASSIGNMENT_STATUS, ASSIGNMENT_TYPE } from '../managers/AssignmentManager';
import classroomManager from '../managers/ClassroomManager';
import courseManager from '../managers/CourseManager';
import gradebookManager from '../managers/GradebookManager';
import groupsManager from '../managers/GroupsManager';
import userManager from '../managers/UserManager';

import ReportIdentityService from './reports/ReportIdentityService';

import UtilityService from './UtilityService';

export default class AssignmentService {
  static getCurrentAssignment = (instanceInfo = null) => {
    if (instanceInfo?.activityId) {
      return assignmentManager.getAssignment(instanceInfo.activityId) || gradebookManager.currentAssignment;
    } else {
      return gradebookManager.currentAssignment;
    }
  }

  static getCurrentAssignmentAsync = async (instanceInfo = null) => {
    if (instanceInfo?.activityId) {
      return assignmentManager.getAssignmentAsync(instanceInfo.activityId) || gradebookManager.currentAssignment;
    } else {
      return gradebookManager.currentAssignment;
    }
  }

  static submitAddAssignments = async (props = {}) => {
    const {
      allowMultipleResources = false,
      singularContentItemId = null,
      singularCourseResourceElementId
    } = props;

    const activityContentArr = [];
    if (!allowMultipleResources) {
      activityContentArr.push({
        contentItemId: singularContentItemId,
        courseResourceElementId: singularCourseResourceElementId
      });
    } else {
      const resourcesMap = groupsManager.assignableGroupResourcesMap;
      const resourceContentItemIds = groupsManager.groupResourceSelectorCheckedChildContentItemIds;
      for (const resourceContentItemId of resourceContentItemIds) {
        const courseResourceElementId = resourcesMap.get(resourceContentItemId)?.elementId;
        activityContentArr.push({
          contentItemId: resourceContentItemId,
          courseResourceElementId
        });
      }
    }

    await this.submitAddAssignment({ ...props, activityContentArr });
  }

  static submitAddAssignment = async (props = {}) => {
    try {
      const {
        activityContentArr, activityId, activityModeId, allowLateSubmission, allowMultipleResources,
        alternateModeId, assignmentType, classroomGroups, courseContentItemId, currentClassrooms,
        currentRoster, editorValue, endDate, endTime, ignoreItemOptionShuffle, includeInReports,
        isReassign, lateSubmitDate, lateSubmitTime, modeOverrideAllowed, nickname, randomizeQuestions,
        scoresReleased, selectedClassroomDropdownOption, showCorrectAnswers, startDate, startTime,
        studentReview, typingAllowBackspace, typingText, typingDuration, typingMinimumSpeed, typingMinimumAccuracy
      } = props;

      const offAccommodationIdsStr = this.getOffAccommodationIdsStr();

      const trimmedNickname = nickname ? nickname.trim() : '';
      const groupIds = classroomGroups;

      let result = false;

      const classroomId = selectedClassroomDropdownOption?.value || classroomManager.currentClassroomId;

      const startRealDate = assignmentManager.convertJSStringToJSDate(startDate, startTime);
      const endRealDate = assignmentManager.convertJSStringToJSDate(endDate, endTime);
      const instruction = editorValue;
      let assignEntityTypeId = '';
      let assignEntityIds = '';

      const additionalPropertiesJson = {};
      const { includeAssignmentAccommodations } = accommodationsManager;
      if (includeAssignmentAccommodations) {
        additionalPropertiesJson.accommodationIds = offAccommodationIdsStr;
      }
      //Learnosity
      additionalPropertiesJson.allowLateSubmission = allowLateSubmission;
      additionalPropertiesJson.showCorrectAnswers = showCorrectAnswers;
      additionalPropertiesJson.shuffle_items = randomizeQuestions;
      additionalPropertiesJson.ignoreItemOptionShuffle = ignoreItemOptionShuffle;

      // Keyboarding
      additionalPropertiesJson.typingAllowBackspace = typingAllowBackspace;
      additionalPropertiesJson.typingText = typingText;
      additionalPropertiesJson.typingDurationInSeconds = typingDuration;
      additionalPropertiesJson.typingMinSpeed = typingMinimumSpeed;
      additionalPropertiesJson.typingMinAccuracy = typingMinimumAccuracy;

      // if allowed we can set the publisherModeId to the chosen activity mode,
      // Otherwise, set the default alternateModeId if it exists.
      if (modeOverrideAllowed) {
        additionalPropertiesJson.publisherModeId = activityModeId;
      } else if (alternateModeId) {
        additionalPropertiesJson.publisherModeId = alternateModeId;
      }
      if (allowLateSubmission) {
        additionalPropertiesJson.lateSubmitDate = assignmentManager.convertAssignmentDateToJavaString(assignmentManager.convertJSStringToJSDate(lateSubmitDate, lateSubmitTime));
      }
      const activityContentStr = JSON.stringify(activityContentArr);
      if (assignmentType === ASSIGNMENT_TYPE.CLASSROOM_USER) {
        if (isReassign) {
          result = await assignmentManager.reassignClassIndividualAssignment({
            activityId,
            classroomId,
            startDateTime: startRealDate,
            endDateTime: endRealDate,
            instruction,
            assignEntityTypeId,
            assignEntityIds,
            includeInReports,
            scoresReleased,
            studentReview,
            status: ASSIGNMENT_STATUS.LOCKED,
            maxScore: 1,
            additionalPropertiesJson,
            nickname: trimmedNickname,
          });
        } else {
          if (allowMultipleResources) {
            const parms = {
              activityContentArr,
              additionalPropertiesJson,
              classroomId,
              courseContentItemId,
              endRealDate,
              includeInReports,
              instruction,
              trimmedNickname,
              scoresReleased,
              startRealDate,
              studentReview,
              maxScore: null,
              status: null
            };
            result = await assignmentManager.multipleAssignmentCreateIterate(parms, ASSIGNMENT_TYPE.CLASSROOM_USER);
          } else {
            result = await assignmentManager.createClassIndividualAssignments({
              activityContentStr,
              additionalPropertiesJson,
              classroomId,
              courseContentItemId,
              endRealDate,
              includeInReports,
              instruction,
              nickname: trimmedNickname,
              scoresReleased,
              startRealDate,
              studentReview,
            });
          }
        }
      } else if (assignmentType === ASSIGNMENT_TYPE.GROUP) {
        if (isReassign) {
          result = await assignmentManager.reassignGroupAssignment({
            activityId,
            classroomId,
            groupIds,
            startDateTime: startRealDate,
            endDateTime: endRealDate,
            instruction,
            includeInReports,
            scoresReleased,
            studentReview,
            status: ASSIGNMENT_STATUS.LOCKED,
            maxScore: 1,
            additionalPropertiesJson,
            trimmedNickname,
          });
        } else {
          if (allowMultipleResources) {
            const parms = {
              activityContentArr,
              additionalPropertiesJson,
              classroomId,
              courseContentItemId,
              endRealDate,
              groupIds,
              includeInReports,
              instruction,
              trimmedNickname,
              scoresReleased,
              startRealDate,
              studentReview,
              maxScore: null,
              status: null
            };
            result = await assignmentManager.multipleAssignmentCreateIterate(parms, ASSIGNMENT_TYPE.GROUP);
          } else {
            result = await assignmentManager.createGroupIndividualAssignments({
              activityContentStr,
              additionalPropertiesJson,
              classroomId,
              courseContentItemId,
              endRealDate,
              groupIds,
              includeInReports,
              instruction,
              nickname: trimmedNickname,
              scoresReleased,
              startRealDate,
              studentReview,
            });
          }
        }
      } else {
        if (assignmentType === ASSIGNMENT_TYPE.CLASSROOM) {
          assignEntityTypeId = ASSIGNMENT_TYPE.CLASSROOM;
          assignEntityIds = classroomManager.currentClassroomId;
        } else if (assignmentType === ASSIGNMENT_TYPE.ALL_CLASSES) {
          // both CLASSROOM and ALL_CLASSES are just CLASSROOM on the server
          assignEntityTypeId = ASSIGNMENT_TYPE.CLASSROOM;
          assignEntityIds = currentClassrooms.join();
        } else {
          assignEntityTypeId = ASSIGNMENT_TYPE.USER;
          assignEntityIds = currentRoster.join();
        }
        if (isReassign) {
          result = await assignmentManager.reassignAssignment({
            activityId,
            classroomId,
            startDateTime: startRealDate,
            endDateTime: endRealDate,
            instruction,
            assignEntityTypeId,
            assignEntityIds,
            includeInReports,
            scoresReleased,
            studentReview,
            status: ASSIGNMENT_STATUS.LOCKED,
            maxScore: 1,
            additionalPropertiesJson,
            nickname: trimmedNickname,
          });
        } else {
          if (allowMultipleResources) {
            const parms = {
              activityContentArr,
              additionalPropertiesJson,
              assignEntityIds,
              assignEntityTypeId,
              classroomId,
              courseContentItemId,
              endRealDate,
              includeInReports,
              instruction,
              nickname: trimmedNickname,
              scoresReleased,
              startRealDate,
              studentReview,
              maxScore: null,
              status: null
            };
            result = await assignmentManager.multipleAssignmentCreateIterate(parms);
          } else {
            // I really want to consolidate this beast into managable, sensible functions.
            // This may be changing, leave it in the service for flow readibility until refactor.
            const idArray = assignEntityIds.split(',').map((value) => value.trim());
            if (ASSIGNMENT_TYPE.CLASSROOM && idArray.length > 1) {
              result = await assignmentManager.multipleAssignmentEntityIdsCreateIterate({
                activityContentStr,
                additionalPropertiesJson,
                assignEntityIds,
                assignEntityTypeId,
                classroomId,
                courseContentItemId,
                endRealDate,
                includeInReports,
                instruction,
                nickname: trimmedNickname,
                scoresReleased,
                startRealDate,
                studentReview,
              });
            } else {
              result = await assignmentManager.createAssignment({
                activityContentStr,
                additionalPropertiesJson,
                assignEntityIds,
                assignEntityTypeId,
                classroomId,
                courseContentItemId,
                endRealDate,
                includeInReports,
                instruction,
                nickname: trimmedNickname,
                scoresReleased,
                startRealDate,
                studentReview,
              });
            }
          }
        }
      }
      return result;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      } else {
        throw new TypeError(typeof error === 'string' ? error : props.t('submitAddAssignmentError'));
      }
    }
  }

  static addLTIAssignment = async (contentItemId, courseResourceElementId, classroomId, courseContentItemId, studentReview) => {
    const activityContent = [{ contentItemId, courseResourceElementId }];
    const activityContentStr = JSON.stringify(activityContent);

    const startDate = new Date(Date.now());
    const endDate = new Date(Date.now());
    endDate.setFullYear(endDate.getFullYear() + 1);

    try {
      const result = await assignmentManager.createClassIndividualAssignments({
        activityContentStr,
        additionalPropertiesJson: {
          allowLateSubmission: false,
          showCorrectAnswers: true,
          shuffle_items: true,
          ignoreItemOptionShuffle: false
        },
        classroomId,
        courseContentItemId,
        endRealDate: endDate.toISOString(),
        includeInReports: true,
        instruction: '',
        nickname: '',
        scoresReleased: true,
        startRealDate: startDate.toISOString(),
        studentReview,
      });
      return result;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      } else {
        throw new TypeError(typeof error === 'string' ? error : 'Failure creating assignment');
      }
    }
  }

  static updateAssignment = async (oldAssignment, changes, alsoUpdateCurrentAssignment = false) => {
    const assignmentId = 'id' in changes ? changes.id : oldAssignment.id;
    const startDateTime = 'timezoneStartAsDate' in changes ? changes.timezoneStartAsDate : oldAssignment.timezoneStartAsDate;
    const endDateTime = 'timezoneEndAsDate' in changes ? changes.timezoneEndAsDate : oldAssignment.timezoneEndAsDate;
    const instruction = 'instruction' in changes ? changes.instruction : oldAssignment.instruction;
    const status = 'status' in changes ? changes.status : oldAssignment.status;
    const maxScore = 'maxScore' in changes ? changes.maxScore : oldAssignment.maxScore;
    const includeInReports = 'includeInReports' in changes ? changes.includeInReports : oldAssignment.includeInReports;
    const studentReview = 'studentReview' in changes ? changes.studentReview : oldAssignment.studentReview;
    const scoresReleased = 'scoresReleased' in changes ? changes.scoresReleased : oldAssignment.scoresReleased;
    let additionalPropertiesJson = 'additionalPropertiesJson' in changes ? changes.additionalPropertiesJson : oldAssignment.additionalPropertiesJson;
    let nickname = 'nickname' in changes ? changes.nickname : oldAssignment.nickname;
    nickname = nickname ? nickname.trim() : '';

    const offAccommodationIdsStr = this.getOffAccommodationIdsStr();

    additionalPropertiesJson = additionalPropertiesJson || {};

    const { includeAssignmentAccommodations } = accommodationsManager;
    if (includeAssignmentAccommodations) {
      additionalPropertiesJson['accommodationIds'] = offAccommodationIdsStr;
    }

    const success = await assignmentManager.updateAssignments({
      assignmentId,
      startDateTime,
      endDateTime,
      instruction,
      status,
      maxScore,
      includeInReports,
      studentReview,
      scoresReleased,
      additionalPropertiesJson,
      nickname
    });
    if (success) {
      const updatedAssignment = {
        ...oldAssignment,
        assignmentId,
        startDateTime,
        endDateTime,
        instruction,
        status,
        maxScore,
        includeInReports,
        studentReview,
        scoresReleased,
        additionalPropertiesJson,
        nickname
      };
      assignmentManager.setAssignment(updatedAssignment);
      if (alsoUpdateCurrentAssignment) {
        gradebookManager.setCurrentAssignment(updatedAssignment);
      }
      return !!success;
    }
    return !!success;
  }

  static updateAllAssignments = async (bulkActivityIds, changes, alsoUpdateCurrentAssignment = false) => {
    const startDateTime = 'timezoneStartAsDate' in changes ? changes.timezoneStartAsDate : null;
    const endDateTime = 'timezoneEndAsDate' in changes ? changes.timezoneEndAsDate : null;
    const instruction = 'instruction' in changes ? changes.instruction : null;
    const status = 'status' in changes ? changes.status : null;
    const includeInReports = 'includeInReports' in changes ? changes.includeInReports : null;
    const studentReview = 'studentReview' in changes ? changes.studentReview : null;
    const scoresReleased = 'scoresReleased' in changes ? changes.scoresReleased : null;
    const nickname = changes.nickname ? changes.nickname.trim() : '';

    const offAccommodationIdsStr = this.getOffAccommodationIdsStr();

    changes.additionalPropertiesJson = changes.additionalPropertiesJson || {};

    const { includeAssignmentAccommodations } = accommodationsManager;
    if (includeAssignmentAccommodations) {
      changes.additionalPropertiesJson['accommodationIds'] = offAccommodationIdsStr;
    }

    const success = await assignmentManager.updateAllAssignments({
      activityIds: bulkActivityIds,
      changeAccommodationIds: changes.changeAccommodationTogglerSectionChecked,
      changeStatus: changes.changeStatusChecked,
      changeStartDate: changes.changeStartDateChecked,
      changeEndDate: changes.changeEndDateChecked,
      changeReports: changes.changeReportsChecked,
      changeInstruction: changes.changeInstructionChecked,
      changeMode: changes.changeModeChecked,
      changeRandomizeQuestions: changes.changeAdditionalSettingsChecked,
      changeLateSubmitDateChecked: changes.changeLateSubmitDateChecked,
      startDateTime,
      endDateTime,
      instruction,
      status,
      includeInReports,
      studentReview,
      scoresReleased,
      additionalPropertiesJson: changes.additionalPropertiesJson,
      nickname,
      offAccommodationIdsStr
    });
    if (success) {
      return !!success;
    }
    return !!success;
  }

  static refreshAssignmentData = async () => {
    const { isStudent } = userManager;
    const classroomId = classroomManager.currentClassroomId;
    const courseId = courseManager.currentCourseId;
    const elementId = courseManager.currentElementId;

    if (ReportIdentityService.isReportStandardsClassroomDetailStudents()) {
      courseManager.setForceShowCourseElementModalAllAssignmentsButton(elementId);
    }
    await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);

    const forceFetchIfAlreadyCached = true;
    await courseManager.fetchCourseData(courseId, classroomId, elementId, forceFetchIfAlreadyCached, isStudent);
  }

  static getAssignmentTitleAndDescriptionParserWrapperObjs = (assignment, useDescription = false) => {
    let resourceWebTitleObj = {
      original: '', stripped: '', parsed: '', parsedMathOnly: ''
    };
    if (assignment.resourceWebTitle) {
      resourceWebTitleObj = UtilityService.reactHtmlParserWrapper(
        assignment.resourceWebTitle
      );
    } else {
      resourceWebTitleObj = UtilityService.reactHtmlParserWrapper(
        assignment.name
      );
    }
    let resourceWebSubtitleObj = {
      original: '', stripped: '', parsed: '', parsedMathOnly: ''
    };
    if (assignment.resourceWebSubtitle) {
      resourceWebSubtitleObj = UtilityService.reactHtmlParserWrapper(
        assignment.resourceWebSubtitle
      );
    } else if (useDescription && assignment.description) {
      resourceWebSubtitleObj = UtilityService.reactHtmlParserWrapper(
        assignment.description
      );
    }
    return { resourceWebTitleObj, resourceWebSubtitleObj };
  }

  static getAssignmentTitleAndDescription = (assignment, allowAlternateSubTitles = true) => {
    let resourceWebTitle = '';
    let resourceWebSubtitle = '';
    let nickname = '';
    if (assignment) {
      if (assignment.resourceWebTitle) {
        resourceWebTitle = assignment.resourceWebTitle;
      } else if (assignment.name) {
        resourceWebTitle = assignment.name;
      }

      if (assignment.resourceWebSubtitle) {
        resourceWebSubtitle = assignment.resourceWebSubtitle;
      } else if (assignment.description && allowAlternateSubTitles) {
        resourceWebSubtitle = assignment.description;
      }

      if (assignment.nickname) {
        nickname = assignment.nickname;
      }
    }
    return { nickname, resourceWebSubtitle, resourceWebTitle };
  }

  static getAggregateAssignmentTitleAndDescription = (data, allowAlternateSubTitles = true) => {
    let courseResourceElementTitle = '';
    let courseResourceElementSubtitle = '';
    if (data) {
      if (data.courseResourceElementTitle) {
        courseResourceElementTitle = data.courseResourceElementTitle;
      } else if (data.resourceContentItemName) {
        courseResourceElementTitle = data.resourceContentItemName;
      }

      if (data.courseResourceElementSubtitle) {
        courseResourceElementSubtitle = data.courseResourceElementSubtitle;
      } else if (data.resourceContentItemDescription && allowAlternateSubTitles) {
        courseResourceElementSubtitle = data.resourceContentItemDescription;
      }
    }
    return { courseResourceElementSubtitle, courseResourceElementTitle };
  }

  static showAssignmentInfoIconButton = (assignment = null, dialogIncludesAssignmentInfo = false) => {
    assignment = assignment || assignmentManager.currentAssignment;
    const { isEmptyHTML } = UtilityService;
    if (!assignment) {
      return false;
    } else if (assignment.contentItemDescription) {
      return true;
    } else if (assignment.resourceInfo) {
      return true;
    } else if (assignment.instructionOverride && !isEmptyHTML(assignment.instructionOverride)) {
      // do not show assignment instruction but show cre.instruction if set as "instructionOverride"
      return true;
    } else if (dialogIncludesAssignmentInfo && classroomManager.hasCoteachers(assignment.classroomId)) {
      // only show icon when co-teacher is present if the dialog supports assignment info.
      return true;
    }
    return false;
  }

  static showAggregateAssignmentInfoIconButton = (cardData = null) => {
    const { isEmptyHTML } = UtilityService;
    if (!cardData) {
      return false;
    } else if (cardData.resourceContentItemDescription) {
      return true;
    } else if (cardData.courseResourceElementResourceInformation) {
      return true;
    } else if (cardData.courseResourceElementResourceInstruction && !isEmptyHTML(cardData.courseResourceElementResourceInstruction)) {
      return true;
    }
    return false;
  }

  static getOffAccommodationIdsStr = () => {
    const { includeAssignmentAccommodations, togglerSectionOffAccommodations } = accommodationsManager;
    if (includeAssignmentAccommodations && togglerSectionOffAccommodations?.length) {
      return togglerSectionOffAccommodations.filter((id) => !!id).toString();
    } else {
      return '';
    }
  }

  static getApplicableErrorMessagesForAssignment = (errors) => {
    const ERROR_MESSAGES = {
      startTimeInputErrored: 'Start time format is not valid (must be hh:mm aa). ',
      endTimeInputErrored: 'End time format is not valid (must be hh:mm aa). ',
      startDateSetErrored: 'Start Date must be set. ',
      endDateSetErrored: 'Due Date must be set. ',
      startDateAfterEndDateErrored: 'The Due Date must be after the Start Date. ',

      lateSubmitTimeInputErrored: 'Late submission time format is not valid (must be hh:mm aa). ',
      lateSubmitDateSetErrored: 'Late submission Date must be set. ',
      startDateAfterLastSubmitDateErrored: 'The late submission Date must be after the Start Date. ',
      lateSubmitDateAfterEndDateErrored: 'The late submission Date must be after the End Date. ',
      lateSubmitAfterClassroomEndErrored: 'The late submission Date must be before classroom end date. ',

      readyAfterEndErrored: 'Changing the status to Not Started requires a Due Date in the future. ',
      startedAfterEndErrored: 'Changing the status to Started requires a Due Date in the future. ',
      lockedAfterStartErrored: 'Changing the status to Locked requires a Start Date in the future. ',
      unlockedBeforeStartErrored: 'Status must be set to Locked when the Start Date is in the future. ',
      unlockedBeforeClassroomStartErrored: 'Changing the status from Locked requires the Start Date to be on or after the Start Date of the Class. ',
      startBeforeClassroomStartErrored: 'Start date must be after classroom start date. ',
      endAfterClassroomEndErrored: 'End date must be before classroom end date. ',
      noSelectionStatus: 'A status must be selected. ',
      noSelectionSection: 'A section must be selected. ',

      timeframeStartError: 'Start date should be after timeframe start date. ',
      timeframeEndError: 'End date should be before timeframe end date. '
    };
    let errorMsgString = '';
    for (const [error_key, value] of Object.entries(errors)) {
      if (value) errorMsgString += ERROR_MESSAGES[error_key];
    }
    return errorMsgString;
  }

  static validateEditAssignmentModal = (assignment, classroom = null) => {
    const errors = {
      startTimeInputErrored: false,
      endTimeInputErrored: false,

      startDateSetErrored: false,
      endDateSetErrored: false,
      startDateAfterEndDateErrored: false,
      readyAfterEndErrored: false,
      startedAfterEndErrored: false,
      lockedAfterStartErrored: false,
      unlockedBeforeStartErrored: false,

      unlockedBeforeClassroomStartErrored: false,
      startBeforeClassroomStartErrored: false,
      endAfterClassroomEndErrored: false,

      lateSubmitDateErrored: false,
      lateSubmitTimeErrored: false,
      lateSubmitDateAfterEndDateErrored: false,
      startDateAfterLastSubmitDateErrored: false,
      lateSubmitAfterClassroomEndErrored: false
    };

    if (!assignment) {
      return { hasError: true, errors };
    }

    const { timezoneStartDate, timezoneStartTime, timezoneEndDate, timezoneEndTime } = assignment;
    let startRealDate = null;
    let endRealDate = null;

    if (timezoneEndDate && timezoneStartDate) {
      errors.startTimeInputErrored = !assignmentManager.isValidTimeInput(timezoneStartTime);
      errors.endTimeInputErrored = !assignmentManager.isValidTimeInput(timezoneEndTime);

      startRealDate = assignmentManager.convertJSStringToJSDate(timezoneStartDate, timezoneStartTime);
      endRealDate = assignmentManager.convertJSStringToJSDate(timezoneEndDate, timezoneEndTime);

      errors.startDateAfterEndDateErrored = endRealDate < startRealDate;
      errors.readyAfterEndErrored = assignment.status === ASSIGNMENT_STATUS.READY && endRealDate < new Date();
      errors.startedAfterEndErrored = assignment.status === ASSIGNMENT_STATUS.STARTED && endRealDate < new Date();
      errors.lockedAfterStartErrored = assignment.status === ASSIGNMENT_STATUS.LOCKED && startRealDate < new Date();
      errors.unlockedBeforeStartErrored = assignment.status !== ASSIGNMENT_STATUS.LOCKED && startRealDate > new Date();

      const classroomStartRealDate = assignmentManager.convertJSStringToJSDate(assignment.classroomTimezoneStartDate, assignment.classroomStartTime);
      const classroomEndRealDate = assignmentManager.convertJSStringToJSDate(assignment.classroomTimezoneEndDate, assignment.classroomStartTime || '11:59:59 PM');

      errors.unlockedBeforeClassroomStartErrored = assignment.status !== ASSIGNMENT_STATUS.LOCKED && classroomStartRealDate > new Date();
      errors.startBeforeClassroomStartErrored = startRealDate < classroomStartRealDate;
      errors.endAfterClassroomEndErrored = endRealDate > classroomEndRealDate;
    }
    errors.startDateSetErrored = !timezoneStartDate;
    errors.endDateSetErrored = !timezoneEndDate;

    // late submit date validation
    const { allowLateSubmission, lateSubmitDate, lateSubmitTime } = assignment;
    const validLateSubmissionAssignmentType = (assignment.assignEntityTypeId !== ASSIGNMENT_TYPE.CLASSROOM &&
      assignment.assignEntityTypeId !== ASSIGNMENT_TYPE.ALL_CLASSES);
    if (allowLateSubmission && validLateSubmissionAssignmentType) {
      errors.lateSubmitTimeErrored = !assignmentManager.isValidTimeInput(lateSubmitTime);

      if (startRealDate && endRealDate) {
        const lateSubmitRealDate = assignmentManager.convertJSStringToJSDate(lateSubmitDate, lateSubmitTime);
        errors.startDateAfterLastSubmitDateErrored = lateSubmitRealDate < startRealDate;
        errors.lateSubmitDateAfterEndDateErrored = lateSubmitRealDate < endRealDate;
        if (!classroom) {
          classroom = classroomManager.getClassroom(assignment.classroomId);
          if (!classroom) {
            classroom = classroomManager.fetchClassroom(assignment.classroomId);
          }
        }
        const classroomEndRealDate = assignmentManager.convertJSStringToJSDate(assignment.classroomTimezoneEndDate, assignment.classroomEndTime || '11:59:59 PM');
        errors.lateSubmitAfterClassroomEndErrored = lateSubmitRealDate > classroomEndRealDate;
      }
    }

    // check timeframe
    if (assignment.timeframeStartDate && startRealDate !== null) {
      const realTimeFrameStartDate = new Date(assignment.timeframeStartDate);
      if (realTimeFrameStartDate > startRealDate) {
        errors.timeframeStartError = true
      }
    }

    if (assignment.timeframeEndDate && endRealDate !== null) {
      const realTimeFrameEndDate = new Date(assignment.timeframeEndDate);
      if (realTimeFrameEndDate < endRealDate) {
        errors.timeframeEndError = true
      }
    }

    const hasError = Object.values(errors).some((elem) => elem);
    return { hasError, errors };
  }

  static validateEditBulkAssignmentModal = (assignment, sectionVal, checkVal) => {
    let hasError = false;
    const errors = {
      startTimeInputErrored: false,
      endTimeInputErrored: false,

      startDateSetErrored: false,
      endDateSetErrored: false,
      startDateAfterEndDateErrored: false,
      readyAfterEndErrored: false,
      startedAfterEndErrored: false,
      lockedAfterStartErrored: false,
      unlockedBeforeStartErrored: false,

      unlockedBeforeClassroomStartErrored: false,
      startBeforeClassroomStartErrored: false,
      endAfterClassroomEndErrored: false,

      noSelectionStatus: false,
      noSelectionSection: false,

      lateSubmitDateErrored: false,
      lateSubmitTimeErrored: false,
      lateSubmitDateAfterEndDateErrored: false,
      startDateAfterLastSubmitDateErrored: false,
      lateSubmitAfterClassroomEndErrored: false
    };

    if (!assignment || !sectionVal) {
      hasError = true;
      return { hasError, errors };
    }

    if (sectionVal === 'activity-details-section') {
      if (!assignment.nickname && // if there is a nickname entered and everything else is off, still allow save.
        !checkVal.changeAccommodationTogglerSectionChecked &&
        !checkVal.changeEndDateChecked &&
        !checkVal.changeInstructionChecked &&
        !checkVal.changeLateSubmitDateChecked &&
        !checkVal.changeModeChecked &&
        !checkVal.changeAdditionalSettingsChecked &&
        !checkVal.changeReportsChecked &&
        !checkVal.changeStartDateChecked &&
        !checkVal.changeStatusChecked
      ) {
        hasError = true;
        // errors.noSelectionSection = true;
        return { hasError, errors };
      }

      const { timezoneStartDate, timezoneStartTime, timezoneEndDate, timezoneEndTime } = assignment;

      if ((checkVal.changeStartDateChecked || checkVal.changeEndDateChecked)) {
        let startRealDate = null;
        let endRealDate = null;

        // start checked end not checked
        if (checkVal.changeStartDateChecked && !checkVal.changeEndDateChecked) {
          errors.startDateSetErrored = !timezoneStartDate;
          errors.startTimeInputErrored = !assignmentManager.isValidTimeInput(timezoneStartTime);
          startRealDate = assignmentManager.convertJSStringToJSDate(timezoneStartDate, timezoneStartTime);
          endRealDate = gradebookManager.latestEndDate;

          // check timeframe
          if (assignment.timeframeStartDate && startRealDate !== null) {
            const realTimeFrameStartDate = new Date(assignment.timeframeStartDate);
            if (realTimeFrameStartDate > startRealDate) {
              errors.timeframeStartError = true
            }
          }
        }

        // end checked start not checked
        if (!checkVal.changeStartDateChecked && checkVal.changeEndDateChecked) {
          errors.endDateSetErrored = !timezoneEndDate;
          errors.endTimeInputErrored = !assignmentManager.isValidTimeInput(timezoneEndTime);
          endRealDate = assignmentManager.convertJSStringToJSDate(timezoneEndDate, timezoneEndTime);
          startRealDate = gradebookManager.earliestStartDate;

          // check timeframe
          if (assignment.timeframeEndDate && endRealDate !== null) {
            const realTimeFrameEndDate = new Date(assignment.timeframeEndDate);
            if (realTimeFrameEndDate < endRealDate) {
              errors.timeframeEndError = true
            }
          }
        }

        // start and end checked
        if (timezoneEndDate && timezoneStartDate && (checkVal.changeStartDateChecked && checkVal.changeEndDateChecked)) {
          errors.startDateSetErrored = !timezoneStartDate;
          errors.endDateSetErrored = !timezoneEndDate;
          errors.startTimeInputErrored = !assignmentManager.isValidTimeInput(timezoneStartTime);
          errors.endTimeInputErrored = !assignmentManager.isValidTimeInput(timezoneEndTime);

          startRealDate = assignmentManager.convertJSStringToJSDate(timezoneStartDate, timezoneStartTime);
          endRealDate = assignmentManager.convertJSStringToJSDate(timezoneEndDate, timezoneEndTime);

          // check timeframe
          if (assignment.timeframeStartDate && startRealDate !== null) {
            const realTimeFrameStartDate = new Date(assignment.timeframeStartDate);
            if (realTimeFrameStartDate > startRealDate) {
              errors.timeframeStartError = true
            }
          }

          if (assignment.timeframeEndDate && endRealDate !== null) {
            const realTimeFrameEndDate = new Date(assignment.timeframeEndDate);
            if (realTimeFrameEndDate < endRealDate) {
              errors.timeframeEndError = true
            }
          }
        }

        const classroomStartRealDate = assignmentManager.convertJSStringToJSDate(assignment.classroomTimezoneStartDate, assignment.classroomStartTime);
        const classroomEndRealDate = assignmentManager.convertJSStringToJSDate(assignment.classroomTimezoneEndDate, assignment.classroomEndTime || '11:59:59 PM');

        errors.startDateAfterEndDateErrored = endRealDate < startRealDate;
        errors.startBeforeClassroomStartErrored = startRealDate < classroomStartRealDate;
        errors.endAfterClassroomEndErrored = endRealDate > classroomEndRealDate;
        console.log(`startBeforeClassroomStartErrored: ${errors.startBeforeClassroomStartErrored} classroomStartRealDate: ${classroomStartRealDate}`);
        console.log(`endAfterClassroomEndErrored: ${errors.endAfterClassroomEndErrored}  classroomEndRealDate: ${classroomEndRealDate}`);
        if (checkVal.changeStatusChecked) {
          errors.noSelectionStatus = (assignment.status === '' || assignment.status === null || assignment.status === undefined);
          errors.readyAfterEndErrored = assignment.status === ASSIGNMENT_STATUS.READY && endRealDate < new Date();
          errors.startedAfterEndErrored = assignment.status === ASSIGNMENT_STATUS.STARTED && endRealDate < new Date();
          errors.lockedAfterStartErrored = assignment.status === ASSIGNMENT_STATUS.LOCKED && startRealDate < new Date();
          errors.unlockedBeforeStartErrored = assignment.status !== ASSIGNMENT_STATUS.LOCKED && startRealDate > new Date();
          errors.unlockedBeforeClassroomStartErrored = assignment.status !== ASSIGNMENT_STATUS.LOCKED && classroomStartRealDate > new Date();
        }
      }

      if (checkVal.changeLateSubmitDateChecked) {
        let startRealDate = null;
        let endRealDate = null;
        if (timezoneStartDate && timezoneEndDate) {
          startRealDate = assignmentManager.convertJSStringToJSDate(timezoneStartDate, timezoneStartTime);
          endRealDate = assignmentManager.convertJSStringToJSDate(timezoneEndDate, timezoneEndTime);
          const lateSubmitRealDate = assignmentManager.convertJSStringToJSDate(assignment.lateSubmitDate, assignment.lateSubmitTime);
          errors.startDateAfterLastSubmitDateErrored = lateSubmitRealDate < startRealDate;
          errors.lateSubmitDateAfterEndDateErrored = lateSubmitRealDate < endRealDate;

          const classroomEndRealDate = assignmentManager.convertJSStringToJSDate(assignment.classroomTimezoneEndDate, assignment.classroomEndTime || '11:59:59 PM');
          errors.lateSubmitAfterClassroomEndErrored = lateSubmitRealDate > classroomEndRealDate;
        }
      }

      if (checkVal.changeStatusChecked) {
        errors.noSelectionStatus = (assignment.status === '' || assignment.status === null || assignment.status === undefined);
      }
    }

    hasError = Object.values(errors).some((elem) => elem);
    return { hasError, errors };
  }

  static setBoundaryStartEndDates() {
    let earliestStartDate = null;
    let earliestTimezoneStartDate = null;
    let earliestTimezoneStartTime = null;
    let latestEndDate = null;
    let latestTimezoneEndDate = null;
    let latestTimezoneEndTime = null;

    for (const id of gradebookManager.bulkActivityIds) {
      const activity = assignmentManager.getAssignment(id);

      if (activity) {
        const startDate = assignmentManager.convertJSStringToJSDate(activity.timezoneStartDate, activity.timezoneStartTime);
        if (earliestStartDate === null || startDate < earliestStartDate) {
          earliestStartDate = startDate;
          earliestTimezoneStartDate = activity.timezoneStartDate;
          earliestTimezoneStartTime = activity.timezoneStartTime;
        }

        const endDate = assignmentManager.convertJSStringToJSDate(activity.timezoneEndDate, activity.timezoneEndime);
        if (latestEndDate === null || startDate < latestEndDate) {
          latestEndDate = endDate;
          latestTimezoneEndDate = activity.timezoneEndDate;
          latestTimezoneEndTime = activity.timezoneEndTime;
        }
      }

      gradebookManager.setBoundaryStartEndDates(earliestStartDate, earliestTimezoneStartDate, earliestTimezoneStartTime, latestEndDate, latestTimezoneEndDate, latestTimezoneEndTime);
    }
  }
}
