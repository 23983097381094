import animals1_avatar from '../../img/avatars/student/85px-animals-01.png';
import animals2_avatar from '../../img/avatars/student/85px-animals-02.png';
import animals3_avatar from '../../img/avatars/student/85px-animals-03.png';
import animals4_avatar from '../../img/avatars/student/85px-animals-04.png';
import animals5_avatar from '../../img/avatars/student/85px-animals-05.png';

import color1_avatar from '../../img/avatars/student/85px-color-01.png';
import color2_avatar from '../../img/avatars/student/85px-color-02.png';
import color3_avatar from '../../img/avatars/student/85px-color-03.png';
import color4_avatar from '../../img/avatars/student/85px-color-04.png';
import color5_avatar from '../../img/avatars/student/85px-color-05.png';

import nature1_avatar from '../../img/avatars/student/85px-nature-01.png';
import nature2_avatar from '../../img/avatars/student/85px-nature-02.png';
import nature3_avatar from '../../img/avatars/student/85px-nature-03.png';
import nature4_avatar from '../../img/avatars/student/85px-nature-04.png';
import nature5_avatar from '../../img/avatars/student/85px-nature-05.png';

import patterns1_avatar from '../../img/avatars/student/85px-patterns-01.png';
import patterns2_avatar from '../../img/avatars/student/85px-patterns-02.png';
import patterns3_avatar from '../../img/avatars/student/85px-patterns-03.png';
import patterns4_avatar from '../../img/avatars/student/85px-patterns-04.png';
import patterns5_avatar from '../../img/avatars/student/85px-patterns-05.png';

import sports1_avatar from '../../img/avatars/student/85px-sports-01.png';
import sports2_avatar from '../../img/avatars/student/85px-sports-02.png';
import sports3_avatar from '../../img/avatars/student/85px-sports-03.png';
import sports4_avatar from '../../img/avatars/student/85px-sports-04.png';
import sports5_avatar from '../../img/avatars/student/85px-sports-05.png';

export const AVATAR_PICKER_DEFAULT_STUDENT_LIST = [
  // animals
  {
    name: 'animals-01',
    src: animals1_avatar
  },
  {
    name: 'animals-02',
    src: animals2_avatar
  },
  {
    name: 'animals-03',
    src: animals3_avatar
  },
  {
    name: 'animals-04',
    src: animals4_avatar
  },
  {
    name: 'animals-05',
    src: animals5_avatar
  },
  // color
  {
    name: 'color-01',
    src: color1_avatar
  },
  {
    name: 'color-02',
    src: color2_avatar
  },
  {
    name: 'color-03',
    src: color3_avatar
  },
  {
    name: 'color-04',
    src: color4_avatar
  },
  {
    name: 'color-05',
    src: color5_avatar
  },
  // nature
  {
    name: 'nature-01',
    src: nature1_avatar
  },
  {
    name: 'nature-02',
    src: nature2_avatar
  },
  {
    name: 'nature-03',
    src: nature3_avatar
  },
  {
    name: 'nature-04',
    src: nature4_avatar
  },
  {
    name: 'nature-05',
    src: nature5_avatar
  },
  // patterns
  {
    name: 'patterns-01',
    src: patterns1_avatar
  },
  {
    name: 'patterns-02',
    src: patterns2_avatar
  },
  {
    name: 'patterns-03',
    src: patterns3_avatar
  },
  {
    name: 'patterns-04',
    src: patterns4_avatar
  },
  {
    name: 'patterns-05',
    src: patterns5_avatar
  },
  // sports
  {
    name: 'sports-01',
    src: sports1_avatar
  },
  {
    name: 'sports-02',
    src: sports2_avatar
  },
  {
    name: 'sports-03',
    src: sports3_avatar
  },
  {
    name: 'sports-04',
    src: sports4_avatar
  },
  {
    name: 'sports-05',
    src: sports5_avatar
  }
];
