import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { MainView, SatCoreRegister, VIEW_SELECTION, register } from 'sat-core';

const t = register('MainView');

export default
@inject('contentManager', 'userManager')
@observer
class MainViewOverride extends Component {
  showResources = async (_props) => {
    const { contentManager, userManager } = this.props;
    const { resourceIFrameUrl, resourceIFrameUrlStudent } = contentManager;
    const { isStudent } = userManager;
    const resourceUrl = isStudent ? resourceIFrameUrlStudent : resourceIFrameUrl;
    if (resourceUrl !== null) {
      window.open(resourceUrl, '_blank');
    }
  }

  getNavTabs = (props, parent) => {
    const { contentManager, userManager } = this.props;
    const { resourceIFrameUrl, resourceIFrameUrlStudent } = contentManager;
    const { isDistrictAdmin, isProductAdmin, isSchoolAdmin, isStudent, isTeacher } = userManager;
    const resourceUrl = isStudent ? resourceIFrameUrlStudent : resourceIFrameUrl;

    const help = resourceUrl ? parent.renderMenuItem(
      <>
        {isStudent ? t('helpStudent') : t('helpTeacher')}
      </>,
      VIEW_SELECTION.RESOURCES,
      { className: 'resources', onClick: () => this.showResources(props) }
    ) : null;

    if (isDistrictAdmin || isSchoolAdmin || isProductAdmin) {
      return help;
    } else if (isTeacher || isStudent) {
      return (
        <>
          {parent.getBaseNavTabs()}
          {help}
        </>
      );
    }
  }

  render() {
    return <MainView {...this.props} getNavTabs={this.getNavTabs} />;
  }
}

SatCoreRegister('MainView', MainViewOverride);
