import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button } from 'semantic-ui-react';
import Modal from '../Modal';
// TODO create '../../css/DeleteAssignmentModal.less';
import '../../css/EditAssignmentModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import UtilityService from '../../services/UtilityService';

@inject('navigationManager')
@observer
class DeleteAssignmentModal extends Component {
  constructor(props) {
    super(props);
    this.ModalBanner = SatCoreComponent('ModalBanner');

    this.state = {
      isSubmitting: false,
      open: false
    };
  }

  open = () => this.setState({ open: true });

  close = () => this.setState({ open: false });

  confirm = () => {
    const { deleteFunction, assignment } = this.props;
    deleteFunction(assignment);
    this.close();
  }

  render() {
    const { assignment, t } = this.props;
    const { open } = this.state;
    const { ModalBanner } = this;

    const assignmentTitle = assignment.resourceWebTitle ? assignment.resourceWebTitle : assignment.name;
    const assignmentTitleObj = UtilityService.reactHtmlParserWrapper(assignmentTitle);
    return (
      <Modal
        onClose={this.close}
        onOpen={this.open}
        open={open}
        size='tiny'
        trigger={<Button basic>{t('delete')}</Button>}>
        <ModalBanner
          label={t('deleteAssignmentLabel')}
          onClose={this.close}
          title={assignmentTitleObj.stripped} />
        <Modal.Content>
          <p>{t('deleteAssignmentDescription')}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic className='keepButton' content={t('assignmentKeep')} onClick={this.close} primary />
          <Button className='deleteButton' content={t('assignmentDelete')} onClick={this.confirm} primary />
        </Modal.Actions>
      </Modal>
    );
  }
}
export default DeleteAssignmentModal;

SatCoreRegister('DeleteAssignmentModal', DeleteAssignmentModal);
