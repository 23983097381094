/* eslint-disable no-return-assign, react/no-unused-state */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Container, Icon, Image, Segment
} from 'semantic-ui-react';

import iconCourse from '../img/icon-course.svg';
import iconPieBlack from '../img/icon-pie-black.svg';
import iconRoster from '../img/icon-roster.svg';
import iconUser from '../img/icon-user.svg';

import '../css/AdminSchoolCard.less';

import imageBlueBackground from '../img/image-blue-background.png';

import { SatCoreRegister } from '../SatCoreRegistry';

import { REPORT_FACULTY_TYPE } from '../services/reports/ReportConstants';

import { DIALOG_NAMES } from '../managers/DialogManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';

@inject('dialogManager', 'navigationManager', 'schoolManager', 'userManager')
@observer
class AdminSchoolCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullImageUrl: '',
      directSchoolImageUrl: ''
    };
  }

  async componentDidMount() {
    const { school } = this.props;
    if (school.imageContentItemId) {
      await this.fetchDirectSchoolImageUrl(school);
    }
  }

  fetchDirectSchoolImageUrl = async (school) => {
    const { schoolManager, userManager } = this.props;
    if (school.imageContentItemId) {
      schoolManager.fetchDirectSchoolImageUrl(
        school, userManager.authKey)
        .then(({ fullImageUrl, directSchoolImageUrl }) => {
          this.setState({
            directSchoolImageUrl: directSchoolImageUrl ? `${directSchoolImageUrl}` : null,
            fullImageUrl: fullImageUrl ? `${fullImageUrl}` : null,
          });
        });
    }
  }

  handleClickImagePicker = () => {
    this.toggleSchoolImageEditor();
  }

  toggleSchoolImageEditor = (fullImageUrl = null, directSchoolImageUrl = null) => {
    const { dialogManager, school } = this.props;
    if (dialogManager.openedDialogs.has(DIALOG_NAMES.SCHOOL_IMAGE)) {
      dialogManager.closeDialog(DIALOG_NAMES.SCHOOL_IMAGE);
      this.setState({
        directSchoolImageUrl: directSchoolImageUrl ? `${directSchoolImageUrl}` : null,
        fullImageUrl: fullImageUrl ? `${fullImageUrl}` : null,
      });
    } else {
      dialogManager.setOpenDialog(DIALOG_NAMES.SCHOOL_IMAGE, {
        school
      }, this.toggleSchoolImageEditor);
    }
  }

  handleClickUsers = async (_event, _data) => {
    const { history, navigationManager, school } = this.props;
    const { id, name, parentId } = school;
    navigationManager.setView(VIEW_SELECTION.USERS);
    const routerUrl = `/users?view=${VIEW_SELECTION.USERS}&institutionId=${id}&districtId=${parentId}&institutionName=${name}`;
    await history.push(routerUrl);
  }

  handleClickProducts = async (_event, _data) => {
    const { history, navigationManager, school, userManager: { canViewSatelliteContent } } = this.props;
    const { id, name, parentId } = school;
    let routerUrl = '';
    if (canViewSatelliteContent) {
      navigationManager.setView(VIEW_SELECTION.CONTENT);
      routerUrl = `/content?view=${VIEW_SELECTION.CONTENT}`;
    } else {
      navigationManager.setView(VIEW_SELECTION.PRODUCTS);
      routerUrl = `/products?view=${VIEW_SELECTION.PRODUCTS}&institutionId=${id}&districtId=${parentId}&institutionName=${name}`;
    }
    await history.push(routerUrl);
  }

  handleClickClassrooms = async (_event, _data) => {
    const { history, navigationManager, school } = this.props;
    const { id, name, parentId } = school;
    navigationManager.setView(VIEW_SELECTION.CLASSROOMS);
    const routerUrl = `/classrooms?view=${VIEW_SELECTION.CLASSROOMS}&institutionId=${id}&districtId=${parentId}&institutionName=${name}`;
    await history.push(routerUrl);
  }

  handleClickReports = async (_event, _data) => {
    const { history, navigationManager, school } = this.props;
    const { id, name, parentId } = school;
    navigationManager.setView(VIEW_SELECTION.REPORTS);

    let routerUrl = `/reports?view=${VIEW_SELECTION.REPORTS}`;
    routerUrl += `&institutionId=${id}`;
    routerUrl += `&districtId=${parentId}`;
    routerUrl += `&institutionName=${name}`;
    routerUrl += `&reportFacultyType=${REPORT_FACULTY_TYPE.SCHOOL}`;
    await history.push(routerUrl);
  }

  renderOption(name, prefix, src, onClick) {
    return (
      <Button onClick={onClick}>
        <div className={`admin-school-card-option-${prefix}-wrapper`}>
          <div className={`admin-school-card-option-${prefix}-icon`}>
            <Image alt='' src={src} />
          </div>
          <div className={`admin-school-card-option-${prefix}-label`}>
            {name}
          </div>
        </div>
      </Button>
    );
  }

  render() {
    const { school, 
            userManager: { isProductAdmin, canViewSatelliteContent, canViewSatelliteUsers, canViewSatelliteClasses, canViewSatelliteReports }, 
            t } = this.props;
    const { directSchoolImageUrl } = this.state;
    return (
      <Container className='admin-school-card-container'>
        <Segment key='tempkey' className='admin-school-card-segment-wrapper'>
          <div className='admin-school-card'>
            <div className={`admin-school-card-image
              ${!directSchoolImageUrl ? 'default-image' : ''}`}>
              {directSchoolImageUrl ? (
                <Image
                  onError={(event) => event.target.src = imageBlueBackground}
                  src={directSchoolImageUrl} />
              ) :
                null}
            </div>
            <div className='admin-school-card-image-icon'>
              <Icon
                name='ellipsis horizontal'
                onClick={() => this.handleClickImagePicker()} />
            </div>
            <div className='admin-school-card-title'>
              <span className='admin-school-card-title-text'>
                {school.name}
              </span>
            </div>
            <div className='admin-school-card-options'>
              {(!isProductAdmin && canViewSatelliteUsers) && this.renderOption(t('usersLabel', 'Users'), 'users', iconUser, this.handleClickUsers)}
              {canViewSatelliteContent && this.renderOption(t('productsLabel', 'Products'), 'products', iconCourse, this.handleClickProducts)}
              {(!isProductAdmin && canViewSatelliteClasses) && this.renderOption(t('classesLabel', 'Classes'), 'classes', iconRoster, this.handleClickClassrooms)}
              {(!isProductAdmin && canViewSatelliteReports) && this.renderOption(t('reportsLabel', 'Reports'), 'reports', iconPieBlack, this.handleClickReports)}
            </div>
          </div>
        </Segment>
      </Container>
    );
  }
}
export default AdminSchoolCard;

SatCoreRegister('AdminSchoolCard', AdminSchoolCard);
