import { toJS } from 'mobx';

import { registerClass } from '../../SatCoreRegistry';

import { GRADEBOOK_TABLE_ROWS_MAX_PAGE_SIZE } from '../../constants';

import assignmentManager, { ASSIGNMENT_STATUS } from '../../managers/AssignmentManager';
import gradebookManager from '../../managers/GradebookManager';

import UtilityService from '../UtilityService';

export default class GradebookSummaryService {
  static isSpecialAggregateGradebookSummarySortCase = (clickedColumn) => {
    return clickedColumn === 'status' || clickedColumn === 'studentStatus' || clickedColumn === 'grade';
  }

  /**
   * Intended to be used as a special case to sort a given
   * `clickedColumn` in the aggregate gradebook **summary** table.
   *
   * Uses backend to fetch the dataset, but the data is ultimately sorted on the frontend
   */
  static handleSpecialAggregateGradebookSummarySortCase = async (
    clickedColumn
  ) => {
    const pageSize = GRADEBOOK_TABLE_ROWS_MAX_PAGE_SIZE;

    const rowsCurrentLength = gradebookManager.assignmentInstances.length;
    const rowsTotalLength = gradebookManager.gradebookTableRowsTotalFromBackend || 0;

    if (!rowsTotalLength || rowsCurrentLength < rowsTotalLength) {
      // we do not have the entire dataset stored, so we need to fetch it
      await this.handleGradebookSummarySortOnBackend(clickedColumn, pageSize);
    }
    await this.handleGradebookSummarySortOnFrontend(clickedColumn);
  }

  static handleGradebookSummarySortOnBackend = async (clickedColumn, pageSize, {
    gradebookContext = ''
  } = {}) => {
    pageSize = pageSize >= 0 ? pageSize : gradebookManager.GRADEBOOK_TABLE_ROWS_PAGE_SIZE;

    gradebookContext = gradebookContext || gradebookManager.activeGradebookTable;
    const sortObj = gradebookManager.gradebookTableSortMap.get(gradebookContext);

    const sortColumn = sortObj.sortColumn || null;
    const sortDirection = sortObj.sortDirection || 'ascending';

    let newDirection;
    if (!clickedColumn) {
      clickedColumn = sortColumn;
      newDirection = sortDirection;
    } else {
      if (sortColumn !== clickedColumn || sortDirection === 'descending') {
        newDirection = 'ascending';
      } else {
        newDirection = 'descending';
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const assignmentId = urlParams.get('assignmentId');
    const assignment = await assignmentManager.fetchActivity(assignmentId);

    const page = 0, clearFirst = true;
    const functions = {
      isSpecialAggregateGradebookSummarySortCase: this.isSpecialAggregateGradebookSummarySortCase
    };
    await gradebookManager.fetchAggregateGradebookData(
      assignment, clickedColumn, newDirection, page,
      pageSize, clearFirst, functions
    );
  }

  static handleGradebookSummarySortOnFrontend = async (clickedColumn, { gradebookContext = '' } = {}) => {
    gradebookContext = gradebookContext || gradebookManager.activeGradebookTable;
    const sortObj = gradebookManager.gradebookTableSortMap.get(gradebookContext);

    const sortColumn = sortObj.sortColumn || null;
    const sortDirection = sortObj.sortDirection || 'ascending';

    if (!sortColumn && !clickedColumn) {
      return;
    }

    let newDirection;
    if (!clickedColumn) {
      clickedColumn = sortColumn;
      newDirection = sortDirection;
    } else {
      if (sortColumn !== clickedColumn || sortDirection === 'descending') {
        newDirection = 'ascending';
      } else {
        newDirection = 'descending';
      }
    }

    let instances = toJS(gradebookManager.assignmentInstances);
    let sortingNumbers = false;

    if (clickedColumn === 'status' || clickedColumn === 'studentStatus') { // sort by STATUS
      /* sort instances in alphabetical order, based on each possible `status` label shown in table */
      const arr = [...instances];

      /**
       * This helper function tells us if the assignment status is `'closed' || 'completed'`
       *
       * If true, any instances of this assignment will only have the Status label `'Submitted'` or `'Not Submitted'`
       * in the gradebook summary table, based on the individual status of a given instance.
       *
       * So this allows us (outside of this function) to decide what status array we should put
       * a given instance in before merging all the status arrays (so they appear sorted in the table).
       *
       * i.e. if this function returns `true`, we can then do a subsequent check
       * (outside of this function) on `instance.status` to see what the status label should be.
       *
       * Outside of this function, if `instance.status` is `'closed' || 'completed'`,
       * we can then assume the label should be `'Submitted'`. Otherwise, the label should be `'Not Submitted'`
       */
      // eslint-disable-next-line no-inner-declarations
      function statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment) {
        return assignment.status === ASSIGNMENT_STATUS.CLOSED || assignment.status === ASSIGNMENT_STATUS.COMPLETED;
      }

      /* SORT BY STATUS: COMPLETED */
      const completed = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return false;
        }
        return instance.status === ASSIGNMENT_STATUS.COMPLETED;
      });

      /* SORT BY STATUS: LOCKED */
      const locked = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return false;
        }
        return instance.status === ASSIGNMENT_STATUS.LOCKED;
      });

      /* SORT BY STATUS: NOT STARTED */
      const notStarted = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return false;
        }
        return instance.status === ASSIGNMENT_STATUS.READY;
      });

      /* SORT BY STATUS: NOT SUBMITTED */
      const notSubmitted = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return !instance.submitted;
        }
      });

      /* SORT BY STATUS: PRESENTATION (edge case, will likely never happen) */
      const presentation = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return false;
        }
        return instance.status === ASSIGNMENT_STATUS.PRESENTATION;
      });

      /* SORT BY STATUS: PREVIEW (edge case, will likely never happen) */
      const preview = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return false;
        }
        return instance.status === ASSIGNMENT_STATUS.PREVIEW;
      });

      /* SORT BY STATUS: STARTED */
      const started = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return false;
        }
        return instance.status === ASSIGNMENT_STATUS.STARTED;
      });

      /* SORT BY STATUS: SUBMITTED */
      const submitted = arr.filter((instance) => {
        const assignment = assignmentManager.getAssignment(instance.activityId);
        if (statusLabelWillOnlyBeSubmittedOrNotSubmitted(assignment)) {
          return instance.submitted;
        }
        return instance.status === ASSIGNMENT_STATUS.CLOSED;
      });

      instances = [...completed, ...locked, ...notStarted, ...notSubmitted, ...presentation, ...preview, ...started, ...submitted];
      if (newDirection === 'descending') {
        instances = [...instances].reverse();
      }
    } else if (clickedColumn === 'grade') { // sort by GRADE
      /* ensure all unsubmitted grades will be last in the list */
      sortingNumbers = true;
      let submittedInstances = instances.filter((instance) => instance.submitted);
      submittedInstances = UtilityService.sortColumn(submittedInstances, newDirection, clickedColumn, sortingNumbers);
      const unsubmittedInstances = instances.filter((instance) => !instance.submitted);
      instances = [...submittedInstances, ...unsubmittedInstances];
    } else {
      instances = UtilityService.sortColumn(instances, newDirection, clickedColumn, sortingNumbers);
    }
    gradebookManager.clearAssignmentInstances();
    gradebookManager.setAssignmentInstances(instances);

    gradebookManager.setGradebookTableSortColumn(clickedColumn);
    gradebookManager.setGradebookTableSortDirection(newDirection);
  }
}

registerClass('GradebookSummaryService', GradebookSummaryService);
