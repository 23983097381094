import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import ReactCrop from 'react-image-crop';

import {
  Button, Image, Input, Loader, Tab
} from 'semantic-ui-react';

import '../../css/AvatarPicker.less';

import 'react-image-crop/dist/ReactCrop.css';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { AVATAR_PICKER_LISTS } from '../../constants';

import Modal from '../Modal';

const TAB_INDEX_AVATARS = 0;
// const TAB_INDEX_UPLOAD = 1;
const TAB_INDEX_CROP = 2;

export default
@inject('userManager')
@observer
class AvatarPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatarPickerName: '',
      crop: { aspect: 1 },
      currentImageName: null,
      loading: false,
      localImgUrl: undefined,
      panes: [], // eslint-disable-line react/no-unused-state
      selectedAvatarIndex: null,
      tabIndex: TAB_INDEX_AVATARS
    };
    this.ModalBanner = SatCoreComponent('ModalBanner');
  }

  componentDidMount() {
    const { userManager } = this.props;

    const { avatarPickerFacultyName, avatarPickerStudentName, isStudent } = userManager;
    const avatarPickerName = isStudent ? avatarPickerStudentName : avatarPickerFacultyName;

    this.setState({ avatarPickerName });
  }

  handleSelectAvatar = (_event, avatarIndex) => {
    this.setState({ selectedAvatarIndex: avatarIndex });
  };

  saveDefault = async () => {
    const { userManager, userId } = this.props;
    const { avatarPickerName, selectedAvatarIndex } = this.state;

    const selectedAvatar = AVATAR_PICKER_LISTS[avatarPickerName]?.[selectedAvatarIndex];

    const selectedAvatarName = selectedAvatar?.name;
    await userManager.saveUserDefaultAvatar(selectedAvatarName, userId);
    this.toggleAvatarEditor();
  };

  toggleAvatarEditor = () => {
    const { props } = this;
    // no need to reset state here - state will be reinitialized each time the AvatarPicker is relaunched
    props.toggleAvatarEditor();
  };

  renderAvatarPane = () => {
    const { avatarPickerName, selectedAvatarIndex } = this.state;

    const avatarPickerList = AVATAR_PICKER_LISTS[avatarPickerName];
    return (
      <Tab.Pane>
        <div className='avatar-picker-container'>
          <div className='avatar-picker'>
            {avatarPickerList?.map?.((avatarObj, avatarIndex) => {
              const isAvatarSelected = selectedAvatarIndex === avatarIndex;
              return (
                <Image
                  key={avatarObj.name}
                  className={classNames(avatarObj.name, {
                    'selected-avatar': isAvatarSelected
                  })}
                  name={avatarObj.name}
                  onClick={(event) => this.handleSelectAvatar(event, avatarIndex)}
                  src={(avatarObj.src.indexOf('base64') > -1) ? avatarObj.src : require(`./${avatarObj.src}`)} />
              );
            })}
          </div>
        </div>
      </Tab.Pane>
    );
  }

  cropImagePane = () => {
    const { tabIndex } = this.state;
    return (
      <div className='image-crop-pane'>
        {(tabIndex === TAB_INDEX_CROP)
          ? this.renderCropElements()
          : null}
      </div>
    );
  }

  uploadImagePane = () => {
    const { t } = this.props;
    const { loading } = this.state;
    return (
      <div className='image-upload-pane'>
        <label className='ui button basic upload-button' htmlFor='avatar-file'>
          {t('chooseFileFormat')}
        </label>
        <Input
          accept='image/png, image/jpg'
          className='avatar-file'
          id='avatar-file'
          name='avatar-file'
          onChange={this.uploadAvatar}
          style={{ opacity: 0 }}
          type='file' />
        {this.currentImage()}
        <Loader active={loading} className='modal-loader' inline />
      </div>
    );
  }

  createPanes = () => {
    const { t, userManager } = this.props;
    const {
      isDistrictAdmin, isSchoolAdmin, isProductAdmin, isTeacher, restrictAvatar
    } = userManager;
    const isAdmin = isDistrictAdmin || isSchoolAdmin;
    const canUploadOrCropImage = isAdmin || isProductAdmin || isTeacher || !restrictAvatar;

    const labelAvatars = t('avatars');

    const avatarPane = {
      menuItem: labelAvatars,
      render: () => this.renderAvatarPane()
    };

    if (canUploadOrCropImage) {
      const labelUploadImage = t('uploadImage');
      const labelCropImage = t('cropImage');

      const uploadPane = { menuItem: labelUploadImage, render: () => <Tab.Pane>{this.uploadImagePane()}</Tab.Pane> };
      const cropPane = { menuItem: labelCropImage, render: () => <Tab.Pane className='crop-pane'>{this.cropImagePane()}</Tab.Pane> };
      return [avatarPane, uploadPane, cropPane];
    }
    return [avatarPane];
  };

  currentImage = () => {
    const { t, userManager } = this.props;
    const { currentImageName, localImgUrl } = this.state;

    if (localImgUrl) {
      return (<Image className='avatar-current-image' src={localImgUrl} wrapped />);
    } else if (currentImageName) {
      const fullImageUrl = userManager.getFullUrlFromName(currentImageName);
      return (<Image className='avatar-current-image' src={fullImageUrl} wrapped />);
    } else if (userManager.fullProfileImageUrl) {
      const fullImageUrl = userManager.fullProfileImageUrl;
      return (<Image className='avatar-current-image' src={fullImageUrl} wrapped />);
    }
    return (<div className='no-image'>{t('pleaseUploadImageFile')}</div>);
  }

  saveImage = async (newImageName) => {
    const { currentImageName } = this.state;

    const { userId, userManager } = this.props;

    const save = true;
    if (newImageName) {
      await userManager.setProfileImageUrl(newImageName, save, userId);
      this.toggleAvatarEditor();
    } else if (currentImageName) {
      await userManager.setProfileImageUrl(currentImageName, save, userId);
      this.toggleAvatarEditor();
    }
  }

  uploadAvatar = async () => {
    const { avatarPickerName, selectedAvatarIndex, tabIndex } = this.state;

    const { t, userManager } = this.props;

    const isDefaultAvatarPickerName = avatarPickerName?.includes?.('DEFAULT');

    // user is uploading an avatar from a non-default picker list
    // e.g. 'AVATAR_PICKER_KYA_LIST' instead of 'AVATAR_PICKER_DEFAULT_LIST'
    if (!isDefaultAvatarPickerName && tabIndex === TAB_INDEX_AVATARS) {
      this.setState({ loading: true });

      const avatarObj = AVATAR_PICKER_LISTS[avatarPickerName]?.[selectedAvatarIndex];

      if (!avatarObj) {
        this.toggleAvatarEditor();
        return;
      }

      const imageUrl = (avatarObj.src.indexOf('base64') > -1) ? avatarObj.src : require(`./${avatarObj.src}`);
      const imageResponse = await fetch(imageUrl);
      const blob = await imageResponse.blob();

      const imgFileName = `${avatarObj.name}${(avatarObj.extension || '.png')}`;
      const localImgFile = new File([blob], imgFileName, { type: blob.type });

      const localImgUrl = URL.createObjectURL(localImgFile);

      const formData = new FormData();
      formData.append('contentItemImageSelectLocalImage', localImgFile);

      const result = await userManager.uploadImage(formData);

      if (result) {
        const currentImageName = result;
        this.setState({ currentImageName, loading: false, localImgUrl });

        await this.saveImage(currentImageName);
      } else {
        this.setState({ currentImageName: null, loading: false });
        const errorUploadImage = t('errorUploadImage');
        alert(errorUploadImage); // eslint-disable-line no-alert
      }
    // here we assume user is uploading a custom image (i.e. not from avatar picker)
    } else if (tabIndex !== TAB_INDEX_AVATARS) {
      this.setState({ loading: true });
      const formData = new FormData();

      const avatarImgFile = document.getElementById('avatar-file');

      if (!avatarImgFile.files.length) {
        this.setState({ loading: false });
        const selectImageToUpload = t('selectImageToUpload');
        alert(selectImageToUpload); // eslint-disable-line no-alert
        return;
      }

      const localImgFile = avatarImgFile.files[0];
      const localImgUrl = URL.createObjectURL(localImgFile);

      formData.append('contentItemImageSelectLocalImage', localImgFile);

      const result = await userManager.uploadImage(formData);

      if (result) {
        this.setState({ currentImageName: result, loading: false, localImgUrl });
      } else {
        this.setState({ currentImageName: null, loading: false });
        const errorUploadImage = t('errorUploadImage');
        alert(errorUploadImage); // eslint-disable-line no-alert
      }
    }
  };

  handleTabChange = (_event, { activeIndex }) => {
    this.setState({ tabIndex: activeIndex });
  }

  loadImage = (image) => {
    this.scaleX = image.naturalWidth / image.width;
    this.scaleY = image.naturalHeight / image.height;
  }

  submitCrop = async () => {
    const { userManager } = this.props;
    const { currentImageName, crop } = this.state;
    if (this.scaleX !== undefined) {
      crop.x *= this.scaleX;
      crop.width *= this.scaleX;
    }

    if (this.scaleY !== undefined) {
      crop.y *= this.scaleY;
      crop.height *= this.scaleY;
    }
    const newImageName = await userManager.submitCrop(currentImageName, crop);
    this.saveImage(newImageName);
  }

  renderCropElements = () => {
    const { userManager } = this.props;
    const { crop, currentImageName, localImgUrl } = this.state;
    const fullUrl = localImgUrl || userManager.getFullUrlFromName(currentImageName);

    if (currentImageName === null && userManager.profileImageUrl === null) {
      return this.currentImage();
    }

    return (
      <div className='cropper-container'>
        <ReactCrop circularCrop={true} crop={crop}
          onChange={(newCrop) => this.setCrop(newCrop)}
          onImageLoaded={this.loadImage}
          src={(fullUrl !== null) ? fullUrl : userManager.fullProfileImageUrl} />
      </div>
    );
  }

  setCrop = (crop) => {
    this.setState({ crop });
  }

  render() {
    const panes = this.createPanes();
    const { openAvatarPicker, t } = this.props;
    const { avatarPickerName, saving, tabIndex } = this.state;
    const { ModalBanner } = this;

    const showCropper = (tabIndex === TAB_INDEX_CROP);
    const showDefault = (tabIndex === TAB_INDEX_AVATARS);

    const isDefaultAvatarPickerName = avatarPickerName?.includes?.('DEFAULT');

    // TODO remove
    // const onClickSave = (showCropper) ? this.submitCrop : (showDefault) ? this.saveDefault : () => this.saveImage();

    let onClickSave;
    if (showCropper) {
      onClickSave = async () => this.submitCrop();
    } else if (showDefault) {
      onClickSave = isDefaultAvatarPickerName ? async () => this.saveDefault() : async () => this.uploadAvatar();
    } else {
      onClickSave = async () => this.saveImage();
    }

    return (
      <Modal
        className='AvatarPickerModal'
        closeOnDimmerClick={false}
        closeOnEscape={true}
        onClose={this.toggleAvatarEditor}
        open={openAvatarPicker}>
        <ModalBanner
          label='Edit Profile Image'
          onClose={this.toggleAvatarEditor} />
        <Modal.Content image scrolling>
          <div className='avatar-picker-parent'>
            <Tab onTabChange={this.handleTabChange} panes={panes} />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button basic className='save-button' disabled={saving}
            onClick={this.toggleAvatarEditor} primary>
            {t('cancel')}
          </Button>
          <Button className='save-button'
            loading={saving}
            onClick={async (_event) => {
              if (!saving) {
                this.setState({ saving: true });
                await onClickSave();
                setTimeout(() => {
                  this.setState({ saving: false });
                }, 500);
              }
            }}
            primary>{(showCropper) ? t('cropAndSave') : t('save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('AvatarPicker', AvatarPicker);
