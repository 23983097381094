import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Loader } from 'semantic-ui-react';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import { DIALOG_NAMES } from '../managers/DialogManager';

import Auth from '../managers/AuthManager';
import { ASSIGNMENT_STATUS, ASSIGNMENT_TYPE } from '../managers/AssignmentManager';

import {
  CONTENT_ITEM_TYPES, CONTENT_MODE, PLAYER_TYPES
} from '../managers/ContentManager';

import AssignmentService from '../services/AssignmentService';
import CourseService from '../services/CourseService';
import ContentService from '../services/ContentService';
import GradebookNavigationService from '../services/gradebook/GradebookNavigationService';
import ImageService from '../services/ImageService';
import ReportOverrideService from '../services/reports/ReportOverrideService';
import ReportStandardsClassroomDetailStudentsService from '../services/reports/ReportStandardsClassroomDetailStudentsService';
import ResourcePacingService from '../services/ResourcePacingService';

import { register } from '../i18n';

const t = register('AssignmentsPopup');

export default @inject(
  'assignmentManager', 'classroomManager', 'contentManager',
  'courseManager', 'dialogManager', 'reportIdentityManager',
  'userManager')
@observer
class AssignmentsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentItemId: null,
      contentItemType: null,
      contentName: null,
      contentSubtitle: null,
      instruction: null,
      isFlowpaper: false,
      learnosityPlayerShowing: false,
      lessonPlayerShowing: false
    };
    this.Avatar = SatCoreComponent('Avatar');
    this.CourseList = SatCoreComponent('CourseElementList');
    this.DocReaderModal = SatCoreComponent('DocReaderModal');
    this.FileViewerModal = SatCoreComponent('FileViewerModal');
    this.FullscreenModal = SatCoreComponent('FullscreenModal');
    this.LearnosityPlayerModal = SatCoreComponent('LearnosityPlayerModal');
  }

  async componentDidMount() {
    const {
      courseElementRow,
      resourceCountColumnName
    } = this.props;
    if (resourceCountColumnName === 'taken' || resourceCountColumnName === 'remaining') {
      this.showTakenOrRemainingModal(courseElementRow, resourceCountColumnName);
    } else {
      this.showContentAssignmentsForRow(courseElementRow);
    }
  }

  closeContentAssignmentModal = async () => {
    const { dialogManager, hideSelf, reportIdentityManager } = this.props;
    hideSelf();
    dialogManager.closeAllDialogs();

    if (reportIdentityManager.isReport) {
      ReportStandardsClassroomDetailStudentsService.cleanupAfterLeaving();
    }
  }

  handleGradebook = async (assignment) => {
    const { dialogManager, history, t } = this.props;
    dialogManager.closeAllDialogs();
    await GradebookNavigationService.navToAggregateGradebookSummaryFromExternalModal(
      {
        allAssignmentsLabel: t('gradebookLabel', 'Gradebook'),
        assignCardData: null,
        assignment,
        currentClassroomId: assignment.classroomId,
        history
      }
    );
    // history.push(`/gradebook?assignmentId=${assignment.id}&view=${VIEW_SELECTION.GRADEBOOK}&classroomId=${assignment.classroomId}`);
  }

  fetchCourseActivityInformation = async (assignment, assignmentElement, contentImageUrl) => {
    const { assignmentManager, userManager } = this.props;
    if (userManager.isTeacher) {
      const result = await assignmentManager.fetchCourseActivityInformation(assignment.id);
      if (result) {
        const title = result.webTitle ? result.webTitle : result.contentName;
        this.handleOpenInfoFromDialog(title, result.contentDescription, result.resourceInformation,
          contentImageUrl, assignment.instruction, assignment, assignmentElement);
      }
    } else {
      // do student things
    }
  }

  handleOpenInfoFromDialog = (infoName, contentItemDescription, resourceInfo, contentItemImageUrl, instruction) => {
    const { dialogManager } = this.props;
    dialogManager.setOpenDialog(DIALOG_NAMES.INFO, {
      contentItemDescription,
      contentItemImageUrl,
      infoName,
      resourceInfo,
      studentInstruction: instruction
    }, () => this.closeOpenInfo());
  }

  closeOpenInfo = async () => {
    const { dialogManager } = this.props;

    dialogManager.closeDialog(DIALOG_NAMES.INFO);
  }

  handleAnswerKey = async (assignment) => {
    const { contentManager, userManager } = this.props;
    const option = await contentManager.getOptionsForAnswerKey(
      assignment.attachmentContentItemId, CONTENT_ITEM_TYPES.PDF_RESOURCE,
      assignment.classroomId, origin, userManager.isTeacher, userManager.userId);
    const { playerType, viewUrl, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    this.setState({
      contentName: assignment.attachmentContentItemTitle,
      contentUrl: viewUrl,
      docreaderViewerShowing: false,
      fileViewerShowing: true,
      instruction: null,
      isFlowpaper,
      learnosityPlayerShowing: false,
      lessonPlayerShowing: false
    });
  }

  hideIframeFromOuterClick = (event) => {
    if ((event.origin === Auth.publisher || event.origin === Auth.lesson) && event.data === 'hideIframe') {
      this.setState({ lessonPlayerShowing: false });
      ContentService.setLessonPlayerShowing(false);
      CourseService.setCourseResourceItemIdForRelatedItems(null);
      CourseService.setCourseContentItemIdForRelatedItems(null);
      CourseService.setTagIds('',true);
      window.removeEventListener('message', this.hideIframeFromOuterClick);
    }
  }

  hideIframe = () => {
    this.setState({ lessonPlayerShowing: false });
  }

  hideModal = () => {
    this.setState({
      docreaderViewerShowing: false,
      fileViewerShowing: false,
      instruction: '',
      learnosityPlayerShowing: false
    });
  }

  showContentAssignmentsForRow = async (courseElementRow) => {
    this.showContentAssignments(
      courseElementRow.courseContentItemId, courseElementRow.courseResourceElementId,
      courseElementRow.parentElementId, courseElementRow.elementId
    );
  }

  showContentAssignments = async (courseContentItemId, courseResourceElementId, parentElementId, elementId) => {
    const { assignmentManager, classroomManager, courseManager, dialogManager } = this.props;
    const { currentClassroomId } = classroomManager;
    dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_ASSIGNMENT, {
      ...this.props,
      assignmentElement: null,
      assignments: assignmentManager.assignmentArray,
      classeslist: null,
      courseContentItemId,
      courseResourceElementId,
      currentClassroomId,
      currentCourseId: courseManager.currentCourseId,
      elementId,
      fetchCourseActivityInformation: this.fetchCourseActivityInformation,
      handleAnswerKey: this.handleAnswerKey,
      handleEditAssignment: this.handleEditAssignment,
      handleGradebook: this.handleGradebook,
      handlePresent: this.handlePresent,
      parentElementId
    },
    this.closeContentAssignmentModal);
  }

  showTakenOrRemainingModal = async (elementRow, resourceCountColumnName) => {
    const {
      dialogManager, labelInfo, showCourseDropdown
    } = this.props;

    const isTaken = resourceCountColumnName === 'taken';
    const { CONTENT_ASSIGNMENT, CONTENT_ELEMENT } = DIALOG_NAMES;
    const dialogName = isTaken ? CONTENT_ASSIGNMENT : CONTENT_ELEMENT;

    const { courseId, takenActivityIds } = elementRow;

    const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();
    const cmapElementId = shouldUseCmapId ? elementRow.id : undefined;
    const standardId = !shouldUseCmapId ? elementRow.id : undefined;

    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = urlParams.get('classroomId');
    const studentId = urlParams.get('studentId');

    const elementRowOrDescription = elementRow?.elementName || elementRow?.elementDescription;

    dialogManager.setOpenDialog(dialogName, {
      ...this.props,
      assignmentElement: null,
      assignments: [],
      classeslist: null,
      closeContentAssignmentModal: this.closeContentAssignmentModal,
      cmapElementId,
      contentName: elementRowOrDescription,
      courseElementList: [],
      currentClassroomId: classroomId,
      currentCourseId: courseId,
      fetchCourseActivityInformation: this.fetchCourseActivityInformation,
      handleAddAssignment: this.handleAddAssignment,
      handleAnswerKey: this.handleAnswerKey,
      handleEditAssignment: this.handleEditAssignment,
      handleGradebook: this.handleGradebook,
      handleOpenInfo: this.handleOpenInfoFromDialog,
      handlePresent: this.handlePresent,
      handleView: this.handleView,
      infoPopupText: t('infoPopupText'),
      labelInfo: typeof labelInfo === 'string' ? labelInfo : `${isTaken ? 'Taken: ' : 'Remaining: '}`,
      showCourseDropdown: typeof showCourseDropdown === 'boolean' ? showCourseDropdown : true,
      standardId,
      standardName: elementRow.elementName,
      studentId,
      takenActivityIds
    },
    this.closeContentAssignmentModal);
  }

  handlePresent = async (params) => {
    const { contentManager, userManager, assignmentManager, classroomManager, courseManager } = this.props;
    const { assignment, contentMode = CONTENT_MODE.PREVIEW, publisherModeId = null } = params;
    let option = null;
    if (userManager.isTeacher && contentMode === CONTENT_MODE.PREVIEW) {
      option = await contentManager.getOptionsForTeacherPreviewAssigned(
        assignment, window.location.origin, userManager.isTeacher, userManager.userId, publisherModeId);
    } else if (userManager.isTeacher && contentMode === CONTENT_MODE.PRESENT) {
      const today = new Date();
      const todayWithBuffer = new Date();
      todayWithBuffer.setHours(today.getHours() + 1);
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      const activityContentStr = JSON.stringify([{
        contentItemId: assignment.contentItemId,
        courseResourceElementId: assignment.courseResourceElementId
      }]);
      await assignmentManager.createAssignment({
        activityContent: activityContentStr,
        assignEntityIds: userManager.userId,
        assignEntityTypeId: ASSIGNMENT_TYPE.TEACHER,
        classroomId: classroomManager.currentClassroomId,
        courseContentItemId: courseManager.currentCourseId,
        endRealDate: tomorrow,
        instruction: '',
        startRealDate: todayWithBuffer,
        status: ASSIGNMENT_STATUS.PRESENTATION
      });
      const currentAssignment = await assignmentManager.getLastAddedAssignment();

      option = await contentManager.getOptionsForTeacherPresent(
        currentAssignment.contentItemId, currentAssignment, currentAssignment.entityTypeId, window.location.origin, userManager.isTeacher);
    } else if (assignment.deliveryMode.includes('student') && userManager.isStudent) {
      const alternateModeId = publisherModeId || assignment.alternateModeIdOverride;
      option = await contentManager.getOptionsForStudentPreview(
        assignment.contentItemId, assignment.entityTypeId, assignment.pdfDeliveryFormat, window.location.origin,
        userManager.isTeacher, userManager.userId, assignment.courseResourceElementId, assignment.deliveryMode,
        classroomManager.currentClassroomId, alternateModeId);
    } else {
      // TODO: put in default and be mindful that this is a shared view (teacher/student).
      console.warn('AssignmentPopup: unable to view');
      return false;
    }

    const { playerType, viewUrl, isFlowpaper } = option;

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);
    const contentImageUrl = ImageService.getImageUrl(assignment);

    const { dialogManager } = this.props;
    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        ...this.props,
        contentItemId: assignment.contentItemId,
        contentItemType: assignment.contentItemEntityTypeId,
        resourceName: assignment.name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
    }

    const {
      resourceWebTitle, resourceWebSubtitle
    } = AssignmentService.getAssignmentTitleAndDescription(assignment);

    if((playerType === PLAYER_TYPES.LESSON_PLAYER)) {
      contentManager.setLessonPlayerShowing((playerType === PLAYER_TYPES.LESSON_PLAYER));
      CourseService.setCourseResourceItemIdForRelatedItems(assignment.courseResourceElementId);
      CourseService.setCourseContentItemIdForRelatedItems(assignment.courseContentItemId);
    }

    this.setState({
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      contentUrl: viewUrl,
      contentName: resourceWebTitle,
      contentSubtitle: resourceWebSubtitle,
      isFlowpaper,
      assignmentId: assignment.id,
      contentItemId: assignment.contentItemId,
      previewContentType: assignment.contentItemEntityTypeId,
      previewContentItemId: assignment.id,
      resourceName: assignment.name,
      instruction: assignment.instruction,
      contentImageUrl
    });
  }

  handleView = async (courseElement, contentMode, pdfFormatSelected, publisherModeId = null) => {
    const { contentManager, assignmentManager, dialogManager, userManager, classroomManager } = this.props;
    let option = null;
    const contentItemId = courseElement.entityId;
    const courseResourceElementId = courseElement.elementId;
    const entityTypeId = courseElement.type;
    const { name } = courseElement;
    let { pdfDeliveryFormat } = courseElement;
    const { deliveryMode } = courseElement;
    // if the user selected a particular format, use that.
    if (pdfFormatSelected) {
      pdfDeliveryFormat = pdfFormatSelected;
    }
    const alternateModeId = publisherModeId || courseElement.alternateModeIdOverride;
    if (deliveryMode.includes('student') && userManager.isStudent) {
      option = await contentManager.getOptionsForStudentPreview(
        contentItemId, entityTypeId, pdfDeliveryFormat, origin, userManager.isTeacher, userManager.userId,
        courseResourceElementId, deliveryMode, classroomManager.currentClassroomId, alternateModeId);
    } else {
      if (contentMode === CONTENT_MODE.PRESENT) {
        const currentAssignment = assignmentManager.getLastAddedAssignment();
        option = await contentManager.getOptionsForTeacherPresent(
          contentItemId, currentAssignment, entityTypeId, origin, userManager.isTeacher);
      } else {
        option = await contentManager.getOptionsForTeacherPreview(
          contentItemId, entityTypeId, pdfDeliveryFormat, origin, userManager.isTeacher,
          userManager.userId, courseResourceElementId, classroomManager.currentClassroomId,
          alternateModeId, courseElement.courseContentItemId);
      }
    }

    const { playerType, viewUrl, isFlowpaper } = option;

    if (!userManager.isTeacher) {
      // TODO: IS STUDENT PREVIEW MODE FOR LESSON GOING TO BE SOMETHING? API RETURNS ONLY TEACHER PREVIEW WITHOUT ACTIVITY.
      if (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER || playerType === PLAYER_TYPES.LESSON_PLAYER) {
        this.handleGeneralMessage('This type is unsupported.');
        return false;
      }
    }

    if (playerType === null && viewUrl !== null) {
      window.open(viewUrl, '_blank');
      return;
    }

    await contentManager.configPlayerWindow(playerType, window, this.hideIframeFromOuterClick);

    if((playerType === PLAYER_TYPES.LESSON_PLAYER)) {
      contentManager.setLessonPlayerShowing((playerType === PLAYER_TYPES.LESSON_PLAYER));
      CourseService.setCourseResourceItemIdForRelatedItems(courseResourceElementId);
      CourseService.setCourseContentItemIdForRelatedItems(courseElement.courseContentItemId);
    }

    if (playerType === PLAYER_TYPES.CONTENT_PREVIEW_PLAYER) {
      dialogManager.setOpenDialog(DIALOG_NAMES.CONTENT_PREVIEW, {
        ...this.props,
        contentItemId,
        contentItemType: entityTypeId,
        resourceName: name
      }, () => dialogManager.closeDialog(DIALOG_NAMES.CONTENT_PREVIEW));
    }

    this.setState({
      assignmentId: '',
      contentImageUrl: '',
      contentItemId,
      contentItemType: entityTypeId,
      contentName: name,
      contentUrl: viewUrl,
      docreaderViewerShowing: (playerType === PLAYER_TYPES.DOCREADER_VIEWER),
      fileViewerShowing: (playerType === PLAYER_TYPES.FILE_VIEWER),
      instruction: '',
      isFlowpaper,
      learnosityPlayerShowing: (playerType === PLAYER_TYPES.LEARNOSITY_PLAYER),
      lessonPlayerShowing: (playerType === PLAYER_TYPES.LESSON_PLAYER),
      previewContentItemId: '',
      previewContentType: entityTypeId,
      resourceName: ''
    });
    assignmentManager.setLoaded(true);
  }

  renderLessonPlayer = () => {
    const { FullscreenModal } = this;
    const { userManager } = this.props;

    if (this.props.renderLessonPlayer !== undefined) {
      return this.props.renderLessonPlayer();
    }

    return (
      <div className='course-content'>
        <FullscreenModal
          closeIframeCallback={this.hideIframe}
          contentImageUrl={this.state.contentImageUrl}
          contentName={this.state.contentName}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='lesson-player'
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderFileViewer = () => {
    const { userManager } = this.props;
    const { FileViewerModal } = this;

    if (this.props.renderFileViewer !== undefined) {
      return this.props.renderFileViewer();
    }

    return (
      <div className='course-content'>
        <FileViewerModal
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='file-viewer'
          resourceName={this.state.resourceName}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderDocreaderViewer = () => {
    const { userManager, contentManager } = this.props;
    const { DocReaderModal } = this;
    if (this.props.renderDocreaderViewer !== undefined) {
      return this.props.renderDocreaderViewer();
    }

    return (
      <div className='course-content'>
        <DocReaderModal
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentMode={this.state.contentMode}
          contentName={this.state.contentName}
          contentType={this.state.previewContentType}
          instruction={this.state.instruction}
          isFlowpaper={this.state.isFlowpaper}
          isTeacher={userManager.isTeacher}
          page='docreader-viewer'
          resourceName={this.state.resourceName}
          sessionId={contentManager.currentflowPaperSessionId}
          url={this.state.contentUrl} />
      </div>
    );
  }

  renderLearnosityPlayer = () => {
    const { userManager, history } = this.props;
    const { LearnosityPlayerModal } = this;

    if (this.props.renderLearnosityPlayer !== undefined) {
      return this.props.renderLearnosityPlayer();
    }

    return (
      <div className='course-content'>
        <LearnosityPlayerModal
          assignmentId={this.state.assignmentId}
          closeModalCallback={this.hideModal}
          contentImageUrl={this.state.contentImageUrl}
          contentItemId={this.state.contentItemId}
          contentName={this.state.contentName}
          contentSubtitle={this.state.contentSubtitle}
          history={history}
          instruction={this.state.instruction}
          isTeacher={userManager.isTeacher}
          page='learnosity-player'
          resourceName={this.state.resourceName} />
      </div>
    );
  }

  handleEditAssignment = (assignment, contentImageUrl, _classroomId, _assignmentElement, deleteAssignment) => {
    const { dialogManager } = this.props;
    this.closeContentAssignmentModal();
    dialogManager.setOpenDialog(DIALOG_NAMES.EDIT_ASSIGNMENT, {
      ...this.props,
      assignmentId: assignment.id,
      assignmentInstruction: assignment.instruction,
      classroomId: _classroomId,
      contentTimeframeStartDate: assignment.timeframeStartDate,
      contentTimeframeEndDate: assignment.timeframeEndDate,
      timeframeStartDateStr: assignment.timeframeStartDateStr,
      timeframeEndDateStr: assignment.timeframeEndDateStr,
      timeframeStartTimeStr: assignment.timeframeStartTimeStr,
      timeframeEndTimeStr: assignment.timeframeEndTimeStr,
      contentImageUrl,
      deleteAssignment,
      standards: assignment.standardsInfo
    },
    () => dialogManager.closeDialog(DIALOG_NAMES.EDIT_ASSIGNMENT));
  }

  closeEditAssignment = async () => {
    this.props.dialogManager.closeDialog(DIALOG_NAMES.EDIT_ASSIGNMENT);
  }

  handleAddAssignment = (courseElement, contentImageUrl) => {
    const { dialogManager } = this.props;
    this.closeContentAssignmentModal();
    dialogManager.setOpenDialog(DIALOG_NAMES.ADD_ASSIGNMENT, {
      ...this.props,
      contentDueDate: ResourcePacingService.dueDate(courseElement),
      contentDuration: ResourcePacingService.duration(courseElement),
      contentImageUrl,
      contentItemEntityId: courseElement.contentItemEntityId,
      contentItemEntityTypeId: courseElement.contentItemEntityTypeId,
      contentItemEntitySubTypeId: courseElement.contentItemEntitySubTypeId,
      contentItemId: courseElement.contentItemId,
      contentTimeframeStartDate: courseElement.timeframeStartDate,
      contentTimeframeEndDate: courseElement.timeframeEndDate,
      timeframeStartDateStr: courseElement.timeframeStartDateStr,
      timeframeEndDateStr: courseElement.timeframeEndDateStr,
      timeframeStartTimeStr: courseElement.timeframeStartTimeStr,
      timeframeEndTimeStr: courseElement.timeframeEndTimeStr,
      contentName: courseElement.name,
      contentSubname: courseElement.description,
      courseContentItemId: courseElement.courseContentItemId,
      courseElementToggleDefaults: {
        includeInReports: courseElement.includeInReports,
        scoresReleased: courseElement.scoresReleased,
        studentInstruction: courseElement.instruction,
        studentReview: courseElement.studentReview
      },
      courseResourceElementId: courseElement.elementId,
      standards: courseElement.standardsInfo
    }, () => dialogManager.closeDialog(DIALOG_NAMES.ADD_ASSIGNMENT));
  }

  render() {
    const {
      docreaderViewerShowing,
      fileViewerShowing,
      learnosityPlayerShowing,
      lessonPlayerShowing
    } = this.state;

    const { assignmentManager } = this.props;
    if (!assignmentManager.loaded) {
      return <Loader active />;
    } else if (learnosityPlayerShowing) {
      return this.renderLearnosityPlayer();
    } else if (lessonPlayerShowing) {
      return (this.renderLessonPlayer());
    } else if (fileViewerShowing) {
      return (this.renderFileViewer());
    } else if (docreaderViewerShowing) {
      return (this.renderDocreaderViewer());
    }
    return <></>;
  }
}

SatCoreRegister('AssignmentsPopup', AssignmentsPopup);
