import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import { kebabCase } from 'lodash';

import { Container, Form, Label, Loader, Pagination, Popup } from 'semantic-ui-react';

import '../css/AssessmentItemShopPanel.less';
import Auth from '../managers/AuthManager';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import CustomCourseResourceService from '../services/CustomCourseResourceService';
import CourseService from '../services/CourseService';

import AssessmentItemCard from './AssessmentItemCard';

/** AssessmentItemShopModal aka resourceItemShopModal is shared among two features: 'COURSE SEARCH' and 'LEARNOSITY CUSTOM COURSES' */
export default
@inject(
  'assessmentManager',
  'contentManager',
  'courseManager',
  'userManager'
)
@observer
class AssessmentItemShopPanel extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    // props specifying what this modal is being used for
    assessmentItemsSearch: false,
    courseResourcesSearch: true,
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.AssessmentItemFilter = SatCoreComponent('AssessmentItemFilter');
    this.CautionBanner = SatCoreComponent('CautionBanner');
    this.CourseLayout = SatCoreComponent('CourseLayout');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
  }

  getInitialState() {
    return {
      activePage: 1,
      customAssessmentName: '',
      customAssessmentSubtitle: '',
      disableAdd: false,
      isCustomAssessmentModalErrorMsgVisible: false,
      serverError: false,
      serverErrorMsg: ''
    };
  }

  handleTogglePopup = (toggleOpen = true) => {
    const { courseManager } = this.props;
    const { isSelectedAssessmentsPopupOpen } = this.state;
    if (toggleOpen && !isSelectedAssessmentsPopupOpen) {
      // open the modal
      this.setState({ isSelectedAssessmentsPopupOpen: true });
    } else if (!toggleOpen && isSelectedAssessmentsPopupOpen) {
      // reset courseId back to what it is in the course card navigator
      const urlParams = new URLSearchParams(window.location.search);
      const currentCourseId = urlParams.get('courseId') || '';
      courseManager.setCurrentCourseId(currentCourseId);
      // close the modal
      this.setState({ isSelectedAssessmentsPopupOpen: false });
    }
  }

  handlePageChange = async (_event, pageInfo) => {
    const { courseManager } = this.props;
    const { activePage } = pageInfo;

    this.setState({ activePage });
    courseManager.setActivePageRelated( activePage );
    await CourseService.searchCourseResourceElements(true);
  }

  handleCheck = (item) => {
    const { assessmentManager } = this.props;
    assessmentManager.putAssessmentItemInCart(item.id, !item.selected);
  }

  handleFetchCourseResources= async (searchCondition = null, searchTerms = null, searchTags = null, searchStandards = null, page) => {
    await CustomCourseResourceService.fetchCourseResourcesList(searchCondition, searchTerms, searchTags, searchStandards, page);
  }

  async componentDidMount() {
    const { courseManager } = this.props;
    courseManager.setIsLoading(false);
  }

  handleLearnosityItemPreview = async (assessmentItem) => {
    await CustomCourseResourceService.handleLearnosityItemPreview(assessmentItem);
  }

  getShoppingItems = (selectedOnly = null) => {
    const { assessmentManager, userManager } = this.props;
    const { SCCheckbox } = this;
    const items = [];
    let assessmentItemList = [];
    if (selectedOnly) {
      assessmentItemList = assessmentManager.cartedAssessmentItemList;
    } else {
      assessmentItemList = assessmentManager.pagedAssessmentItemList;
    }

    assessmentItemList.map((pItem, _index) => {
      if (!selectedOnly && assessmentManager.assessmentItems.has(pItem.id)) {
        pItem = { ...assessmentManager.assessmentItems.get(pItem.id) };
      }
      if (!selectedOnly || (pItem.selected && !pItem.attached)) {
        items.push(
          <Form.Field key={`${pItem.id}_FF`} className='add-assessment-field'>
            <SCCheckbox
              key={pItem.id}
              checked={pItem.selected}
              disabled={pItem.attached}
              onChange={() => { this.handleCheck(pItem); }} />
            <AssessmentItemCard
              assessmentItem={pItem}
              authKey={userManager.authKey}
              disabled={pItem.attached}
              handleCardClick={() => {
                if (!pItem.attached) {
                  this.handleCheck(pItem);
                }
              }}
              handleLearnosityItemPreview={() => this.handleLearnosityItemPreview(pItem)}
              imageUrl={pItem.imageUrl} />
          </Form.Field>);
        return true;
      }
    });
    return items;
  }

  renderAssessmentItemModalContent = () => {
    return (
      <div className={classNames('assessment-item-search-modal-content-container', 'course-search-container')}>
        <div className='assessment-item-search-modal-content-left scrolling content'>
          {this.renderAssessmentItemFilter({ searchType: 'courseResourcesSearch' })}
        </div>
        <div className='assessment-item-search-modal-content-rest scrolling content'>
          <div className='assessment-item-search-modal-content-mid'>
            {/* placeholder */}
          </div>
          <div className='assessment-item-search-modal-content-right'>
            {this.renderAssessmentItemList()}
          </div>
        </div>
      </div>
    );
  }

  /** @param {{ searchType: 'courseResourcesSearch' }} */
  renderAssessmentItemFilter = ({ searchType } = {}) => {
    const { AssessmentItemFilter } = this;
    const { isCustomCourseResource } = this.props;
    return (
      <div className={`assessment-item-filter-wrapper ${kebabCase(searchType)}`}>
        <AssessmentItemFilter
          assessmentItemsSearch={false}
          courseResourcesSearch={true}
          dontSearchOnOpen={true}
          forRelatedContent={true}
          handleFetchAssessmentItems={this.handleFetchCourseResources}
          isCustomCourseResource={isCustomCourseResource}
          multiSelect={true} />
      </div>
    );
  };

  renderAssessmentItemList = () => {
    const { CourseLayout } = this;
    const { courseManager, history } = this.props;

    const activePage = courseManager.activePageRelated;

    const { assessmentManager, t } = this.props;

    const { length } = courseManager.relatedContentElementList;

    const { totalPagesRelated } = courseManager;
    const { totalItemsRelated } = courseManager;
    const itemCount = courseManager.relatedContentElementList.length;

    let courseSearchResultsCounts = '';
    courseSearchResultsCounts = `Showing ${itemCount} of ${totalItemsRelated} results`;

    const paginationJsx = (
      <Pagination
        activePage={activePage}
        onPageChange={this.handlePageChange}
        totalPages={totalPagesRelated} />
    );

    let headerContentJsx = <></>;
    if (length > 0) {
      headerContentJsx = paginationJsx;
    } else if (!courseManager.isLoading && courseManager.relatedContentElementList.length === 0) {
      headerContentJsx = t('noResultsZeroStateMsg');
    } else if (assessmentManager.assessmentItemsLoading || courseManager.isLoading) {
      headerContentJsx = (
        <Loader active className='modal-loader modal-loader-small-font' />
      );
    } else if (assessmentManager.originalAssessmentItems.size > 0) {
      headerContentJsx = t('noResultsZeroStateMsg');
    }
    return (
      <Container className='assessment-item-list-container' fluid>
        <div className={classNames('modal-content-header', {
          'assessment-item-list-header-sticky': !assessmentManager.assessmentItemsLoading && !courseManager.isLoading
        })}>
          {(assessmentManager.assessmentItemsLoading || courseManager.isLoading)?
            <div className="results-counts">Loading ...</div> :
            <div className="results-counts">{courseSearchResultsCounts}</div>}
          <div className='modal-content-header-pagination'>
            {headerContentJsx}
          </div>
        </div>
        {(courseManager.relatedContentElementList.length > 0) && (
          <Container className='class-content resources-container class-details-inner-container' fluid>
            <CourseLayout
              courseElementList={courseManager.relatedContentElementList}
              forRelatedContent={true}
              fromSatelliteTab={true}
              history={history}
              isCourseSearch={true}
              showBanners={true}
              showTags={true} />
          </Container>
        )}
        {/* TODO remove, header pagination is now positioned as sticky for this modal */}
        {/* TODO so there is no longer a need for footer pagination here */}
        {/* <div className='modal-content-footer'>
          {!!length && length >= 5 && (
          <div className='modal-content-footer-pagination'>
            {paginationJsx}
          </div>
          )}
        </div> */}
      </Container>
    );
  }

  render() {
    const { assessmentManager, courseManager, t } = this.props;

    const selectedCount = assessmentManager.cartedAssessmentItems.size;

    return (
      <div className='panelWrapper related-resources'>
        <div className='modal-header'>
          <div className='modal-header-bar'>
            <span className='modal-header-label'>
              {t('selectItems')}
            </span>
            <div className='modal-header-buttons'>
              <Popup
                className={`selected-assessments-popup ${selectedCount ? 'has-selected-assessments' : 'has-no-selected-assessments'}`}
                content={selectedCount ? (
                  <>
                    <div className='selected-assessments-popup-title'>
                      {t('Selected Assessments')}
                    </div>
                    <Container
                      className='course-assessment-container'
                      fluid>
                      <Container className='assessment-item-list-container' fluid>
                        {this.getShoppingItems('selectedOnly')}
                      </Container>
                    </Container>
                  </>
                ) : t('noSelectedAssessments')}
                hoverable
                offset={[0, -295]}
                on='hover'
                onClose={() => { this.handleTogglePopup(false); }}
                onOpen={() => { this.handleTogglePopup(true); }}
                position='left center'
                trigger={(
                  <div className={`selected-count-wrapper ${selectedCount ? 'has-selected-count' : 'has-no-selected-count'}`}>
                    <Label circular>
                      {selectedCount}
                    </Label>
                    <span className='selected-text-label'>
                      {t('Selected')}
                    </span>
                  </div>
                )} />
            </div>
          </div>
        </div>
        <h2 className='assessment-panel-title'>{t('relatedResources')}</h2>
        {this.renderAssessmentItemModalContent()}
      </div>
    );
  }
}

SatCoreRegister('AssessmentItemShopPanel', AssessmentItemShopPanel);
