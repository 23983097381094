/* eslint-disable sort-keys */
import _ from 'lodash';

import classNames from 'classnames';

import { MIN_HIGH_ENGAGEMENT, REPORT_COLOR_KEY, REPORT_CONTEXT_TYPE } from './ReportConstants';

import reportContextManager from '../../managers/reports/ReportContextManager';
import reportIdentityManager from '../../managers/reports/ReportIdentityManager';
import reportStandardsManager from '../../managers/reports/ReportStandardsManager';
import reportUsageManager from '../../managers/reports/ReportUsageManager';

import { flattenChildren } from '../../utils';

import ReportJsonHelperService from './ReportJsonHelperService';
import ReportUsageService from './ReportUsageService';

export default class ReportScoreService {
  static getReportScoreColorKeyDataArray = () => {
    const { reportContextType } = reportContextManager;
    const { activeReportType, isStandardsReport, isTableIndividualReport, reportInfoClassNames } = reportIdentityManager;
    const { selectedReportCmapObj } = reportStandardsManager;

    let reducedScoreSummaryData;

    const { REPORT_SCORE_INFO_BY_ELEMENT_FOR_STUDENTS, REPORT_SCORE_INFO_BY_STANDARD_FOR_STUDENTS } = ReportJsonHelperService;
    const allScoreInfo = isStandardsReport ? REPORT_SCORE_INFO_BY_STANDARD_FOR_STUDENTS() : REPORT_SCORE_INFO_BY_ELEMENT_FOR_STUDENTS();
    if (isTableIndividualReport) {
      const { pathname } = window.location;
      const isLti = pathname.includes('/lti-courses');
      const urlParams = new URLSearchParams(window.location.search);
      const studentId = (isLti) ? reportIdentityManager.studentId : urlParams.get('studentId');

      const studentScoreInfo = {};

      // note: if `standards` is undefined within the report (e.g. a course report), we will just get back an empty array
      const flatStandards = flattenChildren(ReportJsonHelperService.REPORT_STANDARDS());

      for (const prop in allScoreInfo) {
        if (selectedReportCmapObj?.id) {
          const currentStandard = flatStandards.find((standard) => standard.id === prop);
          if (currentStandard?.curriculumMapId === selectedReportCmapObj?.id) {
            if (allScoreInfo[prop]) {
              studentScoreInfo[prop] = {
                [studentId]: allScoreInfo[prop][studentId] ?
                  allScoreInfo[prop][studentId] : this.getEmptyStudentScoreInfo()
              };
            }
          }
        } else {
          if (allScoreInfo[prop][studentId]) {
            studentScoreInfo[prop] = {
              [studentId]: allScoreInfo[prop][studentId] ?
                allScoreInfo[prop][studentId] : this.getEmptyStudentScoreInfo()
            };
          }
        }
      }

      const scoreSummaryData = Object.keys(studentScoreInfo).map((elementId) => {
        const multiScoreInfoObj = studentScoreInfo[elementId];
        return this.getScoreSummaryData({ multiScoreInfoObj });
      });
      if (scoreSummaryData?.length) {
        reducedScoreSummaryData = scoreSummaryData.reduce((previous, current) => {
          return {
            mastering: previous.mastering + current.mastering,
            meeting: previous.meeting + current.meeting,
            approaching: previous.approaching + current.approaching,
            developing: previous.developing + current.developing,
            notEnoughData: previous.notEnoughData + current.notEnoughData
          };
        });
      } else {
        reducedScoreSummaryData = {
          mastering: 0,
          meeting: 0,
          approaching: 0,
          developing: 0,
          notEnoughData: 1
        };
      }
    }

    const ALLOWED = this.ALLOWED_CUT_SCORE();

    return (
      Object.keys(REPORT_COLOR_KEY).map((cutScoreKey) => {
        const cutScore = REPORT_COLOR_KEY[cutScoreKey];
        const camelCaseCutScore = _.camelCase(cutScore);
        const camelCaseReportTypeCutScore = _.camelCase(`${reportContextType}-${activeReportType}-${cutScore}`);
        const count = reducedScoreSummaryData && reducedScoreSummaryData[camelCaseCutScore];
        return ALLOWED[activeReportType.toUpperCase()][cutScoreKey] && {
          color: `${reportInfoClassNames} ${cutScore}`,
          count: typeof count === 'number' ? count : undefined,
          id: cutScore,
          label: `${camelCaseReportTypeCutScore}Label`,
          subLabel: `${camelCaseReportTypeCutScore}SubLabel`
        };
      }).filter((cutScoreKey) => cutScoreKey) // only return allowed keys
    );
  }

  static getEmptyStudentScoreInfo = () => {
    return {
      averageScore: null,
      averageViewedTime: null,
      items: null,
      maxScore: null,
      remaining: null,
      reportingCategoryWeightedScore: null,
      submittedScore: null,
      taken: null,
      weightedScore: null,
      viewedTime: null,
      engagement: null,
      timeOnCorrect: null,
      timeOnIncorrect: null,
    };
  }

  /**
   * @param {{
   *   multiScoreInfoObj: import('./ReportJsonHelperService').ReportScoreInfo['elementId']
   * }} props
   */
  static getScoreSummaryData = ({ multiScoreInfoObj } = {}) => {
    const scoreSummaryBarData = {
      mastering: 0,
      meeting: 0,
      approaching: 0,
      developing: 0,
      notEnoughData: 0
    };
    if (multiScoreInfoObj) {
      const { activeReportScorePropName } = reportIdentityManager;
      const scoreInfoObjIds = Object.keys(multiScoreInfoObj);

      // loop through each data column of a given row; count each category
      // e.g. get counts for 'mastering', 'meeting', 'approaching', 'developing', 'notEnoughData'
      for (const key of scoreInfoObjIds) {
        const scoreInfoObj = multiScoreInfoObj[key];

        const cell = { value: scoreInfoObj[activeReportScorePropName] };
        const scoreCellClassName = this.getReportScoreCellClassName(cell);
        scoreSummaryBarData[_.camelCase(scoreCellClassName)]++;
      }

      if (!reportIdentityManager.isTableSummaryReport || scoreSummaryBarData.notEnoughData) {
        return { ...scoreSummaryBarData };
      } else {
        const childFaculties = ReportJsonHelperService.REPORT_CHILD_FACULTIES();
        // Given a row, if total of all `childFaculties` exceeds the count of cells having data, we are missing data for at least 1 cell.
        // If we are missing data for a given row, we want to visualize `notEnoughData` as a gray block within the summary bar.
        // ---
        // note: `notEnoughData` will not exceed 1 when data exists for this case.
        // This is to prevent the notEnoughData visual spread from getting too large.
        // (i.e. we want the visual spread of populated data ('mastering', 'meeting', etc) to take precedence).
        const adjustedNotEnoughData = childFaculties?.length > scoreInfoObjIds?.length ? 1 : 0;

        return { ...scoreSummaryBarData, notEnoughData: adjustedNotEnoughData };
      }
    } else {
      return { ...scoreSummaryBarData, notEnoughData: 1 };
    }
  }

  /** @param {import('react-table').Cell} cell */
  static getReportScoreCellClassName = (cell) => {
    const { selectedReportCmapObj } = reportStandardsManager;
    const rangeKeyName = selectedReportCmapObj?.cutScore || '';

    let scoreCellClassName;
    if (!reportContextManager.isContextEngagementReport) {
      const { activeReportScorePropName } = reportIdentityManager;

      let score;
      if (typeof cell.value === 'number') {
        score = Math.round(cell.value);
      } else if (cell.value && typeof cell.value[activeReportScorePropName] === 'number') {
        score = Math.round(cell.value[activeReportScorePropName]);
      }
      const { isCourseReport, isStandardsReport } = reportIdentityManager;

      let MASTERING, MEETING, APPROACHING, DEVELOPING, NOT_ENOUGH_DATA;
      if (isCourseReport) {
        MASTERING = this.COURSE_CUT_SCORE.MASTERING;
        MEETING = this.COURSE_CUT_SCORE.MEETING;
        APPROACHING = this.COURSE_CUT_SCORE.APPROACHING;
        DEVELOPING = this.COURSE_CUT_SCORE.DEVELOPING;
        NOT_ENOUGH_DATA = this.COURSE_CUT_SCORE.NOT_ENOUGH_DATA;
      } else if (isStandardsReport) {
        MASTERING = this.STANDARDS_CUT_SCORE.MASTERING;
        MEETING = this.STANDARDS_CUT_SCORE.MEETING;
        APPROACHING = this.STANDARDS_CUT_SCORE.APPROACHING;
        DEVELOPING = this.STANDARDS_CUT_SCORE.DEVELOPING;
        NOT_ENOUGH_DATA = this.STANDARDS_CUT_SCORE.NOT_ENOUGH_DATA;
      }

      if (NOT_ENOUGH_DATA(score, rangeKeyName)) {
        scoreCellClassName = REPORT_COLOR_KEY.NOT_ENOUGH_DATA;
      } else if (MASTERING(score, rangeKeyName)) {
        scoreCellClassName = REPORT_COLOR_KEY.MASTERING;
      } else if (MEETING(score, rangeKeyName)) {
        scoreCellClassName = REPORT_COLOR_KEY.MEETING;
      } else if (APPROACHING(score, rangeKeyName)) {
        scoreCellClassName = REPORT_COLOR_KEY.APPROACHING;
      } else if (DEVELOPING(score, rangeKeyName)) {
        scoreCellClassName = REPORT_COLOR_KEY.DEVELOPING;
      } else {
        scoreCellClassName = REPORT_COLOR_KEY.NOT_ENOUGH_DATA;
      }
    }

    let scoreCellUsageClassName;
    if (reportUsageManager.useEnhancedUsageReports) {
      scoreCellUsageClassName = ReportUsageService.getScoreCellUsageClassName(cell);
    }

    const { progressFilters, showEngagementNumbers, showProgressNumbers, engagementFilters } = reportContextManager;

    let scoreCellProgressClassNames;
    if (reportContextManager.isContextProgressReport) {
      scoreCellProgressClassNames = this.getScoreCellProgressClassName(cell, rangeKeyName, progressFilters);

      if (!showProgressNumbers) {
        scoreCellProgressClassNames += ' hide-cell-value';
      }
    }

    let scoreCellEngagementClassNames;
    if (reportContextManager.isContextEngagementReport) {
      const { highEngagement, lowEngagement } = engagementFilters;

      const isFacultyAverageCell = cell?.column?.id?.toLowerCase?.()?.includes?.('average');

      const engagementCellScore = isFacultyAverageCell ? cell?.value :
        cell?.value?.['engagementData']?.['engagementScore'];

      scoreCellEngagementClassNames = classNames({
        'hide-cell-value': !showEngagementNumbers,
        'hide-high-engagement': !highEngagement,
        'hide-low-engagement': !lowEngagement,
        'high-engagement': engagementCellScore >= MIN_HIGH_ENGAGEMENT,
        'low-engagement': engagementCellScore < MIN_HIGH_ENGAGEMENT,
        'not-enough-data': typeof engagementCellScore !== 'number'
      });
    }

    const concatenatedClassNames = classNames(
      scoreCellClassName, scoreCellUsageClassName, scoreCellProgressClassNames, scoreCellEngagementClassNames
    );
    return concatenatedClassNames?.trim?.();
  }

  static getReportScoreCellKeyName = (cell) => {
    const { selectedReportCmapObj } = reportStandardsManager;
    const rangeKeyName = selectedReportCmapObj?.cutScore || '';

    let scoreCellKeyName;
    const { activeReportScorePropName } = reportIdentityManager;

    let score;
    if (typeof cell.value === 'number') {
      score = Math.round(cell.value);
    } else if (cell.value && typeof cell.value[activeReportScorePropName] === 'number') {
      score = Math.round(cell.value[activeReportScorePropName]);
    }
    const { isCourseReport, isStandardsReport } = reportIdentityManager;

    let MASTERING, MEETING, APPROACHING, DEVELOPING, NOT_ENOUGH_DATA;
    if (isCourseReport) {
      MASTERING = this.COURSE_CUT_SCORE.MASTERING;
      MEETING = this.COURSE_CUT_SCORE.MEETING;
      APPROACHING = this.COURSE_CUT_SCORE.APPROACHING;
      DEVELOPING = this.COURSE_CUT_SCORE.DEVELOPING;
      NOT_ENOUGH_DATA = this.COURSE_CUT_SCORE.NOT_ENOUGH_DATA;
    } else if (isStandardsReport) {
      MASTERING = this.STANDARDS_CUT_SCORE.MASTERING;
      MEETING = this.STANDARDS_CUT_SCORE.MEETING;
      APPROACHING = this.STANDARDS_CUT_SCORE.APPROACHING;
      DEVELOPING = this.STANDARDS_CUT_SCORE.DEVELOPING;
      NOT_ENOUGH_DATA = this.STANDARDS_CUT_SCORE.NOT_ENOUGH_DATA;
    }

    if (NOT_ENOUGH_DATA(score, rangeKeyName)) {
      scoreCellKeyName = REPORT_COLOR_KEY.NOT_ENOUGH_DATA;
    } else if (MASTERING(score, rangeKeyName)) {
      scoreCellKeyName = REPORT_COLOR_KEY.MASTERING;
    } else if (MEETING(score, rangeKeyName)) {
      scoreCellKeyName = REPORT_COLOR_KEY.MEETING;
    } else if (APPROACHING(score, rangeKeyName)) {
      scoreCellKeyName = REPORT_COLOR_KEY.APPROACHING;
    } else if (DEVELOPING(score, rangeKeyName)) {
      scoreCellKeyName = REPORT_COLOR_KEY.DEVELOPING;
    } else {
      scoreCellKeyName = REPORT_COLOR_KEY.NOT_ENOUGH_DATA;
    }

    return scoreCellKeyName?.trim?.();
  }

  /** **Default:** can be overridden by using `ReportScoreServiceOverride.COURSE_CUT_SCORE` for a given satellite */
  static COURSE_CUT_SCORE = {
    MASTERING: (_score, _rangeKeyName) => {
      // currently unused
      return false;
    },
    MEETING: (score, _rangeKeyName) => {
      const { reportContextType } = reportContextManager;
      const { LIKERT } = REPORT_CONTEXT_TYPE;
      switch (reportContextType) {
        case LIKERT:
          return score >= 4;
        default:
          return score >= 80;
      }
    },
    APPROACHING: (score, _rangeKeyName) => {
      const { reportContextType } = reportContextManager;
      const { LIKERT } = REPORT_CONTEXT_TYPE;
      switch (reportContextType) {
        case LIKERT:
          return score < 4 && score >= 1;
        default:
          return score < 80 && score >= 60;
      }
    },
    DEVELOPING: (score, _rangeKeyName) => {
      const { reportContextType } = reportContextManager;
      const { LIKERT } = REPORT_CONTEXT_TYPE;
      switch (reportContextType) {
        case LIKERT:
          return score < 1 && score >= 0;
        default:
          return score < 60 && score >= 0;
      }
    },
    NOT_ENOUGH_DATA: (score, _rangeKeyName) => {
      return typeof score !== 'number';
    }
  };

  /** **Default:** can be overridden by using `ReportScoreServiceOverride.STANDARDS_CUT_SCORE` for a given satellite */
  static STANDARDS_CUT_SCORE = {
    MASTERING: (_score, _rangeKeyName) => {
      // currently unused
      return false;
    },
    MEETING: (score, _rangeKeyName) => {
      return score >= 80;
    },
    APPROACHING: (score, _rangeKeyName) => {
      return score < 80 && score >= 60;
    },
    DEVELOPING: (score, _rangeKeyName) => {
      return score < 60 && score >= 0;
    },
    NOT_ENOUGH_DATA: (score, _rangeKeyName) => {
      return typeof score !== 'number';
    }
  };

  /** **Default:** can be overridden by using `ReportScoreServiceOverride.ALLOWED_CUT_SCORE` for a given satellite */
  static ALLOWED_CUT_SCORE = () => {
    return {
      COURSE: {
        MASTERING: false,
        MEETING: true,
        APPROACHING: true,
        DEVELOPING: true,
        NOT_ENOUGH_DATA: true
      },
      STANDARDS: {
        MASTERING: false,
        MEETING: true,
        APPROACHING: true,
        DEVELOPING: true,
        NOT_ENOUGH_DATA: true
      }
    };
  };
}
