import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Container, Dropdown, Loader } from 'semantic-ui-react';

import '../../css/ContentAssignmentModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { ASSIGNMENT_STATUS } from '../../managers/AssignmentManager';
import { CONTENT_MODE } from '../../managers/ContentManager';
import { DIALOG_NAMES } from '../../managers/DialogManager';

import GradebookNavigationService from '../../services/gradebook/GradebookNavigationService';
import ImageService from '../../services/ImageService';

import Modal from '../Modal';

/**
 * aka **Times Assigned Modal**
 */
export default @inject(
  'assignmentManager', 'classroomManager',
  'courseManager', 'dialogManager',
  'gradebookManager', 'navigationManager',
  'userManager')
@observer
class ContentAssignmentModal extends Component {
  state = {
    contentName: '',
    fullCourseElement: null
  }

  constructor(props) {
    super(props);
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.AssignmentCard = SatCoreComponent('AssignmentCard');
    this.AggregateAssignmentCard = SatCoreComponent('AggregateAssignmentCard');
  }

  async componentDidMount() {
    await this.fetchData();
  }

  closeContentAssignmentModal = async ({ navToAggregateGradebook = false } = {}) => {
    const {
      closeContentAssignmentModal,
      navigationManager,
      skipClearBreadcrumbsOnClose,
      t
    } = this.props;

    this.setState({
      contentName: '',
      fullCourseElement: null
    });
    closeContentAssignmentModal();
    if (!skipClearBreadcrumbsOnClose) {
      navigationManager.clearAllPaths();
    }
    if (navToAggregateGradebook) {
      const allAssignmentsLabel = t('allAssignments', 'All Assignments');
      await GradebookNavigationService.navToAggregateGradebookSummaryFromExternalModal({
        ...this.props, allAssignmentsLabel
      });
    }
  }

  handleOpenInfo = (cardData, contentItemImageUrl) => {
    const { dialogManager } = this.props;

    dialogManager.setOpenDialog(DIALOG_NAMES.INFO, {
      contentItemDescription: cardData.resourceContentItemDescription,
      contentItemImageUrl,
      infoName: cardData.courseResourceElementTitle || cardData.resourceContentItemName,
      resourceInfo: cardData.courseResourceElementResourceInformation,
      studentInstruction: cardData.courseResourceElementResourceInstruction,
    }, () => dialogManager.closeDialog(DIALOG_NAMES.INFO));
  }

  handlePresent = async (params) => {
    const { assignment, contentMode = CONTENT_MODE.PREVIEW, pdfFormatSelected = null, publisherModeId = null } = params;
    const { dialogManager, handlePresent, setPreviewModeId, userManager } = this.props;
    const { modeOverrideAllowed } = assignment;

    if (userManager.isTeacher && setPreviewModeId && publisherModeId === null && modeOverrideAllowed) {
      // If mode override is allowed, open a modal for the teacher to select a mode for preview
      dialogManager.setOpenDialog(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL, {
        close: this.closeSelectPreviewThemeModal,
        contentMode,
        courseElement: assignment,
        open: true,
        pdfFormatSelected,
        setPreviewModeId
      });
    } else if (userManager.isTeacher && publisherModeId !== null && modeOverrideAllowed) {
      return handlePresent({ assignment, contentMode, pdfFormatSelected, publisherModeId });
    } else {
      // eslint-disable-next-line react/destructuring-assignment
      return handlePresent({ assignment, contentMode, pdfFormatSelected });
    }
  }

  handleView = async (params) => {
    const { cardData, contentMode = CONTENT_MODE.PREVIEW, pdfFormatSelected = null, publisherModeId = null } = params;
    const { dialogManager, handleView, setPreviewModeId, userManager } = this.props;
    const { modeOverrideAllowed } = cardData;

    const alternateModeId = publisherModeId || cardData.alternateModeIdOverride;
    if (userManager.isTeacher && setPreviewModeId && publisherModeId === null && modeOverrideAllowed) {
      // If mode override is allowed, open a modal for the teacher to select a mode for preview
      dialogManager.setOpenDialog(DIALOG_NAMES.SELECT_PREVIEW_MODE_MODAL, {
        close: this.closeSelectPreviewThemeModal,
        contentMode,
        courseElement: cardData,
        open: true,
        pdfFormatSelected,
        setPreviewModeId
      });
    } else if (userManager.isTeacher && alternateModeId !== null) {
      return handleView({ cardData, contentMode, pdfFormatSelected, alternateModeId });
    } else {
      // eslint-disable-next-line react/destructuring-assignment
      return handleView({ cardData, contentMode, pdfFormatSelected });
    }
  }

  handleGradebook = (data) => {
    const { handleGradebook } = this.props;
    handleGradebook(data);
    this.closeContentAssignmentModal();
  }

  renderAssignmentCards = () => {
    const {
      assignmentElement, assignmentManager, currentClassroomId,
      fetchCourseActivityInformation, handleAnswerKey,
      handleEditAssignment, handleGradebook
    } = this.props;
    const { AssignmentCard } = this;
    const assignments = assignmentManager.assignmentArray;

    const components = [];

    assignments.map((assignment) => {
      const contentImageUrl = ImageService.getImageUrl(assignment);
      components.push(<AssignmentCard
        key={`AAC_${assignment.id}`}
        assignment={assignment}
        contentImageUrl={contentImageUrl}
        dialogIncludesAssignmentInfo={false}
        fetchCourseActivityInformation={(assignment) => fetchCourseActivityInformation(assignment, assignmentElement, contentImageUrl)}
        handleAnswerKey={handleAnswerKey}
        handleEditAssignment={(assignment, contentImageUrl) => handleEditAssignment(assignment, contentImageUrl, currentClassroomId, assignmentElement, this.deleteAssignment)}
        handleGradebook={handleGradebook}
        handlePresent={() => this.handlePresent({ assignment })} />
      );
      return true;
    });
    return components;
  }

  renderAggregateAssignmentCards = () => {
    const { assignmentManager, fetchCourseActivityInformation, handleView } = this.props;
    const { AggregateAssignmentCard } = this;
    const items = [];

    assignmentManager.aggregateAssignmentArray.map((cardData) => {
      const imageData = {
        contentItemId: cardData.resourceContentItemId,
        elementContentItemImageUrl: cardData.courseContentItemImageUrl,
        imageUrl: cardData.resourceContentItemImageUrl
      };
      const contentImageUrl = ImageService.getImageUrl(imageData);
      const entityId = cardData.classroomId + cardData.courseResourceElementId;
      items.push(<AggregateAssignmentCard
        key={`AAC_${entityId}`}
        cardData={cardData}
        contentImageUrl={contentImageUrl}
        dialogIncludesAssignmentInfo={true}
        fetchCourseActivityInformation={fetchCourseActivityInformation}
        // handleAnswerKey={this.handleAnswerKey}
        // handleEditAssignment={this.handleEditAssignment}
        handleGradebook={this.handleGradebook}
        handleOpenInfo={this.handleOpenInfo}
        handlePresent={handleView} />
      );
      return true;
    });
    return items;
  }

  fetchData = async (filterCourseContentItemId = null) => {
    const {
      assignmentManager, courseManager,
      assignmentElement,
      standardId,
      cmapElementId,
      contentName,
      courseContentItemId,
      courseResourceElementId,
      parentElementId,
      elementId,
      currentClassroomId,
      fetchAssignmentsToGrade,
      studentId,
      takenActivityIds,
      useAggregateCards = false
    } = this.props;
    if (useAggregateCards) {
      // We will use either status or todays date range to run the query.
      const status = fetchAssignmentsToGrade ? ASSIGNMENT_STATUS.CLOSED : null;

      // if no status, create a date range from start of today to end of today
      let startDate = null;
      let endDate = null;
      if (status === null) {
        startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(startDate);
        endDate.setHours(23, 59, 59, 999);
      }

      // fetch the assignments
      await assignmentManager.fetchTeacherAggregateAssignments(currentClassroomId, null, startDate, endDate, status,
        null, '', null, null, 0, true, null, null, null
      ).then(() => {
        this.setState({
          contentName
        });
      });
    } else {
      // If a standard id is passed, fetch by standard.
      if (standardId) {
        // there will only be a student id prop for the individual student standards report
        assignmentManager.fetchReportableAssignmentsByStandard(
          currentClassroomId, standardId, studentId, filterCourseContentItemId, takenActivityIds
        ).then(() => {
          this.setState({
            contentName
          });
        });
      } else if (cmapElementId) {
        // there will only be a student id prop for the individual student standards report
        assignmentManager.fetchReportableAssignmentsByCmapElement(
          currentClassroomId, cmapElementId, studentId, filterCourseContentItemId, takenActivityIds
        ).then(() => {
          this.setState({
            contentName
          });
        });
      } else if (fetchAssignmentsToGrade) {
        // If the fetch for grade flag is passed, call for that.
        assignmentManager.fetchAssignmentsToGrade(
          currentClassroomId, 0, true, false
        ).then(() => {
          this.setState({
            contentName
          });
        });
      } else {
        // else just get assignments
        let courseResourceElementIdTemp = courseResourceElementId;
        if (courseResourceElementIdTemp === undefined) {
          courseResourceElementIdTemp = parentElementId;
        }
        // if the element wasn't passed, fetch it from params.
        let fullCourseElement = null;
        if (!assignmentElement) {
          const fullCourseData = await courseManager.fetchCourseData(
            courseContentItemId, currentClassroomId, courseResourceElementIdTemp, true, false);
          if (fullCourseData) {
            for (let i = 0; i < fullCourseData.length; i++) {
              if (fullCourseData[i].elementId === elementId) {
                fullCourseElement = fullCourseData[i];
                break;
              }
            }
            fullCourseElement.courseContentItemId = courseContentItemId;
          }
        } else {
          fullCourseElement = assignmentElement;
        }

        const nullCourseResourceElementId = null;
        const resourceContentItemId = fullCourseElement ? fullCourseElement.entityId : null;
        const page = 0;
        const dialogOpen = false;

        assignmentManager.fetchContentAssignments(
          courseContentItemId, nullCourseResourceElementId,
          currentClassroomId, resourceContentItemId,
          page, dialogOpen
        ).then(() => {
          this.setState({
            contentName: fullCourseElement?.name,
            fullCourseElement
          });
        });
      }
    }
  }

  deleteAssignment = async () => {
    const { assignmentManager: { hasMore } } = this.props;
    hasMore && await this.fetchData();
  }

  filterCourseContent = async (e, { value }) => {
    this.fetchData(value);
  };

  render() {
    const {
      assignmentManager, classeslist, contentAssignmentsOpen, fetchAssignmentsToGrade,
      infoPopupText, labelInfo, modalBannerTitle, possiblyShowAllAssignmentsButton, showCourseDropdown,
      t, useAggregateCards, userManager
    } = this.props;
    const { assignmentCourseOptions } = assignmentManager;

    const { contentName } = this.state;

    let label = 'Assignment Information: ';
    if (labelInfo !== undefined && labelInfo !== '' && labelInfo !== null) {
      label = labelInfo;
    } else if (classeslist) {
      if (fetchAssignmentsToGrade) {
        label = t('readyToGrade');
      } else {
        label = t('dueToday');
      }
    }

    const { ModalBanner } = this;

    let assignments = [];
    let renderAssignments = null;
    if (useAggregateCards) {
      assignments = assignmentManager.aggregateAssignmentArray;
      renderAssignments = this.renderAggregateAssignmentCards;
    } else {
      assignments = assignmentManager.assignmentArray;
      renderAssignments = this.renderAssignmentCards;
    }

    const showAllAssignmentsButton = possiblyShowAllAssignmentsButton && assignments && assignments.length > 1;

    return (
      <>
        <Modal
          className='ContentAssignmentModal'
          closeOnDimmerClick={false}
          closeOnEscape={true}
          onClose={this.closeContentAssignmentModal}
          open={contentAssignmentsOpen}
          size='large'>

          <ModalBanner
            infoPopupText={infoPopupText}
            label={label}
            onClose={this.closeContentAssignmentModal}
            title={modalBannerTitle || contentName} />
          <Modal.Content>
            {showCourseDropdown && assignmentCourseOptions && assignmentCourseOptions.length > 1 && (
              <Container className='filter-wrapper'>
                <Dropdown
                  defaultValue={0}
                  fluid
                  onChange={this.filterCourseContent}
                  options={assignmentCourseOptions}
                  placeholder='All Courses' />
              </Container>
            )}
            {showAllAssignmentsButton && userManager.isTeacher && (
              <div className='modal-top-buttons'>
                <div className='btn-all-assignments-wrapper'>
                  <Button
                    className='btn-all-assignments'
                    onClick={(_event, _data) => this.closeContentAssignmentModal({
                      navToAggregateGradebook: true
                    })}
                    primary>
                    {t('allAssignments')}
                  </Button>
                </div>
              </div>
            )}
            <Container className='scroller-wrapper' fluid>
              {(assignments && assignments.length) ? (
                <div className='ui items parent'>
                  {renderAssignments()}
                </div>
              )
                :
                assignmentManager.loaded ? (
                  <div className='ui items parent'>
                    <span className='not-found'>No Activities Found</span>
                  </div>
                )
                  : (
                    <div className='ui items parent'>
                      <Loader key={0} active />
                    </div>
                  )}
            </Container>
          </Modal.Content>

          <Modal.Actions>
            <Button basic className='cancelButton' onClick={this.closeContentAssignmentModal} primary>Close</Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

SatCoreRegister('ContentAssignmentModal', ContentAssignmentModal);
