import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import {
  Auth, SatCoreComponent, accommodationsManager, appManager, assignmentManager,
  classroomManager, contentManager, courseManager, dialogManager, dynamicSatelliteManager,
  gradebookManager, navigationManager, studentProgressManager, userManager
} from 'sat-core';
import { API_ROOT, LESSON_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE } from './api-config';

import 'semantic-ui-less/semantic.less';
import './index.less';
import * as serviceWorker from './serviceWorker';

/* Put component override imports here */
import './services/AccommodationsServiceOverride';
import './views/LoginOverride';
import './views/MainViewOverride';
import './views/ProgressViewOverride';
import './components/CourseListBannerOverride';
import './components/StudentAssignmentCardOverride';

// NOTE: handled via sat-core fetchDynamicSatelliteByDomain
// import demoTranslations from './demoTranslations';
// i18nAddSatellite('demo', demoTranslations);

const App = SatCoreComponent('App');

const logo = undefined; // NOTE: handled via sat-core fetchDynamicSatelliteByDomain

Auth.init(API_ROOT, PUBLISHER_ROOT, PUBLISHER_SATELLITE_CODE, logo, LESSON_ROOT);

accommodationsManager.setIncludeClassroomAccommodations(true);
appManager.init({ classWorkspaceFeature: true });
assignmentManager.setEnableCourseSearch(true);
assignmentManager.setLateSubmissionOptionAllowed(true);
classroomManager.setEnabledStudentsOnly(false);
classroomManager.setIncludeGroups(true);
dynamicSatelliteManager.setIsDynamicSatellite(true);
gradebookManager.setAllowAssignmentLink(true);
gradebookManager.setAllowGradebookStandards(true);
navigationManager.setAllowCarouselAdaptiveHeight(true);
studentProgressManager.setAllowStudentProgressNavTab(true);
userManager.setAllowStudentUsernamesAsEmail(true);
userManager.setAllowViewSatelliteProductTab(true);
// NOTE: handled via sat-core fetchDynamicSatelliteByDomain
// userManager.setClientPrefix('DEMO');

ReactDOM.render(
  <Provider
    assignmentManager={assignmentManager}
    classroomManager={classroomManager}
    contentManager={contentManager}
    courseManager={courseManager}
    dialogManager={dialogManager}
    gradeBookManager={gradebookManager}
    navigationManager={navigationManager}
    userManager={userManager}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
