/* eslint-disable sort-keys */
export const MAX_REPORT_FACULTY_SCORE_CELLS_PER_PAGE = 14;

export const MIN_HIGH_ENGAGEMENT = 4.0;

export const REPORT_ASSIGNMENT_TYPE = {
  ALL: 'allAssignments',
  ASSESSMENT: 'assessment',
  INSTRUCTION: 'instruction',
  PRACTICE: 'practice'
};

export const REPORT_ATTRIBUTE_FILTER_TYPE = {
  ALL: 'allStandards',
  REPORTING_CATEGORY: 'reportingCategory',
  CLUSTERS: 'clusters',
  READINESS_AND_SUPPORTING: 'readinessAndSupporting'
};

export const REPORT_COLOR_KEY = {
  MASTERING: 'mastering',
  MEETING: 'meeting',
  APPROACHING: 'approaching',
  DEVELOPING: 'developing',
  NOT_ENOUGH_DATA: 'not-enough-data'
};

export const REPORT_CONTENT_TYPE = {
  ALL: 'allContent'
  // TODO remove, defunct // PREREQUISITE: 'prerequisite',
  // TODO remove, defunct // ONLEVEL: 'onlevel'
};

export const REPORT_CONTEXT_TYPE = {
  PERFORMANCE: 'performance',
  LIKERT: 'likert',
  USAGE: 'usage',
  PROGRESS: 'growth', // called growth because progress is a troublesome classname for the pagination control
  ITEM_LEVEL: 'itemLevel',
  RESOURCE_LEVEL: 'resourceLevel',
  ENGAGEMENT: 'engagement',
};

export const REPORT_FACULTY_TABLE_CELL_LABEL_KEY = {
  DISTRICT: 'schoolName',
  SCHOOL: 'classroomName',
  CLASSROOM: 'studentName'
};

export const REPORT_FACULTY_TYPE = {
  DISTRICT: 'district',
  SCHOOL: 'school',
  CLASSROOM: 'classroom'
};

export const REPORT_FAILURE_BEHAVIOR_TYPE = {
  FALLBACK: 'fallback',
  FALLBACK_AND_FORCE_NO_DATA_STATE: 'fallbackAndForceNoDataState',
  NORMAL: 'normal',
  RETRY_WITH_DIFFERENT_COURSE_OR_CMAP: 'retryWithDifferentCmap',
  RETRY_WITH_PARSED_REPORT_ID_AND_FORCE_NO_DATA_STATE: 'retryWithNormalReportIdAndForceNoDataState'
};

export const REPORT_LESSON_FILTER_TYPE = {
  ALL: 'allLessons',
  CLICKBOOK: 'clickbook',
  EXPERTRACK: 'expertrack'
};

export const REPORT_POPUP_OFFSET = {
  MODIFIED_CLASSROOM_DETAIL_CELL: [-25, 7],
  MODIFIED_CLASSROOM_INDIVIDUAL_CELL: [-45, 7]
};

export const REPORT_TABLE_TYPE = {
  SUMMARY: 'summary',
  DETAIL: 'detail',
  INDIVIDUAL: 'individual'
};

export const REPORT_TYPE = {
  COURSE: 'course',
  STANDARDS: 'standards'
};

export const REPORT_URL_KEY = {
  '/reports': '/reports',
  '/reports/course-district-summary': '/reports/course-district-summary',
  '/reports/course-district-detail': '/reports/course-district-detail',
  '/reports/course-school-summary': '/reports/course-school-summary',
  '/reports/course-school-detail': '/reports/course-school-detail',
  '/reports/course-classroom-summary': '/reports/course-classroom-summary',
  '/reports/course-classroom-detail': '/reports/course-classroom-detail',
  '/reports/course-classroom-individual': '/reports/course-classroom-individual',
  '/reports/standards-district-summary': '/reports/standards-district-summary',
  '/reports/standards-district-detail': '/reports/standards-district-detail',
  '/reports/standards-school-summary': '/reports/standards-school-summary',
  '/reports/standards-school-detail': '/reports/standards-school-detail',
  '/reports/standards-classroom-summary': '/reports/standards-classroom-summary',
  '/reports/standards-classroom-detail': '/reports/standards-classroom-detail',
  '/reports/standards-classroom-detail-students': '/reports/standards-classroom-detail-students',
  '/reports/standards-classroom-individual': '/reports/standards-classroom-individual'
};

export const REPORT_USAGE_COLOR_KEYS = [
  {
    color: '#809dc8', // TODO refactor as className
    descriptionBody: 'lessTimeHighPerformanceDescriptionBody',
    descriptionHeader: 'lessTimeHighPerformanceDescriptionHeader',
    label: 'lessTimeHighPerformance'
  },
  {
    color: '#ccd8e9', // TODO refactor as className
    descriptionBody: 'moreTimeHighPerformanceDescriptionBody',
    descriptionHeader: 'moreTimeHighPerformanceDescriptionHeader',
    label: 'moreTimeHighPerformance'
  },
  {
    color: '#d7858d', // TODO refactor as className
    descriptionBody: 'lessTimeLowPerformanceDescriptionBody',
    descriptionHeader: 'lessTimeLowPerformanceDescriptionHeader',
    label: 'lessTimeLowPerformance'
  },
  {
    color: '#efced1', // TODO refactor as className
    descriptionBody: 'moreTimeLowPerformanceDescriptionBody',
    descriptionHeader: 'moreTimeLowPerformanceDescriptionHeader',
    label: 'moreTimeLowPerformance'
  }
];

export const REPORT_USAGE_MODES = {
  BACKGROUND_COLORS_ONLY: 'backgroundColorsOnly',
  MINUTES_AND_COLORS: 'minutesAndColors',
  MINUTES_ONLY: 'minutesOnly'
};
