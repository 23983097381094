import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { Button, Checkbox, Container, Form, Message } from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/ExportGradesModal.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { ASSIGNMENT_DATA_EXPORT_TYPE } from '../../managers/AssignmentManager';

import GradebookExportService from '../../services/gradebook/GradebookExportService';
import PopupService from '../../services/PopupService';

export default
@inject('exportManager', 'gradebookManager', 'reportIdentityManager', 'userManager')
class ExportGradesModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.ModalBanner = SatCoreComponent('ModalBanner');
    this.SCRadio = SatCoreComponent('SCRadio');
  }

  getInitialState() {
    const { exportManager, isExportForReportTableRowResource, reportIdentityManager, userManager } = this.props;

    const { isFacultyDistrictReport } = reportIdentityManager;

    // Usernames may or may not be an email. If it is, we want to auto populate it.
    const { username } = userManager;
    let email = '';
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username)) {
      email = username;
    }

    // for setting initial export radio option
    let exportReportId = exportManager.allowExportFullGradebookSummary ?
      ASSIGNMENT_DATA_EXPORT_TYPE.EXPORT_AGGREGATE_INSTANCES :
      ASSIGNMENT_DATA_EXPORT_TYPE.EXPORT_AGGREGATE_INSTANCES_MINI;
    if (isExportForReportTableRowResource && isFacultyDistrictReport) {
      exportReportId = ASSIGNMENT_DATA_EXPORT_TYPE.EXPORT_AGGREGATE_ITEM_ANALYSIS;
    }

    return {
      email,
      error: null,
      exportReportId,
      shouldHideUnscorableItems: false,
      valid: email !== ''
    };
  }

  requestExport = async () => {
    const {
      assignment,
      assignmentId,
      closeExportModal,
      isAssignmentsFromRemovedCourses,
      isExportForReportTableRowResource,
      reportIdentityManager
    } = this.props;

    const { isFacultyDistrictReport } = reportIdentityManager;

    const { props, state } = this;
    const {
      email, shouldHideUnscorableItems, valid
    } = state;

    if (valid) {
      let hideUnscorable;
      const exportType = ASSIGNMENT_DATA_EXPORT_TYPE;
      const {
        EXPORT_AGGREGATE_INSTANCES,
        EXPORT_AGGREGATE_INSTANCES_FOR_REMOVED_COURSES,
        EXPORT_AGGREGATE_ITEM_ANALYSIS,
        EXPORT_AGGREGATE_ITEM_ANALYSIS_FOR_REMOVED_COURSES,
        EXPORT_AGGREGATE_MINI_ANALYSIS,
        EXPORT_DIST_AGG_ITEM_ANALYSIS,
        EXPORT_SCH_AGG_INSTANCES,
        EXPORT_SCH_AGG_ITEM_ANALYSIS
      } = exportType;

      if (state.exportReportId === EXPORT_AGGREGATE_ITEM_ANALYSIS || state.exportReportId === EXPORT_AGGREGATE_MINI_ANALYSIS) {
        hideUnscorable = shouldHideUnscorableItems;
      }

      let exportReportId;
      if (isExportForReportTableRowResource) {
        if (state.exportReportId === EXPORT_AGGREGATE_INSTANCES) {
          exportReportId = EXPORT_SCH_AGG_INSTANCES;
        } else if (state.exportReportId === EXPORT_AGGREGATE_ITEM_ANALYSIS) {
          exportReportId = isFacultyDistrictReport ? EXPORT_DIST_AGG_ITEM_ANALYSIS : EXPORT_SCH_AGG_ITEM_ANALYSIS;
        }
      } else if (isAssignmentsFromRemovedCourses) {
        if (state.exportReportId === EXPORT_AGGREGATE_INSTANCES) {
          exportReportId = EXPORT_AGGREGATE_INSTANCES_FOR_REMOVED_COURSES;
        } else if (state.exportReportId === EXPORT_AGGREGATE_ITEM_ANALYSIS) {
          exportReportId = EXPORT_AGGREGATE_ITEM_ANALYSIS_FOR_REMOVED_COURSES;
        }
      } else {
        exportReportId = state.exportReportId;
      }

      await GradebookExportService.requestGradebookExport(
        exportReportId, email, assignment, assignmentId, hideUnscorable, {
          isAssignmentsFromRemovedCourses,
          isExportForReportTableRowResource,
          reportTableRow: isExportForReportTableRowResource ? props?.row : undefined
        }
      );
      closeExportModal();
    } else {
      this.setState({ error: 'Please enter a valid email.' });
    }
  }

  handleChangeExportReportId = async (event) => {
    const exportReportId = event.target.value;
    this.setState({ exportReportId });
  }

  handleEmailChange = (e) => {
    this.validate(e.target.value);
  }

  handleToggleHideUnscorableItems = async (_event, data) => {
    const shouldHideUnscorableItems = data.checked;
    this.setState({ shouldHideUnscorableItems });
  }

  validate = (email) => {
    const valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    this.setState({ valid, email });
  }

  clearError = () => {
    this.setState({ error: null });
  }

  renderRadioOption = (radioOptionToRender) => {
    const { SCRadio } = this;
    const exportType = ASSIGNMENT_DATA_EXPORT_TYPE;
    const { gradebookManager, isExportForReportTableRowResource, isFromGradebook, reportIdentityManager, t } = this.props;

    const { isFacultyDistrictReport } = reportIdentityManager;

    const { exportReportId } = this.state;

    let disabledRollover;

    const isGradebookSummaryExportRadioOption = radioOptionToRender?.includes?.(ASSIGNMENT_DATA_EXPORT_TYPE.EXPORT_AGGREGATE_INSTANCES);
    const isStandardsExportRadioOption = radioOptionToRender === ASSIGNMENT_DATA_EXPORT_TYPE.EXPORT_AGGREGATE_STANDARDS;

    if (isExportForReportTableRowResource) {
      if (isGradebookSummaryExportRadioOption && isFacultyDistrictReport) {
        // 'Summary Export' is currently not supported at the District Report level,
        // so do not render the 'Summary Export' radio option here
        return null;
      } else if (isStandardsExportRadioOption) {
        // 'Standards Export' is currently not supported for this use case,
        // so do not render the 'Standards Export' radio option here
        return null;
      }
    } else if (isStandardsExportRadioOption) {
      const hasStandardAlignments = isFromGradebook && gradebookManager.gradebookStandardsData?.hasAlignments;
      const hasStandardStudentData = isFromGradebook && gradebookManager.gradebookStandardsData?.hasStudentData;

      if (!hasStandardAlignments) {
        disabledRollover = t('noStandardsMessage');
      } else if (!hasStandardStudentData) {
        disabledRollover = t('noStandardsStudentDataMessage');
      }
    }

    return PopupService.renderPopup({
      content: disabledRollover,
      disabled: !disabledRollover, // do not launch popup content if rollover text is empty
      offset: [-9, 0],
      position: 'top left',
      trigger: (
        <SCRadio
          additionalClassNames={radioOptionToRender}
          checked={radioOptionToRender === exportReportId}
          disabled={!!disabledRollover}
          label={exportType.getLabel(radioOptionToRender)}
          name='exportGroup'
          onChange={this.handleChangeExportReportId}
          value={radioOptionToRender} />
      )
    });
  }

  render() {
    const {
      // row,
      assignment,
      closeExportModal,
      exportGradesOpen,
      exportManager,
      gradebookManager,
      isExportForReportTableRowResource,
      modalBannerLabel,
      t
    } = this.props;

    const {
      email, error, exportReportId, shouldHideUnscorableItems, valid
    } = this.state;

    const {
      allowExportFullGradebookSummary,
      allowExportMiniGradebookSummary,
      allowExportFullGradebookItemAnalysis,
      allowExportMiniGradebookItemAnalysis,
      allowExportFullGradebookStandards,
    } = exportManager;

    const { allowGradebookStandards } = gradebookManager;

    const exportType = ASSIGNMENT_DATA_EXPORT_TYPE;
    const {
      EXPORT_AGGREGATE_INSTANCES,
      EXPORT_AGGREGATE_INSTANCES_MINI,
      EXPORT_AGGREGATE_ITEM_ANALYSIS,
      EXPORT_AGGREGATE_MINI_ANALYSIS,
      EXPORT_AGGREGATE_STANDARDS
    } = exportType;

    // TODO ensure we are hiding 'item analysis' when applicable once BE piece of SIR-664 is implemented
    const entityTypeId = assignment?.contentItemEntityTypeId || assignment?.entityTypeId;
    // TODO SIR-681 // const entityTypeId = assignment?.contentItemEntityTypeId || assignment?.entityTypeId || row?.original?.elementType;

    const isAssessment = entityTypeId === 'assessment';
    const isLesson = entityTypeId === 'lesson';
    const isLearnosity = entityTypeId?.includes('learnosity');

    const showAggregateItemAnalysisRadioOption = isAssessment || isLesson || isLearnosity || isExportForReportTableRowResource;

    const radioOptionsJsx = (
      <>
        {/* GRADEBOOK SUMMARY EXPORT */}
        {allowExportFullGradebookSummary && this.renderRadioOption(EXPORT_AGGREGATE_INSTANCES)}
        {allowExportMiniGradebookSummary && this.renderRadioOption(EXPORT_AGGREGATE_INSTANCES_MINI)}
        {/* GRADEBOOK ITEM ANALYSIS EXPORT */}
        {allowExportFullGradebookItemAnalysis && showAggregateItemAnalysisRadioOption && (
          this.renderRadioOption(EXPORT_AGGREGATE_ITEM_ANALYSIS)
        )}
        {allowExportMiniGradebookItemAnalysis && showAggregateItemAnalysisRadioOption && (
          this.renderRadioOption(EXPORT_AGGREGATE_MINI_ANALYSIS)
        )}
        {/* NON-SCORABLE ITEMS TOGGLER for `hideUnscorable` */}
        {!isLearnosity && (
          exportReportId === EXPORT_AGGREGATE_ITEM_ANALYSIS || exportReportId === EXPORT_AGGREGATE_MINI_ANALYSIS
        ) && (
          <div className='toggle-hide-or-show-wrapper export-grades-toggler'>
            <Checkbox
              className='hide-or-show-toggler hide-or-show-toggler-directly-on-background'
              defaultChecked={shouldHideUnscorableItems}
              label={t(shouldHideUnscorableItems ? 'hideUnscorableTrueLabel' : 'hideUnscorableFalseLabel')}
              onChange={this.handleToggleHideUnscorableItems}
              toggle />
          </div>
        )}
        {/* GRADEBOOK STANDARDS EXPORT */}
        {allowGradebookStandards && allowExportFullGradebookStandards && (
          this.renderRadioOption(EXPORT_AGGREGATE_STANDARDS)
        )}
      </>
    );

    const { ModalBanner } = this;
    return (
      <Modal className='export-grades-modal' open={exportGradesOpen} size='tiny'>
        <ModalBanner
          label={modalBannerLabel || t('exportCSV')}
          onClose={() => closeExportModal()} />
        <Modal.Content>
          <Container>
            {radioOptionsJsx}
          </Container>

          <div className='export-generation-explanation-msg'>
            {t('exportGenerationExplanationMsg')}
          </div>

          <Form.Field className='email-export-field'>
            <Form.Input
              label='Email: '
              name='email'
              onChange={this.handleEmailChange}
              onFocus={this.clearError}
              placeholder='name@email.com'
              type='email'
              value={email} />
            {error && (
              <Message
                content={error}
                error
                visible={error !== null} />
            )}
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          {/* CANCEL */}
          <Button
            basic
            content='Cancel'
            onClick={() => { closeExportModal(); }}
            primary />
          {/* SEND */}
          <Button
            className={valid ? '' : 'display-disabled'}
            content='Send'
            onClick={() => this.requestExport()}
            primary />
        </Modal.Actions>
      </Modal>
    );
  }
}

SatCoreRegister('ExportGradesModal', ExportGradesModal);
