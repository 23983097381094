import React, { Component } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import {
  Container, Loader, Pagination
} from 'semantic-ui-react';

import '../css/AdminClassroomsView.less';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { ICON_CIRCLE_STATUS } from '../constants';

import { DIALOG_NAMES } from '../managers/DialogManager';
import { VIEW_SELECTION } from '../managers/NavigationManager';

import { removeSessionStorageItem, scrollBrowserWindowToTop } from '../utils';

import DropdownService from '../services/DropdownService';
import IconCircleStatusService from '../services/IconCircleStatusService';
import ReportIdentityService from '../services/reports/ReportIdentityService';
import UserService from '../services/UserService';

export default @inject(
  'accommodationsManager',
  'adminClassroomManager',
  'classroomManager',
  'dialogManager',
  'filteredHeaderTableManager',
  'libraryManager',
  'navigationManager',
  'productManager',
  'schoolManager',
  'userManager'
)
@observer
class AdminClassroomsView extends Component {
  filteredHeaderTableKey = 'admin-classrooms-table-key';

  constructor(props) {
    super(props);
    this.BreadCrumbs = SatCoreComponent('BreadCrumbs');
    this.ButtonBar = SatCoreComponent('ButtonBar');
    this.ColorKeyCard = SatCoreComponent('ColorKeyCard');
    this.CustomDropdown = SatCoreComponent('CustomDropdown');
    this.FilteredHeaderTable = SatCoreComponent('FilteredHeaderTable');
    this.IconCircleStatus = SatCoreComponent('IconCircleStatus');
    this.RegTypeInfoIconPopup = SatCoreComponent('RegTypeInfoIconPopup');

    this.FIRST_PRODUCT_DROPDOWN_OPTION = { key: -1, text: props.t('allProducts'), value: 'all' };
    this.FIRST_COURSE_DROPDOWN_OPTION = { key: -1, text: props.t('allCourses'), value: 'all' };

    this.LOADING_PRODUCT_DROPDOWN_OPTIONS = [{ key: -1, text: props.t('loadingProductDropdownOptions'), value: 'all' }];
    this.LOADING_COURSE_DROPDOWN_OPTIONS = [{ key: -1, text: props.t('loadingCourseDropdownOptions'), value: 'all' }];

    this.state = {
      allLibraryCourseData: undefined,
      archived: false,
      courseDropdownOptions: [this.FIRST_COURSE_DROPDOWN_OPTION],
      institutionId: null,
      loadingCourseDropdownOptions: false,
      loadingProductDropdownOptions: false,
      productDropdownOptions: [this.FIRST_PRODUCT_DROPDOWN_OPTION],
      selectedCourseDropdownValue: 'all',
      selectedProductDropdownValue: 'all',
      shouldRefreshCourseDropdownOptions: true,
      shouldRefreshProductDropdownOptions: true,
    };
  }

  async componentDidMount() {
    const {
      filteredHeaderTableManager, navigationManager, schoolManager
    } = this.props;

    filteredHeaderTableManager.clearAll();

    ReportIdentityService.clearAllReportSessionStorage();
    ReportIdentityService.clearAllReportManagers();

    removeSessionStorageItem('adminClassroomReportFacultyName');
    removeSessionStorageItem('adminClassroomReportLeadTeacherName');

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('view')) {
      navigationManager.setView(urlParams.get('view'));
    } else {
      navigationManager.setView(VIEW_SELECTION.CLASSROOMS);
    }

    let institutionId = '';
    if (urlParams.has('institutionId')) {
      /* user came from a school card */
      institutionId = urlParams.get('institutionId');
      this.setState({ institutionId });

      const { institutionName } = await navigationManager.getAdminUrlParams();
      schoolManager.setSearchText(institutionName);
      await this.setBreadcrumbs();
    }
    filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);

    await this.fetchClassrooms();

    // note: for performance reasons, no need to fetch data for product/course dropdowns until they are opened
    // (see `onOpen` functions for renderAdminClassroomsProductDropdown and renderAdminClassroomsCourseDropdown)
    // TODO remove
    // await this.refreshProductDropdownOptions();
    // await this.refreshCourseDropdownOptions();
  }

  /**
   * Handle the following cases:
   *
   * (1) User is coming from the 'Classes' tab & needs to break out
   * of being filtered by a previous `institutionId` — or
   *
   * (2) URL changes while the component is still mounted
   *
   * (3) Filters were cleared in manager so we reset and remove all filtering
   */
  async componentDidUpdate() {
    const { filteredHeaderTableManager } = this.props;

    const urlParams = new URLSearchParams(window.location.search);
    let changesMade = false;
    let institutionId = '';
    /* (1) if institutionId was passed to the component & has changed, update it */
    if (urlParams.has('institutionId')) {
      institutionId = urlParams.get('institutionId');
      // eslint-disable-next-line react/destructuring-assignment
      if (institutionId !== this.state.institutionId) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ institutionId });
        changesMade = true;
      }
    } else {
      /* (2) If institutionId was not passed, but one still exists in state, clear it */
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state.institutionId) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ institutionId: null });
        changesMade = true;
      }
      /* (3) If table manager is cleared, reset all the filters */
      if (filteredHeaderTableManager.isCleared) {
        filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
        changesMade = true;
      }
    }
    if (changesMade) {
      await this.fetchClassrooms();
    }
  }

  setBreadcrumbs = async () => {
    const { navigationManager, userManager, t } = this.props;
    const { isDistrictAdmin } = userManager;
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions('userManager');
    if (isDistrictAdmin || hasMultipleInstitutions) {
      navigationManager.clearAllPaths();

      const schoolsBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        path: { name: t('schoolsLabel') }
      };
      const schoolNameBreadcrumbObj = {
        fromView: VIEW_SELECTION.DASHBOARD,
        paramName: 'institutionName',
        path: {}
      };
      const classroomsBreadcrumbObj = {
        fromView: VIEW_SELECTION.CLASSROOMS,
        path: { name: t('classesLabel', 'Classes') }
      };
      await navigationManager.setBreadcrumb(schoolsBreadcrumbObj);
      await navigationManager.setBreadcrumb(schoolNameBreadcrumbObj);
      await navigationManager.setBreadcrumb(classroomsBreadcrumbObj);
    }
  }

  handleClickViewRoster = async (adminClassroom) => {
    const { history, navigationManager } = this.props;
    await this.fetchCurrentClassroom(adminClassroom);

    const { classroomId } = adminClassroom;
    let routerUrl = `/roster?classroomId=${classroomId}`;
    const paramsToExcludeFromStr = 'userId';
    const { urlParamStr } = await navigationManager.getAdminUrlParams(paramsToExcludeFromStr);
    if (urlParamStr) {
      routerUrl += `&${urlParamStr}`;
    }
    await history.push(routerUrl);
  }

  handleClickSettings = async (adminClassroom) => {
    const { history, navigationManager } = this.props;

    await this.fetchCurrentClassroom(adminClassroom/* , { shouldNotSetAdminClassroom: true } */);

    const { classroomId, leadTeacherId } = adminClassroom;
    let routerUrl = `/settings?classroomId=${classroomId}&leadTeacherId=${leadTeacherId}`;
    const paramsToExcludeFromStr = 'userId';
    const { urlParamStr } = await navigationManager.getAdminUrlParams(paramsToExcludeFromStr);
    if (urlParamStr) {
      routerUrl += `&${urlParamStr}`;
    }
    await history.push(routerUrl);
  }

  handleClickReports = async (adminClassroom) => {
    const { dialogManager, history } = this.props;
    const { archived } = this.state;
    scrollBrowserWindowToTop();
    dialogManager.setOpenDialog(DIALOG_NAMES.CLASSROOM_REPORT_TYPE_SELECTOR_MODAL, {
      classroom: adminClassroom,
      classroomStatus: archived ? 'archived' : 'active',
      history,
      onClose: () => {
        dialogManager.closeDialog(DIALOG_NAMES.CLASSROOM_REPORT_TYPE_SELECTOR_MODAL);
      }
    }/* , () => {} */);
  }

  handleClickAccommodations = async (adminClassroom) => {
    const { history, navigationManager } = this.props;
    await this.fetchCurrentClassroom(adminClassroom);

    const { classroomId } = adminClassroom;
    let routerUrl = `/accommodations?classroomId=${classroomId}`;
    const paramsToExcludeFromStr = 'userId';
    const { urlParamStr } = await navigationManager.getAdminUrlParams(paramsToExcludeFromStr);
    if (urlParamStr) {
      routerUrl += `&${urlParamStr}`;
    }
    await history.push(routerUrl);
  }

  onPageChange = async (_event, pageInfo) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      const { activePage } = pageInfo;
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, activePage);
      const map = new Map([
        ['activePage', activePage]
      ]);
      await this.fetchClassrooms(map);
    }
  }

  handleSort = async (sortColumn, filters) => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      if (sortColumn) {
        const sortDirection = tableData.direction === 'ascending' ? 'descending' : 'ascending';
        filteredHeaderTableManager.setSortColumn(this.filteredHeaderTableKey, sortColumn);
        filteredHeaderTableManager.setSortDirection(this.filteredHeaderTableKey, sortDirection);
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
        const map = new Map([
          ['sortColumn', sortColumn],
          ['sortDirection', sortDirection === 'ascending' ? 'ascending' : 'descending'],
          ['filters', filters]
        ]);
        await this.fetchClassrooms(map);
      } else {
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      }
    }
  }

  handleClickBarButton = async (_event, buttonBarObj = {}) => {
    const { filteredHeaderTableManager } = this.props;

    const archived = buttonBarObj?.buttonKey === 'archived';
    this.setState({ archived });

    const tableData = toJS(filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey));
    const filters = tableData?.filters;

    await this.handleFilter(filters, { archived });
  }

  handleFilter = async (filters, { archived, courseId, filterKey, productId } = {}) => {
    const { filteredHeaderTableManager } = this.props;
    const { state } = this;

    // note: per DEMO-966, active/archived state should **not** reset upon clearing all filters
    // i.e. active/archived state will remain the same when `shouldClearAllFilters` is true
    archived = typeof archived === 'boolean' ? archived : state.archived;

    const shouldClearAllFilters = filterKey === 'ALL_FILTERS';

    productId = shouldClearAllFilters ? 'all' : (productId || state.selectedProductDropdownValue);

    courseId = shouldClearAllFilters ? 'all' : (courseId || state.selectedCourseDropdownValue);

    if (shouldClearAllFilters) {
      this.setState({
        selectedCourseDropdownValue: 'all',
        selectedProductDropdownValue: 'all',
        shouldRefreshCourseDropdownOptions: true,
        shouldRefreshProductDropdownOptions: true
      });
    }

    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      const activePage = 1;
      filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, activePage);
      const map = new Map([
        ['activePage', activePage],
        ['filters', filters],
        ['archived', archived]
      ]);
      if (productId) {
        map.set('productId', productId);
      }
      if (courseId) {
        map.set('courseId', courseId);
      }
      await this.fetchClassrooms(map);
    }
  }

  fetchCurrentClassroom = async (adminClassroom, { shouldNotSetAdminClassroom = false } = {}) => {
    const { adminClassroomManager, classroomManager } = this.props;
    const { classroomId } = adminClassroom;
    const classroom = await adminClassroomManager.fetchClassroom(classroomId) || {};

    classroom.name = classroom.name || adminClassroom.classroomName;
    classroom.nickname = classroom.nickname || adminClassroom.classroomNickname;

    if (!shouldNotSetAdminClassroom) {
      await classroomManager.setAdminClassroom(classroomId, classroom);
    }
    return classroom;
  }

  /**
   * Wrapper method for `adminClassroomManager.fetchClassrooms(...)`
   * @param {Map<string, any>} map - optional map of `key, value` pairs
   *
   * if `!map` or `key` is not in map, state `value` of `key` will be used
   */
  fetchClassrooms = async (map = null) => {
    const { adminClassroomManager, filteredHeaderTableManager } = this.props;
    const { state } = this;

    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      let activePage, pageSize, sortColumn, sortDirection, filters, archived, productId, courseId;
      if (map) {
        activePage = map.get('activePage');
        pageSize = map.get('pageSize');
        sortColumn = map.get('sortColumn');
        sortDirection = map.get('sortDirection');
        filters = map.get('filters');
        archived = map.get('archived');
        productId = map.get('productId');
        courseId = map.get('courseId');
      }
      activePage = activePage || tableData.activePage;
      pageSize = pageSize || adminClassroomManager.ADMIN_CLASSROOM_FETCH_PAGE_SIZE;
      sortColumn = sortColumn || tableData.column;
      sortDirection = sortDirection || tableData.direction;
      filters = filters || (tableData.filters ? tableData.filters : []);
      archived = typeof archived === 'boolean' ? archived : state.archived;

      productId = productId || state.selectedProductDropdownValue;
      productId = productId !== 'all' ? productId : undefined;

      courseId = courseId || state.selectedCourseDropdownValue;
      courseId = courseId !== 'all' ? courseId : undefined;

      /* if user selected a school from the dashboard, filter by that school */
      const urlParams = new URLSearchParams(window.location.search);
      let institutionId;
      if (urlParams.has('institutionId')) {
        institutionId = urlParams.get('institutionId');
      }
      const updatedFilters = [...filters];
      if (institutionId) {
        const institutionFilter = {
          field: 'institutionId',
          operator: 'eq',
          value: institutionId
        };
        updatedFilters.push(institutionFilter);
      }
      await adminClassroomManager.fetchClassrooms(
        activePage, pageSize, sortColumn,
        sortDirection === 'ascending' ? 'asc' : 'desc',
        updatedFilters, {
          archived, courseId, productId
        }
      );
    }
  }

  refreshProductDropdownOptions = async () => {
    const { productManager } = this.props;
    const products = await productManager.fetchLicensedCourseProducts();

    const productDropdownOptions = DropdownService.getDropdownOptions({
      originalData: products,
      optionalFirstDropdownOption: this.FIRST_PRODUCT_DROPDOWN_OPTION, // eslint-disable-line sort-keys
      keyPropName: 'id', // eslint-disable-line sort-keys
      labelPropName: (product) => product.friendlyName || product.displayName,
      valuePropName: 'id'
    });

    this.setState({
      productDropdownOptions,
      selectedProductDropdownValue: 'all'
    });
  }

  refreshCourseDropdownOptions = async ({
    selectedProductDropdownValue
  } = {}) => {
    const { libraryManager } = this.props;

    const { state } = this;

    let { allLibraryCourseData } = state;

    selectedProductDropdownValue = selectedProductDropdownValue || state.selectedProductDropdownValue;
    const productId = selectedProductDropdownValue !== 'all' ? selectedProductDropdownValue : undefined;

    const activePage = 0;
    const pageSize = 999;
    const typesToSearch = 'course_resource';
    const sortColumnName = 'name';
    const sortDirection = 'asc';
    const searchText = '';

    if (!Array.isArray(allLibraryCourseData)) {
      allLibraryCourseData = await libraryManager.fetchLibraryCourses(
        activePage, pageSize, typesToSearch, sortColumnName, sortDirection, searchText
      ) || [];
    }

    const filteredLibraryCourseData = productId ? allLibraryCourseData.filter((libraryCourse) => {
      return libraryCourse?.libraryResource?.productsList?.includes?.(productId);
    }) : allLibraryCourseData;

    const courseDropdownOptions = DropdownService.getDropdownOptions({
      originalData: filteredLibraryCourseData,
      optionalFirstDropdownOption: this.FIRST_COURSE_DROPDOWN_OPTION, // eslint-disable-line sort-keys
      keyPropName: 'id', // eslint-disable-line sort-keys
      labelPropName: 'title',
      valuePropName: 'id'
    });

    this.setState({
      allLibraryCourseData,
      courseDropdownOptions,
      selectedCourseDropdownValue: 'all'
    });
  }

  createHeaderData = () => {
    const { accommodationsManager, classroomManager, t, userManager } = this.props;
    const { allowSsoViewClassroomSettings } = classroomManager;
    const headerData = [
      {
        /* HEADER: SCHOOL (institution) NAME */
        columnClassName: 'truncate-long',
        filterAutoCompleteCallback: null,
        filterKey: 'schoolName',
        filterLabel: '',
        label: 'School',
        sortKey: 'schoolName'
      },
      {
        /* HEADER: CLASSROOM NAME */
        columnClassName: 'truncate-long',
        filterAutoCompleteCallback: null,
        filterKey: 'classroomName',
        filterLabel: '',
        label: t('className', 'Class Name'),
        sortKey: 'classroomName'
      },
      {
        /* HEADER: CLASSROOM DISPLAY NAME (nickname) */
        columnClassName: 'truncate-long',
        filterAutoCompleteCallback: null,
        filterKey: 'classroomNickname',
        filterLabel: '',
        hide: !userManager.isSsoUser,
        label: t('classroomNickname'),
        sortKey: 'classroomNickname'
      },
      // TODO remove, unused
      // {
      //   /* HEADER: CLASSROOM ID */
      //   columnClassName: 'truncate-long',
      //   filterAutoCompleteCallback: null,
      //   filterKey: null,
      //   filterLabel: '',
      //   label: t('classId', 'Class ID'),
      //   sortKey: null
      // },
      {
        /* HEADER: CLASSROOM START DATE */
        columnClassName: 'truncate-short',
        filterAutoCompleteCallback: null,
        filterComparator: 'contains',
        filterKey: 'classroomStartDate',
        filterLabel: '',
        label: t('classroomStartDate'),
        sortKey: 'classroomStartDate',
        utilizeDateComparatorFilterOptions: true
      },
      {
        /* HEADER: CLASSROOM END DATE */
        columnClassName: 'truncate-short',
        filterAutoCompleteCallback: null,
        filterComparator: 'contains',
        filterKey: 'classroomEndDate',
        filterLabel: '',
        label: t('classroomEndDate'),
        sortKey: 'classroomEndDate',
        utilizeDateComparatorFilterOptions: true
      },
      {
        /* HEADER: LEAD TEACHER FIRST NAME */
        columnClassName: 'truncate-medium',
        filterAutoCompleteCallback: null,
        filterKey: 'teacherFirstName',
        filterLabel: '',
        label: t('leadTeacherFirstName'),
        sortKey: 'teacherFirstName'
      },
      {
        /* HEADER: LEAD TEACHER LAST NAME */
        columnClassName: 'truncate-medium',
        filterAutoCompleteCallback: null,
        filterKey: 'teacherLastName',
        filterLabel: '',
        label: t('leadTeacherLastName'),
        sortKey: 'teacherLastName'
      },
      {
        /* HEADER: ACCOMMODATIONS */
        headerCellClassName: 'clickableButtonHeaderCell',
        hide: !accommodationsManager.includeClassroomAccommodations,
        id: 'accommodations'
      },
      {
        /* HEADER: VIEW ROSTER (placeholder) */
        headerCellClassName: 'clickableButtonHeaderCell',
        id: 'roster'
      },
      {
        /* HEADER: SETTINGS (placeholder) */
        headerCellClassName: 'clickableButtonHeaderCell',
        hide: userManager.isSsoUser && !allowSsoViewClassroomSettings,
        id: 'settings'
      },
      {
        /* HEADER: REPORTS */
        headerCellClassName: 'clickableButtonHeaderCell',
        id: 'reports'
      }
    ];
    return headerData;
  }

  createBodyData = () => {
    const {
      accommodationsManager, adminClassroomManager, classroomManager, t, userManager
    } = this.props;
    const { allowSsoViewClassroomSettings } = classroomManager;

    const { IconCircleStatus } = this;

    const bodyData = [];
    // eslint-disable-next-line array-callback-return
    adminClassroomManager.classroomsArray.map((classroom) => {
      const reportsIconCircleStatus = IconCircleStatusService.getIconCircleStatusForAdminClassroomReportButton(classroom);

      let classroomRow = [
        {
          /* BODY: SCHOOL (institution) NAME */
          columnButtonOnClick: null,
          columnClassName: 'truncate-long',
          columnName: 'schoolName',
          columnOnClick: null,
          columnText: classroom.institutionName,
          id: 'schoolName',
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* BODY: CLASSROOM NAME */
          columnButtonOnClick: null,
          columnClassName: 'truncate-long',
          columnName: 'classroomName',
          columnOnClick: null,
          columnText: classroom.classroomName,
          id: 'classroomName',
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* BODY: CLASSROOM DISPLAY NAME (nickname) */
          columnButtonOnClick: null,
          columnClassName: 'truncate-long',
          columnName: 'classroomNickname',
          columnOnClick: null,
          columnText: classroom.classroomNickname,
          hide: !userManager.isSsoUser,
          id: 'classroomNickname',
          showTooltip: true,
          showTootipEvent: 'click'
        },
        // TODO remove, unused
        // {
        //   /* BODY: CLASSROOM ID */
        //   columnButtonOnClick: null,
        //   columnClassName: 'truncate-long',
        //   columnName: 'classroomId',
        //   columnOnClick: null,
        //   columnText: classroom.classroomId,
        //   showTooltip: true,
        //   showTootipEvent: 'click'
        // },
        {
          /* BODY: CLASSROOM START DATE */
          columnButtonOnClick: null,
          columnClassName: 'truncate-auto',
          columnName: 'classroomStartDate',
          columnOnClick: null,
          columnText: classroom.classroomStartDate,
          id: 'classroomStartDate',
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* BODY: CLASSROOM END DATE */
          columnButtonOnClick: null,
          columnClassName: 'truncate-auto',
          columnName: 'classroomStartDate',
          columnOnClick: null,
          columnText: classroom.classroomEndDate,
          id: 'classroomEndDate',
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* BODY: LEAD TEACHER FIRST NAME */
          columnButtonOnClick: null,
          columnClassName: 'truncate-medium',
          columnName: 'teacherFirstName',
          columnOnClick: null,
          columnText: classroom.leadTeacherFirstName,
          id: 'teacherFirstName',
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* BODY: LEAD TEACHER LAST NAME */
          columnButtonOnClick: null,
          columnClassName: 'truncate-medium',
          columnName: 'teacherLastName',
          columnOnClick: null,
          columnText: classroom.leadTeacherLastName,
          id: 'teacherLastName',
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* BODY: ACCOMMODATIONS */
          columnButtonOnClick: () => this.handleClickAccommodations(classroom),
          columnClassName: '',
          columnName: 'accommodations',
          // columnOnClick: () => this.handleClickAccommodations(classroom),
          columnText: t('accommodations'),
          hide: !accommodationsManager.includeClassroomAccommodations,
          id: 'accommodations',
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* BODY: VIEW ROSTER */
          columnButtonOnClick: () => this.handleClickViewRoster(classroom),
          columnClassName: '',
          columnName: 'roster',
          columnOnClick: null,
          columnText: t('roster'),
          id: 'roster',
          showTooltip: true,
          showTootipEvent: 'click'
        },
        {
          /* BODY: SETTINGS */
          columnButtonOnClick: () => this.handleClickSettings(classroom),
          columnClassName: '',
          columnName: 'settings',
          columnOnClick: null,
          columnText: t('settings'),
          hide: userManager.isSsoUser && !allowSsoViewClassroomSettings,
          id: 'settings',
          showTooltip: true,
          showTootipEvent: 'click'
        },
      ];
      if (userManager.canViewSatelliteReports) {
        classroomRow.push(        {
          /* BODY: REPORTS */
          columnButtonOnClick: () => this.handleClickReports(classroom),
          columnClassName: '',
          columnName: 'reports',
          columnOnClick: null,
          columnText: (
            <div className='reports-button-inner-wrapper icon-circle-status-outer-wrapper'>
              <div className='reports-button-text'>
                {t('reports')}
              </div>
              <IconCircleStatus status={reportsIconCircleStatus} />
            </div>
          ),
          hide: userManager.isSsoUser && !allowSsoViewClassroomSettings,
          id: 'reports',
          showTooltip: true,
          showTootipEvent: 'click'
        });
      }
      bodyData.push(classroomRow);
    }, this);
    return bodyData;
  }

  renderAdminClassroomsHeaderTitle = () => {
    const { RegTypeInfoIconPopup } = this;
    const { t } = this.props;
    return (
      <div className='admin-classrooms-header-title-wrapper'>
        <div className='admin-classrooms-view-title theme-header-title'>
          {t('classesLabel', 'Classes')}
        </div>
        <div className='admin-classrooms-view-info-wrapper'>
          <RegTypeInfoIconPopup
            iconImageClassName='admin-classrooms-view-info-icon'
            popupClassName='admin-classrooms-popup' />
        </div>
      </div>
    );
  }

  renderAdminClassroomsButtonBar = () => {
    const { ButtonBar } = this;
    return (
      <div className='admin-classrooms-button-bar-wrapper'>
        <ButtonBar
          buttonBarObjs={[
            { buttonKey: 'active', defaultActive: true },
            { buttonKey: 'archived' }
          ]}
          onClickBarButton={this.handleClickBarButton}
        />
      </div>
    );
  }

  renderAdminClassroomsProductAndCourseDropdowns = () => {
    return (
      <div className='admin-classrooms-product-and-course-dropdowns'>
        {this.renderAdminClassroomsProductDropdown()}
        {this.renderAdminClassroomsCourseDropdown()}
      </div>
    );
  }

  renderAdminClassroomsProductDropdown = () => {
    const { CustomDropdown } = this;
    const { t } = this.props;
    const { loadingProductDropdownOptions, productDropdownOptions, selectedProductDropdownValue } = this.state;
    const semanticUiDropdownProps = DropdownService.getSemanticUiDropdownProps();
    return (
      <div className='admin-classrooms-product-dropdown'>
        <CustomDropdown {...semanticUiDropdownProps}
          dropdownLabel={t('productDropdownLabel')}
          loading={loadingProductDropdownOptions}
          onChange={async (_event, data) => {
            const { filteredHeaderTableManager } = this.props;
            const productId = data?.value;

            this.setState({
              courseDropdownOptions: [this.FIRST_COURSE_DROPDOWN_OPTION],
              selectedCourseDropdownValue: 'all',
              selectedProductDropdownValue: productId,
              shouldRefreshCourseDropdownOptions: true
            });

            // update filters to filter by newly selected productId (if one was chosen)
            const tableData = toJS(filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey));
            const filters = tableData?.filters;
            await this.handleFilter(filters, {
              courseId: 'all', // productId has changed, so ensure we reset course filter by setting value to 'all'
              productId
            });
          }}
          onOpen={async () => {
            // fetch options upon opening product dropdown if we do not have the options yet
            const { shouldRefreshProductDropdownOptions } = this.state;
            if (shouldRefreshProductDropdownOptions) {
              this.setState({ loadingProductDropdownOptions: true });
              await this.refreshProductDropdownOptions();
              this.setState({
                loadingProductDropdownOptions: false,
                shouldRefreshProductDropdownOptions: false
              });
            }
          }}
          options={loadingProductDropdownOptions ? this.LOADING_PRODUCT_DROPDOWN_OPTIONS : productDropdownOptions}
          selectedDropdownValue={selectedProductDropdownValue} />
      </div>
    );
  }

  renderAdminClassroomsCourseDropdown = () => {
    const { CustomDropdown } = this;
    const { t } = this.props;
    const { courseDropdownOptions, loadingCourseDropdownOptions, selectedCourseDropdownValue } = this.state;
    const semanticUiDropdownProps = DropdownService.getSemanticUiDropdownProps();
    return (
      <div className='admin-classrooms-course-dropdown'>
        <CustomDropdown {...semanticUiDropdownProps}
          dropdownLabel={t('courseDropdownLabel')}
          loading={loadingCourseDropdownOptions}
          onChange={async (_event, data) => {
            const { filteredHeaderTableManager } = this.props;
            const courseId = data?.value;

            this.setState({
              selectedCourseDropdownValue: courseId
            });
            // update filters to filter by newly selected courseId (if one was chosen)
            const tableData = toJS(filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey));
            const filters = tableData?.filters;
            await this.handleFilter(filters, { courseId });
          }}
          onOpen={async () => {
            // fetch options upon opening course dropdown if we do not have the options yet
            const { shouldRefreshCourseDropdownOptions } = this.state;
            if (shouldRefreshCourseDropdownOptions) {
              this.setState({ loadingCourseDropdownOptions: true });
              await this.refreshCourseDropdownOptions();
              this.setState({
                loadingCourseDropdownOptions: false,
                shouldRefreshCourseDropdownOptions: false
              });
            }
          }}
          options={loadingCourseDropdownOptions ? this.LOADING_COURSE_DROPDOWN_OPTIONS : courseDropdownOptions}
          selectedDropdownValue={selectedCourseDropdownValue} />
      </div>
    );
  }

  renderAdminClassroomsColorKeyCard = () => {
    const { EMPTY_CIRCLE, HALF_CIRCLE, FULL_CIRCLE } = ICON_CIRCLE_STATUS;
    const { ColorKeyCard, IconCircleStatus } = this;

    const iconKeyDataArray = [FULL_CIRCLE, EMPTY_CIRCLE, HALF_CIRCLE].map((status) => {
      return {
        className: status.className,
        icon: <IconCircleStatus status={status} />,
        label: `adminClassroomsColorKeyCardLabel_${status.key}`
      };
    });

    return (
      <div className='admin-classrooms-colorkeycard-outer-wrapper'>
        <ColorKeyCard boldLabel={false} iconKeyDataArray={iconKeyDataArray} />
      </div>
    );
  }

  renderAdminClassroomsPagination = (activePage) => {
    const { adminClassroomManager } = this.props;
    return (
      <div className='admin-classrooms-table-pagination'>
        <Pagination
          activePage={activePage}
          onPageChange={this.onPageChange}
          totalPages={adminClassroomManager.totalPages} />
      </div>
    );
  }

  render() {
    const { adminClassroomManager, filteredHeaderTableManager, history, userManager, t } = this.props;
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions('userManager');
    const { isDistrictAdmin } = userManager;

    const { institutionId } = this.state;

    const { BreadCrumbs, FilteredHeaderTable } = this;

    let tableHeaderData, tableBodyData = [];
    if (adminClassroomManager.loaded) {
      tableHeaderData = this.createHeaderData();
      tableBodyData = this.createBodyData(
        adminClassroomManager.classroomsArray
      );
    }
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    let activePage = 0;
    let sortColumn = '';
    let sortDirection = '';
    let filters = [];
    if (tableData) {
      activePage = tableData.activePage;
      sortColumn = tableData.column;
      sortDirection = tableData.direction;
      filters = tableData.filters;
    }

    const renderAdminClassroomsViewHeader = () => {
      return (
        <div className='admin-classrooms-view-header'>
          <div className='admin-classrooms-view-header-left'>
            {this.renderAdminClassroomsHeaderTitle()}
            {this.renderAdminClassroomsButtonBar()}
            {this.renderAdminClassroomsProductAndCourseDropdowns()}
          </div>

          <div className='admin-classrooms-view-header-mid'>
            {/* placeholder */}
          </div>

          <div className='admin-classrooms-view-header-right'>
            {this.renderAdminClassroomsColorKeyCard()}
            {tableBodyData.length > 0 && (
              this.renderAdminClassroomsPagination(activePage)
            )}
          </div>
        </div>
      );
    };

    return (
      <Container className='admin-classrooms-view' fluid>
        {institutionId && (isDistrictAdmin || hasMultipleInstitutions) && (
          <div className='header-nav'>
            <Container className='bread-crumb-wrapper top' fluid>
              <BreadCrumbs history={history} />
            </Container>
          </div>
        )}
        <Container>
          {renderAdminClassroomsViewHeader()}

          <div className='admin-classrooms-table-container'>
            <FilteredHeaderTable
              allowClearAllFilters
              autoCompleteSearchData={null}
              column={sortColumn}
              direction={sortDirection}
              filtersData={filters}
              handleFilter={this.handleFilter}
              handleSort={this.handleSort}
              tableBodyData={tableBodyData}
              tableHeaderData={tableHeaderData}
              tableId={this.filteredHeaderTableKey} />
          </div>
          {!adminClassroomManager.loaded && (
            <div className='null-state-panel'>
              <Loader key={0} active inline />
            </div>
          )}
          {adminClassroomManager.loaded &&
            (!tableBodyData || tableBodyData.length < 1) && (
            <div className='null-state-panel'>
              {t('noDataFound')}
            </div>
          )}
        </Container>
      </Container>
    );
  }
}
SatCoreRegister('AdminClassroomsView', AdminClassroomsView);
