/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button, Checkbox, Container, Form, Header,
  Image, Input, Loader, Message, Popup, Radio
} from 'semantic-ui-react';

import ReactModalDraggable from 'react-modal-resizable-draggable';

import { DateInput, TimeInput } from 'semantic-ui-calendar-react';

import '../../css/EditAssignmentModal.less';

import assessmentDefaultCardImage from '../../img/default-assessment.svg';
import info from '../../img/group-1583.svg';
import Auth from '../../managers/AuthManager';

import { customAssessmentDefaultCardImageUri } from '../../services/UriService';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { ASSIGNMENT_STATUS, ASSIGNMENT_TYPE } from '../../managers/AssignmentManager';
import { CONTENT_ITEM_SUB_TYPES, CONTENT_ITEM_TYPES } from '../../managers/ContentManager';
import { VIEW_SELECTION } from '../../managers/NavigationManager';

import AssignmentService from '../../services/AssignmentService';
import RosterService from '../../services/RosterService';
import UtilityService from '../../services/UtilityService';

import ActivityModeDropdown from '../ActivityModeDropdown';
import RichTextEditor from '../RichTextEditor';

export default
@inject(
  'accommodationsManager', 'assignmentManager', 'classroomManager',
  'contentManager', 'courseManager', 'gradebookManager', 'navigationManager')
@observer
class EditAssignmentModal extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = this.getInitialState();
    this.statusDescriptionList = [];
    this.statusDescriptionList[ASSIGNMENT_STATUS.LOCKED] = t('lockedDescription');
    this.statusDescriptionList[ASSIGNMENT_STATUS.READY] = t('readyDescription');
    this.statusDescriptionList[ASSIGNMENT_STATUS.STARTED] = t('startedDescription');
    this.statusDescriptionList[ASSIGNMENT_STATUS.CLOSED] = t('closedDescription');
    this.statusDescriptionList[ASSIGNMENT_STATUS.PREVIEW] = t('previewDescription');
    this.statusDescriptionList[ASSIGNMENT_STATUS.COMPLETED] = t('completedDescription');

    this.AccommodationsTogglerSection = SatCoreComponent('AccommodationsTogglerSection');
    this.DeleteAssignmentModal = SatCoreComponent('DeleteAssignmentModal');
    this.ModalBannerDraggable = SatCoreComponent('ModalBannerDraggable');
    this.SCCheckbox = SatCoreComponent('SCCheckbox');
    this.SCRadio = SatCoreComponent('SCRadio');
    this.StandardsList = SatCoreComponent('StandardsList');
    this.TimeAdjustModal = SatCoreComponent('TimeAdjustModal');
  }

  getInitialState() {
    const {
      contentTimeframeEndDate, contentTimeframeStartDate, timeframeEndDateStr,
      timeframeEndTimeStr, timeframeStartDateStr, timeframeStartTimeStr
    } = this.props;

    // these are now formatted on BE ? // const formattedTimeframeStartDate = dateFormat(timeframeStartDate, 'twoDigitDate') : null;
    const formattedTimeframeStartDate = timeframeStartDateStr;

    // these are now formatted on BE ? // const formattedTimeframeEndDate = dateFormat(timeframeEndDate, 'twoDigitDate') : null;
    const formattedTimeframeEndDate = timeframeEndDateStr;

    // these are now formatted on BE ? // const formattedTimeframeStartTime = dateFormat(timeframeStartDate, 'twoDigitTime') : null;
    const formattedTimeframeStartTime = timeframeStartTimeStr;

    // these are now formatted on BE ? // const formattedTimeframeEndTime = dateFormat(timeframeEndDate, 'twoDigitTime') : null;
    const formattedTimeframeEndTime = timeframeEndTimeStr;

    return {
      activityModeId: null,
      allowLateSubmission: false,
      ignoreItemOptionShuffle: false,
      lateSubmitDate: null,
      lateSubmitTime: null,
      originalAssignment: null,
      assignment: null,

      showKeyboardingSettings: false,

      status: null,
      statusDescription: null,
      timezoneStartDate: null,
      timezoneStartTime: null,
      timezoneEndDate: null,
      timezoneEndTime: null,

      timeframeStartDate: contentTimeframeStartDate,
      timeframeEndDate: contentTimeframeEndDate,
      formattedTimeframeStartDate,
      formattedTimeframeEndDate,
      formattedTimeframeStartTime,
      formattedTimeframeEndTime,

      includeInReports: null,
      scoresReleased: null,
      studentReview: null,
      instruction: '',
      randomizeQuestions: true,
      showCorrectAnswers: true,

      serverErrorMsg: null,
      hasServerError: false,
      openWarning: false,

      startDateSetErrored: false,
      endDateSetErrored: false,
      startDateAfterEndDateErrored: false,
      readyAfterEndErrored: false,
      startedAfterEndErrored: false,
      lockedAfterStartErrored: false,
      unlockedBeforeStartErrored: false,

      unlockedBeforeClassroomStartErrored: false,
      startBeforeClassroomStartErrored: false,
      endAfterClassroomEndErrored: false,
      nickname: ''
    };
  }

  async componentDidMount() {
    const { assignmentId, assignmentManager, classroomId, classroomManager } = this.props;
    const { alwaysShowTypingSettingsForLessonAssessment } = assignmentManager;
    const assignment = await assignmentManager.getAssignmentAsync(assignmentId);

    if (!RosterService.hasRoster({ assignment })) {
      await classroomManager.fetchClassroomRoster(classroomId);
    }

    const {
      status, timezoneStartDate, timezoneStartTime,
      timezoneEndDate, timezoneEndTime, instruction
    } = assignment;

    const additionalPropertiesJson = assignment.additionalPropertiesJson ? assignment.additionalPropertiesJson : {};

    const randomizeQuestions = additionalPropertiesJson.hasOwnProperty('shuffle_items') ?
      additionalPropertiesJson.shuffle_items : false;
    const ignoreItemOptionShuffle = additionalPropertiesJson.hasOwnProperty('ignoreItemOptionShuffle') ?
      additionalPropertiesJson.ignoreItemOptionShuffle : false;
    const showCorrectAnswers = additionalPropertiesJson.hasOwnProperty('showCorrectAnswers') ?
      additionalPropertiesJson.showCorrectAnswers : false;
    const publisherModeId = additionalPropertiesJson.hasOwnProperty('publisherModeId') ?
      additionalPropertiesJson.publisherModeId : null;
    const allowLateSubmission = additionalPropertiesJson.hasOwnProperty('allowLateSubmission') ?
      additionalPropertiesJson.allowLateSubmission : false;
    const lateSubmitDateStr = additionalPropertiesJson.hasOwnProperty('lateSubmitDate') ?
      additionalPropertiesJson.lateSubmitDate : null;

    // Keyboarding: If the satellite allows to always show keyboarding settings, check the resource types,
    // as Lesson, Assessment and Keyboarding Resource are all valid.
    // If not, only allow for Keyboarding resource.
    const showKeyboardingSettings = alwaysShowTypingSettingsForLessonAssessment ? (
      assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE ||
      assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LESSON ||
      assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT
    ) : assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE;

    this.setState({ showKeyboardingSettings });

    let keyboardingResource = null;
    if (showKeyboardingSettings) {
      keyboardingResource = await this.fetchContentItemResource(assignment.contentItemId);
    }

    const typingAllowBackspace = additionalPropertiesJson.hasOwnProperty('typingAllowBackspace') ?
      additionalPropertiesJson.typingAllowBackspace : keyboardingResource ? keyboardingResource.typingAllowBackspace : false;
    const typingText = additionalPropertiesJson.hasOwnProperty('typingText') ?
      additionalPropertiesJson.typingText : keyboardingResource ? keyboardingResource.typingText : '';
    const typingDuration = additionalPropertiesJson.hasOwnProperty('typingDurationInSeconds') ?
      additionalPropertiesJson.typingDurationInSeconds : keyboardingResource ? keyboardingResource.typingDuration : 0;
    const typingMinimumSpeed = additionalPropertiesJson.hasOwnProperty('typingMinSpeed') ?
      additionalPropertiesJson.typingMinSpeed : keyboardingResource ? keyboardingResource.typingMinimumSpeed : 0;
    const typingMinimumAccuracy = additionalPropertiesJson.hasOwnProperty('typingMinAccuracy') ?
      additionalPropertiesJson.typingMinAccuracy : keyboardingResource ? keyboardingResource.typingMinimumAccuracy : 0;

    let lateSubmitDate = null;
    let lateSubmitTime = null;
    if (lateSubmitDateStr) {
      const tempLateDate = new Date(lateSubmitDateStr);
      lateSubmitDate = tempLateDate.toLocaleDateString();
      lateSubmitTime = tempLateDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    this.setState({
      activityModeId: publisherModeId || assignment.alternateModeIdOverride || assignment.alternateModeId,
      allowLateSubmission,
      assignment: { ...assignment },
      ignoreItemOptionShuffle,
      lateSubmitDate,
      lateSubmitError: false,
      lateSubmitMessage: '',
      lateSubmitTime,
      nickname: assignment.nickname,
      originalAssignment: { ...assignment },
      status,
      statusDescription: this.statusDescriptionList[status],
      timezoneStartDate,
      timezoneStartTime,
      timezoneEndDate,
      timezoneEndTime,
      includeInReports: assignment.includeInReports,
      scoresReleased: assignment.scoresReleased,
      studentReview: assignment.studentReview,
      instruction,
      randomizeQuestions,
      showCorrectAnswers,
      typingAllowBackspace,
      typingText,
      typingDuration,
      typingMinimumSpeed,
      typingMinimumAccuracy
    });
  }

  fetchContentItemResource = async (contentItemId) => {
    const { contentManager } = this.props;
    const keyboardingResource = await contentManager.fetchResourceItem(contentItemId);
    return keyboardingResource;
  }

  handleChangeAssignmentStatus = async (event) => {
    const status = event.target.value;
    await this.setState({
      status,
      statusDescription: this.statusDescriptionList[status]
    });
    this.validate();
  }

  handleChangeNickname = async (event) => {
    const { name, value } = event.target;
    if (value.length < 201) {
      await this.setState((prevState) => {
        const newState = { ...prevState };
        newState[name] = value;
        return newState;
      });
      this.validate();
    }
  }

  handleChangeToggle = async (_event, data, toggleKey) => {
    await this.setState({
      [toggleKey]: data.checked
    });
    this.validate();
  }

  handleChangeStartDate = async (_event, value) => {
    await this.setState({ timezoneStartDate: value });
    this.validate();
  }

  handleChangeEndDate = async (_event, value) => {
    this.checkResetAssignmentStatus(value, null);
    await this.setState({ timezoneEndDate: value });
    this.validate();
  }

  handleChangeStartTime = async (_event, value) => {
    await this.setState({
      timezoneStartTime: value ? value.toUpperCase() : ''
    });
    this.validate();
  }

  handleChangeEndTime = async (_event, value) => {
    this.checkResetAssignmentStatus(null, value);
    await this.setState({
      timezoneEndTime: value ? value.toUpperCase() : ''
    });
    this.validate();
  }

  handleChangeLateSubmitDate = async (event, { value }) => {
    await this.setState({ lateSubmitDate: value ? value.toUpperCase() : '', lateSubmitError: false });
    this.validate();
  };

  handleChangeLateSubmitTime = async (event, { value }) => {
    await this.setState({ lateSubmitTime: value ? value.toUpperCase() : '', lateSubmitError: false });
    this.validate();
  };

  handleClickRandomizeQuestions = async (_event) => {
    // const checked = _event.target.value;
    const { randomizeQuestions } = this.state;
    await this.setState({
      randomizeQuestions: !randomizeQuestions
    });
    this.validate();
  }

  handleClickShowCorrectAnswers = async (_event) => {
    // const checked = _event.target.value;
    const { showCorrectAnswers } = this.state;
    await this.setState({
      showCorrectAnswers: !showCorrectAnswers
    });
    this.validate();
  }

  handleClickIgnoreItemOptionShuffle = async (_event) => {
    // const checked = _event.target.value;
    const { ignoreItemOptionShuffle } = this.state;
    await this.setState({
      ignoreItemOptionShuffle: !ignoreItemOptionShuffle
    });
    this.validate();
  }

  handleChangeTypingBackspace = async (value) => {
    await this.setState({typingAllowBackspace: value});
    this.validate();
  }

  handleChangeTypingText = async (_event, { value }) => {
    await this.setState({typingText: value});
    this.validate();
  };

  handleChangeTypingDuration = async (_event, { value }) => {
    await this.setState({typingDuration: value});
    this.validate();
  };

  handleChangeMinimumSpeed = async (_event, { value }) => {
    await this.setState({typingMinimumSpeed: value});
    this.validate();
  };

  handleChangeMinimumAccuracy = async (_event, { value }) => {
    await this.setState({typingMinimumAccuracy: value});
    this.validate();
  };

  editorChange = (data, _assignment) => {
    if (data) {
      this.setState({ instruction: data });
    } else {
      this.setState({ instruction: '<p></p>' });
    }
  }

  setLateSubmission = async (allowLateSubmission) => {
    const { timezoneEndDate, timezoneEndTime } = this.state;
    if (allowLateSubmission) {
      this.setState({ lateSubmitDate: timezoneEndDate, lateSubmitTime: timezoneEndTime });
    }
    await this.setState({ allowLateSubmission });
    this.validate();
  }

  handleChangeActivityMode = (activityModeId) => {
    this.setState({ activityModeId });
  }

  deleteAssignment = async () => {
    const { assignmentId, assignmentManager, classroomManager, deleteAssignment } = this.props;
    await assignmentManager.deleteAssignment(assignmentId);
    this.closeEditAssignmentModal();
    await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);
    deleteAssignment && await deleteAssignment(assignmentId);
  }

  closeWarning = () => {
    this.setState({ openWarning: false });
  }

  handleClickActivityNumberLink = () => {
    const { assignmentId, classroomManager, gradebookManager, history } = this.props;
    gradebookManager.setActiveGradebookType('aggregate');
    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = urlParams.get('classroomId') || props.classroomId || classroomManager.currentClassroomId;
    const routerUrl = `/gradebook?assignmentId=${assignmentId}&view=${VIEW_SELECTION.GRADEBOOK}&fromAssignments=true&classroomId=${classroomId}`;
    history.push(routerUrl);
    this.cancel();
  }

  cancel = () => {
    const { originalAssignment } = this.state;
    const { assignmentManager, gradebookManager } = this.props;
    assignmentManager.setAssignment(originalAssignment);
    gradebookManager.setCurrentAssignment(originalAssignment);

    this.setState(this.getInitialState());
    this.closeEditAssignmentModal();
  }

  submitEditAssignment = async () => {
    this.setState({ isSubmitting: true });
    const { assignmentId, assignmentManager, classroomManager, gradebookManager } = this.props;
    const assignment = await assignmentManager.getAssignmentAsync(assignmentId);
    const additionalPropertiesJson = (assignment.additionalPropertiesJson) ? assignment.additionalPropertiesJson : {};

    const { hasError } = this.validate();
    if (hasError) {
      return;
    }

    const {
      allowLateSubmission, ignoreItemOptionShuffle, status, timezoneStartDate, timezoneStartTime,
      timezoneEndDate, timezoneEndTime, includeInReports, lateSubmitDate, lateSubmitTime, scoresReleased,
      studentReview, instruction, randomizeQuestions, showCorrectAnswers, activityModeId, nickname,
      typingAllowBackspace, typingText, typingDuration, typingMinimumSpeed, typingMinimumAccuracy
    } = this.state;

    const startDateTime = assignmentManager.convertJSStringToJSDate(timezoneStartDate, timezoneStartTime);
    const endDateTime = assignmentManager.convertJSStringToJSDate(timezoneEndDate, timezoneEndTime);

    additionalPropertiesJson.shuffle_items = randomizeQuestions;
    additionalPropertiesJson.showCorrectAnswers = showCorrectAnswers;
    additionalPropertiesJson.ignoreItemOptionShuffle = ignoreItemOptionShuffle;
    additionalPropertiesJson.publisherModeId = activityModeId;
    additionalPropertiesJson.allowLateSubmission = allowLateSubmission;
    additionalPropertiesJson.typingAllowBackspace = typingAllowBackspace;
    additionalPropertiesJson.typingText = typingText;
    additionalPropertiesJson.typingDurationInSeconds = typingDuration;
    additionalPropertiesJson.typingMinSpeed = typingMinimumSpeed;
    additionalPropertiesJson.typingMinAccuracy = typingMinimumAccuracy;
    if (allowLateSubmission) {
      additionalPropertiesJson.lateSubmitDate = assignmentManager.convertAssignmentDateToJavaString(assignmentManager.convertJSStringToJSDate(lateSubmitDate, lateSubmitTime));
    }

    const changes = {
      status,
      timezoneStartDate,
      timezoneStartTime,
      timezoneEndDate,
      timezoneEndTime,
      timezoneStartAsDate: startDateTime,
      timezoneEndAsDate: endDateTime,
      includeInReports,
      scoresReleased,
      studentReview,
      instruction,
      additionalPropertiesJson,
      nickname: nickname ? nickname.trim() : ''
    };
    const result = await AssignmentService.updateAssignment(assignment, changes);

    if (result) {
      // update the fullTimezoneEndTime for UI purposes
      changes.fullTimezoneEndTime = endDateTime.toLocaleString();
      assignmentManager.setAssignment({ ...assignment, ...changes });
      gradebookManager.setCurrentAssignment({ ...assignment, ...changes });
      this.closeEditAssignmentModal();
      await classroomManager.fetchClassroomData(classroomManager.currentClassroomId);
      this.setState({ isSubmitting: false });
    } else {
      this.setState({
        isSubmitting: false,
        hasServerError: true,
        serverErrorMsg: 'Something went wrong when saving the assignment, please try again'
      });
    }
  }

  validate = async () => {
    const { classroomManager } = this.props;
    const {
      allowLateSubmission, assignment, status, timezoneStartDate, timezoneStartTime,
      timezoneEndDate, timezoneEndTime, includeInReports, lateSubmitDate, lateSubmitTime,
      studentReview, scoresReleased
    } = this.state;

    let classroom = null;
    if (assignment) {
      const classroomId = (assignment.classroomId !== null && assignment.classroomId !== 'undefined') ? assignment.classroomId : assignment.subdomainId;
      if (classroomId) {
        classroom = classroomManager.getClassroom(classroomId);
        if (!classroom) {
          classroom = await classroomManager.fetchClassroom(classroomId);
        }
      }
    }

    const assignmentToValidate = {
      allowLateSubmission,
      ...assignment,
      lateSubmitDate,
      lateSubmitTime,
      status,
      timezoneStartDate,
      timezoneStartTime,
      timezoneEndDate,
      timezoneEndTime,
      includeInReports,
      studentReview,
      scoresReleased,
      classroomTimezoneStartDate: classroom ? classroom.timezoneStartDate : null,
      classroomStartTime: classroom ? classroom.startTime : null,
      classroomTimezoneEndDate: classroom ? classroom.timezoneEndDate : null,
      classroomEndTime: classroom ? classroom.endTime : null
    };
    const { hasError, errors } = AssignmentService.validateEditAssignmentModal(assignmentToValidate, classroom);
    this.setState({ hasError, errors });
  }

  closeEditAssignmentModal = () => {
    this.props.closeEditAssignment();
  }

  getDefaultImage = () => {
    const { courseManager } = this.props;
    const isCustomCourse = courseManager.isCustomCourse(courseManager.currentCourseId);

    let customLeafCardImageClassNames = 'default';
    let defaultCustomLeafCardImg = assessmentDefaultCardImage;

    if (courseManager.useSpecialCustomCourseCardImages && isCustomCourse) {
      customLeafCardImageClassNames += 'custom-default-special';
      defaultCustomLeafCardImg = customAssessmentDefaultCardImageUri;
    }
    return (
      <Image
        alt='Custom default lesson image'
        className={customLeafCardImageClassNames}
        src={defaultCustomLeafCardImg} />
    );
  }

  // if the assignment has expired and the teacher changes the date or time to the future, update the status
  checkResetAssignmentStatus = (newTimezoneEndDate, newTimezoneEndTime) => {
    const { assignment } = this.state;
    const { assignmentManager } = this.props;

    const { isAssignmentExpired, isAssignmentExpiring } = assignmentManager.getAssignmentExpiredStatus(assignment);

    if (isAssignmentExpired || isAssignmentExpiring) {
      const timezoneEndDate = newTimezoneEndDate || assignment.timezoneEndDate;
      const timezoneEndTime = newTimezoneEndTime || assignment.timezoneEndTime;
      const currentEndDate = assignmentManager.convertJSStringToJSDate(timezoneEndDate, timezoneEndTime);
      const assignmentEndDate = assignmentManager.convertJSStringToJSDate(assignment.timezoneEndDate, assignment.timezoneEndTime);
      if (currentEndDate.getTime() > assignmentEndDate.getTime()) {
        // call to update status, create pseudo event object as param.
        this.handleChangeAssignmentStatus({ target: { value: ASSIGNMENT_STATUS.STARTED } });
      }
    }
  }

  renderLeftColumnContent = (assignmentReady) => {
    const { t } = this.props;
    const { assignment, hasServerError, serverErrorMsg } = this.state;
    const { SCRadio } = this;

    const assignedUserIds = (assignmentReady) ? assignment.assignEntityId.split(',') : '';

    const isAssignmentTypeClassroom = assignment.assignEntityTypeId === ASSIGNMENT_TYPE.CLASSROOM;
    const isAssignmentTypeUser = assignment.assignEntityTypeId === ASSIGNMENT_TYPE.USER;
    const isAssignmentTypeAllClasses = assignment.assignEntityTypeId === ASSIGNMENT_TYPE.ALL_CLASSES;

    return (
      <>
        <Header as='h3' className='section-header'>{t('assignTo', 'Assign To')}</Header>
        <Container className='field-wrapper'>

          {/* ASSIGNMENT TYPE: Entire Class */}
          <Form.Field>
            {(isAssignmentTypeClassroom && assignedUserIds.length === 1) && (
              <SCRadio
                checked
                disabled
                label={t('entireClassLabel', 'Entire Class')}
                name='typeGroup' />
            )}
          </Form.Field>

          {/* ASSIGNMENT TYPE: Individual */}
          <Form.Field>
            {(isAssignmentTypeUser) && (
              <SCRadio
                checked
                disabled
                label='Individual'
                name='typeGroup' />
            )}
            {(isAssignmentTypeUser) && this.renderAssignmentRoster(assignedUserIds)}
          </Form.Field>

          {/* ASSIGNMENT TYPE: All Classes */}
          <Form.Field>
            {(isAssignmentTypeClassroom && assignedUserIds.length > 1) && (
              <SCRadio
                checked
                disabled
                label={t('allClassesLabel', 'All Classes')}
                name='typeGroup' />
            )}
            {isAssignmentTypeAllClasses && this.renderAllClassrooms(assignedUserIds)}
          </Form.Field>
        </Container>
        <Message
          className='start-message'
          content={serverErrorMsg}
          error
          visible={hasServerError} />
      </>
    );
  }

  renderRightColumnContent = (hasError = false, errorMsgString = '') => {
    const { assignmentManager } = this.props;
    const { assignment, showKeyboardingSettings } = this.state;
    // TODO uncomment lessons and assessments when the showCorrectAnswers feature is rolled out.
    const showAdditionalSettings = (
      // assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LESSON ||
      // assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT ||
      assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE ||
      assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.KEYBOARDING_RESOURCE
    );

    return (
      <>
        {this.renderActivityNumberLink()}
        {this.renderStatusSection()}
        {this.renderTimeframeSection()}
        {this.renderDateRangeSection(hasError, errorMsgString)}
        {assignmentManager.lateSubmissionOptionAllowed && this.renderLateSubmissionSection(hasError, errorMsgString)}
        <Message hidden={!hasError} negative>{errorMsgString}</Message>
        {assignment.modeOverrideAllowed && this.renderModeOverrideSection()}
        {this.renderReportsAndGradingSection()}
        {(showAdditionalSettings || showKeyboardingSettings) && this.renderAdditionalSettingsSection()}
        {this.renderStudentInstructionSection()}
        {this.renderAccommodationTogglerSection()}
        {this.renderDeleteAssignmentSection()}
      </>
    );
  }

  renderAssignmentRoster = (assignedUserIds) => {
    const { classroomId, assignment, classroomManager } = this.props;
    const roster = classroomManager.rosterMap.get(classroomId);
    if (roster === null || roster === undefined || assignment === null || assignment === undefined) {
      return null;
    }
    const { SCCheckbox } = this;

    return (
      <Container className='check-list'>
        {roster.map((student, _index) => {
          const checked = (assignedUserIds.indexOf(student.userId) > -1);
          return (
            <SCCheckbox
              key={`${student.userId + student.id}_R`}
              checked={checked}
              label={`${student.firstName} ${student.lastName}`}
              readOnly />
          );
        })}
      </Container>
    );
  }

  renderAllClassrooms = (assignedClasses) => {
    const {
      classroomManager
    } = this.props;

    const { SCCheckbox } = this;

    return (
      <Container className='check-list'>
        {classroomManager.classroomsArray.filter((classroom) => !classroom.archived).map((classroom, _index) => {
          const checked = (assignedClasses.indexOf(classroom.id) > -1);
          const classNameObj = UtilityService.reactHtmlParserWrapper(
            classroomManager.getClassName(classroom.id)
          );
          return (
            <SCCheckbox
              key={`${classroom.id}_R`}
              checked={checked}
              label={classNameObj.stripped}
              readOnly
              title={classNameObj.stripped} />
          );
        })}
      </Container>
    );
  }

  /* SECTION: ACTIVITY NUMBER (ASGMT) */
  renderActivityNumberLink = () => {
    const { activityNumber, t } = this.props;
    return activityNumber && (
      <>
        <div className='activity-number-link-wrapper'>
          <div
            className='activity-number-link'
            onClick={this.handleClickActivityNumberLink}>
            {t('viewActivityNumberGradebook', { activityNumber })}
          </div>
        </div>
      </>
    );
  }

  /* SECTION: STATUS */
  renderStatusSection = () => {
    const { t } = this.props;
    const { statusDescription } = this.state;
    return (
      <>
        <Header as='h3' className='section-header'>
          {t('status')}
        </Header>
        <Container className='field-wrapper'>
          <Container className='status-controls'>
            <Form.Field className='status-name'>
              <Header as='h4' className='section-sub-header'>{t('name')}</Header>
              {this.renderAssignmentStatusRadioButton(ASSIGNMENT_STATUS.LOCKED)}
              {this.renderAssignmentStatusRadioButton(ASSIGNMENT_STATUS.READY)}
              {this.renderAssignmentStatusRadioButton(ASSIGNMENT_STATUS.STARTED)}
              {this.renderAssignmentStatusRadioButton(ASSIGNMENT_STATUS.CLOSED)}
              {this.renderAssignmentStatusRadioButton(ASSIGNMENT_STATUS.COMPLETED)}
            </Form.Field>
            <Form.Field className='status-description'>
              <Header as='h4' className='section-sub-header'>
                {t('description')}
              </Header>
              <div className='status-description'>
                {statusDescription}
              </div>
            </Form.Field>

          </Container>
        </Container>
      </>
    );
  }

    /* SECTION: Timeframe DATE RANGE */
    renderTimeframeSection = (hasError, errorMsgString = '') => {
      const {
        timeframeStartDate, timeframeEndDate, formattedTimeframeStartDate, formattedTimeframeStartTime,
        formattedTimeframeEndDate, formattedTimeframeEndTime
      } = this.state;
      const { t } = this.props;

      if (timeframeStartDate || timeframeEndDate) {
        return (
          <>
            <Header as='h3' className='section-header'>{t('timeFrame')}</Header>
            <div className='section-subtext'>
              {t('timeFrameDescription')}
            </div>
            <Container className='field-wrapper'>
              <Container className='time-display'>
                <Form.Field>
                  <label>{t('startDate')}: </label>
                  <span>{formattedTimeframeStartDate}</span>
                </Form.Field>
                <Form.Field>
                  <label>{t('startTime')}: </label>
                  <span>{formattedTimeframeStartTime}</span>
                </Form.Field>
              </Container>
              <Container className='time-display'>
                <Form.Field>
                  <label>{t('endDate')}: </label>
                  <span>{formattedTimeframeEndDate}</span>
                </Form.Field>
                <Form.Field>
                  <label>{t('endTime')}: </label>
                  <span>{formattedTimeframeEndTime}</span>
                </Form.Field>
              </Container>
            </Container>
          </>
        )
      } else {
        return (<></>);
      }
    }

  /* SECTION: DATE RANGE */
  renderDateRangeSection = (_hasError, _errorMsgString = '') => {
    const { t } = this.props;
    const {
      assignment, timeframeStartDate, timeframeEndDate, timezoneEndDate, timezoneEndTime, timezoneStartDate, timezoneStartTime
    } = this.state;
    return (
      <>
        <Header as='h3' className='section-header'>
          {t('dateRange')}
        </Header>
        {(timeframeStartDate || timeframeEndDate) && (
          <div className='section-subtext'>
            {t('dateRangeDescription')}
          </div>
        )}
        <Container className='field-wrapper'>
          <Container className='time-controls'>
            <Form.Field>
              <DateInput
                animation='false'
                className='date-input'
                dateFormat='MM/DD/YYYY'
                iconPosition='left'
                label={t('startDate')}
                name='startDate'
                onChange={(event, { value }) => this.handleChangeStartDate(event, value)}
                placeholder={t('startDate')}
                value={timezoneStartDate} />
            </Form.Field>
            <Form.Field>
              <TimeInput
                animation='false'
                className='time-input'
                iconPosition='left'
                label={t('startTime')}
                name='startDate'
                onChange={(e, { value }) => this.handleChangeStartTime(e, value, assignment)}
                placeholder={t('startTime')}
                timeFormat='AMPM'
                value={timezoneStartTime} />
            </Form.Field>
          </Container>
        </Container>
        <Container className='field-wrapper'>
          <Container className='time-controls'>
            <Form.Field>
              <DateInput
                animation='false'
                className='date-input'
                dateFormat='MM/DD/YYYY'
                iconPosition='left'
                label={t('endDate')}
                name='endDate'
                onChange={(event, { value }) => this.handleChangeEndDate(event, value)}
                placeholder={t('endDate')}
                value={timezoneEndDate} />
            </Form.Field>
            <Form.Field>
              <TimeInput
                animation='false'
                className='time-input'
                iconPosition='left'
                label={t('endTime')}
                name='endTime'
                onChange={(event, { value }) => this.handleChangeEndTime(event, value)}
                placeholder={t('endTime')}
                timeFormat='AMPM'
                value={timezoneEndTime} />
            </Form.Field>
          </Container>
        </Container>
      </>
    );
  }

  renderLateSubmissionSection = (hasError, errorMsgString = '') => {
    const { assignmentManager, t } = this.props;
    const { allowLateSubmission, assignment, lateSubmitDate, lateSubmitTime } = this.state;
    const { assignEntityTypeId } = assignment;
    const validLateSubmissionAssignmentType = (assignEntityTypeId !== ASSIGNMENT_TYPE.CLASSROOM &&
      assignEntityTypeId !== ASSIGNMENT_TYPE.ALL_CLASSES);
    return (
      <>
        {(assignmentManager.lateSubmissionOptionAllowed &&
          validLateSubmissionAssignmentType) && (
          <>
            <Header as='h3'>
              <div className='allow-late-submit-control'>
                <Radio
                  checked={allowLateSubmission}
                  disabled={!assignmentManager.lateSubmissionOptionAllowed}
                  label={t('lateSubmission')}
                  onChange={() => { this.setLateSubmission(!allowLateSubmission); }}
                  toggle />
                <Popup
                  content={t('lateSubmissionText')}
                  hideOnScroll
                  on='click'
                  position='top center'
                  trigger={<Image alt='' className='img-info' src={info} />} />
              </div>
            </Header>
            <Container className='field-wrapper'>
              {allowLateSubmission && (
                <Container className='time-controls'>
                  <Form.Field>
                    <DateInput
                      animation='false'
                      className='date-input'
                      dateFormat='MM/DD/YYYY'
                      iconPosition='left'
                      label={t('lateSubmitDateLabel')}
                      name='endDate'
                      onChange={this.handleChangeLateSubmitDate}
                      placeholder={t('dueDate')}
                      value={(lateSubmitDate !== null) ? lateSubmitDate : ''} />
                  </Form.Field>
                  <Form.Field>
                    <TimeInput
                      animation='false'
                      className='time-input'
                      iconPosition='left'
                      label='&nbsp;'
                      name='lateSubmitTime'
                      onChange={this.handleChangeLateSubmitTime}
                      placeholder={t('dueTime')}
                      timeFormat='AMPM'
                      value={(lateSubmitTime !== null) ? lateSubmitTime : ''} />
                  </Form.Field>
                </Container>
              )}
            </Container>
          </>
        )}
      </>
    );
  }

  /* SECTION: Theme Override */
  renderModeOverrideSection = () => {
    const { t } = this.props;
    const { activityModeId, assignment } = this.state;
    return (
      <>
        <Container className='section-wrapper mode'>
          <div className='section-header'>
            {t('modeOption')}
          </div>
          <div className='section-subtext'>
            {t('modeOptionDescription')}
          </div>
          <ActivityModeDropdown
            activityMode={activityModeId}
            courseResourceElementId={assignment.courseResourceElementId}
            setActivityMode={this.handleChangeActivityMode} />
        </Container>
      </>
    );
  }

  /* SECTION: REPORTS AND GRADING */
  renderReportsAndGradingSection = () => {
    const { t } = this.props;
    return (
      <>
        <Container className='section-wrapper reports-and-grading'>
          <div className='section-header'>
            {t('reportsAndGrading')}
          </div>
          <div className='section-subtext'>
            {t('reportsAndGradingDescription')}
          </div>
          {this.renderAssignmentReleaseToggle('includeInReports', 'releaseToReportsLabel')}
          {this.renderAssignmentReleaseToggle('scoresReleased')}
          {this.renderAssignmentReleaseToggle('studentReview')}

          {/* TODO DEMO-746, DEMO-907 */}
          {/* TODO remove the below code if we confirm we want to ALWAYS show the review toggle (regardless of assignment type) */}
          {/* {contentManager.isControlledReviewableType(
            assignment.contentItemEntityTypeId,
            assignment.pdfDeliveryFormat
          ) && this.renderAssignmentReleaseToggle('studentReview')} */}
        </Container>
      </>
    );
  }

  /* SECTION: STUDENT INSTRUCTION */
  renderStudentInstructionSection = () => {
    const { assignment, instruction } = this.state;
    const { assignmentInstruction } = this.props;
    const editorDataStr = instruction || assignmentInstruction || null;
    return (
      <>
        <Header as='h3' className='section-header'>
          Student Instructions (click to edit)
        </Header>
        <Container className='editor-container'>
          <Form.Field>
            <RichTextEditor
              data={editorDataStr}
              maxCharacters={500}
              onChange={(data) => this.editorChange(data, assignment)} />
          </Form.Field>
        </Container>
      </>
    );
  }

  /* SECTION: ACCOMMODATIONS */
  renderAccommodationTogglerSection = () => {
    const { accommodationsManager } = this.props;
    const { includeAssignmentAccommodations } = accommodationsManager;

    const { assignment } = this.state;
    const rosterItems = RosterService.getSelectedUsersRoster({ assignment });

    const { AccommodationsTogglerSection } = this;

    return includeAssignmentAccommodations && (
      <>
        <AccommodationsTogglerSection {...this.props}
          assignment={assignment}
          rosterItems={rosterItems}
          style={{ marginTop: '30px' }} />
      </>
    );
  }

  /* SECTION: ADDITIONAL SETTINGS */
  renderAdditionalSettingsSection = () => {
    const {
      assignment,
      showKeyboardingSettings,
      typingAllowBackspace,
      typingDuration,
      typingMinimumAccuracy,
      typingMinimumSpeed,
      typingText,
    } = this.state;
    const { t } = this.props;

    const {
      allowBackspaceEdit,
      allowBackpaceShow,
      allowDurationEdit,
      allowDurationShow,
      allowMinSpeedEdit,
      allowMinSpeedShow,
      allowMinAccuracyEdit,
      allowMinAccuracyShow,
      allowTestTextEdit,
      allowTestTextShow
    } = CONTENT_ITEM_SUB_TYPES.getVisibilityPermissions(assignment.contentItemEntitySubTypeId);

    return (
      <>
        <Header as='h3' className='section-header'>
          Additional Settings
        </Header>
        <Container className='editor-container additionalSettings'>
          {/* Add lessons and assessments to this filter when the showCorrectAnswers feature gets rolled out. */}
          {((assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) || Auth.showShuffleOverride) && (
            <>
              {(assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) && (
                <Form.Field>
                  <Radio
                    checked={this.state.randomizeQuestions} // eslint-disable-line react/destructuring-assignment
                    label={t('randomizeQuestions', 'Randomize question order')}
                    name='randomizeQuestions'
                    onClick={(event) => this.handleClickRandomizeQuestions(event)} />
                </Form.Field>
              )}
              {((assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LEARNOSITY_ACTIVITY_RESOURCE) || Auth.showShuffleOverride) && (
                <Form.Field>
                  <div className={`shuffleItemsWrapper ${Auth.showShuffleOverride ? 'showShuffleOverride' : ''}`}>
                    {Auth.showShuffleOverride ? (
                      <Checkbox
                        checked={!this.state.ignoreItemOptionShuffle} // eslint-disable-line react/destructuring-assignment
                        label={t('ignoreItemOptionShuffle', 'Ignore item option shuffle')}
                        name='ignoreItemOptionShuffle'
                        onClick={(event) => this.handleClickIgnoreItemOptionShuffle(event)} />
                    ) : (
                      <Radio
                        checked={!this.state.ignoreItemOptionShuffle} // eslint-disable-line react/destructuring-assignment
                        label={t('ignoreItemOptionShuffle', 'Ignore item option shuffle')}
                        name='ignoreItemOptionShuffle'
                        onClick={(event) => this.handleClickIgnoreItemOptionShuffle(event)} />
                    )}
                    <Popup
                      content={t('ignoreItemOptionShuffleMessage')}
                      hideOnScroll
                      on='hover'
                      trigger={<Image alt='' className='img-info' src={info} />}
                      wide />
                  </div>
                </Form.Field>
              )}
            </>
          )}
          {/* <Form.Field>  TODO - unhide this when this feature gets rolled out
            { (assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.LESSON ||
                assignment.contentItemEntityTypeId === CONTENT_ITEM_TYPES.ASSESSMENT) &&
              <Radio
                checked={this.state.showCorrectAnswers}
                label={'Show feedback'}
                name='showCorrectAnswers'
                onClick={(event) => this.handleClickShowCorrectAnswers(event)}/>
            }
          </Form.Field> */}
          {showKeyboardingSettings && (
            <>
              {allowBackpaceShow && (
                <Form.Field>
                  <div className='resource-typing-input-wrapper'>
                    <Radio
                      checked={typingAllowBackspace}
                      disabled={!allowBackspaceEdit}
                      label={t('allowBackSpaceLabel')}
                      onChange={() => { this.handleChangeTypingBackspace(!typingAllowBackspace); }}
                      toggle />
                  </div>
                </Form.Field>
              )}
              {allowTestTextShow && (
                <Form.Field>
                  <div className='resource-option-label'>{t('typingTextLabel')}</div>
                  <div className='resource-typing-input-wrapper'>
                    <Input
                      className='input-typing-text'
                      disabled={!allowTestTextEdit}
                      onChange={this.handleChangeTypingText}
                      size='mini'
                      value={typingText} />
                  </div>
                </Form.Field>
              )}
              {allowDurationShow && (
                <Form.Field>
                  <div className='resource-option-label'>{t('typingDurationLabel')}</div>
                  <div className='resource-typing-input-wrapper'>
                    <Input
                      className='input-typing-text'
                      disabled={!allowDurationEdit}
                      onChange={this.handleChangeTypingDuration}
                      size='mini'
                      value={typingDuration} />
                  </div>
                </Form.Field>
              )}
              <div className='resource-typing-input-group'>
                {allowMinSpeedShow && (
                  <Form.Field>
                    <div className='resource-typing-input-wrapper col'>
                      <div className='resource-option-label'>{t('typingMinimumSpeedLabel')}</div>
                      <Input
                        className='input-typing-text'
                        disabled={!allowMinSpeedEdit}
                        onChange={this.handleChangeMinimumSpeed}
                        size='mini'
                        value={typingMinimumSpeed} />
                    </div>
                  </Form.Field>
                )}
                {allowMinAccuracyShow && (
                  <Form.Field>
                    <div className='resource-typing-input-wrapper col'>
                      <div className='resource-option-label'>{t('typingMinimumAccuracyLabel')}</div>
                      <Input
                        className='input-typing-text'
                        disabled={!allowMinAccuracyEdit}
                        onChange={this.handleChangeMinimumAccuracy}
                        size='mini'
                        value={typingMinimumAccuracy} />
                    </div>
                  </Form.Field>
                )}
              </div>
            </>
          )}
        </Container>
      </>
    );
  }

  /* SECTION: DELETE ASSIGNMENT */
  renderDeleteAssignmentSection = () => {
    const { t } = this.props;
    const { assignment } = this.state;
    const { DeleteAssignmentModal } = this;
    return (
      <>
        <Header as='h3' className='section-header'>{t('deleteAssignment')}</Header>
        <Container>
          <Form.Field>
            <DeleteAssignmentModal assignment={assignment} deleteFunction={this.deleteAssignment} />
          </Form.Field>
        </Container>
      </>
    );
  }

  renderAssignmentStatusRadioButton = (status, _translationKey = null) => {
    const { t } = this.props;
    const { SCRadio } = this;
    return (
      <SCRadio
        additionalClassNames={`radio-assignment-status ${status}`}
        // eslint-disable-next-line react/destructuring-assignment
        checked={this.state.status === status}
        label={t(status)}
        // TODO remove // label={translationKey ? t(translationKey) : ASSIGNMENT_STATUS.getFlag(status)}
        name='statusGroup'
        onChange={(event) => this.handleChangeAssignmentStatus(event)}
        value={status} />
    );
  }

  renderAssignmentReleaseToggle = (toggleKey, toggleLabel = null) => {
    const { t } = this.props;
    return (
      <Radio
        // eslint-disable-next-line react/destructuring-assignment
        checked={this.state[toggleKey]}
        label={t(toggleLabel || toggleKey)}
        onChange={(event, data) => {
          this.handleChangeToggle(event, data, toggleKey);
        }}
        toggle />
    );
  }

  render() {
    const {
      assignmentManager, contentImageUrl, editAssignmentOpen, standards, t
    } = this.props;

    const { StandardsList } = this;

    const { assignment, errors = null, hasError = false, isSubmitting, nickname } = this.state;

    const assignmentReady = (assignment !== null && assignment !== undefined);

    const { ModalBannerDraggable } = this;

    const { TimeAdjustModal } = this;

    let errorMsgString = null;
    if (hasError === true) {
      errorMsgString = AssignmentService.getApplicableErrorMessagesForAssignment(errors);
    }

    if (assignmentReady) {
      const {
        resourceWebTitleObj, resourceWebSubtitleObj
      } = AssignmentService.getAssignmentTitleAndDescriptionParserWrapperObjs(assignment);

      const nicknameText = nickname && nickname !== 'undefined' ? nickname : '';

      return (
        <div className='EditAssignmentModal'>
          <ReactModalDraggable
            className='editAssignmentModal'
            isOpen={editAssignmentOpen}
            onRequestClose={(event) => {
              const isOverlay = event?.target?.className === 'flexible-modal-mask';
              if (!isOverlay) {
                this.cancel(assignment);
              }
            }}>
            <div className='modalBody'>
              {/* MODAL HEADER CONTENT */}
              <ModalBannerDraggable
                label={t('editAssignment')}
                onClose={() => this.cancel(assignment)} />
              <div className='content'>
                <div className='assignment-banner'>
                  {(contentImageUrl !== null &&
                    contentImageUrl !== undefined && contentImageUrl !== '')
                    ? <Image src={contentImageUrl} /> : this.getDefaultImage()}
                  <div className='title-wrapper'>
                    <div
                      className='flex-header'
                      title={resourceWebTitleObj.stripped}>
                      {resourceWebTitleObj.parsed}
                    </div>
                    <div
                      className='flex-subheader'
                      title={resourceWebSubtitleObj.stripped}>
                      {resourceWebSubtitleObj.parsed}
                    </div>
                    <StandardsList
                      assignmentId={assignment.id}
                      contentImageUrl={contentImageUrl}
                      standards={standards}
                      title={resourceWebTitleObj.original} />
                    {assignmentManager.useAssignmentNickname && (
                      <div className='nickname-wrapper'>
                        <Container className='nickname-container'>
                          <div className='label'>
                            {`${t('nicknameLabel', 'Nickname')}`}:
                          </div>
                          <Form.Field>
                            <Input
                              className='nickname-input'
                              name='nickname'
                              onChange={this.handleChangeNickname}
                              placeholder={t('nicknamePlaceholder', 'Enter nickname...')}
                              value={nicknameText} />
                          </Form.Field>
                        </Container>
                      </div>
                    )}
                  </div>
                </div>

                {/* MODAL BODY CONTENT */}
                <div className='ui form'>
                  <Container className='assignment-wrapper-modal'>
                    <div className='left-column'>
                      {this.renderLeftColumnContent(assignmentReady)}
                    </div>
                    <div className='right-column'>
                      {this.renderRightColumnContent(hasError, errorMsgString)}
                    </div>
                  </Container>
                </div>
              </div>
              {/* TODO possibly remove — `TimeAdjustModal` appears to be unused */}
              <TimeAdjustModal close={this.closeWarning} open={this.state.openWarning} status={this.state.warningStatus} />

              {/* MODAL ACTION BUTTONS */}
              <div className='actions'>
                <Button
                  basic
                  className='cancelButton'
                  onClick={() => this.cancel(assignment)}
                  primary
                  type='button'>
                  {t('cancel')}
                </Button>
                <Button
                  className='ui primary button saveButton'
                  disabled={hasError}
                  onClick={this.submitEditAssignment}
                  type='button'>
                  {t('save')}
                  {isSubmitting && (
                    <Loader
                      key={0}
                      active
                      inline
                      size='tiny'
                      style={{ marginLeft: '4px', marginRight: '-4px' }} />
                  )}
                </Button>
              </div>
            </div>
          </ReactModalDraggable>
        </div>
      );
    }
    return (<></>);
  }
}

SatCoreRegister('EditAssignmentModal', EditAssignmentModal);
