import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import { useExpanded, useTable } from 'react-table';

import { Icon, Loader, Pagination, Table } from 'semantic-ui-react';

import iconBarChartThin from '../../img/icon-bar-chart-thin.svg';

import '../../css/reports/ReportTable.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import { REPORT_URL_KEY } from '../../services/reports/ReportConstants';

import { removeDuplicates } from '../../utils';

import PopupService from '../../services/PopupService';
import ReportIdentityService from '../../services/reports/ReportIdentityService';
import ReportOverrideService from '../../services/reports/ReportOverrideService';
import ReportScoreService from '../../services/reports/ReportScoreService';
import ReportStandardsService from '../../services/reports/ReportStandardsService';
import ReportTableService from '../../services/reports/ReportTableService';
import ReportTypeService from '../../services/reports/ReportTypeService';

import ReportDownloadButtons from './ReportDownloadButtons';

/**
 * To help understand the overall architecture of our reports,
 * it may be helpful to visit the following links:
 *
 * C2C Satellite Report Code Structure diagram
 * https://drive.google.com/file/d/1cAVis4F8VAfQW5w__AI0-jkWsuHGzWaC/view
 *
 * Overview of working with `react-table` dependency
 * https://react-table-v7-docs.netlify.app/docs/overview
 * https://react-table-v7-docs.netlify.app/docs/quick-start
 * https://react-table-v7-docs.netlify.app/docs/examples/basic
 *
 * Confluence
 * https://evotext.atlassian.net/wiki/spaces/OP/overview#:~:text=C2C%20Satellite%20Report%20Code%20Structure%20(4/15/22)
 *
 * Report refactoring JIRA story & primary ticket
 * https://evotext.atlassian.net/browse/DEMO-1310
 * https://evotext.atlassian.net/browse/DEMO-1320
 */
const ReportTable = observer((initProps) => {
  const { loadingReportView, t } = initProps;

  const {
    reportContextManager, reportCourseManager,
    reportIdentityManager, reportOverrideManager,
    reportStandardsManager, reportTableManager,
    userManager, groupsManager
  } = useContext(MobXProviderContext);

  const [loadingReportTable, setLoadingReportTable] = useState(true);

  const ReportAverageResultCell = SatCoreComponent('ReportAverageResultCell');
  const ReportFacultyResultCell = SatCoreComponent('ReportFacultyResultCell');
  const ReportResourceCountCell = SatCoreComponent('ReportResourceCountCell');
  const ReportResourceTitleCell = SatCoreComponent('ReportResourceTitleCell');
  const ReportScoreSummaryBarCell = SatCoreComponent('ReportScoreSummaryBarCell');
  const ZeroStateCard = SatCoreComponent('ZeroStateCard');

  const {
    activeReportFacultyType,
    activeReportType,
    isCourseReport,
    isFacultyAdminReport,
    isFacultyClassroomReport,
    isFacultyDistrictReport,
    isFacultySchoolReport,
    isStandardsReport,
    isTableDetailReport,
    isTableIndividualReport,
    reportInfoClassNames
  } = reportIdentityManager;

  // TODO unused // const hasBetaPermission = userManager.hasSatelliteBetaAccessUserPermission;

  /** @see https://react-table-v7-docs.netlify.app/docs/quick-start */
  const generateColumnsConfig = () => {
    const cellExpanderConfig = {
      /** CONFIG: `cell-expander` */
      Cell: (props) => renderRowExpanderCell(props),
      Header: (props) => renderHeaderRowExpanderCell(props),
      className: `cell-expander ${reportInfoClassNames}`,
      id: 'expander'
    };
    let reportColumnsConfig = [
      {
      /** CONFIG: `cell-resource-title` */
        Cell: (props) => renderResourceTitleCell(props),
        Header: t(`${activeReportType}TableHeader`),
        accessor: (props) => {
          if (isCourseReport) {
            return {
              name: props.elementName
            };
          } else if (isStandardsReport) {
            return {
              description: props.description,
              name: props.name
            };
          }
        },
        className: `cell-after-expander cell-resource-title ${reportInfoClassNames}`
      },
      {
        /** CONFIG: `cell-resource-export` */
        Cell: (props) => renderResourceExportCell(props),
        Header: '',
        accessor: 'resourceExport',
        className: `cell-resource-export ${reportInfoClassNames}`
      },
      {
        /** CONFIG: 'cell-gradebook-view assignments' */
        Cell: (props) => renderGradebookViewCell(props),
        Header: t('assignments'),
        accessor: 'gradbookView',
        className: `cell-gradebook-view assignments ${reportInfoClassNames}`
      },
      {
        /** CONFIG: 'cell-view-details' */
        Cell: (props) => renderViewDetailsCell(props),
        Header: t('viewDetailsHeader'),
        accessor: 'viewDetails',
        className: `cell-view-details ${reportInfoClassNames}`
      },
      {
        /** CONFIG: 'cell-resource-count taken' */
        Cell: (props) => renderResourceCountCell(props, 'taken'),
        Header: t('taken'),
        accessor: (props) => ReportStandardsService.getCustomResourceCountValue(props, 'taken'),
        className: `cell-resource-count taken ${reportInfoClassNames}`
      },
      {
        /** CONFIG: 'cell-resource-count remaining' */
        Cell: (props) => renderResourceCountCell(props, 'remaining'),
        Header: t('remaining'),
        accessor: (props) => ReportStandardsService.getCustomResourceCountValue(props, 'remaining'),
        className: `cell-resource-count remaining ${reportInfoClassNames}`
      },
      {
      /** CONFIG: `cell-average-result` */
        Cell: (props) => renderAverageResultCell(props),
        Header: t(`${activeReportFacultyType}Average`),
        accessor: (props) => {
          const {
            isContextEngagementReport,
            isContextLikertReport,
            isContextPerformanceReport,
            isContextProgressReport,
            isContextUsageReport
          } = reportContextManager;
          if (isContextPerformanceReport) {
            return (!!props.averageScore || props.averageScore === 0) ? props.averageScore : props.classAverage;
          } else if (isContextLikertReport) {
            if (isStandardsReport) {
              return (!!props.averageScore || props.averageScore === 0) ? props.averageScore : props.classAverage;
            } else {
              return (!!props.likertAverageScore || props.likertAverageScore === 0) ? props.likertAverageScore : props.likertClassAverage;
            }
          } else if (isContextUsageReport) {
            return props.averageViewedTime;
          } else if (isContextEngagementReport) {
            return (!!props.classEngagementAverage || props.classEngagementAverage === 0) ? props.classEngagementAverage : '—';
          } else if (isContextProgressReport) {
            return (!!props.progressScore || props.progressScore === 0) ? props.progressScore : '—';
          }
        },
        className: `cell-average-result ${reportInfoClassNames}`
      },
      /** CONFIG: `cell-score-summary-bar` */
      ...ReportTypeService.initReportTableScoreSummaryBarCellConfig({
        ...initProps,
        handleViewNextReport: initProps.handleViewNextReport,
        renderScoreSummaryBarCell,
        renderScoreSummaryBarHeaderCell
      }),
      /** CONFIG: `cell-faculty-result` */
      ...ReportTypeService.initReportTableFacultyCellConfigArray({
        ...initProps,
        handleViewNextReport: initProps.handleViewNextReport,
        renderFacultyResultCell
      })
    ];

    // special case: `hiddenReportTableFacultyCellsMap`
    reportColumnsConfig = reportColumnsConfig.filter((obj) => {
      return !reportTableManager.hiddenReportTableFacultyCellsMap.has(obj.id);
    });

    // special case: `cell-gradebook-view`
    const showAssignmentsColumn = isCourseReport && isFacultyClassroomReport;
    if (!showAssignmentsColumn) {
      reportColumnsConfig = reportColumnsConfig.filter((obj) => {
        return !obj.className?.includes('cell-gradebook-view assignments');
      });
    }

    // special case: `cell-view-details`
    let showViewDetailsColumn = window.location.pathname === REPORT_URL_KEY['/reports/standards-classroom-detail'];
    showViewDetailsColumn = showViewDetailsColumn
      && (reportContextManager.isContextPerformanceReport || reportContextManager.isContextProgressReport
        || reportContextManager.isContextEngagementReport);
    if (!showViewDetailsColumn) {
      reportColumnsConfig = reportColumnsConfig.filter((obj) => {
        return !obj.className?.includes('cell-view-details');
      });
    }

    const { pathname } = window.location;
    const isLti = pathname.includes('/lti-courses');

    // special case: `cell-resource-count taken`
    const showTakenColumn = isStandardsReport && isTableIndividualReport && !isLti;
    if (!showTakenColumn) {
      reportColumnsConfig = reportColumnsConfig.filter((obj) => {
        return !obj.className?.includes('cell-resource-count taken');
      });
    }

    // special case: `cell-resource-count remaining`
    const showRemainingColumn = isStandardsReport && isTableIndividualReport && !isLti;
    if (!showRemainingColumn) {
      reportColumnsConfig = reportColumnsConfig.filter((obj) => {
        return !obj.className?.includes('cell-resource-count remaining');
      });
    }

    // hide class average column in progress report
    const { isContextProgressReport } = reportContextManager;
    const showClassAverageColumn = !isContextProgressReport;
    if (!showClassAverageColumn) {
      reportColumnsConfig = reportColumnsConfig.filter((obj) => {
        return !obj.className?.includes('cell-average-result');
      });
    }

    // special case: individual student report
    if (isTableIndividualReport) {
      // put `cell-average-result` at the end of `reportColumnsConfig` array.
      let cellAverageScoreConfig;
      reportColumnsConfig = reportColumnsConfig.filter((obj) => {
        if (obj.className?.includes('cell-average-result')) {
          cellAverageScoreConfig = obj;
          return false;
        } else {
          return true;
        }
      });
      if (cellAverageScoreConfig) {
        reportColumnsConfig.push(cellAverageScoreConfig);
      }
    }

    // special case: detail report
    if (isTableDetailReport) {
      reportColumnsConfig = reportColumnsConfig.filter((obj) => {
        if (obj.className?.includes('cell-faculty-result')) {
          // if any groups are selected in the filter, only show the columns of the members of those groups
          if (groupsManager.groups.length) {
            if (groupsManager.memberIds.includes(obj.id)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      });
    }

    // prevent app from crashing if reportResponseJson has duplicate cell (score/usage) ids
    const uniqueReportColumnsConfig = removeDuplicates(reportColumnsConfig, 'id');
    return [
      cellExpanderConfig, {
        Header: 'header-parent-row',
        columns: uniqueReportColumnsConfig
      }
    ];
  };

  const handlePageChange = (_event, data) => {
    reportTableManager.setActiveReportTablePage(data.activePage);
    ReportTableService.handlePaginateFacultyResultCells({ allColumns, setHiddenColumns });
  };

  /** RENDER: `cell-expander` (header) */
  const renderHeaderRowExpanderCell = (props) => {
    const { getToggleAllRowsExpandedProps, isAllRowsExpanded } = props;
    return PopupService.renderPopup({
      className: `header-row-expander-popup ${reportInfoClassNames} report-table-header-popup`,
      content: (
        <div>
          {t(isAllRowsExpanded ? 'descriptionForCollapseAllRows' : 'descriptionForExpandAllRows')}
        </div>
      ),
      offset: [-11, 0],
      position: 'top right',
      trigger: (
        <>
          <span {...getToggleAllRowsExpandedProps({
            title: undefined // removes native placeholder rollover
          })}>
            <Icon name={isAllRowsExpanded ? 'caret down' : 'caret right'} />
          </span>
        </>
      )
    });
  };

  /** RENDER: `cell-expander` (body) */
  const renderRowExpanderCell = (props) => {
    const { row } = props;
    return (
      row.canExpand ? (
        <span {...row.getToggleRowExpandedProps({
          className: `${row['className']} ${reportInfoClassNames}`,
          title: undefined // removes native placeholder rollover
          // TODO useful if we ever implement row depth padding // style: { paddingLeft: `${row.depth * 16}px` }
        })}>
          <Icon name={row.isExpanded ? 'caret down' : 'caret right'} />
        </span>
      ) : null
    );
  };

  /** RENDER: `cell-resource-title` */
  const renderResourceTitleCell = (props) => {
    return (
      <ReportResourceTitleCell {...initProps} {...props} />
    );
  };

  /** RENDER: `cell-resource-export` */
  const renderResourceExportCell = (props) => {
    const allowResourceExport = reportOverrideManager.allowReportTableRowResourceExport;
    const isExpandableRow = ReportTableService.isExpandableRow(props);

    const showResourceExportColumn = isCourseReport && (isFacultyDistrictReport || isFacultySchoolReport) && isTableDetailReport;

    return allowResourceExport && showResourceExportColumn && !isExpandableRow ? (
      <ReportDownloadButtons {...initProps} {...props} isExportForReportTableRowResource />
    ) : null;
  };

  /** RENDER: `cell-gradebook-view` */
  const renderGradebookViewCell = (props) => {
    const { row } = props;
    const { original } = row;
    const { activityCount, courseId, courseContentItemId, elementContentItemId, elementId } = original;

    const isDistrictOrSchoolAdminWithManageActivitiesPermission = userManager.isDistrictOrSchoolAdmin &&
      userManager.permissionIds.includes('manage_activities');

    const archived = reportIdentityManager.hasArchivedFacultyClassroomReportStatus;

    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = urlParams.get('classroomId');

    const showLink = !archived && !!(isDistrictOrSchoolAdminWithManageActivitiesPermission || userManager.isTeacher) && !!(
      activityCount > 0 && courseId && courseContentItemId && elementId && elementContentItemId
    );

    return showLink ? (
      <div className='clickable' onClick={() => {
        return initProps.navToAggregateGradebook({
          classroomId,
          contentItemId: elementContentItemId,
          courseContentItemId,
          currentCourseId: courseId,
          elementId
        });
      }}>
        {t('viewDetails')}
      </div>
    ) : (<div className='cursor-default'>&nbsp;</div>);
  };

  /** RENDER: `cell-view-details` */
  const renderViewDetailsCell = (props) => {
    const isRowExemptFromHavingValue = ReportTableService.isRowExemptFromHavingValue(props.row);
    if (isRowExemptFromHavingValue) {
      return <div className='inner-cell-no-data' />;
    }
    const standardElement = props.row.original;
    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = urlParams.get('classroomId');
    const classroomStatus = urlParams.get('classroomStatus');
    const fromAdminClassroomsView = ReportIdentityService.userCameFromAdminClassroomsView();
    const institutionId = urlParams.get('institutionId');
    const institutionName = urlParams.get('institutionName');

    const additionalParams = {
      byStudents: true,
      classroomId,
      classroomStatus,
      fromAdminClassroomsView,
      institutionId,
      institutionName,
      standardElementId: standardElement.id,
      truncatedStandardDescription: ReportStandardsService.getTruncatedStandardsDescription(
        standardElement
      )
    };
    const reportType = null, reportFacultyType = null, reportTableType = null;
    const specificReportUrl = ReportIdentityService.getReportUrl(
      reportType, reportFacultyType, reportTableType, additionalParams
    );

    const archived = reportIdentityManager.hasArchivedFacultyClassroomReportStatus;

    const showLink = !archived && !!standardElement?.name;

    return showLink && (
      <div onClick={() => {
        return initProps.handleViewNextReport({ specificReportUrl });
      }}>
        {t('viewDetails')}
      </div>
    );
  };

  /** RENDER: `cell-resource-count` DEPRECATED */
  const renderResourceCountCell = (props, columnName) => {
    const { row, value } = props;
    const { original } = row;
    const allowOnClick = userManager.isTeacher && !row.canExpand && value > 0 && original.id !== 'rollupAverageRow';
    return (
      <ReportResourceCountCell {...initProps} {...props}
        allowOnClick={allowOnClick}
        resourceCountColumnName={columnName} />
    );
  };

  /** RENDER: `cell-average-result` */
  const renderAverageResultCell = (props) => {
    return (
      <ReportAverageResultCell {...initProps} {...props} />
    );
  };

  /** RENDER: `cell-score-summary-bar` (header) */
  const renderScoreSummaryBarHeaderCell = (_props) => {
    const { isFacultyDistrictReport, isFacultyClassroomReport } = reportIdentityManager;

    const urlParams = new URLSearchParams(window.location.search);
    const classroomId = urlParams.get('classroomId');
    const classroomStatus = urlParams.get('classroomStatus');
    const institutionId = urlParams.get('institutionId');
    const institutionName = urlParams.get('institutionName');

    return (
      <div onClick={() => initProps.handleViewNextReport({
        classroomId: (isFacultyClassroomReport || null) && (classroomId || null),
        classroomStatus,
        institutionId: (!isFacultyDistrictReport || null) && (institutionId || null),
        institutionName: (!isFacultyDistrictReport || null) && (institutionName || null)
      })}>
        {t('details')}
      </div>
    );
  };

  /** RENDER: `cell-score-summary-bar` (body) */
  const renderScoreSummaryBarCell = (props) => {
    return <ReportScoreSummaryBarCell {...initProps} {...props} />;
  };

  /** RENDER: `cell-faculty-result` */
  const renderFacultyResultCell = (props) => {
    return (
      <ReportFacultyResultCell {...initProps} {...props} />
    );
  };

  const {
    // columns, // TODO unused
    // state: { hiddenColumns, expanded } // TODO unused
    allColumns,
    flatRows,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    initialState,
    prepareRow,
    rows,
    setHiddenColumns,
    toggleRowExpanded
  } = useTable({
    columns: React.useMemo(() => generateColumnsConfig(), []),
    data: reportTableManager.reportTableData
  }, useExpanded);

  /** equivalent to componentDidMount(), i.e. only called after initial render */
  useEffect(() => {
    (async () => {
      setLoadingReportTable(true);
      ReportTypeService.initReportTableData();

      if (!isTableIndividualReport) {
        ReportTableService.handlePaginateFacultyResultCells({
          allColumns, setHiddenColumns
        });
      }
      reportTableManager.setShouldReinitializeReportTableExpandedRows(true);
      setLoadingReportTable(false);
    })();
  }, [initialState.hiddenColumns]);

  // eslint-disable-next-line no-unused-vars
  const [_headerParentRow, headerRow] = headerGroups;

  const renderReportTableZeroState = ({ customTranslationKey } = {}) => {
    const { reportCourseNamesByInstitutionMap } = reportCourseManager;
    const { forceShowNoDataZeroState, hasReportResponseJsonError, reportResponseJson } = reportIdentityManager;
    const { allowUseCmapId } = reportOverrideManager;
    const { reportCmapObjsByInstitutionMap } = reportStandardsManager;
    const availableReportStandards = ReportStandardsService.getAvailableReportStandards();

    let translationKey = customTranslationKey || 'noReportDataToDisplay';
    if (!customTranslationKey) {
      if (!forceShowNoDataZeroState && !hasReportResponseJsonError && reportResponseJson) {
        if (isCourseReport && reportCourseNamesByInstitutionMap?.size) {
          translationKey = 'selectCourse';
        } else if (isStandardsReport) {
          if (isFacultyAdminReport) {
            if ((!allowUseCmapId || reportCmapObjsByInstitutionMap?.size) && availableReportStandards?.length) {
              translationKey = 'selectStandard';
            }
          } else if (availableReportStandards?.length) {
            translationKey = 'selectStandard';
          }
        }
      }
    }
    return (
      <ZeroStateCard {...initProps}
        additionalClassNames={reportInfoClassNames}
        icon={iconBarChartThin}
        translationKey={t(translationKey)} />
    );
  };

  /** called whenever table state changes; e.g. whenever a row is expanded */
  useEffect(() => () => {
    const {
      shouldReinitializeReportTableExpandedRows,
      shouldUpdateReportTableExpandedRows
    } = reportTableManager;

    if (flatRows?.length && shouldReinitializeReportTableExpandedRows) {
      // reinitialize expanded table rows via mobx cache
      for (const tableRow of flatRows) {
        const expandedRowMap = ReportTableService.getReportTableExpandedRowMap();
        const tableRowIdProp = isCourseReport ? 'elementId' : 'id';
        const isExpanded = expandedRowMap.get(tableRow.original[tableRowIdProp]);
        toggleRowExpanded(tableRow.id, !!isExpanded);
      }
      reportTableManager.setShouldReinitializeReportTableExpandedRows(false);
      reportTableManager.setShouldUpdateReportTableExpandedRows(true);
    } else if (flatRows?.length && shouldUpdateReportTableExpandedRows) {
      // update the cached expanded rows via mobx
      ReportTableService.setReportTableExpandedRows([...flatRows]);
    }
  });

  const renderReportTable = () => {
    return (
      <div className={`report-table-container ${reportInfoClassNames}`}>
        <div className={`report-table-top-container ${reportInfoClassNames}`}>
          {/* note: we should avoid putting additional components in `report-table-top-container` unless absolutely necessary */}
          {/* --- */}
          {/* **avoid** - anything put here will likely need extensive css manipulation to appear to be in the 'correct' position */}
          {/* use ReportHeader instead of this container if possible */}
          {/* --- */}
          {/* paginator currently needs to be here because its state is controlled by the `react-table` library */}
          {renderTablePaginator()}
        </div>
        <div className={`report-table-wrapper ${reportInfoClassNames}`}>
          <Table {...getTableProps()}
            celled className={`report-table ${reportInfoClassNames}`} singleLine striped>
            {renderTableHeader()}
            {renderTableBody()}
          </Table>
        </div>
      </div>
    );
  };

  const renderTablePaginator = () => {
    const { reportInfoClassNames } = reportIdentityManager;
    const { activeReportTablePage } = reportTableManager;
    const totalPages = ReportTableService.getTotalFacultyResultCellPages(allColumns);

    ReportTableService.modifyCertainStyleOffsetsToAccommodateReportTablePaginator(totalPages);

    return totalPages > 1 && (
      <div className={`report-table-paginator ${reportInfoClassNames}`}>
        <Pagination
          activePage={activeReportTablePage}
          className={reportInfoClassNames}
          onPageChange={handlePageChange}
          totalPages={totalPages} />
      </div>
    );
  };

  const renderTableHeader = () => {
    return (
      <Table.Header>
        <Table.Row {...headerRow.getHeaderGroupProps({
          className: headerRow['className']
        })}>
          {headerRow.headers.map((headerColumn) => {
            return (
              <Table.HeaderCell key={`header-column-${headerColumn.id}`}
                {...headerColumn.getHeaderProps({
                  className: headerColumn['className']
                })}>
                {headerColumn.render('Header')}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
    );
  };

  const renderTableBody = () => {
    return (
      <Table.Body {...getTableBodyProps()}>
        {rows.map((row, _bodyRowIndex) => {
          prepareRow(row);
          const { elementType } = row.original;
          const expandableRowClassName = ReportTableService.getExpandableRowClassNameIfApplicable(elementType, { row });

          const rowProps = row.getRowProps();
          const isRollupAverageRow = row.original.id === 'rollupAverageRow';
          let reportRowClassNames = rowProps.className || '';
          reportRowClassNames += `${reportRowClassNames ? ' ' : ''}${isRollupAverageRow ? 'rollup-average-row' : ''}`;
          reportRowClassNames += `${reportRowClassNames ? ' ' : ''}${expandableRowClassName.trim()}`.trim();
          reportRowClassNames += `${reportRowClassNames ? ' ' : ''}${reportInfoClassNames}`;

          const shouldHideTableRow = ReportTableService.shouldHideTableRow(row);

          // for each cell, determine the value we should use
          const cells = row.cells.map((cell, _bodyCellIndex) => {
            let cellClassNames = cell.column['className'] || '';
            let value;
            if (expandableRowClassName) {
              cellClassNames += expandableRowClassName;
            }
            const isRowExemptFromHavingValue = ReportTableService.isRowExemptFromHavingValue(row);
            if (!isRowExemptFromHavingValue) {
              if (cellClassNames.includes('result')) {
                const isFacultyAverageCell = cell?.column?.id?.toLowerCase?.()?.includes?.('average');
                if (isStandardsReport && !isFacultyAverageCell && (
                  cell.row.original.id === 'rollupAverageRow' || cell.row.canExpand)
                ) {
                  value = ReportStandardsService.getCustomReportFacultyResultCellValue(
                    { ...cell, flatRows }) || null;
                  cell = { ...cell, value };
                } else if (isFacultyAverageCell && cell.row.original.hasRollup) {
                  value = cell.row.original.overallRollup?.averageScore;
                  cell = { ...cell, value };
                }
              }
            }
            return cell;
          });
          row.cells = cells;

          return !shouldHideTableRow && (
            <Table.Row key={`body-row-${row.id}`} {...row.getRowProps()}
              className={reportRowClassNames}>
              {row.cells.map((cell, _bodyCellIndex) => {
                // for each cell, determine the classNames we should use
                let cellClassNames = cell.column['className'] || '';
                if (expandableRowClassName) {
                  cellClassNames += expandableRowClassName;
                }
                const isRowExemptFromHavingValue = ReportTableService.isRowExemptFromHavingValue(row);
                if (!isRowExemptFromHavingValue) {
                  if (cellClassNames.includes('result')) {
                    const isFacultyAverageCell = cell?.column?.id?.toLowerCase?.()?.includes?.('average');
                    if (!isFacultyAverageCell && (
                      cell.row.original.id === 'rollupAverageRow' || cell.row.canExpand)
                    ) {
                      cellClassNames += ` ${ReportScoreService.getReportScoreCellClassName(cell)}`;
                    } else if (isFacultyAverageCell && cell.row.original.hasRollup) {
                      cellClassNames += ` ${ReportScoreService.getReportScoreCellClassName(cell)}`;
                    } else {
                      cellClassNames += ` ${ReportScoreService.getReportScoreCellClassName(cell)}`;
                    }
                    if (isCourseReport && isFacultyClassroomReport && cell.value?.excludeCount) {
                      cellClassNames += ' modified';
                    }
                  }
                }
                return (
                  <Table.Cell key={`cell-${cell.column.id}`}
                    {...cell.getCellProps({
                      className: cellClassNames
                    })}>
                    {cell.render('Cell', cell.value >= 0 ? { value: cell.value } : undefined)}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          );
        })}
      </Table.Body>
    );
  };

  const { loadingReportCourseNames, selectedReportCourseContentItemId } = reportCourseManager;
  const { forceShowNoDataZeroState, hasReportResponseJsonError, reportLastModified } = reportIdentityManager;
  const { allowUseCmapId } = reportOverrideManager;
  const { selectedReportCmapContentItemId } = reportStandardsManager;
  const { reportTableData } = reportTableManager;

  const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();

  let shouldShowReportTableZeroState, customTranslationKey = '';
  if (isStandardsReport && !allowUseCmapId && isFacultyAdminReport && !selectedReportCourseContentItemId) {
    shouldShowReportTableZeroState = true;
    customTranslationKey = 'selectStandard';
  } else if (isStandardsReport && shouldUseCmapId && isFacultyAdminReport && !selectedReportCmapContentItemId) {
    shouldShowReportTableZeroState = true;
    customTranslationKey = 'selectStandard';
  } else if (forceShowNoDataZeroState) {
    shouldShowReportTableZeroState = true;
  } else if (hasReportResponseJsonError) {
    shouldShowReportTableZeroState = true;
  } else if (isCourseReport && isFacultyAdminReport && !selectedReportCourseContentItemId) {
    shouldShowReportTableZeroState = true;
  }

  customTranslationKey = customTranslationKey || (
    (isFacultyAdminReport && !reportLastModified) ? 'noReportLastModified' : ''
  );

  customTranslationKey = customTranslationKey || (
    (isFacultyClassroomReport && !reportLastModified) ? 'noReportLastModifiedClassroom' : ''
  );

  customTranslationKey = customTranslationKey || (
    (isCourseReport && isFacultyAdminReport && reportLastModified &&
      selectedReportCourseContentItemId && (!reportTableData || !reportTableData?.length)
    ) ? 'noReportDataToDisplay' : ''
  );

  if (shouldShowReportTableZeroState) {
    return renderReportTableZeroState({ customTranslationKey });
  } else if (loadingReportView || loadingReportTable || loadingReportCourseNames) {
    return <Loader active />;
  } else if (reportTableData?.length) {
    return renderReportTable();
  } else {
    return renderReportTableZeroState({ customTranslationKey });
  }
});
export default ReportTable;

SatCoreRegister('ReportTable', ReportTable);
