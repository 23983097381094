/* eslint-disable prefer-destructuring */
import React, { useCallback, useRef } from 'react';
import { SatCoreRegister } from '../SatCoreRegistry';

/**
 * **KeyboardActionListener** is a scalable component that manages keyboard interactions
 * within its children. While currently implementing basic Enter/Space click simulation
 * and screen reader announcements, it is designed to be extended for complex keyboard
 * navigation patterns.
 *
 * Current features:
 * - Enter/Space key to click handling
 * - Focus preservation through React re-renders
 * - DOM path-based element tracking
 * - Screen reader announcements for table rows
 *    - Automatically detects TR elements and role="row"
 *    - Announces cell contents along with their column headers
 *    - Uses table header cells for column identification
 *    - Handles colspan and rowspan attributes
 *    - Supports scope attribute for row/column headers
 *    - Includes both column headers and aria-labels when available
 *    - Skips cells that are hidden with display: none
 *    - Skips empty cells and their headers
 *    - Uses ARIA live region for reliable screen reader support
 *    - Improves announcement of 'n/a' values
 *
 * Potential extensions:
 * - Arrow key navigation between elements
 * - Tab trap management for modals
 * - Custom key bindings
 * - Gesture mapping
 * - Additional screen reader patterns for other components
 * - Custom announcement formats
 * - etc
 *
 * @component
 * @example
 * // Basic usage with table including colspan, scope, and aria-labels
 * return (
 *   <KeyboardActionListener>
 *     <table>
 *       <thead>
 *         <tr>
 *           <th scope="col">Name</th>
 *           <th scope="col" colspan="2">Performance</th>
 *         </tr>
 *         <tr>
 *           <th></th>
 *           <th scope="col">Score</th>
 *           <th scope="col">Grade</th>
 *         </tr>
 *       </thead>
 *       <tbody>
 *         <tr tabIndex={0}>
 *           <th scope="row">John</th>
 *           <td aria-label="Final score with bonus">85</td>
 *           <td aria-label="Grade with curve applied">A</td>
 *         </tr>
 *       </tbody>
 *     </table>
 *   </KeyboardActionListener>
 * );
 * // Screen reader will announce: "Name: John, Score: Final score with bonus: 85, Grade: Grade with curve applied: A"
 */
const KeyboardActionListener = ({ children }) => {
  // Ref to store the focused element and its path
  const focusStateRef = useRef({
    element: null,
    path: [],
    previousElement: null
  });

  // Ref for live region
  const liveRegionRef = useRef(null);

  // Initialize live region
  React.useEffect(() => {
    // Create live region if it doesn't exist
    if (!liveRegionRef.current) {
      const region = document.createElement('div');
      region.setAttribute('aria-live', 'polite');
      region.setAttribute('aria-atomic', 'true');
      region.className = 'aria-offscreen';
      document.body.appendChild(region);
      liveRegionRef.current = region;
    }

    // Cleanup
    return () => {
      if (liveRegionRef.current) {
        document.body.removeChild(liveRegionRef.current);
      }
    };
  }, []);

  // Store focus path when element is focused
  const storeFocusPath = (element) => {
    const path = [];
    let current = element;

    // Build the path from the focused element up to the document body
    while (current && current !== document.body) {
      const index = Array.from(current.parentElement?.children || []).indexOf(current);
      path.unshift(index);
      current = current.parentElement;
    }

    return path;
  };

  // Find element by stored path
  const findElementByPath = (path) => {
    let current = document.body;

    for (const index of path) {
      current = current.children[index];
      if (!current) {
        return null;
      }
    }

    return current;
  };

  // Check if an element is hidden
  const isElementHidden = (element) => {
    if (!element) {
      return true;
    }
    const style = window.getComputedStyle(element);
    return style.display === 'none';
  };

  // Format content for screen reader announcement
  const formatContentForAnnouncement = (content) => {
    if (typeof content !== 'string') {
      return content;
    } else if (content.toLowerCase() === 'n/a') {
      return 'NA';
    }
    return content;
  };

  // Announce content to screen readers
  const announce = useCallback((message) => {
    if (liveRegionRef.current) {
      // Clear existing announcement first
      liveRegionRef.current.textContent = '';

      // Use requestAnimationFrame to ensure the clear takes effect
      requestAnimationFrame(() => {
        liveRegionRef.current.textContent = message;
      });
    }
  }, []);

  // Get headers associated with a cell based on scope and position
  const getCellHeaders = (cell, headerCells, columnIndex) => {
    const headers = [];
    let currentColumnIndex = columnIndex;

    // Process header cells to find relevant headers based on scope and position
    headerCells.forEach((headerCell, index) => {
      const scope = headerCell.getAttribute('scope');
      const colspan = parseInt(headerCell.getAttribute('colspan')) || 1;

      // Check if this header applies to our cell
      if (
        // Column headers
        (scope === 'col' && index === currentColumnIndex) ||
        // Row headers
        (scope === 'row' && headerCell.closest('tr') === cell.closest('tr')) ||
        // Colgroup headers that span over our column
        (scope === 'colgroup' &&
         currentColumnIndex >= index &&
         currentColumnIndex < index + colspan) ||
        // Rowgroup headers
        (scope === 'rowgroup' && (
          headerCell.closest('thead') === cell.closest('thead') ||
          headerCell.closest('tbody') === cell.closest('tbody')
        ))
      ) {
        headers.push(headerCell.textContent.trim());
      }

      // Update current column index based on colspan
      currentColumnIndex += colspan - 1;
    });

    return headers;
  };

  // Get readable content from table row
  const getAnnounceableTableRowContent = (row) => {
    if (!row) {
      return '';
    }

    // Check if element is a table row or has role="row"
    const isTableRow = row.tagName === 'TR' || row.getAttribute('role') === 'row';
    if (!isTableRow) {
      return '';
    }

    // Find the table element
    const table = row.closest('table');
    if (!table) {
      return '';
    }

    // Get all header cells from the table, including those with scope
    const headerCells = Array.from(table.querySelectorAll('th'))
      .filter((cell) => !isElementHidden(cell));

    // Get visible data cells from the current row
    const dataCells = Array.from(row.querySelectorAll('td, th[scope="row"]'))
      .filter((cell) => !isElementHidden(cell));

    // Track column index for colspan handling
    let currentColumnIndex = 0;

    // Build a readable string from visible cell contents with their corresponding headers
    const cellContents = dataCells.map((cell) => {
      const textContent = cell.textContent.trim();
      const ariaLabel = cell.getAttribute('aria-label');
      let content = '';
      const additionalLabels = [];

      // First try cell's own text content
      if (textContent) {
        content = formatContentForAnnouncement(textContent);
      }

      // Check for elements with aria-labels within the cell
      const elementsWithLabels = cell.querySelectorAll('[aria-label]');
      elementsWithLabels.forEach((element) => {
        const elementLabel = element.getAttribute('aria-label');
        if (elementLabel) {
          additionalLabels.push(formatContentForAnnouncement(elementLabel));
        }
      });

      // If we have both content and additional labels, combine them
      if (content && additionalLabels.length > 0) {
        content = `${additionalLabels.join(', ')}, ${content}`;
      } else if (!content && additionalLabels.length > 0) {
        content = additionalLabels.join(', ');
      }

      // If still no content, check cell's own aria-label as fallback
      if (!content && ariaLabel) {
        content = formatContentForAnnouncement(ariaLabel);
      }

      // Skip if no content found at all
      if (!content) {
        currentColumnIndex += parseInt(cell.getAttribute('colspan')) || 1;
        return null;
      }

      let headerText = '';
      let columnHeaderText = '';

      // First get any column/row headers
      // Check headers attribute first
      const headerId = cell.getAttribute('headers');
      if (headerId) {
        const headerElements = headerId.split(' ')
          .map((id) => document.getElementById(id))
          .filter((element) => element && !isElementHidden(element));

        if (headerElements.length) {
          columnHeaderText = headerElements
            .map((element) => formatContentForAnnouncement(element.textContent.trim()))
            .join(', ');
        }
      }

      // If no headers attribute found, try scope-based headers
      if (!columnHeaderText) {
        const scopedHeaders = getCellHeaders(cell, headerCells, currentColumnIndex);
        if (scopedHeaders.length) {
          columnHeaderText = scopedHeaders.map((scopedHeader) => {
            return formatContentForAnnouncement(scopedHeader);
          }).join(', ');
        }
      }

      // Then check for aria-label
      if (ariaLabel) {
        // If we have both column header and aria-label, combine them
        headerText = columnHeaderText ?
          `${columnHeaderText}: ${formatContentForAnnouncement(ariaLabel)}` :
          formatContentForAnnouncement(ariaLabel);
      } else {
        // If no aria-label, just use column header if available
        headerText = columnHeaderText;
      }

      // Update column index for next cell
      currentColumnIndex += parseInt(cell.getAttribute('colspan')) || 1;

      // Return formatted string with header if available
      return headerText ? `${headerText}: ${content}` : content;
    }).filter(Boolean); // Remove null entries from empty cells

    return cellContents.join(', ');
  };

  const handleFocus = useCallback((event) => {
    // If there was a previous element, explicitly remove its aria-selected state
    if (focusStateRef.current.previousElement) {
      focusStateRef.current.previousElement.setAttribute('aria-selected', 'false');
    }

    const target = event.target;

    // Update focus state with previous element tracking
    focusStateRef.current = {
      element: target,
      path: storeFocusPath(target),
      previousElement: focusStateRef.current.element
    };

    // Set aria-selected on the newly focused element
    target.setAttribute('aria-selected', 'true');

    // Get and announce table row content if applicable
    const rowContent = getAnnounceableTableRowContent(event.target);
    if (rowContent) {
      // Clear previous announcement before making new one
      if (liveRegionRef.current) {
        liveRegionRef.current.textContent = '';
      }

      // Delay the new announcement slightly
      requestAnimationFrame(() => {
        announce(rowContent);
      });
    }
  }, [announce]);

  const handleInteraction = useCallback((event, isKeyboard = false) => {
    if (isKeyboard && event.key !== 'Enter' && event.key !== ' ') {
      return;
    }

    if (isKeyboard) {
      event.preventDefault();
    }

    const focusState = focusStateRef.current;
    if (!focusState.element) {
      return;
    }

    // Store the current focus state
    const currentPath = focusState.path;

    // Before interaction, mark element as not selected
    focusState.element.setAttribute('aria-selected', 'false');

    // If it's a click event, focus the element first
    if (!isKeyboard) {
      focusState.element.focus();
    }

    // Simulate the click if it's a keyboard event
    if (isKeyboard) {
      focusState.element.click();
    }

    // Using requestAnimationFrame ensures our focus preservation happens after:
    // 1. React has processed any state updates triggered by the click
    // 2. The DOM has been fully updated
    // 3. The browser has completed its render cycle
    requestAnimationFrame(() => {
      // Try to find the element by stored path first
      const elementByPath = findElementByPath(currentPath);

      if (elementByPath && elementByPath.focus) {
        elementByPath.focus();
        elementByPath.setAttribute('aria-selected', 'true');
      } else if (focusState.element && document.contains(focusState.element)) {
        // Fallback to stored element reference if it still exists in DOM
        focusState.element.focus();
        focusState.element.setAttribute('aria-selected', 'true');
      }
    });
  }, []);

  const handleKeyDown = useCallback((event) => {
    const isKeyboard = true;
    handleInteraction(event, isKeyboard);
  }, [handleInteraction]);

  const handleClick = useCallback((event) => {
    const isKeyboard = false;
    handleInteraction(event, isKeyboard);
  }, [handleInteraction]);

  // Clone children to add focus and keydown handlers
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        // eslint-disable-next-line quote-props
        onClick: (event) => {
          handleClick(event);
          // Preserve existing onClick handler
          child.props.onClick?.(event);
        },
        // eslint-disable-next-line quote-props
        onFocus: (event) => {
          handleFocus(event);
          // Preserve existing onFocus handler
          child.props.onFocus?.(event);
        },
        // eslint-disable-next-line quote-props
        onKeyDown: (event) => {
          handleKeyDown(event);
          // Preserve existing onKeyDown handler
          child.props.onKeyDown?.(event);
        },
        // Add aria-selected attribute to help manage screen reader focus
        'aria-selected': 'false' // eslint-disable-line sort-keys
      });
    }
    return child;
  });

  return <>{childrenWithProps}</>;
};
export default KeyboardActionListener;

SatCoreRegister('KeyboardActionListener', KeyboardActionListener);
