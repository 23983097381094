import kya_drb1_avatar from '../../img/avatars/kya/kya-drb-01-drums.png';
import kya_drb2_avatar from '../../img/avatars/kya/kya-drb-02-elvis.png';
import kya_drb3_avatar from '../../img/avatars/kya/kya-drb-03-hike.png';
import kya_drb4_avatar from '../../img/avatars/kya/kya-drb-04-hippie.png';
import kya_drb5_avatar from '../../img/avatars/kya/kya-drb-05-roman.png';
import kya_drb6_avatar from '../../img/avatars/kya/kya-drb-06-skateboard.png';

import kya_pinky1_avatar from '../../img/avatars/kya/kya-pinky-01-dj.png';
import kya_pinky2_avatar from '../../img/avatars/kya/kya-pinky-02-skate.png';
import kya_pinky3_avatar from '../../img/avatars/kya/kya-pinky-03-vegetable.png';

import kya_zoid1_avatar from '../../img/avatars/kya/kya-zoid-01-ballet.png';
import kya_zoid2_avatar from '../../img/avatars/kya/kya-zoid-02-biking.png';
import kya_zoid3_avatar from '../../img/avatars/kya/kya-zoid-03-soccer.png';
import kya_zoid4_avatar from '../../img/avatars/kya/kya-zoid-04-yoga.png';

/** Custom 'Know Yourself' (KYA) Avatars */
export const AVATAR_PICKER_KYA_LIST = [
  // drb (Dr. B)
  {
    name: 'kya-drb-01',
    src: kya_drb1_avatar
  },
  {
    name: 'kya-drb-02',
    src: kya_drb2_avatar
  },
  {
    name: 'kya-drb-03',
    src: kya_drb3_avatar
  },
  {
    name: 'kya-drb-04',
    src: kya_drb4_avatar
  },
  {
    name: 'kya-drb-05',
    src: kya_drb5_avatar
  },
  {
    name: 'kya-drb-06',
    src: kya_drb6_avatar
  },
  // pinky
  {
    name: 'kya-pinky-01',
    src: kya_pinky1_avatar
  },
  {
    name: 'kya-pinky-02',
    src: kya_pinky2_avatar
  },
  {
    name: 'kya-pinky-03',
    src: kya_pinky3_avatar
  },
  // zoid
  {
    name: 'kya-zoid-01',
    src: kya_zoid1_avatar
  },
  {
    name: 'kya-zoid-02',
    src: kya_zoid2_avatar
  },
  {
    name: 'kya-zoid-03',
    src: kya_zoid3_avatar
  },
  {
    name: 'kya-zoid-04',
    src: kya_zoid4_avatar
  }
];
