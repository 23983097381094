import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ReactHtmlParser from 'react-html-parser';
import Iframe from 'react-iframe';

import {
  Button, Card, Confirm, Header, Placeholder
} from 'semantic-ui-react';
import Modal from '../Modal';

import '../../css/ContentPreviewDialog.less';

import { SatCoreComponent, SatCoreRegister } from '../../SatCoreRegistry';

import Auth from '../../managers/AuthManager';

import { CONTENT_ITEM_TYPES, CONTENT_MODE } from '../../managers/ContentManager';

import UtilityService from '../../services/UtilityService';

export default
@inject('contentManager', 'userManager', 'assignmentManager', 'bandwidthManager')
@observer
class ContentPreviewDialog extends Component {
  constructor(props) {
    super(props);
    this.InfoModal = SatCoreComponent('InfoModal');

    this.state = {
      addInfoOpen: false,
      confirmationOpen: false
    };
  }

  getContentData = async () => {
    const { contentManager } = this.props;
    await contentManager.fetchResourceItem(this.props.contentItemId);
  }

  generateStreamUrl = (entityId, entityTypeId) => {
    const { userManager, bandwidthManager } = this.props;
    const redirectUrl = `${Auth.ecms}/api/redirectToStreamUrl?authKey=${userManager.authKey}&entityId=${entityId}&entityTypeId=${entityTypeId}&bandwidth=${bandwidthManager.currentBitRate}&cacheBuster=${(new Date().getTime())}`;
    return redirectUrl;
  }

  generateResourceItemUrl = (resourceItemFolder, resourceItemName, resourceItemId, contentItemId) => {
    const { userManager } = this.props;

    const redirectUrl = `${Auth.ecms}/api/redirectToResource?authKey=${userManager.authKey}&resourceFolder=${resourceItemFolder}&resourceFileName=${resourceItemName}&entityId=${resourceItemId}&contentItemId=${contentItemId}&cacheBuster=${(new Date().getTime())}`;

    return redirectUrl;
  }

  closeModal = () => {
    if (this.props.closeModalCallback) {
      this.props.closeModalCallback();
    }
    this.props.close();
  }

  handleOpenInfo = () => {
    this.setState({ addInfoOpen: true });
  }

  closeInfoModal = () => {
    this.setState({ addInfoOpen: false });
  }

  startSubmit = async () => {
    this.setState({ confirmationOpen: true });
  }

  closeConfirmation = async () => {
    this.setState({ confirmationOpen: false });
  }

  renderStudentInstructionButton = () => {
    const { instruction, userManager } = this.props;
    if (instruction && instruction !== '<p></p>') {
      return (
        <Button
          className={(!userManager.isTeacher ? 'basic student-instruction white' : 'basic white')}
          onClick={() => this.handleOpenInfo()}
          primary>
          Student Instructions
        </Button>
      );
    }
    return null;
  }

  renderSubmitButton = () => {
    const { userManager } = this.props;
    if (!userManager.isTeacher && this.props.contentMode === CONTENT_MODE.ASSESS) {
      return (<Button className='student' onClick={() => this.startSubmit()} primary>Submit</Button>);
    }
    return null;
  }

  submitAndClose = async () => {
    const { assignmentManager, userManager, assignmentId } = this.props;
    if (!userManager.canViewAsTeacher) {
      await assignmentManager.submitActivityInstance(assignmentId);
    }
    this.closeModal();
  }

  renderRubric = (rubric) => (
    <table className='rubricPreview fontContentContainer'>
      <thead>
        <tr>
          <th className='noBorder fontContentContainer' />

          {rubric.columns.map((index, column) => (
            <th key={`${index}_${rubric.id}`} className='columnHeader center fontContentContainer'>{column}</th>
          ))}
          ;
          <th className='noBorder fontContentContainer'>Maximum Points</th>

        </tr>
      </thead>

      <tbody>
        {rubric.categories.map((index, category) => (
          <tr key={`${rubric.id}_${category.id}`}>
            <td className='categoryLabel center fontContentContainer'>{category.name}</td>
            {category.columns.map((index, column) => {
              const cellId = `${category.categoryId}_${index}`;
              return (<td key={cellId} className='rubricCell fontContentContainer'>{column}</td>);
            })}
            <td className='maxPoints fontContentContainer'>{category.maxPoints}</td>
          </tr>
        ))}
        ;
      </tbody>
    </table>
  )

  renderVideoAction = (subtitles) => {
    const trackItems = [];
    subtitles.map((subtitle) => {
      const filename = this.generateResourceItemUrl('userFiles', subtitle.remoteFileName);
      trackItems.push(
        <track
          key={`tid_${subtitle.id}`}
          kind='subtitles'
          label={subtitle.label}
          src={filename}
          srcLang={subtitle.languageCode} />
      );
      return true;
    });
    return trackItems;
  };

  render() {
    const { open, contentItemId, contentItemType, resourceName, fullContentSize = false } = this.props;
    const { contentManager } = this.props;
    const resourceItem = contentManager.getResourceItem(contentItemId);
    const { InfoModal } = this;

    if (resourceItem) {
      let size = 'small';
      if (contentItemType === CONTENT_ITEM_TYPES.PDF_RESOURCE || resourceItem.mime === 'application/pdf') {
        size = 'fullscreen';
      }
      let videoHtml = <center><video autoPlay controls controlsList='nodownload' disablePictureInPicture onContextMenu={(e) => e.preventDefault()} width='470'><source src={this.generateStreamUrl(resourceItem.id, CONTENT_ITEM_TYPES.VIDEO_RESOURCE)} type={resourceItem.mime} /></video></center>;
      if (resourceItem.subtitles) {
        videoHtml = (
          <center>
            <video autoPlay controls controlsList='nodownload' crossOrigin='anonymous' disablePictureInPicture width='470'>
              <source src={this.generateStreamUrl(resourceItem.id, CONTENT_ITEM_TYPES.VIDEO_RESOURCE)} type={resourceItem.mime} />
              {' '}
              {this.renderVideoAction(resourceItem.subtitles)}
              {' '}
            </video>
          </center>
        );
      }
      const transformFn = UtilityService.reactHtmlParserTransform;
      return (
        <Modal className={`preview-dialog${fullContentSize ? ' full-content-size' : ''}`} closeOnDimmerClick={false} onClose={this.closeModal} open={open} size={size}>
          {/* <ModalBanner  title={resourceName} onClose={this.closeModal}/> */}
          <Modal.Header className='modal-header'>
            <Header.Content className='modal-header-bar'>
              <span className='modal-header-label'>
                {ReactHtmlParser(resourceName, {
                  transform: transformFn
                })}
              </span>
              <div>
                {this.renderStudentInstructionButton()}
                {this.renderSubmitButton()}
                <Button aria-label='Close Modal' basic icon='close' onClick={this.closeModal} size='huge' />
              </div>
            </Header.Content>
          </Modal.Header>
          <Modal.Content scrolling>
            <div className='resourceItemPreviewPanel'>
              {(contentItemType === CONTENT_ITEM_TYPES.IMAGE_RESOURCE)
                ? <img alt={resourceItem.name} id='imageSource' src={this.generateResourceItemUrl('userFiles', resourceItem.remoteFileName, resourceItem.id, contentItemId)} title='' />
                : (contentItemType === CONTENT_ITEM_TYPES.VIDEO_RESOURCE)
                  ? videoHtml
                  : (contentItemType === CONTENT_ITEM_TYPES.ARTICLE_RESOURCE)
                    ? <div className='preview-html-wrapper' dangerouslySetInnerHTML={{ __html: resourceItem.articleHtml }} />
                    : (contentItemType === CONTENT_ITEM_TYPES.LESSON_PLAN)
                      ? <div className='preview-html-wrapper' dangerouslySetInnerHTML={{ __html: resourceItem.lessonPlanHtml }} />
                      : (contentItemType === CONTENT_ITEM_TYPES.AUDIO_RESOURCE)
                        ? (
                          <center>
                            <audio autoPlay className='media' controls>
                              <source autoPlay controls width='470' />
                              <source src={this.generateStreamUrl(resourceItem.id, CONTENT_ITEM_TYPES.AUDIO_RESOURCE)} type='audio/mpeg' />
                              Your browser does not support the audio element.
                            </audio>
                          </center>
                        ) : (contentItemType === CONTENT_ITEM_TYPES.FILE_RESOURCE && resourceItem.mime === 'application/pdf') || contentItemType === CONTENT_ITEM_TYPES.PDF_RESOURCE
                          ? (
                            <div className='preview-html-wrapper'>
                              <Iframe
                                height='100%'
                                id='myFrame'
                                position='relative'
                                title={UtilityService.stripHtmlTagsAndStrip(resourceName)}
                                url={resourceItem.presignedAwsUrl}
                                width='100%' />
                            </div>
                          ) : null}
            </div>
          </Modal.Content>
          <InfoModal
            addInfoOpen={this.state.addInfoOpen}
            closeInfoModal={this.closeInfoModal}
            contentItemDescription={null}
            infoName={this.props.resourceName}
            resourceInfo={null}
            studentInstruction={this.props.instruction} />
          <Confirm
            cancelButton='No'
            confirmButton='Yes'
            content='Submitting this assignment will give it to your teacher.  Are you ready to submit?'
            header='Submit Assignment'
            onCancel={() => this.setState({ confirmationOpen: false })}
            onConfirm={() => {
              this.submitAndClose();
              this.setState({ confirmationOpen: false });
            }}
            open={this.state.confirmationOpen} />
        </Modal>
      );
    }
    return (
      <Modal className='preview-dialog' onMount={() => {
        const { props } = this;
        if (!props?.skipGetContentData) {
          this.getContentData();
        }
      }} open={open}>
        <Card>
          <Card.Content>
            <Placeholder>
              <Placeholder.Image square />
            </Placeholder>
          </Card.Content>
        </Card>
      </Modal>
    );
  }
}

SatCoreRegister('ContentPreviewDialog', ContentPreviewDialog);
