import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import classNames from 'classnames';

import {
  Button, Container, Dropdown, Form, Grid,
  Header, Image, Input, Loader, Message
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import Modal from '../components/Modal';

import '../css/ClassSettingsView.less';

import classroomDefault from '../img/default-classroom.svg';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import { DIALOG_NAMES } from '../managers/DialogManager';
import { PATH_TYPES, VIEW_SELECTION } from '../managers/NavigationManager';

import ClassroomService from '../services/ClassroomService';
import UserService from '../services/UserService';

export default
@inject(
  'classroomManager', 'dialogManager', 'navigationManager', 'userManager')
@observer
class ClassSettingsView extends Component {
  classroomStatusOptions = [
    {
      key: 'active',
      text: 'Active',
      value: false
    },
    {
      key: 'archived',
      text: 'Archived',
      value: true
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      archived: false,
      archivedOpen: false,
      classroom: null,
      endDate: '',
      endDateError: false,
      endErrorMessage: '',
      error: false,
      errorMessage: '',
      failed: false,
      hasError: false,
      imageUrl: '',
      loading: false,
      name: '',
      nameError: false,
      nameErrorMessage: '',
      nickNameError: false,
      nickNameErrorMessage: '',
      nickname: '',
      saved: false,
      startDate: '',
      startDateError: false,
      startErrorMessage: ''
    };

    this.ClassroomInfoBanner = SatCoreComponent('ClassroomInfoBanner');
    this.CoTeacherAccessCode = SatCoreComponent('CoTeacherAccessCode');
    this.CourseListBanner = SatCoreComponent('CourseListBanner');
    this.DotMenu = SatCoreComponent('DotMenu');
    this.StudentAccessCode = SatCoreComponent('StudentAccessCode');
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const { classroomManager, navigationManager, userManager } = this.props;
    const { currentClassroomId } = classroomManager;

    if (userManager.isDistrictOrSchoolAdmin) {
      const result = await ClassroomService.initAdminData({
        ...this.props,
        shouldFetchClassroomWithCompleteTeacherData: true
      });

      const classrooom = classroomManager.getClassroom(classroomManager.currentClassroomId);

      if (result?.shouldInitMoreDataIfApplicable || !classrooom) {
        await this.initData();
      }
      this.setState({ loading: false });
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      if (!currentClassroomId && urlParams.has('classroomId')) {
        classroomManager.setCurrentClassroomId(urlParams.get('classroomId'));
      }
      navigationManager.setView(VIEW_SELECTION.DASHBOARD);
      await this.initData();
      this.setState({ loading: false });
    }
  }

  initData = async () => {
    const { classroomManager, history, userManager } = this.props;
    if (!classroomManager.getClassroom(classroomManager.currentClassroomId)) {
      // TODO remove
      // const classroom = classroomManager.fetchClassroom(classroomManager.currentClassroomId).then(() => {
      //   this.setClassroomStateAndBreadcrumbs();
      // });

      const classroom = await classroomManager.fetchClassroom(classroomManager.currentClassroomId);
      this.setClassroomStateAndBreadcrumbs();

      if (userManager.isDistrictOrSchoolAdmin && !classroom) {
        await history.push(`/classrooms?view=${VIEW_SELECTION.CLASSROOMS}`);
        // eslint-disable-next-line no-useless-return
        return;
      }
    } else {
      this.setClassroomStateAndBreadcrumbs();
    }
  }

  setClassroomStateAndBreadcrumbs = () => {
    this.setClassroomState();
    this.setClassroomBreadcrumbs();
  }

  setClassroomState = () => {
    const { classroomManager } = this.props;
    const classroom = classroomManager.getClassroom(classroomManager.currentClassroomId) || {};
    this.setState({
      archived: classroom.archived,
      classroom,
      endDate: classroom.timezoneEndDate,
      failed: false,
      imageUrl: classroom.imageUrl,
      name: classroom.name,
      nickname: classroom.nickname,
      saved: false,
      startDate: classroom.timezoneStartDate
    });
  }

  setClassroomBreadcrumbs = () => {
    const { classroomManager, navigationManager, userManager } = this.props;
    const isAdmin = userManager.isDistrictAdmin || userManager.isSchoolAdmin;
    if (!isAdmin) {
      navigationManager.clearAllPaths();
    }
    navigationManager.addPath({
      currentClassroomId: classroomManager.currentClassroomId,
      currentCourseId: null,
      currentElementId: null,
      name: classroomManager.getClassName(classroomManager.currentClassroomId),
      treeNavigationFunction: null,
      type: PATH_TYPES.LINK,
    });
  }

  handleChangeStartDate = (_event, { value }) => {
    const startDate = value;
    this.setState({
      failed: false,
      saved: false,
      startDate,
      startDateError: false,
      startErrorMessage: ''
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.validate(startDate, this.state.endDate);
  }

  handleChangeEndDate = (_event, { value }) => {
    const endDate = value;
    this.setState({
      endDate,
      endDateError: false,
      endErrorMessage: '',
      failed: false,
      saved: false
    });
    // eslint-disable-next-line react/destructuring-assignment
    this.validate(this.state.startDate, endDate);
  }

  handleChange = (_event, data = {}) => {
    const { name, value } = data;
    const { startDate, endDate } = this.state;
    if (name === 'name') {
      this.setState({
        failed: false, name: value, saved: false
      });
      // eslint-disable-next-line react/destructuring-assignment
      this.validate(startDate, endDate, value, this.state.nickname);
    } else if (name === 'archived') {
      this.setState({
        archived: value, failed: false, saved: false
      });
    } else if (name === 'nickname') {
      this.setState({
        failed: false, nickname: value, saved: false
      });
      // eslint-disable-next-line react/destructuring-assignment
      this.validate(startDate, endDate, this.state.name, value);
    } else {
      this.setState({ failed: false, saved: false });
    }
  }

  closeWarning = () => {
    this.setState({ archived: false, archivedOpen: false });
  }

  submitUpdateClass = () => {
    const { archived } = this.state;
    if (archived === true || archived === 'true') {
      this.setState({ archivedOpen: true });
    } else {
      this.confirmSubmitUpdateClass();
    }
  }

  confirmSubmitUpdateClass = async () => {
    const { userManager } = this.props;
    this.setState({ archivedOpen: false });

    const { classroomManager, history } = this.props;
    const { archived, endDate, imageUrl, name, nickname, startDate } = this.state;
    let saved = false;
    let failed = false;

    const hasError = this.validate();
    if (hasError) {
      return;
    }
    const result = await classroomManager.updateClassroom(
      classroomManager.currentClassroomId,
      name,
      nickname,
      archived,
      endDate,
      startDate,
      imageUrl
    );
    if (result) {
      saved = true;
      failed = false;
    } else {
      saved = false;
      failed = true;
    }
    this.setState({ saved, failed });
    if (saved && archived) {
      classroomManager.removeClassroom(classroomManager.currentClassroomId);
      if (userManager.isDistrictOrSchoolAdmin) {
        history.replace(`/classrooms?view=${VIEW_SELECTION.CLASSROOMS}`);
      } else {
        history.replace('/');
      }
    } else if (saved) {
      const { userManager } = this.props;
      const isAdmin = userManager.isDistrictAdmin || userManager.isSchoolAdmin;
      if (isAdmin) {
        const result = await ClassroomService.initAdminData({
          ...this.props,
          shouldFetchClassroomWithCompleteTeacherData: true
        });
        if (result && result.shouldInitMoreDataIfApplicable) {
          this.setClassroomBreadcrumbs();
        }
      } else {
        this.setClassroomBreadcrumbs();
      }
    }
  }

  validate = (
    startDate = this.state.startDate,
    endDate = this.state.endDate,
    name = this.state.name,
    nickname = this.state.nickname
  ) => {
    const { /* userManager, */classroomManager } = this.props;
    // TODO remove // const { isSsoUser } = userManager;

    let hasError = false;

    // const isSsoUserAndSelfRegClassroom = UserService.isSsoUserAndSelfRegClassroom();
    const isSelfRegClassroom = ClassroomService.isSelfRegClassroom();

    const allowSsoEditClassroomSettings = UserService.allowSsoEditClassroomSettings();

    if (/* !isSsoUser */isSelfRegClassroom || allowSsoEditClassroomSettings) {
      if (!name || name.trim() === '') {
        this.setState({
          failed: false,
          name: '',
          nameError: true,
          nameErrorMessage: 'Name is required',
          saved: false
        });
        hasError = true;
      } else if (name.length >= 100) {
        this.setState({
          failed: false,
          name: '',
          nameError: true,
          nameErrorMessage: 'Name length should less than 100 characters',
          saved: false
        });
        hasError = true;
      }
    }

    if (/* isSsoUser && !isSsoUserAndSelfRegClassroom */!isSelfRegClassroom) {
      if (!nickname || nickname.trim() === '') {
        this.setState({
          failed: false,
          nickNameError: true,
          nickNameErrorMessage: 'Name is required',
          nickname: '',
          saved: false
        });
        hasError = true;
      } else if (nickname.length >= 100) {
        this.setState({
          failed: false,
          nickNameError: true,
          nickNameErrorMessage: 'Name length should less than 100 characters',
          saved: false
        });
        hasError = true;
      }
    }

    const classroom = classroomManager.getClassroom(classroomManager.currentClassroomId);
    let skipStart = false;
    let skipEnd = false;
    if (classroom && classroom.timezoneStartDate === startDate) {
      skipStart = true;
    }

    if (classroom && classroom.timezoneEndDate === endDate) {
      skipEnd = true;
    }

    if (!skipStart || !skipEnd) {
      if (endDate && startDate) {
        const endRealDate = new Date(endDate);
        const startRealDate = new Date(startDate);
        if (endRealDate < startRealDate) {
          this.setState({
            endDateError: true,
            endErrorMessage: 'The End Date cannot occur before the Start Date',
            failed: false,
            saved: false
          });
          hasError = true;
        }
      }
    }

    if (!skipStart) {
      if (startDate === null || startDate === '') {
        this.setState({
          failed: false,
          saved: false,
          startDateError: true,
          startErrorMessage: 'The Start Date is required'
        });
        hasError = true;
      }
    }

    if (!skipStart || !skipEnd) {
      if (endDate !== null &&
      endDate !== '' &&
      startDate !== null &&
      startDate !== '') {
        const endRealDate = new Date(endDate);
        const startRealDate = new Date(startDate);
        if (endRealDate < startRealDate) {
          this.setState({
            failed: false,
            saved: false,
            startDateError: true,
            startErrorMessage: 'The Start Date cannot occur after the End date'
          });
          hasError = true;
        }
      }
    }

    if (!skipEnd) {
      if (endDate === null || endDate === '') {
        this.setState({
          endDateError: true,
          endErrorMessage: 'The End Date is required',
          failed: false,
          saved: false
        });
        hasError = true;
      }
    }
    this.setState({ hasError });
    return hasError;
  }

  toggleClassImageEditor = (saved, imageUrl) => {
    const { dialogManager, classroomManager: { currentClassroomId: classroomId } } = this.props;

    saved && this.setState({ imageUrl });

    if (dialogManager.openedDialogs.has(DIALOG_NAMES.CLASS_IMAGE)) {
      dialogManager.closeDialog(DIALOG_NAMES.CLASS_IMAGE);
    } else {
      dialogManager.setOpenDialog(DIALOG_NAMES.CLASS_IMAGE, { classroomId }, this.toggleClassImageEditor);
    }
  }

  renderInstitutionNameFormField() {
    const { t } = this.props;
    const hasMultipleInstitutions = UserService.hasMultipleInstitutions();

    const { classroom } = this.state;
    const institutionName = classroom?.institutionName || classroom?.institutionId || '';

    return hasMultipleInstitutions && (
      <Form.Field>
        <label>
          {t('institutionName')}
        </label>
        <Input
          name='institutionName'
          readOnly
          type='text'
          value={institutionName} />
      </Form.Field>
    );
  }

  render() {
    const { classroomManager, defaultImage, history, userManager, t } = this.props;
    const { currentClassroomId } = classroomManager;

    const isAdmin = userManager.isDistrictAdmin || userManager.isSchoolAdmin;

    const {
      archived, archivedOpen, endDate, endDateError, endErrorMessage, failed, hasError,
      loading, name, nameError, nameErrorMessage, nickNameError, nickNameErrorMessage,
      nickname, saved, startDate, startDateError, startErrorMessage
    } = this.state;

    const {
      ClassroomInfoBanner, CourseListBanner,
      CoTeacherAccessCode, DotMenu, StudentAccessCode
    } = this;

    let classroom = null;
    let imageUrl = null;

    if (currentClassroomId) {
      classroom = classroomManager.getClassroom(currentClassroomId);
      if (classroom !== null) {
        imageUrl = classroomManager.getClassroomImageUrl(classroom.id);
      }
    }

    const isStartDateBeforeEndDate = startDate && endDate &&
      new Date(startDate).valueOf() < new Date(endDate).valueOf();

    // const isSsoUserOrHasSsoInstitution = UserService.isSsoUserOrHasSsoInstitution();
    // const isSsoUserAndSelfRegClassroom = UserService.isSsoUserAndSelfRegClassroom();

    const isSelfRegClassroom = ClassroomService.isSelfRegClassroom();

    const allowSsoEditClassroomSettings = UserService.allowSsoEditClassroomSettings();
    return (
      <Container className='class-settings-view' fluid>
        <CourseListBanner
          customContainerClassName='course-list-banner-custom-container'
          hideAddCourseButton={true}
          hideAddStudentButton={true}
          history={history}
          showBreadCrumbs='bottom' />

        {isAdmin && (
          <ClassroomInfoBanner {...this.props}
            classroom={classroom}
            style={{ margin: '0 258px 0 0' }} />
        )}

        {(currentClassroomId && classroom && !userManager.isStudent) ? (
          <Container className='class-settings'>
            <Container className='settings-image'>
              <DotMenu clickMenu={() => this.toggleClassImageEditor()} />
              <div className='image-wrapper'>
                {imageUrl === null ?
                  <Image className='default-class-image' src={defaultImage || classroomDefault} wrapped /> :
                  <Image className='real-class-image' src={imageUrl} wrapped />}
              </div>
            </Container>
            <Container className='settings-form'>

              <Grid className='settings-grid' textAlign='center' verticalAlign='middle'>
                <Grid.Column>
                  {!isAdmin && <Header as='h2'>{classroom.name}</Header>}
                  <Header as='h3'>{t('classInformationTitle', 'Class Information')}</Header>
                  <Form as='div'>
                    {/* INSTITUTION NAME */}
                    {this.renderInstitutionNameFormField()}
                    {/* CLASSROOM NAME */}
                    <Form.Field>
                      <label>{t('className', 'Class Name')}</label>
                      <Input
                        disabled={/* userManager.isSsoUser */!isSelfRegClassroom && !allowSsoEditClassroomSettings}
                        name='name'
                        onChange={this.handleChange}
                        placeholder='Name'
                        type='text'
                        value={name} />
                    </Form.Field>
                    <Message
                      content={nameErrorMessage}
                      error
                      style={!name || name.length >= 100 ? {} : { display: 'none' }}
                      visible={nameError} />

                    {/* CLASSROOM DISPLAY NAME */}
                    {/* (userManager.isSsoUser && !isSsoUserAndSelfRegClassroom) */!isSelfRegClassroom ? (
                      <>
                        <Form.Field>
                          <label>{t('classDisplayName', 'Class Display Name')}</label>
                          <Input
                            name='nickname'
                            onChange={this.handleChange}
                            placeholder='Name'
                            type='text'
                            value={nickname} />
                        </Form.Field>
                        <Message
                          content={nickNameErrorMessage}
                          error
                          style={!nickname || nickname.length >= 100 ? {} : { display: 'none' }}
                          visible={nickNameError} />
                      </>
                    ) : null}

                    {/* START DATE */}
                    <Container className='date-wrapper' fluid>
                      <Form.Field>
                        <DateInput
                          animation='false'
                          dateFormat='MM/DD/YYYY'
                          disabled={/* userManager.isSsoUser */!isSelfRegClassroom && !allowSsoEditClassroomSettings}
                          duration={0}
                          iconPosition='right'
                          label={t('startDate', 'Start Date')}
                          name='startDate'
                          onChange={this.handleChangeStartDate}
                          placeholder={t('startDate', 'Start Date')}
                          value={startDate} />
                        <Message
                          content={startErrorMessage}
                          error
                          style={isStartDateBeforeEndDate ? { display: 'none' } : {}}
                          visible={startDateError} />
                      </Form.Field>

                      {/* END DATE */}
                      <Form.Field>
                        <DateInput
                          animation='false'
                          dateFormat='MM/DD/YYYY'
                          disabled={/* userManager.isSsoUser */!isSelfRegClassroom && !allowSsoEditClassroomSettings}
                          duration={0}
                          iconPosition='right'
                          label={t('endDate', 'End Date')}
                          name='endDate'
                          onChange={this.handleChangeEndDate}
                          placeholder={t('endDate', 'End Date')}
                          value={endDate} />
                        <Message
                          content={endErrorMessage}
                          error
                          style={isStartDateBeforeEndDate ? { display: 'none' } : {}}
                          visible={endDateError} />
                      </Form.Field>
                    </Container>

                    {/* CLASSROOM STATUS */}
                    {(classroomManager.getActivityCount(classroomManager.currentClassroomId) <= 0 &&
                      (isSelfRegClassroom || allowSsoEditClassroomSettings)) ? (
                        <Form.Field className='status'>
                          <label>{t('classStatus', 'Class Status')}</label>
                          <Dropdown
                            fluid
                            name='archived'
                            onChange={this.handleChange}
                            options={this.classroomStatusOptions}
                            selection
                            value={archived} />
                        </Form.Field>
                      ) : null}

                    {/* ACCESS CODE */}
                    <Header as='h3' className='access-header'>{t('accessCode', 'Access Code')}</Header>
                    <Form.Field>
                      <div className='access-code-container'>
                        <>
                          {/* !isSsoUserOrHasSsoInstitution */isSelfRegClassroom && (
                            <StudentAccessCode classId={classroomManager.currentClassroomId}
                              onClick={this.handleChange} />
                          )}
                          <CoTeacherAccessCode classId={classroomManager.currentClassroomId}
                            onClick={this.handleChange} />
                        </>
                      </div>
                    </Form.Field>

                    {/* ACTIONS */}
                    <Button
                      className={classNames('settings-save-button', {
                        'settings-save-button-saved': saved
                      })}
                      disabled={hasError}
                      onClick={this.submitUpdateClass}
                      primary
                      type='button'>
                      Save
                    </Button>
                    {saved ? (
                      <Message positive>
                        <Message.Header>Success</Message.Header>
                        <p>
                          Your changes have been saved.
                        </p>
                      </Message>
                    )
                      : null}
                    {failed ? (
                      <Message negative>
                        <Message.Header>Something went wrong</Message.Header>
                        <p>
                          Your changes failed to save please try again.
                        </p>
                      </Message>
                    )
                      : null}
                  </Form>
                </Grid.Column>
              </Grid>
            </Container>
            <Modal className='warning-modal' onClose={this.closeWarning} open={archivedOpen} size='small'>
              <Modal.Header>Warning!</Modal.Header>
              <Modal.Content>
                <p>You are about to archive your classroom!</p>
                <p>If you continue, you will lose all future access to this classroom.</p>
                <p />
                <p><strong>Are you sure you want to do this?</strong></p>
              </Modal.Content>
              <Modal.Actions>
                <Button basic onClick={this.closeWarning} primary type='button'>No, Cancel</Button>
                <Button onClick={this.confirmSubmitUpdateClass} primary type='button'>Yes continue</Button>
              </Modal.Actions>
            </Modal>
          </Container>
        ) : (
          <Container className='class-settings'>
            <div className='no-courses'>
              {loading ? <Loader active /> : t('classroomNotFound')}
            </div>
          </Container>
        )}
      </Container>
    );
  }
}

SatCoreRegister('ClassSettingsView', ClassSettingsView);
