import { registerClass } from '../../SatCoreRegistry';

import exportManager from '../../managers/ExportManager';
import gradebookManager from '../../managers/GradebookManager';
import reportIdentityManager from '../../managers/reports/ReportIdentityManager';

export default class GradebookExportService {
  static requestGradebookExport = async (
    reportId, emailAddress, assignment, assignmentId, hideUnscorable = null, {
      isAssignmentsFromRemovedCourses = false,
      isExportForReportTableRowResource = false,
      reportTableRow = undefined
    } = {}
  ) => {
    if (isExportForReportTableRowResource) {
      return this.requestReportTableRowGradebookExport({
        emailAddress,
        hideUnscorable,
        reportId,
        reportTableRow
      });
    } else if (gradebookManager.activeGradebookType === 'aggregate') {
      const parameterKeysArray = [
        'classroomId',
        'contentItemId',
      ];
      if (typeof hideUnscorable === 'boolean') {
        parameterKeysArray.push('hideUnscorable');
      }
      if (!isAssignmentsFromRemovedCourses) {
        parameterKeysArray.push('assignmentId');
        parameterKeysArray.push('courseContentItemId');
      }
      const { classroomId, contentItemId, courseContentItemId } = assignment;
      let body = {
        classroomId: classroomId || assignment.domainId,
        contentItemId,
        emailAddress,
        parameterKeysArray,
        reportId
      };

      if (typeof hideUnscorable === 'boolean') {
        body = { ...body, hideUnscorable };
      }
      if (!isAssignmentsFromRemovedCourses) {
        body = { ...body, assignmentId, courseContentItemId };
      }

      return exportManager.requestExport(body);
    }
  }

  static requestReportTableRowGradebookExport = async ({
    hideUnscorable,
    emailAddress,
    reportId,
    reportTableRow
  } = {}) => {
    const reportFacultyIdKey = reportIdentityManager.isReport ? `${reportIdentityManager.activeReportFacultyType}Id` : undefined;

    const parameterKeysArray = [
      reportFacultyIdKey, // e.g. 'schoolId'
      'contentItemId',
      'compositeId',
      'courseContentItemId',
      'duplicateKey'
    ];
    if (typeof hideUnscorable === 'boolean') {
      parameterKeysArray.push('hideUnscorable');
    }

    const contentItemId = reportTableRow?.original?.elementContentItemId;
    const courseContentItemId = reportTableRow?.original?.courseContentItemId;

    const institutionId = reportIdentityManager.activeReportInstitutionId;

    const compositeId = institutionId + contentItemId;

    let body = {
      compositeId,
      contentItemId,
      courseContentItemId,
      duplicateKey: compositeId,
      emailAddress,
      parameterKeysArray,
      [reportFacultyIdKey]: institutionId,
      reportId
    };
    if (typeof hideUnscorable === 'boolean') {
      body = { ...body, hideUnscorable };
    }
    return exportManager.requestExport(body);
  };
}

registerClass('GradebookExportService', GradebookExportService);
