import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Confirm, Image, Loader, Pagination } from 'semantic-ui-react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';
import { DIALOG_NAMES } from '../managers/DialogManager';
import UtilityService from '../services/UtilityService';

import '../css/Class.less';
import '../css/GroupRosterTable.less';

import iconMember from '../img/icon-green-check.svg';

export default
@inject(
  'classroomManager', 'dialogManager',
  'filteredHeaderTableManager',
  'groupsManager', 'userManager'
)
@observer
class GroupRosterTable extends Component {
  filteredHeaderTableKey='classroom-group-roster-table-key';

  constructor(props) {
    super(props);
    this.state = {
      bodyData: [],
      filterOptionsData: [],
      headerData: [],
      isActive: true,
      loadingGroupRosterTable: false,
      selectedFilterOptionsData: [],
      showAlertDeleteError: false,
      showConfirmDelete: false,
      showConfirmDeleteId: '',
      showInfoForm: {}
    };
    this.FilteredHeaderTable = SatCoreComponent('FilteredHeaderTable');
  }

  async componentDidMount() {
    const {
      filteredHeaderTableManager, isActive, location, userManager
    } = this.props;
    const { isDistrictAdmin, isSchoolAdmin, isTeacher } = userManager;
    const isAdmin = isDistrictAdmin || isSchoolAdmin;
    if (isAdmin || isTeacher) {
      const { classroomManager } = this.props;

      const urlParams = new URLSearchParams(location.search);
      if (urlParams.has('classroomId')) {
        classroomManager.setCurrentClassroomId(urlParams.get('classroomId'));
      }

      this.setState({ isActive });

      // register the table
      filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);

      // initialize data
      this.refreshData();
    }
  }

  componentDidUpdate = async () => {
    const { filteredHeaderTableManager, isActive } = this.props;
    let changesMade = false;

    /* If table manager is cleared, reset all the filters */
    if (filteredHeaderTableManager.isCleared) {
      filteredHeaderTableManager.registerTable(this.filteredHeaderTableKey, 1);
      changesMade = true;
    // eslint-disable-next-line react/destructuring-assignment
    } else if (this.state.isActive !== isActive) {
      changesMade = true;
      await this.clearTableData(isActive);
    }

    if (changesMade) {
      await this.refreshData();
    }
  }

  componentWillUnmount = async () => {
    this.clearTableData(true);
  }

  setActivePageAndRefreshData = async () => {
    this.setState({ loadingGroupRosterTable: true });
    const { filteredHeaderTableManager } = this.props;
    const { bodyData } = this.state;
    const FIRST_STUDENT_INDEX = 0;
    const SECOND_GROUP_INDEX = 3;
    // check for second group index of first student.
    // if it doesn't exist and we're not on page 1, we need to move back a page
    const tableId = this.filteredHeaderTableKey;
    const activePage = filteredHeaderTableManager.getTableData(tableId)?.activePage;
    if (!bodyData?.[FIRST_STUDENT_INDEX]?.[SECOND_GROUP_INDEX] && activePage > 1) {
      filteredHeaderTableManager.setActivePage(tableId, activePage - 1);
      await this.refreshData();
      await this.createFilterOptionData();
      setTimeout(() => {
        this.setState({ loadingGroupRosterTable: false });
      }, 1000);
    } else {
      this.setState({ loadingGroupRosterTable: false });
      return { didNotRefreshData: true };
    }
  }

  refreshData = async (map = null, classroomMap = null) => {
    const { classroomManager, groupsManager } = this.props;
    const { filterOptionsData, isActive } = this.state;
    const { currentClassroomId } = classroomManager;
    const sortColumn = classroomMap ? classroomMap.get('sortColumn') : null;
    const sortDirection = classroomMap ? classroomMap.get('sortDirection') : null;
    const classroomRoster = groupsManager.rosterMap.get(currentClassroomId);
    if (sortColumn != null || !classroomRoster) {
      await groupsManager.fetchClassroomRoster(currentClassroomId, sortColumn, sortDirection);
    }
    this.getFilteredGroupsByClassroom(currentClassroomId, map, !isActive).then(() => {
      this.createTableData();
      if (!filterOptionsData || filterOptionsData.length < 1) {
        this.createFilterOptionData();
      }
    });
  }

  createFilterOptionData = async () => {
    const { groupsManager, classroomManager, t } = this.props;
    const { isActive } = this.state;
    const { currentClassroomId } = classroomManager;
    // while loop over groups, create the group filter dropdown options
    const groupFilterOptions = [];
    const selectedFilterOptionsData = [];
    const groups = await groupsManager.getGroupsByClassroom(currentClassroomId, !isActive);

    if (groups && groups.length > 0) {
      const defaultOption = { label: t('groupFilterDefaultText', 'All Groups'), value: -1 };
      groupFilterOptions.push(defaultOption);
      selectedFilterOptionsData.push(defaultOption);
      groups.map((group) => {
        const option = { label: group.name, value: group.id };
        if (!groupFilterOptions.find((groupOption) => groupOption.value === group.id)) {
          groupFilterOptions.push(option);
        }
      }, this);
    }
    this.setState({ filterOptionsData: groupFilterOptions, selectedFilterOptionsData });
  }

  onPageChange = async (_event, pageInfo) => {
    const { filteredHeaderTableManager } = this.props;
    const { selectedFilterOptionsData } = this.state;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      const filters = []; // we aren't using filters currently for the groups page
      const groupIds = [];
      const { activePage } = pageInfo;
      // convert options to a list of group ids.
      selectedFilterOptionsData.forEach((groupOption) => groupOption.value !== -1 && groupIds.push(groupOption.value));
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, activePage);
      const map = new Map([
        ['activePage', activePage],
        ['filters', filters],
        ['groupIds', groupIds]
      ]);
      await this.refreshData(map);
    }
  }

  // This is not really used for this as we aren't filtering from the FilteredHeaderTable columns
  handleTableFilter = async () => {
    const { filteredHeaderTableManager } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      const activePage = 1;
      const filters = []; // we aren't using filters currently for the groups page
      // convert options to a list of group ids.
      filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, activePage);
      const map = new Map([
        ['activePage', activePage],
        ['filters', filters]
      ]);
      this.refreshData(map);
    }
  }

  // Handles group filter dropdown
  handleGroupFilter = async (selectedOptions) => {
    const { filteredHeaderTableManager, t } = this.props;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      const activePage = 1;
      const filters = []; // we aren't using filters currently for the groups page
      const groupIds = [];
      let selectedFilterOptionsData = [];
      const hasGroupOptionsSelected = selectedOptions && selectedOptions.find((groupOption) => groupOption.value !== -1) != undefined;
      const hasAllSelected = selectedOptions && selectedOptions.find((groupOption) => groupOption.value === -1) !== undefined;
      // convert options to a list of group ids.
      if (selectedOptions && selectedOptions.length > 0) {
        selectedOptions.forEach((groupOption) => {
          // see if all groups had been selected prior
          // eslint-disable-next-line react/destructuring-assignment
          const hadAllSelected = this.state.selectedFilterOptionsData.find((groupOption) => groupOption.value === -1) !== undefined;
          if (hadAllSelected) {
            if (hasGroupOptionsSelected) {
              // this changed from ALL to having some groups selected, remove the all, leave the others
              selectedFilterOptionsData = selectedOptions.filter((groupOption) => groupOption.value !== -1);
              groupIds.push(groupOption.value);
            } else {
              selectedFilterOptionsData = [{ label: t('groupFilterDefaultText', 'All Groups'), value: -1 }];
            }
          } else if (hasAllSelected) {
            selectedFilterOptionsData = [{ label: t('groupFilterDefaultText', 'All Groups'), value: -1 }];
          } else {
            selectedFilterOptionsData = selectedOptions;
          }
        }, this);
      } else {
        selectedFilterOptionsData = [{ label: t('groupFilterDefaultText', 'All Groups'), value: -1 }];
      }
      filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, activePage);
      // TODO unused
      // const map = new Map([
      //   ['activePage', activePage],
      //   ['filters', filters],
      //   ['groupIds', groupIds]
      // ]);
      this.setState({ selectedFilterOptionsData });
    }
  }

  // Called when group filter menu closes.  This is when we update the data
  handleGroupFilterClose = async (event) => {
    const { filteredHeaderTableManager, t } = this.props;
    const { selectedFilterOptionsData } = this.state;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      const groupIds = [];
      if (selectedFilterOptionsData && selectedFilterOptionsData.length > 0) {
        // convert options to a list of group ids.
        selectedFilterOptionsData.forEach((groupOption) => {
          if (groupOption.value !== -1) { // -1 is for all groups option, don't send any groupIds in this case
            groupIds.push(groupOption.value);
          }
        });
      } else {
        // if there are none selected, reset to All Groups
        this.setState({ selectedFilterOptionsData: [{ label: t('groupFilterDefaultText', 'All Groups'), value: -1 }] });
      }
      const map = new Map([
        ['activePage', tableData.activePage],
        ['filters', tableData.filters],
        ['groupIds', groupIds]
      ]);
      this.refreshData(map);
    }
  }

  handleSort = async (sortColumn, filters) => {
    const { filteredHeaderTableManager } = this.props;
    const { selectedFilterOptionsData } = this.state;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      const groupIds = [];
      if (sortColumn) {
        // convert options to a list of group ids.
        selectedFilterOptionsData.forEach((groupOption) => groupIds.push(groupOption.value));
        const sortDirection = tableData.direction === 'ascending' ? 'descending' : 'ascending';
        filteredHeaderTableManager.setSortColumn(this.filteredHeaderTableKey, sortColumn);
        filteredHeaderTableManager.setSortDirection(this.filteredHeaderTableKey, sortDirection);
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
        // if sorting by first or last name we need to sort the class roster call
        if (sortColumn === 'lastName' || sortColumn === 'firstName') {
          const classroomMap = new Map([
            ['sortColumn', sortColumn],
            ['sortDirection', sortDirection === 'ascending' ? 'ascending' : 'descending']
          ]);
          const filterLabel = selectedFilterOptionsData?.[0]?.label;
          const map = (!filterLabel || filterLabel === 'All Groups') ? null : new Map([
            ['activePage', tableData.activePage],
            ['filters', filters],
            ['groupIds', groupIds]
          ]);
          await this.refreshData(map, classroomMap);
        } else if (sortColumn.includes('groupId-')) {
          // handle special sorting of group columns.
          const sortTokens = sortColumn.split('-');
          const groupId = sortTokens[1];
          this.createTableData(groupId, sortDirection);
        }
      } else {
        filteredHeaderTableManager.setFilters(this.filteredHeaderTableKey, filters);
      }
    }
  }

  getFilteredGroupsByClassroom = async (classroomId, map = null, archived = false) => {
    const { groupsManager, filteredHeaderTableManager, classroomManager } = this.props;
    const { currentClassroomId } = classroomManager;
    const tableData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    if (tableData) {
      let activePage, pageSize, sortColumn, sortDirection, filters, groupIds;
      if (map) {
        activePage = map.get('activePage');
        pageSize = map.get('pageSize');
        sortColumn = map.get('sortColumn');
        sortDirection = map.get('sortDirection');
        filters = map.get('filters');
        groupIds = map.get('groupIds');
      }
      activePage = activePage || tableData.activePage;
      sortColumn = sortColumn || tableData.column;
      sortDirection = sortDirection || tableData.direction;
      filters = filters || (tableData.filters ? tableData.filters : []);

      await groupsManager.getFilteredGroupsByClassroom(
        (classroomId || currentClassroomId || null), groupIds, archived,
        activePage, sortColumn, sortDirection, filters, pageSize
      );
    }
  }

  createRosterRowCell = (colName, cellText) => {
    const cellData = {
      columnButtonOnClick: null,
      columnClassName: 'truncate-long',
      columnName: colName,
      columnOnClick: null,
      columnText: cellText,
      showTooltip: colName.includes('-name'),
      showTootipEvent: 'hover'
    };
    return cellData;
  }

  createRosterRowHeaderCell = (label, sortKey, infoKey, data) => {
    const headerData = {
      columnClassName: 'truncate-long',
      filterAutoCompleteCallback: null,
      filterKey: '',
      filterLabel: '',
      infoKey: data ? infoKey : null,
      infoPopupContent: this.renderColumnInfoPopupContent(data),
      label,
      sortKey
    };
    return headerData;
  }

  createTableData = async (groupId = null, sortDirection = 'ascending') => {
    const { groupsManager, classroomManager } = this.props;
    const { groups } = groupsManager;
    let bodyData = [];
    const sortGroupRowData = [];
    const nonSortGroupRowData = [];
    const headerData = [];

    if (groups && groups.length > 0) {
      // Requirement for sorting by group: IF groupId !== null create two sets of of row data.
      // One set will just have rows for members of the group that matches groupId
      // The other set will have rows for the rest of the class but not the ones that match groupId
      // If sort direction is DESCENDING group rows are added to main rows var first, then the others
      // and vice versa.
      let sortGroup = null;
      if (groupId) {
        sortGroup = groupsManager.getGroup(groupId);
      }
      const rosterItems = await groupsManager.rosterMap.get(classroomManager.currentClassroomId);
      if (rosterItems !== null && rosterItems !== undefined) {
        // eslint-disable-next-line array-callback-return
        let rowIndex = 0;
        // create first two column headers
        headerData.push(this.createRosterRowHeaderCell('First Name', 'firstName', null, null));
        headerData.push(this.createRosterRowHeaderCell('Last Name', 'lastName', null, null));

        if (sortGroup) {
          rosterItems.map((rosterItem) => {
            const groupRosterRow = [];
            const currentStudentId = rosterItem.userId;
            const sortGroupMember = sortGroup.members.find((element) => element.id === currentStudentId);

            // Create name cells
            if (sortGroupMember) {
              groupRosterRow.push(this.createRosterRowCell('group-col-first-name', rosterItem.firstName));
              groupRosterRow.push(this.createRosterRowCell('group-col-last-name', rosterItem.lastName));
            }

            groupsManager.groups.map((group) => {
              // create group header
              if (sortGroup && rowIndex < 1) {
                headerData.push(this.createRosterRowHeaderCell(group.name, `groupId-${group.id}`, `group-${group.id}`, group));
              }
              const member = group.members.find((element) => element.id === currentStudentId);
              const cellContent = member ?
                <div className='member-icon-container'><Image alt='' className='member-icon' src={iconMember} /></div> : '';
              if (sortGroupMember) {
                groupRosterRow.push(this.createRosterRowCell(`group-col-${group.id}`, cellContent));
              }
            }, this);
            rowIndex++;
            sortGroupRowData.push(groupRosterRow);
          }, this);
        }

        rosterItems.map((rosterItem) => {
          const rosterRow = [];
          const currentStudentId = rosterItem.userId;
          const sortGroupMember = sortGroup ? sortGroup.members.find((element) => element.id === currentStudentId) : false;

          // Create name cells
          if (!sortGroupMember) {
            rosterRow.push(this.createRosterRowCell('group-col-first-name', rosterItem.firstName));
            rosterRow.push(this.createRosterRowCell('group-col-last-name', rosterItem.lastName));
          }

          groupsManager.groups.map((group) => {
            // create group header
            if (!sortGroup && rowIndex < 1) {
              headerData.push(this.createRosterRowHeaderCell(group.name, `groupId-${group.id}`, `group-${group.id}`, group));
            }
            const member = group.members.find((element) => element.id === currentStudentId);
            const cellContent = member ?
              <div className='member-icon-container'><Image alt='' className='member-icon' src={iconMember} /></div> : '';
            if (!sortGroupMember) {
              rosterRow.push(this.createRosterRowCell(`group-col-${group.id}`, cellContent));
            }
          }, this);
          rowIndex++;
          nonSortGroupRowData.push(rosterRow);
        }, this);
      }
      // Set the body data based on if we are sorting by group
      if (sortGroup) {
        if (sortDirection === 'descending') {
          bodyData = [...sortGroupRowData, ...nonSortGroupRowData];
        } else {
          bodyData = [...nonSortGroupRowData, ...sortGroupRowData];
        }
      } else {
        bodyData = nonSortGroupRowData;
      }
    }
    this.setState({ bodyData, headerData });
  }

  confirmDeleteGroup = (group) => {
    this.setState({ showConfirmDelete: true, showConfirmDeleteId: group.id });
    this.showInfoFormClose();
  }

  handleDeleteGroup = async (groupId) => {
    const { groupsManager } = this.props;
    const response = await groupsManager.deleteClassroomGroup(groupId);
    if (response) {
      if (response.status === 'SUCCESS') {
        this.setState({ showConfirmDelete: false, showConfirmDeleteId: '' });
        const obj = await this.setActivePageAndRefreshData();
        if (obj?.didNotRefreshData) {
          await this.refreshData();
        }
        this.createFilterOptionData();
      } else {
        if (response.statusMessage === 'Group is in use.') {
          this.setState({ showAlertDeleteError: true, showConfirmDelete: false, showConfirmDeleteId: '' });
        }
      }
    } else {
      this.setState({ showAlertDeleteError: 'Error deleting group.', showConfirmDelete: false, showConfirmDeleteId: '' });
    }
  }

  handleReactivateGroup = async (group) => {
    const { groupsManager } = this.props;
    this.showInfoFormClose();
    await groupsManager.updateClassroomGroup(group.id, group.name, group.description, false);
    const obj = await this.setActivePageAndRefreshData();
    if (obj?.didNotRefreshData) {
      await this.refreshData();
      await this.createFilterOptionData();
    }
  }

  handleEditGroupModal = (group) => {
    const { dialogManager, groupsManager } = this.props;
    this.setState({ showInfoForm: {} }); // this closes the info popup in the filtered header table.
    groupsManager.setCurrentGroupId(group.id);
    dialogManager.setOpenDialog(DIALOG_NAMES.ADD_EDIT_GROUP, {}, this.closeEditGroupModal);
  }

  closeEditGroupModal = async (_event, didSave, didArchive) => {
    const { dialogManager } = this.props;
    dialogManager.closeDialog(DIALOG_NAMES.ADD_EDIT_GROUP);
    if (didSave) {
      if (didArchive) {
        const obj = await this.setActivePageAndRefreshData();
        if (obj?.didNotRefreshData) {
          await this.refreshData();
          await this.createFilterOptionData();
        }
      } else {
        await this.handleGroupFilterClose();
      }
    }
  }

  showInfoFormOpen = (infoKey) => {
    this.setState({ showInfoForm: { infoKey } });
  }

  showInfoFormClose = () => {
    this.setState({ showInfoForm: {} });
  }

  clearTableData = async (isActive) => {
    const { filteredHeaderTableManager, t } = this.props;
    filteredHeaderTableManager.setActivePage(this.filteredHeaderTableKey, 1);
    this.setState({
      bodyData: [],
      filterOptionsData: [],
      headerData: [],
      isActive,
      selectedFilterOptionsData: [{ label: t('groupFilterDefaultText', 'All Groups'), value: -1 }]
    });
  }

  getDropdownButtonLabel = () => {
    const { t } = this.props;
    const { selectedFilterOptionsData } = this.state;
    const hasGroupOptionsSelected = selectedFilterOptionsData.find((groupOption) => groupOption.value !== -1) != undefined;
    let buttonLabel = t('groupFilterDefaultText', 'Edit');
    if (hasGroupOptionsSelected) {
      if (selectedFilterOptionsData.length > 0) {
        if (selectedFilterOptionsData.length === 1) {
          buttonLabel = selectedFilterOptionsData[0].label;
        } else {
          buttonLabel = selectedFilterOptionsData.length + t('groupFilterSelectedText', 'Edit');
        }
      }
    }
    return buttonLabel;
  }

  renderColumnInfoPopupContent = (group) => {
    const { isActive, t } = this.props;
    if (group) {
      const descriptionObj = UtilityService.reactHtmlParserWrapper(
        group.description
      );
      return (
        <div className='groupInfoPopupContent'>
          <div className='groupName'>{group.name}</div>
          <div className='groupDescription'>{descriptionObj.parsed}</div>
          <div className='buttonWrapper'>
            {isActive ? (
              <Button
                className='ui primary button saveButton'
                onClick={() => { this.handleEditGroupModal(group); }}
                type='button'>
                {t('editGroupButtonText', 'Edit')}
              </Button>
            )
              : (
                <>
                  <Button
                    className='ui basic primary button groupDeleteButton'
                    onClick={() => { this.confirmDeleteGroup(group); }}
                    type='button'>
                    {t('deleteGroupButtonText', 'Delete Group')}
                  </Button>
                  <Button
                    className='ui primary button groupReactivateButton'
                    onClick={() => { this.handleReactivateGroup(group); }}
                    type='button'>
                    {t('reactivateGroupButtonText', 'Reactivate')}
                  </Button>
                </>
              )}
          </div>
        </div>
      );
    } else {
      return ('');
    }
  }

  render() {
    const { groupsManager, filteredHeaderTableManager, t } = this.props;
    const { filterOptionsData } = this.state;

    const { FilteredHeaderTable } = this;

    // eslint-disable-next-line react/destructuring-assignment
    const tableBodyData = this.state.bodyData;
    // eslint-disable-next-line react/destructuring-assignment
    const tableHeaderData = this.state.headerData;

    const tableMetaData = filteredHeaderTableManager.getTableData(this.filteredHeaderTableKey);
    let activePage = 0;
    let sortColumn = '';
    let sortDirection = '';
    let filters = [];
    if (tableMetaData) {
      activePage = tableMetaData.activePage;
      sortColumn = tableMetaData.column;
      sortDirection = tableMetaData.direction;
      filters = tableMetaData.filters;
    }

    const { loadingGroupRosterTable } = this.state;
    const loading = !groupsManager.loaded || loadingGroupRosterTable;
    return (
      <>
        {loading ? (
          <div className='groupRosterTable'>
            <div className='null-state-panel'>
              <Loader key={0} active inline />
            </div>
          </div>
        ) : (
          <div className='groupRosterTable'>
            {(!tableBodyData || !tableBodyData?.length) && (
              <div className='null-state-panel'>
                {t('noDataText', 'No data to display')}
              </div>
            )}
            {!!tableBodyData?.length && (
              <>
                <div className='tableHeaderContainer'>
                  <div className='group-filter-label'>
                    {t('filterBy')}
                  </div>
                  <div className='group-filter-wrapper'>
                    <ReactMultiSelectCheckboxes
                      getDropdownButtonLabel={this.getDropdownButtonLabel}
                      onChange={this.handleGroupFilter}
                      onMenuClose={this.handleGroupFilterClose}
                      options={filterOptionsData}
                      // eslint-disable-next-line react/destructuring-assignment
                      value={this.state.selectedFilterOptionsData} />
                  </div>
                  <div className='group-table-spacer'>
                    {/* placeholder */}
                  </div>
                  <div className='group-table-pagination'>
                    <Pagination
                      activePage={activePage}
                      onPageChange={this.onPageChange}
                      totalPages={groupsManager.totalPages} />
                  </div>
                </div>
                <div className='tableContainer'>
                  <FilteredHeaderTable
                    autoCompleteSearchData={null}
                    column={sortColumn}
                    direction={sortDirection}
                    filtersData={filters}
                    handleFilter={this.handleTableFilter}
                    handleSort={this.handleSort}
                    // eslint-disable-next-line react/destructuring-assignment
                    showInfoForm={this.state.showInfoForm}
                    showInfoFormClose={this.showInfoFormClose}
                    showInfoFormOpen={this.showInfoFormOpen}
                    tableBodyData={tableBodyData}
                    tableHeaderData={tableHeaderData}
                    tableId={this.filteredHeaderTableKey} />
                </div>
              </>
            )}
          </div>
        )}
        <Confirm
          cancelButton='No, Cancel'
          className='deleteGroupConfirm'
          confirmButton='Yes, Continue'
          content={t('askRemoveGroup')}
          header='Warning!'
          onCancel={() => this.setState({
            showConfirmDelete: false, showConfirmDeleteId: ''
          })}
          onConfirm={() => {
            // eslint-disable-next-line react/destructuring-assignment
            this.handleDeleteGroup(this.state.showConfirmDeleteId);
          }}
          // eslint-disable-next-line react/destructuring-assignment
          open={this.state.showConfirmDelete} />
        <Confirm
          className='alertDeleteError'
          confirmButton='OK'
          content={t('alertDeleteError')}
          header='Warning!'
          onCancel={() => this.setState({
            showAlertDeleteError: false
          })}
          onConfirm={() => this.setState({
            showAlertDeleteError: false
          })}
          // eslint-disable-next-line react/destructuring-assignment
          open={this.state.showAlertDeleteError} />
      </>
    );
  }
}

SatCoreRegister('GroupRosterTable', GroupRosterTable);
