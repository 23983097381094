/* eslint-disable sort-keys */
import { kebabCase } from 'lodash';

import { action, computed, makeObservable, observable, toJS } from 'mobx';

import { getRegisteredClass } from '../../SatCoreRegistry';

import { VIEW_SELECTION } from '../NavigationManager';

import Auth from '../AuthManager';
import reportContextManager from './ReportContextManager';
import reportUsageManager from './ReportUsageManager';
import userManager from '../UserManager';

import { generateUrl } from '../../utils/url';

import {
  REPORT_CONTEXT_TYPE,
  REPORT_FACULTY_TYPE, REPORT_FAILURE_BEHAVIOR_TYPE,
  REPORT_TABLE_TYPE, REPORT_TYPE, REPORT_URL_KEY
} from '../../services/reports/ReportConstants';

import { getSessionStorageItem } from '../../utils';

const REPORT_IDENTITY_ENDPOINTS = {
  FETCH_REPORT_RESPONSE_JSON: '/api/viewReportOutput'
};

export class ReportIdentityManager {
  constructor() {
    makeObservable(this);
  }

  @observable reportResponseJson = null;
  @observable hasReportResponseJsonError = false;

  @observable forceShowNoDataZeroState = false;

  @observable hasSomeLikertReportData = false;
  @observable hasSomeConventionalReportData = false;

  // TODO remove
  // @observable isExperimentalReportToggled = false;

  @observable allReportTranslationKeysByUrlKeyMap = new Map(Object.entries(REPORT_URL_KEY));

  @observable ltiReportTable = REPORT_TABLE_TYPE.SUMMARY;
  @observable studentId;
  @observable studentFullName;

  @action clearAll = () => {
    this.setReportResponseJson(null);
    this.setHasReportResponseJsonError(false);
    this.setHasSomeLikertReportData(false);
    this.setHasSomeConventionalReportData(false);

    // TODO remove
    // // TODO DEMO-2356 - this is temporary for testing BE report rewrite
    // this.setIsExperimentalReportToggled(false);
  }

  /**
   * @param {{
   *   classroomId: string;
   *   courseId: string;
   *   curriculumMapId: string;
   *   failureBehaviorType: string;
   *   institutionId: string;
   *   reportId: string;
   * }} options
   */
  @action fetchReportResponseJson = async (options = {}) => {
    const {
      curriculumMapId = null, // required for **cmap standards** reports (only sirius at the moment)
      failureBehaviorType = REPORT_FAILURE_BEHAVIOR_TYPE.NORMAL
    } = options;
    let {
      classroomId = null, // required for **classroom (teacher)** reports
      courseId = null, // required for **standards district/school (admin)** reports (unless using cmap)
      institutionId = null, // required for **district/school (admin)** reports
      reportId = '' // required
    } = options;

    const isCourseReport = reportId ? reportId.includes('COURSE') : this.isCourseReport;
    const isStandardsReport = reportId ? reportId.includes('STANDARDS') : this.isStandardsReport;

    const oldReportResponseJson = toJS(this.reportResponseJson);

    const ReportIdentityService = getRegisteredClass('ReportIdentityService');
    const ReportOverrideService = getRegisteredClass('ReportOverrideService');

    let entityId;

    try {
      const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();

      this.setReportResponseJson(null);
      this.setHasReportResponseJsonError(false);
      this.setForceShowNoDataZeroState(false);

      const urlParams = new URLSearchParams(window.location.search);
      reportId = reportId || urlParams.get('reportId');

      // TODO remove
      // // TODO DEMO-2356 - this is temporary for testing BE report rewrite
      // const allowExperimentalReport = userManager.hasExperimentalAccessPermission;

      // if (allowExperimentalReport) {
      //   const isReportStandardsClassroomDetailStudents = ReportIdentityService.isReportStandardsClassroomDetailStudents();
      //   if (!reportId && isReportStandardsClassroomDetailStudents) {
      //     reportId = `${this.REPORT_IDS.standards.classroom}`;
      //   }
      //   if (this.isExperimentalReportToggled) {
      //     reportId += '_R';
      //     // special case because SIRIUS_COURSE_CLASSROOM_PERFORM_R has 33 characters and doesn't fit in the report.id column
      //     if (reportId === 'SIRIUS_COURSE_CLASSROOM_PERFORM_R') {
      //       reportId = 'SIRIUS_COURSE_CLASS_PERFORM_R';
      //     }
      //     // and more special cases
      //     if (reportId === 'GALL_STANDARDS_SCHOOL_CLICKBOOK_R') {
      //       reportId = 'GALL_STANDARDS_SCH_CLICKBOOK_R';
      //     }
      //     if (reportId === 'GALL_STANDARDS_SCHOOL_EXPERTRACK_R') {
      //       reportId = 'GALL_STANDARDS_SCH_EXPERTRACK_R';
      //     }
      //     if (reportId === 'SIRIUS_STANDARDS_SCHOOL_PERFORM_R') {
      //       reportId = 'SIRIUS_STANDARDS_SCH_PERFORM_R';
      //     }
      //   }
      // }

      if (!reportId) {
        throw new TypeError('ReportIdentityManager.fetchReportResponseJson: reportId is required');
      }

      const ReportCourseService = getRegisteredClass('ReportCourseService');
      const ReportStandardsService = getRegisteredClass('ReportStandardsService');

      const selectedReportCourseContentItemId = ReportCourseService.getSelectedReportCourseContentItemId();

      courseId = courseId || urlParams.get('courseId') || selectedReportCourseContentItemId;
      classroomId = classroomId || urlParams.get('classroomId');
      institutionId = institutionId || this.activeReportInstitutionId;

      if (reportId.includes('DIST') || reportId.includes('SCH')) {
        if (!institutionId) {
          throw new TypeError('ReportIdentityManager.fetchReportResponseJson: institutionId is required for admin report');
        }
        if (isStandardsReport && !courseId && !shouldUseCmapId) {
          throw new TypeError('ReportIdentityManager.fetchReportResponseJson: courseId is required for standards admin report');
        } else if (shouldUseCmapId && !curriculumMapId) {
          throw new TypeError('ReportIdentityManager.fetchReportResponseJson: curriculumMapId is required');
        } else if (isStandardsReport && curriculumMapId) {
          entityId = `${institutionId}${curriculumMapId}`;
        } else if (isStandardsReport) {
          entityId = `${institutionId}${courseId}`;
        } else {
          entityId = `${institutionId}`;
        }
      } else if (reportId.includes('CLASS') || ReportIdentityService.isCustomStandardsFilterReport(reportId)) {
        // TODO remove // specifying clickbook and expertrack is for the experimental _R reports and can be removed at a later date
        // TODO remove // || reportId.includes('STANDARDS_EXPERTRACK') || reportId.includes('STANDARDS_CLICKBOOK'))
        if (!classroomId) {
          throw new TypeError('ReportIdentityManager.fetchReportResponseJson: classroomId is required for classroom report');
        }
        entityId = `${classroomId}`;
      }

      const apiUrlPrefix = `${Auth.ecms}${REPORT_IDENTITY_ENDPOINTS.FETCH_REPORT_RESPONSE_JSON}`;

      const apiUrl = generateUrl(apiUrlPrefix, {
        entityId,
        reactErrorType: true,
        reportId
      });
      const response = await Auth.fetch(apiUrl, { method: 'GET' });

      if (response && response.status === 'SUCCESS') {
        this.setReportResponseJson(response);

        if (isCourseReport) {
          const { availableReportCourses, originalReportCourses } = ReportCourseService.getAvailableReportCourses(response);

          const hasLikert = !!availableReportCourses?.some?.((reportCourse) => {
            return reportCourse.hasLikert;
          });
          const hasConventional = !hasLikert || !!availableReportCourses?.some?.((reportCourse) => {
            return reportCourse.hasConventional;
          });

          this.setHasSomeLikertReportData(hasLikert);
          this.setHasSomeConventionalReportData(hasConventional);

          if (!!originalReportCourses?.length && !availableReportCourses?.length) {
            if (selectedReportCourseContentItemId) {
              this.setForceShowNoDataZeroState(true);
            }
          }
        } else {
          if (isStandardsReport) {
            const { LIKERT, PERFORMANCE } = REPORT_CONTEXT_TYPE;

            const hasAvailableReportLikertStandards = !!(ReportStandardsService.getAvailableReportStandards(response, {
              reportContextType: LIKERT
            })?.length);

            const hasAvailableReportConventionalStandards = !hasAvailableReportLikertStandards || !!(
              ReportStandardsService.getAvailableReportStandards(response, {
                reportContextType: PERFORMANCE
              })?.length
            );
            this.setHasSomeLikertReportData(hasAvailableReportLikertStandards);
            this.setHasSomeConventionalReportData(hasAvailableReportConventionalStandards);
          }
          this.setForceShowNoDataZeroState(false);
        }
      } else {
        throw new TypeError(JSON.stringify(response));
      }
    } catch (error) {
      // TODO remove // const ReportIdentityService = getRegisteredClass('ReportIdentityService');
      // TODO remove // ReportIdentityService.clearAllReportManagers();
      // TODO remove // ReportIdentityService.clearAllReportSessionStorage();

      const {
        FALLBACK,
        FALLBACK_AND_FORCE_NO_DATA_STATE,
        NORMAL,
        RETRY_WITH_DIFFERENT_COURSE_OR_CMAP,
        RETRY_WITH_PARSED_REPORT_ID_AND_FORCE_NO_DATA_STATE
      } = REPORT_FAILURE_BEHAVIOR_TYPE;
      if (!failureBehaviorType || failureBehaviorType === NORMAL || error?.message?.includes('NO report found')) {
        // TODO remove // this.setHasReportResponseJsonError(true);
        // TODO remove // console.error(error);

        const ReportJsonHelperService = getRegisteredClass('ReportJsonHelperService');
        const zeroStateReportResponseJson = ReportJsonHelperService.ZERO_STATE_REPORT_RESPONSE_JSON({ entityId });

        this.setReportResponseJson(zeroStateReportResponseJson);
        this.setForceShowNoDataZeroState(true);
      } else if (failureBehaviorType.includes(FALLBACK)) {
        const ReportTableService = getRegisteredClass('ReportTableService');
        ReportTableService.forceRerenderReportTable(oldReportResponseJson);
        if (failureBehaviorType === FALLBACK_AND_FORCE_NO_DATA_STATE) {
          this.setForceShowNoDataZeroState(true);
        }
      } else if (failureBehaviorType === RETRY_WITH_PARSED_REPORT_ID_AND_FORCE_NO_DATA_STATE) {
        await this.fetchReportResponseJson({
          ...options,
          reportId: ReportIdentityService.getReportIdFromParsedReportTypes()
        });
        this.setForceShowNoDataZeroState(true);
      } else if (failureBehaviorType === RETRY_WITH_DIFFERENT_COURSE_OR_CMAP) {
        if (this.isFacultyAdminReport && isStandardsReport) {
          const ReportOverrideService = getRegisteredClass('ReportOverrideService');
          const shouldUseCmapId = ReportOverrideService.shouldUseCmapId();
          if (shouldUseCmapId && curriculumMapId) {
            this.retryFetchStandardsReportResponseJsonWithDifferentCmap({ ...options });
          } else if (this.isFacultyAdminReport && courseId) {
            this.retryFetchCourseReportResponseJsonWithDifferentCourse({ ...options });
          }
        }
      }
    }
  }

  @action retryFetchStandardsReportResponseJsonWithDifferentCmap = async (options) => {
    // we are assuming here that we passed the first cmapId entry in reportCmapObjsByInstitutionMap
    // further, we are assuming the `api/viewReportOutput` request failed because the given cmapId was not associated with any report data
    const { curriculumMapId } = options;

    const ReportStandardsService = getRegisteredClass('ReportStandardsService');
    ReportStandardsService.removeCmapEntryFromMap(curriculumMapId);

    const differentFirstCmapId = ReportStandardsService.getFirstMappedCmapId();

    await this.fetchReportResponseJson({ ...options, curriculumMapId: differentFirstCmapId });
  }

  @action retryFetchCourseReportResponseJsonWithDifferentCourse = async (options) => {
    // we are assuming here that we passed the first id entry in reportCourseNamesByInstitutionMap
    // further, we are assuming the `api/viewReportOutput` request failed because the given id was not associated with any report data
    const { courseId } = options;

    const ReportCourseService = getRegisteredClass('ReportCourseService');
    ReportCourseService.removeCourseEntryFromMap(courseId);

    const differentFirstCourseId = ReportCourseService.getFirstMappedCourseId();

    await this.fetchReportResponseJson({ ...options, courseId: differentFirstCourseId });
  }

  @action setReportResponseJson = (response) => {
    this.reportResponseJson = response;
  }

  @action setHasReportResponseJsonError = (hasError) => {
    this.hasReportResponseJsonError = hasError;
  }

  @action setForceShowNoDataZeroState = (toggle) => {
    this.forceShowNoDataZeroState = toggle;
  }

  @action setHasSomeLikertReportData = (toggle) => {
    this.hasSomeLikertReportData = toggle;
  }

  @action setHasSomeConventionalReportData = (toggle) => {
    this.hasSomeConventionalReportData = toggle;
  }

  // TODO remove
  // @action setIsExperimentalReportToggled = (toggle) => {
  //   this.isExperimentalReportToggled = toggle;
  // }

  @action setLtiReportTable = (reportTable) => {
    this.ltiReportTable = reportTable;
  }

  @action setStudentId = (studentId) => {
    this.studentId = studentId;
  }

  @action setStudentFullName = (studentFullName) => {
    this.studentFullName = studentFullName;
  }

  @computed get activeReportId() {
    return this.reportResponseJson?.reportId;
  }

  @computed get activeReportEntityId() {
    return this.reportResponseJson?.entityId;
  }

  @computed get urlParamInstitutionId() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('institutionId');
  }

  @computed get activeReportInstitutionId() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('institutionId') || userManager.institutionId;
  }

  @computed get reportStartUrlKey() {
    if (this.activeReportType && this.initialActiveReportFacultyType) {
      return `/reports/${this.activeReportType}-${this.initialActiveReportFacultyType}-${REPORT_TABLE_TYPE.SUMMARY}`;
    } else {
      return undefined;
    }
  }

  @computed get reportInfoClassNames() {
    const { reportContextType } = reportContextManager;
    const { usageMode, useEnhancedUsageReports } = reportUsageManager;
    const enhancedUsageClassName = useEnhancedUsageReports ? ' enhanced-usage' : '';
    const usageModeClassName = useEnhancedUsageReports && usageMode ? ` ${kebabCase(usageMode)}` : '';
    return `report ${this.activeReportType} ${this.activeReportFacultyType} ${this.activeReportTableType} ${reportContextType}` +
      `${enhancedUsageClassName}${usageModeClassName}`;
  }

  @computed get reportLastModified() {
    return this.reportResponseJson?.timezoneReportDate;
  }

  @computed get reportFacultyName() {
    const facultyNameKey = this.activeReportFacultyType ? `${this.activeReportFacultyType}Name` : undefined;
    if (facultyNameKey) {
      const ReportJsonHelperService = getRegisteredClass('ReportJsonHelperService');
      let facultyName = ReportJsonHelperService.REPORT_CONTENT_JSON_DATA()[facultyNameKey] || '';

      const ReportIdentityService = getRegisteredClass('ReportIdentityService');
      const fromAdminClassroomsView = ReportIdentityService.userCameFromAdminClassroomsView();

      if (!facultyName && fromAdminClassroomsView) {
        facultyName = getSessionStorageItem('adminClassroomReportFacultyName') || '';
        return facultyName;
      }
      return facultyName;
    } else {
      return '';
    }
  }

  @computed get activeReportScorePropName() {
    const {
      isContextEngagementReport,
      isContextLikertReport,
      isContextPerformanceReport,
      isContextProgressReport,
      isContextUsageReport
    } = reportContextManager;

    const { isFacultyAdminReport, isStandardsReport } = this;

    if (isContextPerformanceReport) {
      if (isStandardsReport) {
        return 'averageScore';
      } else {
        return 'grade';
      }
    } else if (isContextLikertReport) {
      if (isStandardsReport) {
        return 'averageScore';
      } else {
        return 'likertGrade';
      }
    } else if (isContextUsageReport) {
      if (isFacultyAdminReport) {
        return 'averageViewedTime';
      } else {
        return 'viewedTime';
      }
    } else if (isContextProgressReport) {
      return 'progressScore';
    } else if (isContextEngagementReport) {
      return 'engagement';
    } else {
      return undefined;
    }
  }

  @computed get hasArchivedFacultyClassroomReportStatus() {
    return this.reportClassroomStatus === 'archived';
  }

  @computed get reportClassroomStatus() {
    const urlParams = new URLSearchParams(window.location.search);
    const classroomStatus = urlParams.get('classroomStatus');
    return classroomStatus;
  }

  @computed get isReport() {
    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');
    return view === VIEW_SELECTION.REPORTS;
  }

  @computed get isCourseReport() {
    return this.activeReportType === REPORT_TYPE.COURSE;
  }

  @computed get isStandardsReport() {
    return this.activeReportType === REPORT_TYPE.STANDARDS;
  }

  @computed get activeReportType() {
    const { activeReportId } = this;
    if (activeReportId?.toLowerCase().includes(REPORT_TYPE.COURSE)) {
      return REPORT_TYPE.COURSE;
    } else if (activeReportId?.toLowerCase().includes(REPORT_TYPE.STANDARDS)) {
      return REPORT_TYPE.STANDARDS;
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('reportType');
    }
  }

  @computed get isFacultyAdminReport() {
    return (
      this.activeReportFacultyType === REPORT_FACULTY_TYPE.DISTRICT ||
      this.activeReportFacultyType === REPORT_FACULTY_TYPE.SCHOOL
    );
  }

  @computed get isFacultyDistrictReport() {
    return this.activeReportFacultyType === REPORT_FACULTY_TYPE.DISTRICT;
  }

  @computed get isFacultySchoolReport() {
    return this.activeReportFacultyType === REPORT_FACULTY_TYPE.SCHOOL;
  }

  @computed get isFacultyClassroomReport() {
    return this.activeReportFacultyType === REPORT_FACULTY_TYPE.CLASSROOM;
  }

  @computed get activeReportFacultyType() {
    const { activeReportId } = this;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('reportFacultyType')) {
      return urlParams.get('reportFacultyType');
    } else if (typeof activeReportId !== 'string') {
      return this.initialActiveReportFacultyType;
    } else if (activeReportId.includes('DIST')) {
      return REPORT_FACULTY_TYPE.DISTRICT;
    } else if (activeReportId.includes('SCH')) {
      return REPORT_FACULTY_TYPE.SCHOOL;
    } else if (activeReportId.includes('CLASS') || this.isCustomStandardsFilterReport) {
      return REPORT_FACULTY_TYPE.CLASSROOM;
    } else {
      return undefined;
    }
  }

  @computed get initialActiveReportFacultyType() {
    const { activePermissionId } = userManager;
    if (activePermissionId?.includes('district')) {
      return REPORT_FACULTY_TYPE.DISTRICT;
    } else if (activePermissionId?.includes('school')) {
      return REPORT_FACULTY_TYPE.SCHOOL;
    } else if (activePermissionId?.includes('teacher')) {
      return REPORT_FACULTY_TYPE.CLASSROOM;
    } else {
      return undefined;
    }
  }

  @computed get isCustomStandardsFilterReport() {
    const ReportIdentityService = getRegisteredClass('ReportIdentityService');
    return ReportIdentityService.isCustomStandardsFilterReport(this.activeReportId);
  }

  @computed get isTableSummaryReport() {
    return this.activeReportTableType === REPORT_TABLE_TYPE.SUMMARY;
  }

  @computed get isTableDetailReport() {
    return this.activeReportTableType === REPORT_TABLE_TYPE.DETAIL;
  }

  @computed get isTableIndividualReport() {
    return this.activeReportTableType === REPORT_TABLE_TYPE.INDIVIDUAL;
  }

  @computed get activeReportTableType() {
    const { pathname } = window.location;
    if (!pathname || (!pathname.includes('reports') && !pathname.includes('/lti-courses'))) {
      return null;
    } else if (pathname.includes(REPORT_TABLE_TYPE.SUMMARY)) {
      return REPORT_TABLE_TYPE.SUMMARY;
    } else if (pathname.includes(REPORT_TABLE_TYPE.DETAIL)) {
      return REPORT_TABLE_TYPE.DETAIL;
    } else if (pathname.includes(REPORT_TABLE_TYPE.INDIVIDUAL)) {
      return REPORT_TABLE_TYPE.INDIVIDUAL;
    } else if (pathname.includes('/lti-courses')) {
      return this.ltiReportTable;
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('reportTableType');
    }
  }

  @computed get ltiTableTypePathname() {
    if (this.ltiReportTable === (REPORT_TABLE_TYPE.SUMMARY)) {
      return '/reports/standards-classroom-summary';
    } else if (this.ltiReportTable === (REPORT_TABLE_TYPE.DETAIL)) {
      return '/reports/standards-classroom-detail';
    } else if (this.ltiReportTable === (REPORT_TABLE_TYPE.INDIVIDUAL)) {
      return '/reports/standards-classroom-individual';
    } else {
      return '/reports/standards-classroom-summary';
    }
  }

  @computed get REPORT_IDS() {
    const flatReportIds = this.FLAT_REPORT_IDS;
    return {
      course: {
        district: flatReportIds.COURSE_DISTRICT_PERFORM,
        school: flatReportIds.COURSE_SCHOOL_PERFORM,
        classroom: flatReportIds.COURSE_CLASS_PERFORM
      },
      standards: {
        district: flatReportIds.STANDARDS_DIST_PERFORM,
        school: flatReportIds.STANDARDS_SCHOOL_PERFORM,
        classroom: flatReportIds.STANDARDS_CLASS_PERFORM,
        filters: {
          // intended for gallopade only (at the moment)
          clickbook: {
            district: flatReportIds.STANDARDS_DIST_CLICKBOOK,
            school: flatReportIds.STANDARDS_SCHOOL_CLICKBOOK,
            classroom: flatReportIds.STANDARDS_CLICKBOOK
          },
          // intended for gallopade only (at the moment)
          expertrack: {
            district: flatReportIds.STANDARDS_DIST_EXPERTRACK,
            school: flatReportIds.STANDARDS_SCHOOL_EXPERTRACK,
            classroom: flatReportIds.STANDARDS_EXPERTRACK
          },
          // intended for sirius only (at the moment)
          allContent: {
            assessment: flatReportIds.STANDARDS_ASSESSMENT,
            instruction: flatReportIds.STANDARDS_INSTRUCTION,
            practice: flatReportIds.STANDARDS_PRACTICE
          }
          // TODO remove (unused, defunct)
          // prerequisite: {
          //   allAssignments: flatReportIds.STANDARDS_PREREQ,
          //   assessment: flatReportIds.STANDARDS_PREREQ_ASSESS,
          //   instruction: flatReportIds.STANDARDS_PREREQ_INSTRUC,
          //   practice: flatReportIds.STANDARDS_PREREQ_PRAC
          // },
          // onlevel: {
          //   allAssignments: flatReportIds.STANDARDS_ONLEVEL,
          //   assessment: flatReportIds.STANDARDS_ONLEVEL_ASSESS,
          //   instruction: flatReportIds.STANDARDS_ONLEVEL_INSTRUC,
          //   practice: flatReportIds.STANDARDS_ONLEVEL_PRAC
          // }
        }
      }
    };
  }

  @computed get REPORT_EXPORT_IDS() {
    const flatReportExportIds = this.FLAT_REPORT_EXPORT_IDS;
    return {
      course: {
        district: flatReportExportIds.COURSE_DISTRICT_PERFORM_X,
        school: flatReportExportIds.COURSE_SCHOOL_PERFORM_X,
        classroom: flatReportExportIds.COURSE_CLASS_PERFORM_X
      },
      standards: {
        district: flatReportExportIds.STANDARDS_DIST_PERFORM_X,
        school: flatReportExportIds.STANDARDS_SCHOOL_PERFORM_X,
        classroom: flatReportExportIds.STANDARDS_CLASS_PERFORM_X
      }
    };
  }

  @computed get FLAT_REPORT_IDS() {
    const { clientPrefix } = userManager;
    const flatReportIds = {
      COURSE_DISTRICT_PERFORM: `${clientPrefix}_COURSE_DISTRICT_PERFORM`,
      COURSE_SCHOOL_PERFORM: `${clientPrefix}_COURSE_SCHOOL_PERFORM`,
      COURSE_CLASS_PERFORM: `${clientPrefix}_COURSE_CLASS_PERFORM`,
      STANDARDS_DIST_PERFORM: `${clientPrefix}_STANDARDS_DIST_PERFORM`,
      STANDARDS_SCHOOL_PERFORM: `${clientPrefix}_STANDARDS_SCHOOL_PERFORM`,
      STANDARDS_CLASS_PERFORM: `${clientPrefix}_STANDARDS_CLASS_PERFORM`,
      // intended for gallopade only (at the moment) ----------------------------
      STANDARDS_DIST_CLICKBOOK: `${clientPrefix}_STANDARDS_DIST_CLICKBOOK`,
      STANDARDS_DIST_EXPERTRACK: `${clientPrefix}_STANDARDS_DIST_EXPERTRACK`,
      STANDARDS_SCHOOL_CLICKBOOK: `${clientPrefix}_STANDARDS_SCHOOL_CLICKBOOK`,
      STANDARDS_SCHOOL_EXPERTRACK: `${clientPrefix}_STANDARDS_SCHOOL_EXPERTRACK`,
      STANDARDS_CLICKBOOK: `${clientPrefix}_STANDARDS_CLICKBOOK`,
      STANDARDS_EXPERTRACK: `${clientPrefix}_STANDARDS_EXPERTRACK`,
      // intended for sirius only (at the moment) -------------------------------
      STANDARDS_ASSESSMENT: `${clientPrefix}_STANDARDS_ASSESSMENT`,
      STANDARDS_INSTRUCTION: `${clientPrefix}_STANDARDS_INSTRUCTION`,
      STANDARDS_PRACTICE: `${clientPrefix}_STANDARDS_PRACTICE`
      // TODO remove (unused, defunct)
      // STANDARDS_ONLEVEL: `${clientPrefix}_STANDARDS_ONLEVEL`,
      // STANDARDS_ONLEVEL_ASSESS: `${clientPrefix}_STANDARDS_ONLEVEL_ASSESS`,
      // STANDARDS_ONLEVEL_INSTRUC: `${clientPrefix}_STANDARDS_ONLEVEL_INSTRUC`,
      // STANDARDS_ONLEVEL_PRAC: `${clientPrefix}_STANDARDS_ONLEVEL_PRAC`,
      // STANDARDS_PREREQ: `${clientPrefix}_STANDARDS_PREREQ`,
      // STANDARDS_PREREQ_ASSESS: `${clientPrefix}_STANDARDS_PREREQ_ASSESS`,
      // STANDARDS_PREREQ_INSTRUC: `${clientPrefix}_STANDARDS_PREREQ_INSTRUC`,
      // STANDARDS_PREREQ_PRAC: `${clientPrefix}_STANDARDS_PREREQ_PRAC`
    };
    if (clientPrefix === 'SIRIUS') {
      return {
        ...flatReportIds,
        COURSE_DISTRICT_PERFORM: `${clientPrefix}_COURSE_DIST_PERFORM`,
        COURSE_SCHOOL_PERFORM: `${clientPrefix}_COURSE_SCH_PERFORM`,
        COURSE_CLASS_PERFORM: `${clientPrefix}_COURSE_CLASSROOM_PERFORM`
      };
    }
    if (clientPrefix === 'GW') {
      return {
        ...flatReportIds,
        COURSE_DISTRICT_PERFORM: `${clientPrefix}_COURSE_DIST_PERFORM`,
        COURSE_SCHOOL_PERFORM: `${clientPrefix}_COURSE_SCH_PERFORM`,
        COURSE_CLASS_PERFORM: `${clientPrefix}_COURSE_CLASS_PERFORM`
      };
    }
    return flatReportIds;
  }

  @computed get FLAT_REPORT_EXPORT_IDS() {
    const { clientPrefix } = userManager;
    const flatReportExportIds = {
      COURSE_CLASS_PERFORM_X: `${clientPrefix}_COURSE_CLASS_PERFORM_X`,
      COURSE_DISTRICT_PERFORM_X: `${clientPrefix}_COURSE_DISTRICT_PERFORM_X`,
      COURSE_SCHOOL_PERFORM_X: `${clientPrefix}_COURSE_SCHOOL_PERFORM_X`,
      STANDARDS_CLASS_PERFORM_X: `${clientPrefix}_STANDARDS_CLASS_PERFORM_X`,
      STANDARDS_DIST_PERFORM_X: `${clientPrefix}_STANDARDS_DIST_PERFORM_X`,
      STANDARDS_SCHOOL_PERFORM_X: `${clientPrefix}_STANDARDS_SCHOOL_PERFORM_X`,
    };
    if (clientPrefix === 'SIRIUS') {
      return {
        ...flatReportExportIds,
        COURSE_DISTRICT_PERFORM_X: `${clientPrefix}_COURSE_DIST_PERFORM_X`,
        COURSE_SCHOOL_PERFORM_X: `${clientPrefix}_COURSE_SCH_PERFORM_X`,
        COURSE_CLASS_PERFORM_X: `${clientPrefix}_COURSE_CLASS_PERFORM_X`,
        STANDARDS_SCHOOL_PERFORM_X: `${clientPrefix}_STANDARDS_SCH_PERFORM_X`
      };
    }
    if (clientPrefix === 'GW') {
      return {
        ...flatReportExportIds,
        COURSE_DISTRICT_PERFORM_X: `${clientPrefix}_COURSE_DIST_PERFORM_X`,
        COURSE_SCHOOL_PERFORM_X: `${clientPrefix}_COURSE_SCH_PERFORM_X`,
        COURSE_CLASS_PERFORM_X: `${clientPrefix}_COURSE_CLASS_PERFORM_X`
      };
    }
    return {
      ...flatReportExportIds,
      ...this.SPECIAL_FLAT_REPORT_EXPORT_IDS
    };
  }

  /**
   * Special Report Export ID cases
   *
   * i.e. exports that need to be selected manually by radio option and/or only available for certain satellites.
   */
  @computed get SPECIAL_FLAT_REPORT_EXPORT_IDS() {
    const { clientPrefix } = userManager;
    const specialFlatReportExportIds = {
      // School Detail (By Student)
      COURSE_SCH_ST_PERFORM_X: `${clientPrefix}_COURSE_SCH_ST_PERFORM_X`,
      STANDARDS_SCH_ST_PERF_X: `${clientPrefix}_STANDARDS_SCH_ST_PERF_X`,
      // Classroom Report (Scores Only)
      COURSE_CLASS_PERFORM_S_X: `${clientPrefix}_COURSE_CLASS_PERFORM_S_X`,
      STANDARDS_CLASS_PERFORM_S_X: `${clientPrefix}_STANDARDS_CLASS_PERFORM_S_X`,
      // Classroom Report (Points Only)
      COURSE_CLASS_PERFORM_P_X: `${clientPrefix}_COURSE_CLASS_PERFORM_P_X`
    };
    return specialFlatReportExportIds;
  }
}

export default new ReportIdentityManager();
