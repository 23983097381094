import { registerClass } from '../../SatCoreRegistry';

import productManager from '../../managers/ProductManager';
import userManager from '../../managers/UserManager';

export default class GradebookMainService {
  /** Return `true | false` based on whether Gradebook should be 'read only' or not. */
  static isGradebookReadOnly = () => {
    return userManager.isDistrictOrSchoolAdmin || productManager.shouldShowExpiredLicenseWarning;
  }
}

registerClass('GradebookMainService', GradebookMainService);
