import React, { useContext } from 'react';
import { MobXProviderContext, observer } from 'mobx-react';

import classNames from 'classnames';

import {
  Button, Image, Popup, Segment
} from 'semantic-ui-react';

import iconTrash from '../img/icon-trash-can.svg';

import { CONTENT_ITEM_TYPES } from '../managers/ContentManager';

import customCourseDefaultCardImage from '../img/image-custom-course-special.png';
import courseDefaultCardImage from '../img/default-course.svg';

import { SatCoreComponent, SatCoreRegister } from '../SatCoreRegistry';

import PopupService from '../services/PopupService';
import UtilityService from '../services/UtilityService';

import { renderButton, scrollBrowserWindowToTop, stripHtmlTags } from '../utils';

const CourseCard = observer((props) => {
  const {
    classroomManager, courseManager, productManager, userManager
  } = useContext(MobXProviderContext);

  const CautionIcon = SatCoreComponent('CautionIcon');

  const {
    course, disabled, handleAddCourseToClass, handleCardClick,
    handleCourseCardRemove, handleCourseCardRename, imageUrl, handleReadOnlyPreview,
    hideTypicalCardNavButtons, showReadOnlyPreviewButton,
    openKey, showTrashIcon, t
  } = props;

  let image;
  if (!course.imageUrl) {
    image = null;
  } else {
    image = <Image alt={course?.altText ? course.altText : null} fluid src={CONTENT_ITEM_TYPES.getContentImageUrl(imageUrl)} />;
  }

  /* COURSE CARD NAME */
  const courseNameObj = UtilityService.reactHtmlParserWrapper(
    course.name
  );

  const actualCourseName = course?.name ? stripHtmlTags(course.name) : '';
  const actualCourseDescription = course?.description ? stripHtmlTags(course.description) : '';

  let strippedCourseNameContent = `${actualCourseName}`;
  if (props.isFromCourseShop) {
    strippedCourseNameContent += actualCourseDescription ? ` | ${actualCourseDescription}` : '';
  }

  const courseNamePopupJsx = PopupService.renderPopup({
    content: strippedCourseNameContent,
    mouseEnterDelay: 300,
    position: 'top left',
    trigger: (
      <div className='course-name clickable'
        onClick={() => handleCardClick(course)}>
        {courseNameObj.parsed}
      </div>
    )
  });

  /* COURSE CARD DESCRIPTION / PRODUCT NAME */
  const courseDescriptionObj = UtilityService.reactHtmlParserWrapper(
    props.description || (course.description && !props.isFromCourseShop ? course.description : null) || undefined
  );
  let strippedCourseDescriptionContent = '';
  if (props.isFromCourseShop) {
    strippedCourseDescriptionContent = props.description || '';
  } else {
    strippedCourseDescriptionContent = actualCourseDescription || '';
  }
  const courseDescriptionPopupJsx = strippedCourseDescriptionContent ? (
    PopupService.renderPopup({
      content: strippedCourseDescriptionContent,
      mouseEnterDelay: 300,
      position: 'bottom left',
      trigger: (
        <div className='course-description-text subtitle'>
          {courseDescriptionObj.parsed}
        </div>
      )
    })
  ) : null;

  /* COURSE CARD SECONDARY DESCRIPTION (e.g. productName if applicable) */
  const courseSecondaryDescriptionPopupJsx = props.secondaryDescription ? PopupService.renderPopup({
    content: props.secondaryDescription,
    mouseEnterDelay: 300,
    offset: [0, 11],
    position: 'bottom left',
    trigger: (
      <div className='course-secondary-description-text'>
        {props.secondaryDescription}
      </div>
    )
  }) : null;

  let cardStyle = {};
  const useSpecialBackgroundColor = disabled && userManager.clientPrefix === 'SIRIUS';
  if (useSpecialBackgroundColor) {
    cardStyle = {
      backgroundColor: '#a3b6d8',
      opacity: '1',
    };
  } else if (disabled) {
    cardStyle = {
      opacity: '0.5'
    };
  }

  const isCustomCourse = courseManager.isCustomCourse(course.id, course.name);

  let courseCardImageWrapperClassNames = 'course-card-image-wrapper';

  if (!useSpecialBackgroundColor && isCustomCourse) {
    courseCardImageWrapperClassNames += ' custom-default';
  }
  let courseCardImageClassNames = 'course-card-image';
  if (!image) {
    courseCardImageClassNames += ' default';
    if (!useSpecialBackgroundColor && isCustomCourse) {
      courseCardImageClassNames += ' custom-default';
    }
  }
  let defaultCardImg;
  if (isCustomCourse && courseManager.useSpecialCustomCourseCardImages) {
    defaultCardImg = customCourseDefaultCardImage;
  } else {
    defaultCardImg = courseDefaultCardImage;
  }
  const isLeadTeacher = classroomManager.isLeadTeacher(classroomManager.currentClassroomId, userManager.userId);

  return (
    <>
      <Segment
        key={`course_card_${course.id}`}
        className={classNames('course-card-satcore clickable list-card', {
          'course-card-satcore-disabled': disabled
        })}
        id={props.id || undefined}
        loading={productManager.loadingProductDropdown}
        style={cardStyle}>
        <div
          className={courseCardImageWrapperClassNames}
          onClick={() => {
            handleCardClick(course);
          }}>
          <div className={courseCardImageClassNames}>
            {(image === null) ? <Image src={defaultCardImg} wrapped /> : image}
          </div>
        </div>
        <div className='card-body'>
          <div className='course-card-title-wrapper'>
            <div className='course-card-title-name'>
              {courseNamePopupJsx}
            </div>
            {!!course.licenseExpired && (
              !userManager.isStudent || productManager.allowShowExpiredLicenseWarningForStudent
            ) && (
              <div className='course-card-title-caution-icon'>
                <CautionIcon expiredLicenseCautionIcon />
              </div>
            )}
          </div>
          {courseDescriptionPopupJsx}
          {courseSecondaryDescriptionPopupJsx}
        </div>
        {(isCustomCourse && hideTypicalCardNavButtons && showTrashIcon && !disabled) && (
          <div className='card-nav-buttons remove-btn-wrapper'>
            <Button
              basic
              className='custom-card-remove-btn'
              onClick={() => { handleCourseCardRemove(course); }}
              primary
              type='button'>
              <Image src={iconTrash} />
            </Button>
          </div>
        )}
        {showReadOnlyPreviewButton && (
          <div className='card-nav-buttons'>
            <Button className='read-only-preview-btn'
              onClick={() => handleReadOnlyPreview(course)} primary>
              {t('readOnlyPreview')}
            </Button>
          </div>
        )}
        {!hideTypicalCardNavButtons && (
        <div className={classNames('card-nav-buttons course-card-nav-buttons', {
          'product-course-card-nav-buttons': productManager.FROM_TEACHER_PRODUCTS_NAV
        })}>
          <Button
            aria-label={`Open ${courseNameObj.stripped}`}
            className={classNames('course-view-button', {
              'product-course-button': productManager.FROM_TEACHER_PRODUCTS_NAV,
              'product-course-view-button': productManager.FROM_TEACHER_PRODUCTS_NAV
            })}
            onClick={() => { handleCardClick(course); }}
            primary
            type='button'>
            {t(openKey || 'open')}
          </Button>
          {productManager.FROM_TEACHER_PRODUCTS_NAV && (
            renderButton({
              additionalClassNames: 'product-course-button button-primary-bg',
              buttonKey: 'addToClass',
              onClick: (event) => {
                scrollBrowserWindowToTop();
                handleAddCourseToClass(event, course);
              },
              primary: true
            })
          )}
          {!productManager.FROM_TEACHER_PRODUCTS_NAV && (
            isLeadTeacher ? (
              <Button
                basic
                className='course-remove-button'
                onClick={() => handleCourseCardRemove(course)}
                primary
                type='button'>
                {t('remove')}
              </Button>
            ) :
              userManager.isTeacher && (
              <Popup
                className='delete-permission-msg'
                content={(
                  <div className='pop-text'>
                    <div>{t('courseDeletePermissionMsg', 'You do not have permission to remove this course.')}</div>
                  </div>
                )}
                hoverable
                on='hover'
                position='bottom center'
                trigger={(
                  <Button className='course-remove-button pseudo-disabled' primary type='button'>
                    {t('remove')}
                  </Button>
                )} />
              )
          )}
          {(isCustomCourse) && (
          <Button
            basic
            className='course-remove-button'
            onClick={() => handleCourseCardRename(course)}
            primary
            type='button'>
            Rename
          </Button>
          )}
        </div>
        )}
      </Segment>
    </>
  );
});
export default CourseCard;

SatCoreRegister('CourseCard', CourseCard);
